import '../ProductReport.css';
import React, { useState, useEffect, useRef } from 'react';
import { useForm } from "react-hook-form";
import { Dropdown } from 'bootstrap';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import GreenFieldService from '../../services/greenFieldService';
import ToolBoxGrid from '../Misc/ToolBoxGrid';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
    import { Toast } from 'primereact/toast';
    import ProgressSpinBarControl from '../Misc/progressSpinBarControl';



var receivedStockSum;
var pendingStockSum;
var remainingStockSum;
var returnStockSum;
var returnToAdminSum;
var soldStockSum;
var stockTotal;


function FranchiesStockReport() {
    const greenFieldService = new GreenFieldService();
    const [date3, setDate3] = useState(null);
    
    const {
        register: Filter,
        errors: errors1,
        handleSubmit: handleFilter,
        setValue: setFilterValue,
    } = useForm();
    const [filters1, setFilters1] = useState(null);
    const [globalFilter, setGlobalFilter] = useState();
    const franchiseId = localStorage.getItem('franchiseId');
    const [stocksData, setStocksData] = useState([]);
    const [columns, setColumns] = useState([{ title: 'Sr No', dataKey: 'serialNo' }, { title: 'Product Name', dataKey: 'productName' }, { title: 'Received Stock', dataKey: 'receivedStock' }, { title: 'Pending Stock', dataKey: 'pendingStock' }, { title: 'Return Stock', dataKey: 'returnStock' },
    { title: 'Sold Stock', dataKey: 'soldStock' }, { title: 'Ramaining Stock', dataKey: 'remainingStock' }]);
    const dt = useRef(null);
    const toast = useRef(null);
    const [spinnerVisible, setSpinnerVisible] = useState(false);



    useEffect(() => {
       setSpinnerVisible(true);
        greenFieldService.GetFranchiseStockDetails(franchiseId).then(data => {
            let srNo = 1;
            data.forEach(a => {
                a.serialNo = srNo;
                srNo++;
            })
            receivedStockSum = (data.reduce((a, v) => a + parseFloat(v.receivedStock), 0));
            pendingStockSum = (data.reduce((a, v) => a + parseFloat(v.pendingStock), 0));
            returnStockSum = (data.reduce((a, v) => a + parseFloat(v.returnStock), 0));
            remainingStockSum = (data.reduce((a, v) => a + parseFloat(v.remainingStock), 0));
            returnToAdminSum = (data.reduce((a, v) => a + parseFloat(v.returnToAdminStock), 0));
            soldStockSum = (data.reduce((a, v) => a + parseFloat(v.soldStock), 0));

            setStocksData(data);
        }).catch(error => {
            toast.current.show({ severity: 'error', sticky: true, summary: 'Error', detail: error });
        })
        .finally(() => {
      
            setSpinnerVisible(false);
                        //getFarmerComplaints();
           // toast.current.clear();
        })

        initFilters1();
    }, []);

    const onFilter = (filterValue) => {
        let data = stocksData.filter(a => a.addedDateFormat >= filterValue.fyDate && a.addedDateFormat <= filterValue.toDate);
        let srNo = 1;
        receivedStockSum = (data.reduce((a, v) => a + parseFloat(v.receivedStock), 0));
        pendingStockSum = (data.reduce((a, v) => a + parseFloat(v.pendingStock), 0));
        returnStockSum = (data.reduce((a, v) => a + parseFloat(v.returnStock), 0));
        remainingStockSum = (data.reduce((a, v) => a + parseFloat(v.remainingStock), 0));
        returnToAdminSum = (data.reduce((a, v) => a + parseFloat(v.returnToAdminStock), 0));
        soldStockSum = (data.reduce((a, v) => a + parseFloat(v.soldStock), 0));
        data.forEach(d => {
            d.serialNo = srNo;
            srNo++;
        })
        setStocksData(data);

    }

    const onChangeGlobal = (e) => {
        let value = e.target.value;
        let _filters1 = { ...filters1 };
        _filters1['global'].value = value;

        setFilters1(_filters1);

        setGlobalFilter(value);
    }

    const initFilters1 = () => {
        setFilters1({
            'global': { value: null, matchMode: FilterMatchMode.CONTAINS },

        });
        setGlobalFilter('');
    }

    const onReset = () => {

        const fields = [
            "fyDate",
            "toDate"
        ];
        fields.forEach((field) => setFilterValue(field, ''));
    }
    let totalFooter = <ColumnGroup>
        <Row>
            <Column footer="Totals:" footerStyle={{ textAlign: 'right' }} />
            <Column footer='' />
            <Column footer={receivedStockSum} />
            <Column footer={pendingStockSum}  />
            <Column footer={returnStockSum}  />
            <Column footer={returnToAdminSum} />
            <Column footer={soldStockSum} />
            <Column footer={remainingStockSum} />
        </Row>
    </ColumnGroup>;


    const header = (
        <div className="table-header">
            <div>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" class="globleInput" value={globalFilter} onChange={onChangeGlobal} placeholder="Global Search" />
                </span>
            </div>
        </div>
    );

    return (
        <div className="ProductReport">
            <h3>Franchise Stock Details</h3>
            <ProgressSpinBarControl spinBarVisible={spinnerVisible} />
            <Toast ref={toast} position={'center'} />
            <div className="Unit" style={{ minHeight: '1029px' }}>
                <div className="box">
                    <div class="field col">
                        <label>From:</label>
                        <input type='date' name='fyDate' className='date' ref={Filter} />
                        <label>To:</label>
                        <input type='date' name='toDate' className='date' ref={Filter} />
                        <button type="button filter" className='filter' onClick={handleFilter(onFilter)}>Filter</button>
                        <button type="button" className='reset' onClick={onReset()}>Reset</button>
                    </div>
                </div>

                <div className='box'>
                    {/* <div>
                        <button type='button'>Copy</button>
                        <button type='button'>CSV</button>
                        <button type='button'>Excel</button>
                        <button type='button'>PDF</button>
                        <button type='button'>Print</button>
                    </div> */}
                    <ToolBoxGrid columns={columns} data={stocksData} fileName="products"></ToolBoxGrid>
                    <DataTable ref={dt} value={stocksData} className='dataTable' header={header} footerColumnGroup={totalFooter} filters={filters1} editMode='row'>
                        <Column field="serialNo" header="Sr No"></Column>
                        <Column field="productName" header="Product Name"></Column>
                        <Column field="receivedStock" header="Received Stock"></Column>
                        <Column field="pendingStock" header="Pending Stock"></Column>
                        <Column field="returnStock" header="Customer Return Stock"></Column>
                        <Column field="returnToAdminStock" header="Return To Admin Stock"></Column>
                        <Column field="soldStock" header="Sold Stock"></Column>
                        <Column field="remainingStock" header="Remaining Stock"></Column>
                    </DataTable>
                </div>
            </div>

        </div>
    );
}
export default FranchiesStockReport;