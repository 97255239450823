import React, { useState, useRef } from 'react';
import { useForm } from "react-hook-form";
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { useEffect } from 'react';
import { InputTextarea } from 'primereact/inputtextarea';
import GreenFieldService from '../services/greenFieldService';
import './Complaints/FranchiseComplaints.css';
import axios from 'axios';
import { Toast } from 'primereact/toast';
import Config from '../Config.json';
import ToolBoxGrid from './Misc/ToolBoxGrid';
import { BlockUI } from 'primereact/blockui';
import authHeader from '../authHeader';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { useHistory } from 'react-router-dom';

function AdminOrderRequest() {
    const { register, handleSubmit, reset, setValue } = useForm();
    const {
        register: Filter,
        errors: errors1,
        handleSubmit: handleFilter,
        setValue: setFilterValue,
    } = useForm();
    const toast = useRef(null);
    const [globalFilter, setGlobalFilter] = useState();
    const [initialReturnData, setInitialRetunData] = useState([]);
    const [isForm, setIsForm] = useState(false);
    const greenFieldService = new GreenFieldService();
    const [orderRequest, setOrderRequest] = useState('');
    const [orderRequestData, setOrderRequestData] = useState([]);
    const [orderRequestOriginalData, setOrderRequestOriginalData] = useState([]);
    const franchiseId = localStorage.getItem('franchiseId');
    const franchiseName = localStorage.getItem('FranchiseName');
    const [selectedOrderRequests, setSelectedOrderReqests] = useState([]);
    const [blockedPanel, setBlockedPanel] = useState(false);
    var accessLevel = parseInt(sessionStorage.getItem('accessLevel'));
    const [filters1, setFilters1] = useState(null);
    const [expandedRows, setExpandedRows] = useState(null);
    const [updatedData, setUpdatedData] = useState([]);
    const [disable, setDisable] = useState(false);
    const [expandedCount, setExpandedCount] = useState(0);
    const [printRowData, setPrintRowData] = useState();
    let history = useHistory();
    const [columns,setColumns]=useState([{ title: 'sr.No', dataKey: 'srNo' },{ title: 'Franchise Name', dataKey: 'franchiseName' },{ title: 'Order Number', dataKey: 'fullOrderNo' },{ title: 'Order Date', dataKey: 'orderDateFormat' },{ title: 'Total Quantity', dataKey: 'quantity' }]);



    const header = (
        <div className="table-header">
            <div>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" class="globleInput" value={globalFilter} onChange={(e) => setGlobalFilter(e.target.value)} placeholder="Global Search" />
                </span>
            </div>
        </div>
    );

    const Button = (
        <div>
            <button className='filter'>View & Print</button>
        </div>
    )

    const getOrderRequests = (() => {

        greenFieldService.getOrderRequests(-1).then(data => {

            let srNo = 1;
            data.forEach(item => {
                item.srNo = srNo++;
                item.orderDate = item.orderDate.replace('T', ' ');
                item.orderDateValue = new Date(item.orderDate);

                let newSrNo = 1;
                item.orderDetails.forEach(a => {
                    a.srNo = newSrNo++;
                })

                let anyConfirm = item.orderDetails.findIndex(a => a.dispatchQuantity > 0 );
            if (anyConfirm >= 0) {
                item.statusColor = "Confirmed";
            }
            else {
                item.statusColor = "Pending";
            }
            }
            

            )
            setOrderRequestData(data);
            setOrderRequestOriginalData(data);
        })
    })


    useEffect(() => {
        getOrderRequests();

        // if (accessLevel > 1) {
        //     setBlockedPanel(false);
        // }
        // else {
        //     setBlockedPanel(true);
        // }

    }, [])
    
    const onFilter = (filterValue) => {
        let fromdate = new Date(filterValue.fyDate);
        fromdate.setDate(fromdate.getDate() - 1);
        let toDate = new Date(filterValue.toDate);

        let data = orderRequestOriginalData.filter(a => a.orderDateValue >= fromdate && a.orderDateValue <= toDate);
        let srNo = 1;
        data.forEach(d => {
            d.serialNo = srNo;
            srNo++;
        })
        // setProductsData(data);
        setOrderRequestData(data);
    }

    const onReset = () => {
        const fields = [
            "fyDate",
            "toDate"
        ];
        fields.forEach((field) => setFilterValue(field, ''));

        setOrderRequestData(orderRequestOriginalData);
    }
    const onBack = () => {
        setIsForm(false);

    }
    const onSave = (data) => {
        data.orderRequest = orderRequest;
        console.warn(data);
        data.franchiseId = parseInt(franchiseId);
        axios.post(`${Config.apiUrl}/OrderRequest/Insert`, data, { headers: authHeader() })
            .then(response => {


                if (response.data.success) {
                    toast.current.show({ severity: 'success', detail: response.data.msg, sticky: true, autoDismiss: true });

                }
                else {
                    toast.current.show({ severity: 'error', detail: response.data.msg, sticky: true });
                }

            }).catch(error => {
                toast.current.show({ severity: 'error', sticky: true, summary: 'Error', detail: error });
            })
            .finally(() => {
                setOrderRequest("");
                //getFarmerComplaints();
                // toast.current.clear();
            })
    }
    const resetFormUI = () => {

    }

    const onAdd = () => {
        reset();
        setIsForm(true);
        resetFormUI();
    }
    const onDelete = () => {
        if (selectedOrderRequests.length === 0) {
            toast.current.show({ severity: 'warn', detail: "No records selected for deletion", sticky: true });
            return;
        }

        let tempComplaints = [...selectedOrderRequests];
        selectedOrderRequests.forEach(item => {
            axios.post(`${Config.apiUrl}/OrderRequest/Delete?id=${item.id}`, { headers: authHeader() })
                .then(response => {
                    if (response.data.success) {
                        toast.current.show({ severity: 'success', detail: response.data.msg, autoDismiss: true });
                        tempComplaints = tempComplaints.filter(a => a.id !== item.id);

                    }
                    else {
                        toast.current.show({ severity: 'error', detail: response.data.msg, sticky: true });
                    }

                }).catch(error => {
                    toast.current.show({ severity: 'error', sticky: true, summary: 'Error', detail: error });
                })
                .finally(() => {
                    setSelectedOrderReqests(tempComplaints);
                    getOrderRequests();
                })
        })

    }

    const onRowGroupExpand = (event, rowData) => {
        setPrintRowData(event.data);
        let count = expandedCount + 1;
        setExpandedCount(count);

        if (count > 1) {
            setDisable(true);
        }
    }

    const onRowGroupCollapse = (event) => {
        let count = expandedCount - 1;
        setExpandedCount(count);
        if (count <= 1) {
            setDisable(false);
        }
    }

    const rowExpansionTemplate = (data) => {
        return (
            <BlockUI blocked={blockedPanel}>

                <div className="orders-subtable">
                    {/* <h5>Orders for {data.name}</h5> */}
                    <DataTable value={data.orderDetails} responsiveLayout="scroll" scrollHeight='300px' footerColumnGroup={!disable ? buttons : ''}>
                        <Column field="srNo" header="Sr.No"></Column>
                        <Column field="productName" header="Prodcut Name"></Column>
                        <Column field="quantity" header="Requested Quantity"></Column>
                        <Column field="dispatchQuantity" header="Dispatch Quantity" editor={(options) => cellEditor(options)} onCellEditComplete={onCellEditComplete}></Column>
                        {/* <Column body={EditButton} field="mobile1" header="Action"></Column> */}
                    </DataTable>

                </div>
            </BlockUI>

        );
    }

    const allowExpansion = (rowData) => {
        return rowData.orders.length > 0;
    };

    const onChangeQuantity = (data, e) => {
        data.editorCallback(e.target.value);
        if (e.target.value > data.rowData.quantity - data.rowData.returnedQuantity) {
            toast.current.show({ severity: 'warn', detail: 'Return Quantity is greater than sold Quantity ', sticky: true });
            data.editorCallback(0);
            return;
        }

    }
    const QuantityInput = (data) => (
        <div className="table-header">
            <div>
                <input id="quantity" type="text" name="quantity" value={data.value} onChange={(e) => onChangeQuantity(data, e)} style={{ width: '100px', height: '30px' }}></input>
                {/* <InputText type="text" value={data.value} onChange={(e) => data.editorCallback(e.target.value)} /> */}

            </div>
        </div>
    );

    const cellEditor = (options) => {
        return QuantityInput(options)
    }

    const onCellEditComplete = (e) => {
        let { rowData, newValue, field, originalEvent: event } = e;
        rowData["dispatchQuantity"] = parseInt(newValue);
        let _updatedData = [...updatedData];
        _updatedData.push(e.newRowData);
        setUpdatedData(_updatedData);
    }

    const onSubmitProduct = (e) => {
        return axios.put(`${Config.apiUrl}/OrderRequest/UpdateQuantity`, updatedData, { headers: authHeader() })
            .then(response => {
                if (response.data.success) {
                    toast.current.show({ severity: 'success', detail: 'Data Submitted Successfully', autoDismiss: true });

                }
                else {
                    toast.current.show({ severity: 'error', detail: response.data.msg, sticky: true });
                }
            }).catch(error => {
                toast.current.show({ severity: 'error', sticky: true, summary: 'Error', detail: error });
            })
            .finally(() => {
                // setAccountData(data);
                // setSpinnerVisible(false);
                // SetFocusValue(true);
            })

    }

    const rowClass = (data) => {
        return data.statusColor;
    }

    const onPrint = (rowData) => {
        // history.push(`/OrderRequest`);
        history.push({
            pathname: '/OrderRequestPrint',
            state: { detail: printRowData }
        })

    }
    const buttons =
        <ColumnGroup>
            <Row>
                <Column footer="" footerStyle={{ textAlign: 'right' }} />
                <Column footer='' />
                <Column footer='' />
                <Column footer=<div><button hidden={disable} className='button' type="button" onClick={() => onSubmitProduct()}>Submit</button> <button hidden={disable} type="button" className='filter' onClick={() => onPrint()} >Print</button></div>
                />

            </Row>
        </ColumnGroup>;

    return (
        <BlockUI blocked={blockedPanel}>
            <div className="AdminStockReturn">
                <Toast ref={toast} position={'center'} />
                <h3>All Order Request</h3>
                <div className="Unit" style={{ minHeight: '1029px' }}>
                    <div className="box" style={{ height: '60px' }}>
                        <div class="field col">
                            <label>From:</label>
                            <input type='date' name='fyDate' className='date' ref={Filter} />
                            <label>To:</label>
                            <input type='date' name='toDate' className='date' ref={Filter} />
                            <button type="button filter" className='filter' onClick={handleFilter(onFilter)}>Filter</button>
                            <button type="button" className='reset' onClick={onReset}>Reset</button>
                        </div>

                    </div>
                    <div className='box'>

                        <div>
                            <ToolBoxGrid columns={columns} data={orderRequestData} fileName="OrderRequests"></ToolBoxGrid>
                            {/* <button type='button'>Copy</button>
                        <button type='button'>CSV</button>
                        <button type='button'>Excel</button>
                        <button type='button'>PDF</button>
                        <button type='button'>Print</button> */}
                        </div>
                        <DataTable value={orderRequestData} header={header} filters={filters1}
                            className="dataTable" editMode="row" rowExpansionTemplate={rowExpansionTemplate} expandedRows={expandedRows} onRowExpand={onRowGroupExpand} onRowCollapse={onRowGroupCollapse} 
                            onRowToggle={(e) => setExpandedRows(e.data) } rowClassName={rowClass}
                        >
                            <Column expander={allowExpansion} style={{ width: '3em' }} />
                            <Column field="srNo" header="serial No" sortable></Column>
                            <Column field="franchiseName" header="Franchise Name" sortable></Column>
                            <Column field="fullOrderNo" header="Order Number" sortable></Column>
                            <Column field="orderDateFormat" header="Order Date" sortable></Column>
                            <Column field="quantity" header="Total Quantity" sortable></Column>
                            {/* <Column field="totalValue" header="Total Value" sortable></Column> */}
                        </DataTable>
                    </div>
                </div>

            </div>
        </BlockUI>


    )
}


export default AdminOrderRequest;