import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import { Dropdown } from 'bootstrap';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { useEffect } from 'react';
import '../components/Complaints/FranchiseComplaints.css';
import { BlockUI } from 'primereact/blockui';


function Employee(){
    const {
        register: Filter,
        errors: errors1,
        handleSubmit: handleFilter,
        setValue: setFilterValue,
    } = useForm();
    const [globalFilter, setGlobalFilter] = useState();
    const [initialReturnData,setInitialRetunData]=useState([]);
    const [blockedPanel, setBlockedPanel] = useState(false);
    var accessLevel = parseInt(sessionStorage.getItem('accessLevel'));

    useEffect(() => {

        if(accessLevel > 1)
        {
          setBlockedPanel(false);
        }
        else{
            setBlockedPanel(true);
        }

    }, [])

    const header = (
        <div className="table-header">
            <div>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" class="globleInput" value={globalFilter} onChange={(e) => setGlobalFilter(e.target.value)} placeholder="Global Search" />
                </span>
            </div>
        </div>
    );

    const Button=(
        <div>
            <button className='filter'>View & Print</button>
        </div>
    )

    return(
        <BlockUI blocked={blockedPanel}>
        <div className="FranchiseComplaints">
        <h3>Employee List</h3>
        <div className="Unit" style={{ minHeight: '1029px' }}>
                <div className="box">
                    <DataTable className='dataTable' globalFilter={globalFilter} header={header} editMode='row'>
                        <Column field="id" header="Sr No"></Column>
                        <Column field="name" header="Name"></Column>
                        <Column field="packing" header="Email"></Column>
                        <Column field="categoryName" header="Mobile"></Column>
                        <Column field="categoryName" header="Designation"></Column>
                        <Column field="categoryName" header="Address"></Column>
                        <Column field="categoryName" header="Joining Date"></Column>
                        <Column body={Button} field="" header="Action"></Column>
                    </DataTable>
                </div>
            </div>

        </div>
        </BlockUI>
    )
}
export default Employee;