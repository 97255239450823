import React, { useState, useRef } from 'react';
import { useForm } from "react-hook-form";
import { Dropdown } from 'bootstrap';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { useEffect } from 'react';
import './FranchiseComplaints.css';
import GreenFieldService from '../../services/greenFieldService';
import { InputTextarea } from 'primereact/inputtextarea';
import axios from 'axios';
import { Toast } from 'primereact/toast';
import Config from '../../Config.json';
import { BlockUI } from 'primereact/blockui';
import ToolBoxGrid from '../Misc/ToolBoxGrid';
import authHeader from '../../authHeader';



function FranchiseComplaints() {
    const { register, handleSubmit, reset, setValue } = useForm();
    const {
        register: Filter,
        errors: errors1,
        handleSubmit: handleFilter,
        setValue: setFilterValue,
    } = useForm();
    const [globalFilter, setGlobalFilter] = useState();
    const [initialReturnData, setInitialRetunData] = useState([]);
    const [isForm, setIsForm] = useState(false);
    const [franchiseCompaints, setFranchiseComplaintsData] = useState([]);
    const greenFieldService = new GreenFieldService();
    const [selectedFranchiseComplaints, setSelectedFranchiseComplaints] = useState([]);
    const franchiseId = localStorage.getItem('franchiseId');
    const [reviewComments, setReviewComments]=useState('');
    const toast = useRef(null);
    const [description, setDescription]=useState("");
    const [fields, setFields] = useState(['description']);
    const [blockedPanel, setBlockedPanel] = useState(false);
    var accessLevel = parseInt(sessionStorage.getItem('accessLevel'));

    const header = (
        <div className="table-header">
            <div>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" class="globleInput" value={globalFilter} onChange={(e) => setGlobalFilter(e.target.value)} placeholder="Global Search" />
                </span>
            </div>
        </div>
    );

    const onFilter = (filterValue) => {
        let data = initialReturnData.filter(a => a.addedDateFormat >= filterValue.fyDate && a.addedDateFormat <= filterValue.toDate);
        let srNo = 1;
        data.forEach(d => {
            d.serialNo = srNo;
            srNo++;
        })
        // setProductsData(data);

    }
    const onBack = () => {
        setIsForm(false);

    }
    
    
    const resetFormUI = () => {
        fields.forEach(item => {
            setValue(item, '');
        });
        setDescription('');
        setSelectedFranchiseComplaints(null);

    }
    const onAdd = () => {
        reset();
        setIsForm(true);
        resetFormUI();
    }
    const onDelete = () => {
        if (selectedFranchiseComplaints.length === 0) {
            toast.current.show({ severity: 'warn', detail: "No records selected for deletion", sticky: true });
            return;
        }

        let tempComplaints = [...selectedFranchiseComplaints];
        selectedFranchiseComplaints.forEach(item => {
            axios.post(`${Config.apiUrl}/FranchiseComplaint/Delete?franchiseComplaintId=${item.id}`, { headers: authHeader() })
                .then(response => {
                    if (response.data.success) {
                        toast.current.show({ severity: 'success', detail: response.data.msg, autoDismiss:true });
                        tempComplaints = tempComplaints.filter(a => a.id !== item.id);

                    }
                    else {
                        toast.current.show({ severity: 'error', detail: response.data.msg, sticky: true });
                    }

                }).catch(error => {
                    toast.current.show({ severity: 'error', sticky: true, summary: 'Error', detail: error });
                })
                .finally(() => {
                    setSelectedFranchiseComplaints(tempComplaints);
                    getFranchiseComplaints();
                })
        })

    }
    const onSave = (data) => {
        data.description = description;
        console.log(Config.apiUrl);
        data.Franchise_Id = parseInt(franchiseId);
        axios.post(`${Config.apiUrl}/FranchiseComplaint/Insert`, data, { headers: authHeader() })
            .then(response => {
                if (response.data.success) {
                    toast.current.show({ severity: 'success', detail: response.data.msg, autoDismiss:true });

                }
                else {
                    toast.current.show({ severity: 'error', detail: response.data.msg, sticky: true });
                }

            }).catch(error => {
                toast.current.show({ severity: 'error', sticky: true, summary: 'Error', detail: error });
            })
            .finally(() => {
                resetFormUI();
                getFranchiseComplaints();
                toast.current.clear();
            })
    }
    const onReviewCommentsChange = (e => {
        setReviewComments(e.target.value);
    });
    const getFranchiseComplaints = () => {
        greenFieldService.GetFranchiseComplaintsData(franchiseId).then(data => {

            data.forEach(item => {
                if (item.status === 0) {
                    item.displayStatus = "UnResolved";
                }
                else {
                    item.displayStatus = "Resolved";
                }
            }) 
            setFranchiseComplaintsData(data);
        });
    }
    useEffect(() => {
        getFranchiseComplaints();

        if(accessLevel > 1)
        {
          setBlockedPanel(false);
        }
        else{
            setBlockedPanel(true);
        }

    }, [])

    const onReset = () => {

        const fields = [
            "fyDate",
            "toDate"
        ];
        fields.forEach((field) => setFilterValue(field, ''));
    }

    const onUpdate = (data) => {
        if (selectedFranchiseComplaints.length === 0) {
            toast.current.show({ severity: 'warn', detail: "No franchise complaint selected", sticky: true });
            return;
        }

        if (reviewComments.length === 0) {
            toast.current.show({ severity: 'warn', detail: "Resolution comments are blank", sticky: true });
            return;
        }
        selectedFranchiseComplaints.forEach(item => {
            item.resolutionComments = reviewComments;
            data.Franchise_Id = parseInt(franchiseId);
        })

        axios.post(`${Config.apiUrl}/FranchiseComplaint/UpdateFranchiseComplaintResolution`, selectedFranchiseComplaints, { headers: authHeader() })
            .then(response => {

                response.data.forEach(item => {
                    if (item.success) {
                        toast.current.show({ severity: 'success', detail: item.msg, autoDismiss:true});

                    }
                    else {
                        toast.current.show({ severity: 'error', detail: item.msg, sticky: true });
                    }
                });

            }).catch(error => {
                toast.current.show({ severity: 'error', sticky: true, summary: 'Error', detail: error });
            })
            .finally(() => {
                //  resetFormUI();
                setSelectedFranchiseComplaints([]);
                getFranchiseComplaints();
                setReviewComments("");
                toast.current.clear();
            })
    }
    return (
        <BlockUI blocked={blockedPanel}>
        <div >
        <Toast ref={toast} position={'center'} />
            <h3>Franchise Complaints</h3>
            <div hidden={isForm} className="FranchiseComplaints">
                <div className="header">
                    <label>Resolution Comments</label> 
                    <input id="Resolution Comments" style={{ width: '200px', height: '40px' }} type="text" name="Resolution Comments" onChange={(e) => setReviewComments(e.target.value)} className="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary"></input>
                    <button className='updateButton button' style={{ width: '100px', height: '40px' }} onClick={onUpdate} type="button">Update</button> 
                    {/* <button className='addButton button' style={{ width: '100px', height: '40px' }} onClick={onAdd} type="button">Add</button> */}
                    <button className='delete' style={{ width: '100px', height: '40px' }} onClick={onDelete} type="button">Delete</button>
                </div>

                <div className='box'>    
                <DataTable className='dataTable' value={franchiseCompaints} selectionMode="checkbox" selection={selectedFranchiseComplaints} onSelectionChange={e => setSelectedFranchiseComplaints(e.value)} header={header} globalFilter={globalFilter} editMode='row' datakey="id">
                        <Column selectionMode="multiple" headerStyle={{ width: '3em' }}></Column>
                        <Column field="id" header="Sr No"></Column>
                        <Column field="franchise_Id" header="Franchise_Id"></Column>
                        <Column field="description" header="Franchise Name"></Column>
                        <Column field="createdate" header="Createdate"></Column>
                        <Column field="resolutionComments" header="Resolution Comment"></Column>
                        <Column field="displayStatus" header="Status"></Column>
                    </DataTable>
                </div>
                {/* </div> */}
            </div>
                  
            <div hidden={!isForm} className="FranchiseComplaints">
                <div className="header">
                    <h3>Add Franchise Complaints</h3>
                    <button className='addButton button ' style={{ width: '100px', height: '35px' }} onClick={onBack} type="button">Back</button>
                </div>
                <div className='box'>
                          
                    <div className="field col">
                        <label>Description</label>
                        {/* <input id="ownerMobile" type="text" name="ownerMobile1"  ref={register} className="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary   "></input> */}
                        <InputTextarea value={description} name="description" ref={register} onChange={(e) => setDescription(e.target.value)} rows={1} cols={10} />
                    </div>
                    <div className="formgroup col">
                        <button className='saveButton button' style={{ width: '100px' }} type="button" onClick={handleSubmit(onSave)}>Save</button>
                    </div>
                </div>
            </div>
            

        </div>
        </BlockUI>
    )
}
export default FranchiseComplaints;