import React, { useState, useEffect, useRef } from 'react';
import { useForm } from "react-hook-form";
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import '../Complaints/FranchiseComplaints.css';
import { InputTextarea } from 'primereact/inputtextarea';
import GreenFieldService from '../../services/greenFieldService';
import axios from 'axios';
import { Toast } from 'primereact/toast';
import Config from '../../Config.json';
import { BlockUI } from 'primereact/blockui';
import authHeader from '../../authHeader';
import { Button } from 'primereact/button';
import moment from 'moment';



function OrderRequest() {
    const { register, handleSubmit, reset, setValue } = useForm();
    const {
        register: Filter,
        errors: errors1,
        handleSubmit: handleFilter,
        setValue: setFilterValue,
    } = useForm();
    const toast = useRef(null);
    const [globalFilter, setGlobalFilter] = useState();
    const [initialReturnData, setInitialRetunData] = useState([]);
    const [isForm, setIsForm] = useState(false);
    const greenFieldService = new GreenFieldService();
    const [orderRequest, setOrderRequest] = useState('');
    const [orderRequestData, setOrderRequestData] = useState([]);
    const [orderRequestOriginalData, setOrderRequestOriginalData] = useState([]);
    const franchiseId = localStorage.getItem('franchiseId');
    const franchiseName = localStorage.getItem('FranchiseName');
    const [selectedOrderRequests, setSelectedOrderReqests] = useState([]);
    const [orderData, setOrderData] = useState([]);
    const [filters1, setFilters1] = useState(null);
    const [expandedRows, setExpandedRows] = useState(null);
    // const [orderNo, setOrderNo] = useState([]);
    const [blockedPanel, setBlockedPanel] = useState(false);
    var accessLevel = parseInt(sessionStorage.getItem('accessLevel'));
    const [productData, setProductsData] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState();
    const [isSubmitBtn,setIsSubmitBtn]=useState(true);
    const [orderNumber,setOrderNumber]=useState();
    const [currentDate,setCurrentDate]=useState();



    const header = (
        <div className="table-header">
            <div>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" className="globleInput" value={globalFilter} onChange={(e) => setGlobalFilter(e.target.value)} placeholder="Global Search" />
                </span>
            </div>
        </div>
    );

    // const Button = (
    //     <div>
    //         <button className='filter'>View & Print</button>
    //     </div>
    // )

    const getOrderRequests = (() => {

        greenFieldService.getOrderRequests(franchiseId).then(data => {

            let srNo = 1;
            data.forEach(item => {
                item.srNo = srNo++;

                let newSrNo=1;
                item.orderDetails.forEach(a=>{
                    a.srNo=newSrNo++;
                })
                // item.formattedDate = item.create_Date.replace('T', ' ')
            }

            )
            setOrderRequestData(data);
            setOrderRequestOriginalData(data);
        })
    })

    const getOrderNumber=()=>{
        greenFieldService.GetMaxOrderNo(franchiseId).then(data => {
            setValue("fullOrderNo",data);
            setOrderNumber(data);
        })
    }

    useEffect(() => {
        getOrderRequests();
        getOrderNumber();
        
        if (accessLevel > 1) {
            setBlockedPanel(false);
        }
        else {
            setBlockedPanel(true);
        }

        greenFieldService.GetProductsData().then(data => {
            data.forEach(a => {
                // a.productName = a.name 
                a.productName = `${a.name} - ${a.packingSize} ${a.unitName}`;
            });
            setProductsData(data);
        });

        let d = moment(new Date()).format('YYYY-MM-DD');
        setValue('orderDate', d);
        setCurrentDate(d);
    }, [])

    const onChangeProduct = (e) => {

        setSelectedProduct(e.value);


    }

    const onFilter = (filterValue) => {
        let data = initialReturnData.filter(a => a.addedDateFormat >= filterValue.fyDate && a.addedDateFormat <= filterValue.toDate);
        let srNo = 1;
        data.forEach(d => {
            d.serialNo = srNo;
            srNo++;
        })
        // setProductsData(data);

    }

    const onReset = () => {
        const fields = [
            "fyDate",
            "toDate"
        ];
        fields.forEach((field) => setFilterValue(field, ''));
    }
    const onBack = () => {
        setIsForm(false);

    }

    var errors = [];

    const validate = (data) => {
        if (!data.quantity) {
            errors.push('Quantity is Required', <br />);
        }
        if (!selectedProduct) {
            errors.push('Product name is required.', <br />);
        }
      


        // return errors;
    };

    const onSave = (data) => {
        // data.orderRequest = orderRequest;
        console.warn(orderData);
        axios.post(`${Config.apiUrl}/OrderRequest/Insert`, orderData, { headers: authHeader() })
            .then(response => {


                if (response.data.success) {
                    toast.current.show({ severity: 'success', detail: response.data.msg, autoDismiss: true });

                }
                else {
                    toast.current.show({ severity: 'error', detail: response.data.msg, sticky: true });
                }

            }).catch(error => {
                toast.current.show({ severity: 'error', sticky: true, summary: 'Error', detail: error });
            })
            .finally(() => {
                getOrderRequests();
                getOrderNumber();
                setOrderData("");
                //getFarmerComplaints();
                // toast.current.clear();
            })
    }
    const resetFormUI = () => {

    }

    // const onAdd = () => {
    //     reset();
    //     setIsForm(true);
    //     resetFormUI();
    // }
    const resetValue = () => {
        setValue('quantity','');
        setSelectedProduct('');
    }
    const onAdd = (data) => {
        validate(data);
        if (errors.length > 0) {
            // eslint-disable-next-line no-lone-blocks
            {
                errors.map(() => {
                    toast.current.show({ severity: 'warn', detail: errors, sticky: true });
                }

                )
            }
            errors = [];
            return;
        }

        let duplicate = orderData.find(a=>a.productName === selectedProduct.productName)
        if(duplicate)
        {
            toast.current.show({ severity: 'warn', detail: "This Product is already added", sticky: true });
            return;

        }

        data.fullOrderNo=orderNumber;
        data.orderDate=currentDate;
        // data.orderDate = moment(currentDate).format("DD-MM-YYYY");

        data.orderNo=parseInt(data.fullOrderNo.replace(/^\D+/g, ""));
        // data.orderNo= parseInt(data.fullOrderNo);
        data.franchiseId = parseInt(franchiseId);
        data.productName = selectedProduct.productName;
        let _orderData = [...orderData];
        let _data = { ...data };
        _orderData.push(_data);
        setOrderData(_orderData);
        resetValue();

        if(_orderData.length > 0){
            setIsSubmitBtn(false);
        }

    }

 


    const onDelete = (rowData) => {
        if (rowData != null) {
            toast.current.show({
                severity: 'warn', sticky: true, content: (
                    <div className="flex flex-column" style={{ flex: '1' }}>
                        <div className="text-center">
                            <i className="pi pi-exclamation-triangle" style={{ fontSize: '3rem' }}></i>
                            <h4>Are you sure?</h4>
                            <p>Confirm to proceed</p>
                        </div>
                        <div className="grid p-fluid">
                            <div className="col-6">
                                <Button type="button" label="Yes" onClick={() => onYes(rowData)} className="p-button-success" />
                            </div>
                            <div className="col-6">
                                <Button type="button" label="No" onClick={() => onNo(rowData)} className="p-button-secondary" />
                            </div>
                        </div>
                    </div>
                )
            });
        }
        else {
            toast.current.show({ severity: 'warn', sticky: true, detail: "PLEASE SELECT ROW" });
            return;
        }
    }

    const onYes = (rowData) => {
        toast.current.clear();
        try {
            let index = orderData.findIndex(a => a.productName === rowData.productName);
            if (index > -1) {

                let _orderData = [...orderData];
                let data = _orderData.splice(index, 1);

                setOrderData(_orderData);

                // let grandTotalValue = (_orderData && _orderData.reduce((a, v) => a + parseFloat(v.total), 0));
                // setFooterValue('grandTotal', grandTotalValue.toFixed(2));
                // setFooterValue('dueAmount', grandTotalValue.toFixed(2));

                toast.current.show({ severity: 'success', detail: "Product Removed from Order", autoDismiss: true });

            }
            else {
                toast.current.show({ severity: 'error', sticky: true, detail: "Product Not Removed from order" });
            }
        }
        catch {

        }
        finally {


            toast.current.clear();

        }


        // window.location.reload();
    }

    const onNo = () => {
        toast.current.clear();
        return;
    };

    const DeleteButtonIcon = (rowData) => {
        return <div>
            <Button icon="pi pi-trash" className="p-button-rounded p-button-danger" onClick={() => onDelete(rowData)} aria-label="Bookmark" />
        </div>
    }

    const rowExpansionTemplate = (data) => {
        return (
            <BlockUI blocked={blockedPanel}>

                <div className="orders-subtable">
                    {/* <h5>Orders for {data.name}</h5> */}
                    <DataTable value={data.orderDetails} responsiveLayout="scroll" scrollHeight='300px'>
                        <Column field="srNo" header="Sr.No"></Column>
                        <Column field="productName" header="Prodcut Name"></Column>
                        <Column field="quantity" header="Quantity"></Column>
                        
                        {/* <Column body={EditButton} field="mobile1" header="Action"></Column> */}
                    </DataTable>

                </div>
            </BlockUI>

        );
    }

    const allowExpansion = (rowData) => {
        return rowData.orders.length > 0;
    };


    return (
        <BlockUI blocked={blockedPanel}>
            <div className="FranchiseComplaints">
                <Toast ref={toast} position={'center'} />
                <h3>Create Order Request</h3>

                <div>
                    <div className="formgrid grid align items left">
                        <div style={{ marginleft: '10px' }}>
                            <div className="field col">

                                <label >Order Date<span style={{ color: 'red' }}>*</span></label>
                                <input type="date" name="orderDate" ref={register} disabled className="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary"></input>
                                <label >Order No<span style={{ color: 'red' }}>*</span></label>
                                <input type="text" name="fullOrderNo" ref={register} autoComplete='off' disabled className="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary"></input>

                            </div>
                            <div className="field col">

                                <label >Product Name<span style={{ color: 'red' }}>*</span></label>
                                <Dropdown className='Dropdown' value={selectedProduct} id="productId" options={productData} optionLabel="productName" onChange={onChangeProduct} filter showClear placeholder="Select Product" />

                                <label>Enter Quantity<span style={{ color: 'red' }}>*</span></label>
                                <input type="text" name="quantity" ref={register} autoComplete='off' className="QuantityTextbox text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary"></input>

                            </div>


                        </div>
                    </div>
                </div>

                <div hidden={isForm} className="Unit" style={{ minHeight: '1029px' }}>
                    <button className='button' type="button" onClick={handleSubmit(onAdd)}>Add Order</button>

                    <div className='box'>

                        <DataTable className='dataTable' value={orderData} globalFilter={globalFilter} header={header} editMode='row'>
                            {/* <Column field="srNo" header="Sr No"></Column> */}
                            <Column field="orderDate" header="Order Date"></Column>
                            <Column field="orderNo" header="Order No"></Column>
                            <Column field="productName" header="Product Name"></Column>
                            <Column field="quantity" header="Quantity"></Column>
                            <Column body={DeleteButtonIcon} field="action" header="Action"></Column>
                        </DataTable>
                        <button className='button' type="button" disabled={isSubmitBtn} onClick={onSave}>Submit Order</button>

                    </div>

                    <DataTable value={orderRequestData} header={header} filters={filters1} 
                                className="dataTable" editMode="row" rowExpansionTemplate={rowExpansionTemplate} expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)}
                                >
                                <Column expander={allowExpansion} style={{ width: '3em' }} />
                                <Column field="srNo" header="serial No" sortable></Column>
                                <Column field="fullOrderNo" header="Order Number" sortable></Column>
                                <Column field="orderDateFormat" header="Order Date" sortable></Column>
                                <Column field="quantity" header="Total Quantity" sortable></Column>
                                {/* <Column field="totalValue" header="Total Value" sortable></Column> */}
                            </DataTable>
                </div>
            </div>
        </BlockUI>


    )
}
export default OrderRequest;