// import './AdminStockReturn.css';
import '../../App.css';
import 'primeflex/primeflex.css';
import { Calendar } from 'primereact/calendar';
import React, { useState, useEffect, useRef } from 'react';
import { useForm } from "react-hook-form";
import { Dropdown } from 'bootstrap';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import GreenFieldService from '../../services/greenFieldService';
import { useHistory } from 'react-router-dom';
import ExportCsvReport from '../../exportReport/exportCsvReport';
import ExportExcelReport from '../../exportReport/exportExcelReport';
import ExportPdfReport from '../../exportReport/exportPdfReport';
import { Toast } from 'primereact/toast';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { BlockUI } from 'primereact/blockui';
import moment from 'moment';
import ProgressSpinBarControl from '../Misc/progressSpinBarControl';
import ToolBoxGrid from '../Misc/ToolBoxGrid';
var netAmountSum;
var gstSum;
var royaltySum;
var discountSum;

function AllReturnInvoices() {

    const [date3, setDate3] = useState(null);
    const toast = useRef(null);
    const {
        register: Filter,
        handleSubmit: handleFilter,
        setValue: setFilterValue,
    } = useForm();
    const [filters1, setFilters1] = useState(null);
    const [globalFilter, setGlobalFilter] = useState();
    const [invoicesData, setInvoicesData] = useState();
    const [initialData, setInitialData] = useState();

    const greenFieldService = new GreenFieldService();
    const exportCsvReport = new ExportCsvReport();
    const exportPdfReport = new ExportPdfReport();
    const exportExcelReport = new ExportExcelReport();
    let history = useHistory();
    const dt = useRef(null);
    const franchiseId = localStorage.getItem('franchiseId');
    const [blockedPanel, setBlockedPanel] = useState(false);
    var accessLevel = parseInt(sessionStorage.getItem('accessLevel'));
    const isAdmin = sessionStorage.getItem('IsAdmin');
    const [spinnerVisible, setSpinnerVisible] = useState(false);
    useEffect(() => {
        let d = moment(new Date()).format('YYYY-MM-DD');
        setFilterValue('fyDate',d);
        setFilterValue('toDate',d);
        
        GetAllReturnInvoiceDetails(franchiseId);
        initFilters1();

        if (accessLevel > 1) {
            setBlockedPanel(false);
        }
        else {
            setBlockedPanel(true);
        }

    }, [])

    const GetAllReturnInvoiceDetails = (franchiseId) => {
        let boolString = isAdmin; 
        let isAdminValue = (boolString.toLowerCase() === "true");
        setSpinnerVisible(true); 
        greenFieldService.GetReturnInvoiceList(!isAdminValue ? franchiseId : -1).then(data => {
            netAmountSum = (data.reduce((a, v) => a + parseFloat(v.returnAmount), 0));
            royaltySum = (data.reduce((a, v) => a + parseFloat(v.royalty), 0));
            gstSum = (data.reduce((a, v) => a + parseFloat(v.returnGst), 0));
            discountSum = (data.reduce((a, v) => a + parseFloat(v.returnDiscount), 0));

            let srNo = 1;
            data.forEach(d => {
                d.serialNo = srNo;
                d.returnGst=parseFloat(d.returnGst).toFixed(2);
                d.returnDiscount=parseFloat(d.returnDiscount).toFixed(2);
                d.returnAmount=parseFloat(d.returnAmount).toFixed(2);
                d.royalty=parseFloat(d.royalty).toFixed(2);
                srNo++;
                d.invoiceDate= d.invoiceDate.replace('T', ' ');  
                // d.invDateValue=new Date(d.invoiceDate);
                d.invoiceDate=dateTime(d.invoiceDate);
                console.log(d.invoiceDate);
            })
            // royaltySum = (data.reduce((a, v) => a + parseFloat(v.royalty), 0));
            setInvoicesData(data);
            setInitialData(data);
        }).catch(error => {
            toast.current.show({ severity: 'error', sticky: true, summary: 'Error', detail: 'Error in getting All Rerurn Invoices Data' });
        })
        .finally(() => {
            setSpinnerVisible(false);
        });
    }

    const onChangeGlobal = (e) => {
        let value = e.target.value;
        let _filters1 = { ...filters1 };
        _filters1['global'].value = value;

        setFilters1(_filters1);

        setGlobalFilter(value);
    }

    const initFilters1 = () => {
        setFilters1({
            'global': { value: null, matchMode: FilterMatchMode.CONTAINS },

        });
        setGlobalFilter('');
    }

    const cols = [
        { field: 'serialNo', header: 'Sr.No' },
        { field: 'invName', header: 'Invoice Number' },
        { field: 'customerName', header: 'Customer' },
        { field: 'invDateFormat', header: 'Date' },
        { field: 'returnGst', header: 'Return Gst' },
        { field: 'returnDiscount', header: 'Return Discount' },
        { field: 'returnAmount', header: 'Return Amount' },
        { field: 'dueAmount', header: 'Due Amount' },
        { field: 'royalty', header: 'Royalty' },


    ];
    const exportColumns = cols.map(col => ({ title: col.header, dataKey: col.field }));

    var errors = [];

    const filterValidate = (data) => {
        if (!data?.fyDate) {
            errors.push('* From Date is required.', <br />);
        }
        if (!data?.toDate) {
            errors.push('* To Date is required', <br />);
        }
    }

    function dateTime(dateValue){
        var x = new Date(dateValue);// x is now a date object
        x.setHours(0,0,0,0); 
        return x;
       }

    const onFilter = (filterValue) => {
        let fromdate=dateTime(filterValue.fyDate)  ;
        // fromdate.setDate(fromdate.getDate());
        let toDate=dateTime(filterValue.toDate);
        filterValidate(filterValue)
        if (errors.length > 0) {
            // eslint-disable-next-line no-lone-blocks
            {
                errors.map(() => {
                    toast.current.show({ severity: 'warn', detail: errors, sticky: true });
                }

                )
            }
            errors = [];
            return;
        }
        let data = initialData.filter(a => a.invoiceDate  >= fromdate && a.invoiceDate  <= toDate);
        let srNo = 1;
        netAmountSum = (data.reduce((a, v) => a + parseFloat(v.returnAmount), 0));
            royaltySum = (data.reduce((a, v) => a + parseFloat(v.royalty), 0));
            gstSum = (data.reduce((a, v) => a + parseFloat(v.returnGst), 0));
            discountSum = (data.reduce((a, v) => a + parseFloat(v.returnDiscount), 0));
        data.forEach(d => {
            d.serialNo = srNo;
            srNo++;
        })


        setInvoicesData(data);

    }

    const onReset = () => {

        const fields = [
            "fyDate",
            "toDate"
        ];
        fields.forEach((field) => setFilterValue(field, ''));
        GetAllReturnInvoiceDetails(franchiseId);

    }



    const buttons = (rowData) => {
        return <div>

            {/* <button className='reset' type="button" onClick={() => onManage(rowData)}>Manage Invoice</button> */}
            <button className=' button' type="button" onClick={() => onPrint(rowData)}>Print</button>


        </div>;
    }

    const onManage = () => {

    }

    let totalFooter = <ColumnGroup>
        <Row>
            <Column footer="Totals:" footerStyle={{ textAlign: 'right' }} />
            <Column footer='' />
            <Column footer='' />
            <Column footer='' />
            <Column footer={parseFloat(gstSum).toFixed(2)} />
            <Column footer={parseFloat(discountSum).toFixed(2)} />
            <Column footer={parseFloat(netAmountSum).toFixed(2)} />
            <Column footer={parseFloat(royaltySum).toFixed(2)} />
            <Column footer='' />

        </Row>
    </ColumnGroup>;

    const onPrint = (rowData) => {
        // history.push('/InvoicePrint');
        // history.push(`/InvoicePrint/${rowData.customerId}/${rowData.franchiseId}/${rowData.categoryId}/${rowData.invNo}`);
        history.push(`/ReInvoicePrint/${rowData.customerId}/${rowData.invNo}/${rowData.franchiseId}`);
    }

    const header = (
        <div className="table-header">
            <div>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" className="globleInput" value={globalFilter} onChange={onChangeGlobal} placeholder="Global Search" />
                </span>
            </div>
        </div>
    );

    const exportCSV = (selectionOnly) => {
        // <exportCsvReport dt={dt}/>
        exportCsvReport.exportCSV(dt)
        // dt.current.exportCSV();
    }

    const exportPdf = () => {
        let fileName = "Invoice Report";
        exportPdfReport.exportPdf(exportColumns, invoicesData, fileName);

    }

    const exportExcel = () => {
        let fileName = "Invoice Report";
        exportExcelReport.exportExcel(invoicesData, fileName);
    }


    return (
        <BlockUI blocked={blockedPanel}>
            <div className="AdminStockReturn">
                <Toast ref={toast} position={'center'} />
                <h3>Return Invoice List</h3>
                <div className="Unit" style={{ minHeight: '1029px' }}>
                    <div className="box">
                        <div class="field col">
                            <label>From:</label>
                            <input type='date' name='fyDate' className='date' ref={Filter} />
                            <label>To:</label>
                            <input type='date' name='toDate' className='date' ref={Filter} />
                            <button type="button filter" onClick={handleFilter(onFilter)} className='filter'>Filter</button>
                            <button type="button" onClick={onReset} className='reset'>Reset</button>
                        </div>
                        {/* <div>
                        
                        Show
                        <select className='dropdown'>
                            <option>50</option>
                            <option>100</option>
                            <option>All</option>
                        </select>
                        entries
                    </div> */}
                    </div>

                    <div className='box'>
                    <ToolBoxGrid columns={exportColumns} data={invoicesData} fileName="Franchise Return Invoices Report"></ToolBoxGrid>

                        <DataTable ref={dt} value={invoicesData} className='dataTable' header={header} filters={filters1} globalFilter={globalFilter} scrollable scrollHeight='800px' footerColumnGroup={totalFooter} editMode='row'>
                            <Column field="serialNo" header="Sr No"></Column>
                            <Column field="invName" header="Invoice Number"></Column>
                            <Column field="customerName" header="Customer"></Column>
                            <Column field="invDateFormat" header="Invoice Date"></Column>
                            <Column field="returnGst" header="Return Gst"></Column>
                            <Column field="returnDiscount" header="Return Discount"></Column> 
                            <Column field="returnAmount" header="Return Amount"></Column>
                            <Column field="royalty" header="Royalty"></Column>
                            <Column body={buttons} header="Action"></Column>
                        </DataTable>
                    </div>
                </div>
            </div>
        </BlockUI>
    );
}
export default AllReturnInvoices;