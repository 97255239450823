import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import { Dropdown } from 'bootstrap';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import './CustomerReport.css';
import { useEffect } from 'react';
import GreenFieldService from '../../services/greenFieldService';
import { useHistory } from "react-router-dom";
import ToolBoxGrid from '../Misc/ToolBoxGrid';


function CustomerReport(id){

    let history = useHistory();
    const [date3, setDate3] = useState(null);
    const {
        register: Filter,
        errors: errors1,
        handleSubmit: handleFilter,
        setValue: setFilterValue,
    } = useForm();
    const [globalFilter, setGlobalFilter] = useState();
    const greenFieldService = new GreenFieldService();
    const [columns,setColumns]=useState([{ title: 'Sr No', dataKey: 'serialNo' },{ title: 'Name', dataKey: 'name' },{ title: 'Mobile', dataKey: 'mobile1' },{ title: 'Address', dataKey: 'address' }]);
    const [customersData, setCustomersData] = useState([]);
    const [rowData, setRowData] = useState();
  

    const onView=(rowData)=>{
        history.push(`/InvoiceList/${rowData[0].id}`);

    }

    const ViewButton = (
        <div>
            <button className='filter' onClick={() => onView(rowData)}>View</button>
        </div>
    );

    const header = (
        <div className="table-header">
            <div>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" class="globleInput" value={globalFilter} onChange={(e) => setGlobalFilter(e.target.value)} placeholder="Global Search" />
                </span>
            </div>
        </div>
    );

    useEffect(() => {
        greenFieldService.GetCustomerData(0).then(data => {
            var serialNo=1;
            data.forEach(a => {
                a.address= `${a.town} ${a.district} ${a.state}`
                a.serialNo=serialNo++;
            })
            setCustomersData(data);
            setRowData(data);
        });

    }, [])


    return(
        <div className="CustomerReport">
            <h3>Customer List</h3>
            <div className="Unit" style={{minHeight: '1029px'}}>
            <div className='box'>
                    {/* <div>
                        <button type='button'>Copy</button>
                        <button type='button'>CSV</button>
                        <button type='button'>Excel</button>
                        <button type='button'>PDF</button>
                        <button type='button'>Print</button>
                    </div> */}
                    <ToolBoxGrid columns={columns} data={customersData} fileName="customer"></ToolBoxGrid>
                    <DataTable className='dataTable' header={header} globalFilter={globalFilter} editMode='row' value={customersData}>
                        <Column field="serialNo" header="Sr No"></Column>
                        <Column field="name" header="Name"></Column>
                        <Column field="mobile1" header="Mobile"></Column>
                        <Column field="address" header="Address"></Column>
                        <Column body={ViewButton} field='' header="Action"></Column>
                    </DataTable>
                </div>

            </div>
        </div>
    );
}
export default CustomerReport;