import './OutsatndingReport.css';
import React, { useState, useRef } from 'react';
import { useForm } from "react-hook-form";
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { useEffect } from 'react';
import GreenFieldService from '../../services/greenFieldService';
import ToolBoxGrid from '../Misc/ToolBoxGrid';
import { Toast } from 'primereact/toast';
import ProgressSpinBarControl from '../Misc/progressSpinBarControl';
import moment from 'moment';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
var receivedStockSum;
var soldSum;
var remainingStockSum;
var customerReturnSum;
var pendingStockSum;
var returnToAdminSum;

function GkmWiseProduct() {
    const greenFieldService = new GreenFieldService();
    const [date3, setDate3] = useState(null);
    const {
        register: Filter,
        errors: errors1,
        handleSubmit: handleFilter,
        setValue: setFilterValue,
    } = useForm();
    const [columns, setColumns] = useState([{ title: 'Sr No', dataKey: 'serialNo' }, { title: 'Franchise Name', dataKey: 'franchise' }, { title: 'Received Stock', dataKey: 'receivedStock' }, { title: 'Pending Stock', dataKey: 'pendingStock' }, { title: 'Return To AdminStock', dataKey: 'returnToAdminStock' },{ title: 'Return Stock', dataKey: 'returnStock' },{ title: 'Sold Stock', dataKey: 'soldStock' },{ title: 'Remaining Stock', dataKey: 'remainingStock' }]);
    const [globalFilter, setGlobalFilter] = useState();
    const [gkmWiseProductData, setGkmWiseProductData] = useState([]);
    const toast = useRef(null);
    const [spinnerVisible, setSpinnerVisible] = useState(false);
    const [productData, setProductsData] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState();



    useEffect(() => {
        greenFieldService.GetAllProductsData().then(data => {
            let srNo=1;
            data.forEach(a => {
                a.productName = `${a.name} - ${a.packingSize} ${a.unitName}`;
                a.packingSize = `${a.packingSize} ${a.unitName}`;
                a.serialNo = srNo;
                srNo++;
            })
            setProductsData(data);

        });
    }, []);

    const onChangeProduct = (e) => {
        setSelectedProduct(e.value);
        // setValue('companyName',e.value?.companyName);
    }

    var errors = [];

    const filterValidate = (data) => {
        if (!data?.fyDate) {
            errors.push('* From Date is required.', <br />);
        }
        if (!data?.toDate) {
            errors.push('* To Date is required', <br />);
        }
    }

    const onFilter = (filterValue) => {
        filterValidate(filterValue)
        if (errors.length > 0) {
            // eslint-disable-next-line no-lone-blocks
            {
                errors.map(() => {
                    toast.current.show({ severity: 'warn', detail: errors, sticky: true });
                }

                )
            }
            errors = [];
            return;
        }
        setSpinnerVisible(true);
        greenFieldService.GetGkmProductQty(filterValue.fyDate, filterValue.toDate,selectedProduct.id).then(data => {
            receivedStockSum = (data.reduce((a, v) => a + parseFloat(v.receivedStock), 0));
            pendingStockSum = (data.reduce((a, v) => a + parseFloat(v.pendingStock), 0));
            customerReturnSum = (data.reduce((a, v) => a + parseFloat(v.returnStock), 0));
            remainingStockSum = (data.reduce((a, v) => a + parseFloat(v.remainingStock), 0));
            returnToAdminSum = (data.reduce((a, v) => a + parseFloat(v.returnToAdminStock), 0));
            soldSum = (data.reduce((a, v) => a + parseFloat(v.soldStock), 0));
            let srNo = 1;
            data.forEach(a => {
                // a.totalSale = a.totalSale.toFixed(2);
                // a.receipt = a.receipt.toFixed(2);
                // a.outstanding = a.outstanding.toFixed(2);
                a.serialNo = srNo;
                srNo++;
            })
            setGkmWiseProductData(data);
            setSpinnerVisible(false);
            // setInitialRetunData(data);
        });

        // let data = outsatndingData.filter(a => a.addedDateFormat >= filterValue.fyDate && a.addedDateFormat <= filterValue.toDate);
        // let srNo = 1;
        // data.forEach(d => {
        //     d.totalSale=d.totalSale.toFixed(2);
        //     d.receipt=d.receipt.toFixed(2);
        //     d.outstanding=d.outstanding.toFixed(2);

        //     d.serialNo = srNo;
        //     srNo++;
        // })
        // setOutsatndingData(data);

    }

    const onReset = () => {

        const fields = [
            "fyDate",
            "toDate"
        ];
        fields.forEach((field) => setFilterValue(field, ''));
        setGkmWiseProductData('');
        receivedStockSum= 0;
        soldSum= 0;
        remainingStockSum= 0;
        customerReturnSum= 0;
        pendingStockSum= 0;
        returnToAdminSum= 0;
    }

    const header = (
        <div className="table-header">
            <div>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" class="globleInput" value={globalFilter} onChange={(e) => setGlobalFilter(e.target.value)} placeholder="Global Search" />
                </span>
            </div>
        </div>
    );

    let totalFooter = <ColumnGroup>
    <Row>
    <Column footer="Totals:" footerStyle={{ textAlign: 'right' }} />
            <Column footer='' />
            <Column footer={receivedStockSum} />
            <Column footer={pendingStockSum} />
            <Column footer={returnToAdminSum} />
            <Column footer={customerReturnSum} />
            <Column footer={soldSum} />
            <Column footer={remainingStockSum} />
    </Row>
    </ColumnGroup>;

    return (
        <div className="OutStanding">
            <ProgressSpinBarControl spinBarVisible={spinnerVisible} />
            <Toast ref={toast} position={'center'} />
            <h3>Gkm Wise Quantity Report</h3>
            <div >
                <div className="box">
                    <div class="field col">
                    <Dropdown className='Dropdown' value={selectedProduct} options={productData} id="product" optionLabel="productName" onChange={onChangeProduct} filter showClear placeholder="Select Product" />

                        <label>From:</label>
                        <input type='date' name='fyDate' className='date' ref={Filter} max={moment().format("YYYY-MM-DD")} />
                        <label>To:</label>
                        <input type='date' name='toDate' className='date' ref={Filter} max={moment().format("YYYY-MM-DD")} />
                        <button type="button filter" className='filter' onClick={handleFilter(onFilter)}>Load Report</button>
                        <button type="button" className='reset' onClick={handleFilter(onReset)}>Reset</button>
                    </div>
                </div>

                <div className='box'>

                    <ToolBoxGrid columns={columns} data={gkmWiseProductData} fileName="Gkm-wise product Report"></ToolBoxGrid>
                    <DataTable value={gkmWiseProductData} header={header} globalFilter={globalFilter} scrollHeight='700px' footerColumnGroup={totalFooter} >
                        <Column field="serialNo" header="Sr No" style={{ width: '5%' }}></Column>
                        <Column field="franchise" header="Franchise Name" style={{ width: '30%' }}></Column>
                        {/* <Column field="company" header="Company"></Column> */}
                        <Column field="receivedStock" header="Received Stock"></Column>
                        <Column field="pendingStock" header="Pending Stock"></Column>
                        <Column field="returnToAdminStock" header="Return To Admin Stock"></Column>
                        <Column field="returnStock" header="Return Stock"></Column>
                        <Column field="soldStock" header="Sold Stock"></Column>
                        <Column field="remainingStock" header="Remaining Stock"></Column>
                        {/* <Column field="stockValue" header="Stock Value"></Column> */}

                    </DataTable>
                </div>
            </div>

        </div>
    );
}
export default GkmWiseProduct;