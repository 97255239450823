import React from 'react'

class ExportCsvReport extends React.Component {
    exportCSV=(dt)=>{
        dt.current.exportCSV();

    }

  
}

export default ExportCsvReport