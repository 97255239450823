import { Button } from 'primereact/button';
import axios from 'axios';
import GreenFieldService from '../../services/greenFieldService';
import Config from '../../Config.json';
import { Toast } from 'primereact/toast';
import 'primeflex/primeflex.css';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from "react-hook-form";
import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import '../../App.css';
import '../Franchise/CreateInvoice.css';
import { TabView, TabPanel } from 'primereact/tabview';
import CustomerMaster from '../Master/CustomerMaster';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { BlockUI } from 'primereact/blockui';
import authHeader from '../../authHeader';
import ProgressSpinBarControl from '../Misc/progressSpinBarControl';
import { useContext } from 'react';
import { GreenFieldContext } from '../../App';
import { shouldLockDate } from '../Misc/utilities';


function CreateInvoice(props) {
    const validationSchema = Yup.object().shape({
        // title: Yup.string()
        //     .required('Title is required'),

    });
    const { register, handleSubmit, reset, setValue } =
        useForm(
            { resolver: yupResolver(validationSchema) }
        );

    const { errors: error1, register: register1, handleSubmit: handleFooter, reset: handleReset, setValue: setFooterValue } =
        useForm(
            { resolver: yupResolver(validationSchema) }
        );
    const greenFieldService = new GreenFieldService();
    const toast = useRef(null);
    const [categories, setCategoryData] = useState([]);
    const [customersData, setCustomersData] = useState([]);

    const [selectedCategory, setSelectedCategory] = useState();
    const [buttonLabel, setButtonLabel] = useState("Save");
    const [globalFilter, setGlobalFilter] = useState('');
    const [selectedRadioData, setSelectedRadioData] = useState(null);
    const [isAddMode, setIsAddMode] = useState(true);
    const [isCreateInvoice, setIsCreateInvoice] = useState(false);
    const [productData, setProductsData] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState();
    const [batchNoData, setBatchNoData] = useState([]);
    const [selectedBatchNo, setSelectedBatchNo] = useState();
    const [availabaleStock, setAvailableStock] = useState();
    const [amountValue, setAmountValue] = useState();
    const [invoiceData, setInvoiceData] = useState([]);
    var [dueAmountValue, setDueAmount] = useState(0);
    const [discountAmount, setDiscountAmount] = useState();
    const [qty, setQty] = useState();
    const [selectedCustomer, setSelectedCustomer] = useState();
    let history = useHistory();
    const franchiseId = localStorage.getItem('franchiseId');
    const [disabledAddButton, setDisabledAddButton] = useState(false);
    const [blockedPanel, setBlockedPanel] = useState(false);
    const [spinnerVisible, setSpinnerVisible] = useState(false);
    const [buttonDisable, setButtonDisable] = useState(false);

    var accessLevel = parseInt(sessionStorage.getItem('accessLevel'));

    var roundUpValue = 0;
    var hamaliValue = 0;
    var transportValue = 0;
    var invNo;

    const [locked, setIsLocked] = useState(false);

    const [lockDate, setIsLockDate] = useState();
    const [finalLock, setFinalLock] = useState(false);   
     const { lockStatus } = useContext(GreenFieldContext);
    /*  const {lockStatus}=useContext(GreenFieldContext);
     const [locked, setIsLocked]=useState(false);
     useEffect(() => {
         alert("IN Create INvoice "+lockStatus);
        setIsLocked(lockStatus);
       }, [lockStatus]) */
    useEffect(() => {
        countDown();
        greenFieldService.GetCategoryData().then(data => {
            setCategoryData(data);
        });
        let isActive=1;
        greenFieldService.GetFranchiseProductsData(franchiseId,isActive).then(data => {
            setProductsData(data);
        });

        getCustomersData();

        if (accessLevel > 1) {
            setBlockedPanel(false);
        }
        else {
            setBlockedPanel(true);
        }
       

    }, [])

    useEffect(() => {
        const interval = setInterval(() => {
            countDown();
        }, 5000);
        return () => clearInterval(interval);


    }, [])

    function countDown() {
        // time = time - 1;
        greenFieldService.GetFranchiseLockScreenStatus(localStorage.getItem('franchiseId')).then(data => {
           // if(locked!=data.currentLockStatus){
            setIsLocked(data.currentLockStatus);
            setIsLockDate(data.lockDate);
            //}
        });
    }



    let getCustomersData = (() => {
        greenFieldService.GetCustomerData(franchiseId).then(data => {
            data.forEach(a => {
                a.isKisanCardValue = a.isKisanCard ? "Yes" : "No";
                a.customerName = `${a.name},|${a.district},${a.town}| Kisan Card -${a.isKisanCardValue}|`;

            })
            setCustomersData(data);
        });

    })
    var errors = [];

    const validate = (data) => {

        if (!selectedCustomer) {
            errors.push('* select Customer is required.', <br />);
        }
        if (!data?.invoiceDate) {
            errors.push('* Invoice Date is required', <br />);
        }

        if (invoiceData.length <= 0) {
            errors.push('* Add Product is required', <br />);
        }

    };

    var gridErrors = [];

    const gridValidate = (data) => {

        if (!selectedProduct) {
            gridErrors.push('* Product is required.', <br />);
        }
        if (!selectedBatchNo) {
            gridErrors.push('* Batch Number is required', <br />);
        }
        if (!data?.quantity) {
            gridErrors.push('* Quantity is required', <br />);
        }


    };

    // const onChangeCategory = (e) => {
    //     setSelectedCategory(e.value);
    //     greenFieldService.GetFranchiseProductsData(franchiseId).then(data => {
    //        /*  let filterData=data.filter(a => a.categoryId === e.value.id);
    //         setProductsData(filterData); */
    //         setProductsData(data); 
    //     });        
    // }

    const onNext = (e) => {


        if (selectedCategory === undefined) {

            toast.current.show({ severity: 'warn', sticky: true, summary: 'Alert', detail: "Please Select Category First" });
            return;
        }
        setIsCreateInvoice(true);
    }

    const onChangeCustomer = (e) => {
        setSelectedCustomer(e.value);
        // let currentDate = moment(new Date()).format('YYYY-MM-DD');
        // setFooterValue("invoiceDate", currentDate);
    }

    const onChangeProduct = (e) => {
        if (selectedProduct !== undefined) {
            const fields = [
                "expiryDate",
                "stock",
                "quantity",
                "frp",
                "mrp",
                "amount",
                "discount",
                "total",
                "type"
            ];
            fields.forEach((field) => setValue(field, ''));
            setSelectedBatchNo('');
            // setSelectedProduct(undefined);
            // setSelectedProduct(e.value);

        }
        // else {
        setSelectedProduct(e.value);

        greenFieldService.GetDistributedBatchNo(e.value.id, franchiseId).then(data => {
            setBatchNoData(data);
        });
        // }

    }


    const onChangeBatchNo = (e) => {
        setSelectedBatchNo(e.value);
        GetAvailableStock(selectedProduct.id, e.value.batchNo, franchiseId);
        GetProductExpiryDate(selectedProduct.id, e.value.batchNo);

        setValue('frp', parseFloat(selectedProduct.salePrice));
        setValue('mrp', parseFloat(selectedProduct.mrp));
        setValue('gst', selectedProduct.taxRate);


    }

    const GetAvailableStock = (productId, batchNo, franchiseId) => {
        greenFieldService.GetFranchiseAvailableStock(productId, batchNo, franchiseId).then(data => {
            setAvailableStock(data);
            setValue('stock', data);
        });
    }

    const GetProductExpiryDate = (productId, batchNo) => {
        greenFieldService.GetExpiryDate(productId, batchNo).then(data => {
            // setExpiryDate(data);
            // let date=moment(data).format('YYYY-MM-DD');
            //  date=moment(data).format('MM-DD-YYYY');
            setValue('expiryDate', data);
        });
    }

    const onQuantity = (e) => {


        let quantity = parseInt(e.target.value);
        if (quantity > availabaleStock) {
            toast.current.show({ severity: 'warn', sticky: true, summary: 'Alert', detail: "Quantity is greater than available Stock" });
            setValue('quantity', null);
            // setDisabledAddButton(true);
            return;
        }
        if (quantity <= 0) {
            toast.current.show({ severity: 'warn', sticky: true, summary: 'Alert', detail: "Quantity should be greater than 0" });
            setValue('quantity', null);
            // setDisabledAddButton(true);
            return;
        }

        setQty(quantity);
        let amount = selectedProduct.salePrice * quantity;
        setAmountValue(amount);
        setValue('amount', parseFloat(amount));
        setValue('type', selectedProduct.type);
        setValue('discount', selectedProduct.discountValue);
        if (selectedProduct.type === "Percentage") {
            let discountValue = amount * selectedProduct.discountValue / 100;
            // let discountRate= (amount * selectedProduct.discountValue * 100) / (100 + selectedProduct.taxRate)
            // let discountValue= discountRate.toFixed(2) ;

            setDiscountAmount(parseFloat(discountValue));
            let totalValue = amount - discountValue;
            setValue('total', parseFloat(totalValue));

        }
        else {
            let discountRate = selectedProduct.discountValue;

            let discountValue = discountRate.toFixed(2);
            // let discountValue=selectedProduct.discountValue * quantity;
            setDiscountAmount(parseFloat(parseFloat(discountValue)));
            let totalValue = amount - discountValue;
            setValue('total', parseFloat(totalValue));

        }

    }

    const onDiscount = (e) => {

        if (selectedProduct.type === "Percentage") {
            // let discountRate= (amountValue * parseFloat(e.target.value) * 100) / (100 + selectedProduct.taxRate)
            let discountValue = e.target.value.toFixed(2);
            setDiscountAmount(discountValue);
            let totalValue = amountValue - discountValue;
            setValue('total', totalValue);
        }
        else {
            let discountValue = parseFloat(e.target.value);
            // let discountRate= (discount * 100) / (100 + selectedProduct.taxRate);

            // let discountValue= discountRate.toFixed(2) ;

            setDiscountAmount(parseFloat(discountValue));
            let totalValue = amountValue - discountValue;
            setValue('total', totalValue);

        }
        // let discountValue=parseFloat(e.target.value);
        // let totalValue=amountValue - discountValue;
        // setValue('total',totalValue);
    }

    const onAdd = (data) => {
        gridValidate(data);
        if (gridErrors.length > 0) {
            // eslint-disable-next-line no-lone-blocks
            {
                gridErrors.map(() => {
                    toast.current.show({ severity: 'warn', detail: gridErrors, sticky: true });
                }

                )
            }
            gridErrors = [];
            return;
        }
        let duplicate = invoiceData.find(a => a.productId === selectedProduct.id && a.batchNo === selectedProduct.batchNo);
        if (duplicate) {
            toast.current.show({ severity: 'warn', detail: 'This Product is already added', sticky: true });
            onReset();
            return;

        }
        if (selectedProduct !== '') {
            data.product = selectedProduct.name;
            data.productId = selectedProduct.id;
            data.batchNo = selectedBatchNo.batchNo;
            data.gst = selectedProduct.taxRate;
            data.discountAmount = discountAmount;
            data.discountPercent = selectedProduct.discountValue;
            data.discountType = selectedProduct.type;
            data.hsnCode=selectedProduct.hsnCode;
            data.quantity = parseInt(data.quantity);
            data.frp = parseFloat(data.frp);
            data.mrp = parseFloat(data.mrp);
            data.amount = parseFloat(data.amount);
            data.total = parseFloat(data.total);
            data.royalty = parseFloat(selectedProduct.royaltyRate * data.quantity);
            if (data.type === "Absolute") {
                data.discount = '---';
            }

            let _invoiceData = [...invoiceData];
            let _data = { ...data };
            _invoiceData.push(_data);


            setInvoiceData(_invoiceData);
            let grandTotalValue = (_invoiceData && _invoiceData.reduce((a, v) => a + parseFloat(v.total), 0));
            setFooterValue('grandTotal', grandTotalValue.toFixed(2));
            setFooterValue('dueAmount', grandTotalValue.toFixed(2));
            setFooterValue('roundUp', 0);
            setFooterValue('hamali', 0);
            setFooterValue('transport', 0);
            setDueAmount(grandTotalValue.toFixed(2));
            onReset();


        }

    }


    const onRoundUpValue = (e) => {
        roundUpValue = parseFloat(e.target.value === '' ? 0 : e.target.value);
        dueCalculate();
    }
    const onHamaliValue = (e) => {
        hamaliValue = parseFloat(e.target.value === '' ? 0 : e.target.value);
        dueCalculate();
    }
    const onTransportValue = (e) => {
        // transportValue = parseFloat(e.target.value === '' ? 0 : e.target.value);
        transportValue = parseFloat(0);

        dueCalculate();
    }
    const dueCalculate = () => {
        let due = parseFloat(dueAmountValue) + roundUpValue + transportValue + hamaliValue;
        // setDueAmount(due);
        setFooterValue('dueAmount', due);
    }

    const onReset = () => {

        const fields = [
            "expiryDate",
            "stock",
            "quantity",
            "frp",
            "mrp",
            "amount",
            "discount",
            "total",
            "type"
        ];
        fields.forEach((field) => setValue(field, ''));
        setSelectedProduct('');
        setSelectedBatchNo('');
    }

    function validateLockDate(selectedDate) {
        if (locked) {

            if (shouldLockDate(selectedDate, lockDate)) {

                setFinalLock(true);
                toast.current.show({ severity: 'warn', detail: `Franchise is locked till ${lockDate}`, summary: 'Payment Details' });
                setFooterValue('invoiceDate','');
            }
            else {
                setFinalLock(false);
            }
        }
        else {
            setFinalLock(false);
        }
    }

    const onSave = (data, e) => {
        e.preventDefault();
        setButtonDisable(true);
        validate(data);
        if (errors.length > 0) {
            // eslint-disable-next-line no-lone-blocks
            {
                errors.map(() => {
                    toast.current.show({ severity: 'warn', detail: errors, sticky: true });
                }

                )
            }
            errors = [];
            return;
        }
        setSpinnerVisible(true);
        if (data.invoiceDate === undefined || '') {

            toast.current.show({ severity: 'warn', sticky: true, summary: 'Alert', detail: "Please enter Invoice Date" });
            setSpinnerVisible(false);
            setButtonDisable(false);
            return;
        }
        if (data.invoiceDate > moment().format("YYYY-MM-DD")) {

            toast.current.show({ severity: 'warn', sticky: true, summary: 'Alert', detail: "Please enter correct Date" });
            setSpinnerVisible(false);
            setButtonDisable(false);
            return;
        }
        let invoiceDataInfo = [];
        console.warn(data);
        //data.categoryId=selectedCategory.id;
        data.categoryId = selectedProduct?.categoryId;
        data.franchiseId = franchiseId;
        data.customerId = selectedCustomer.id;
        data.invoiceDate = data.invoiceDate;
        data.roundUp = parseFloat(data.roundUp);
        data.grandTotal = parseFloat(data.grandTotal);
        data.hamali = parseFloat(data.hamali);
        // data.transport = parseFloat(data.transport);
        data.transport = 0;
        data.dueAmount = parseFloat(data.dueAmount);
        data.invoiceDetailsInfo = invoiceData;

        return axios.post(`${Config.apiUrl}/Invoice/SaveInvoiceDetails`, data, { headers: authHeader() })
            .then(response => {
                if (response.data.success) {
                    toast.current.show({ severity: 'success', detail: response.data.msg, autoDismiss: true });
                    setSpinnerVisible(false);
                    // localStorage.setItem('invNo', (response.data.invNo));
                    invNo = parseInt(response.data.invNo);
                    PrintPage();
                }
                else {
                    toast.current.show({ severity: 'error', detail: response.data.msg, sticky: true });
                }
            }).catch(error => {
                toast.current.show({ severity: 'error', sticky: true, summary: 'Error', detail: error.message });
            })
            .finally(() => {
                setInvoiceData('')
                setButtonDisable(false);
                setSpinnerVisible(false);

                // setSpinnerVisible(false);
                // SetFocusValue(true);
            })
    }

    const PrintPage = () => {
        history.push(`/InvoicePrint/${selectedCustomer.id}/${invNo}/${franchiseId}`);
    }
    // const updateInvoice = (data) => {

    //     // setSpinnerVisible(true);
    //     data.id = selectedRadioData.id;
    //     return axios.put(`${Config.apiUrl}/Units/`, data)
    //         .then(response => {
    //             if (response.data.success) {
    //                 toast.current.show({ severity: 'success', detail: 'Data Update Successfully', sticky: true });
    //             }
    //             else {
    //                 toast.current.show({ severity: 'error', detail: response.data.msg, sticky: true });
    //             }
    //         }).catch(error => {
    //             toast.current.show({ severity: 'error', sticky: true, summary: 'Error', detail: error });
    //         })
    //         .finally(() => {
    //             window.location.reload();
    //             // setSpinnerVisible(false);
    //             // SetFocusValue(true);
    //         })
    // }

    const onEdit = (e) => {
        setIsAddMode(false);
        //  id=selectedRadioData.id;
        setValue("name", selectedRadioData.name);
        setButtonLabel("Update");
    }

    const header = (

        <div className="table-header">

            <div>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" value={globalFilter} onChange={(e) => setGlobalFilter(e.target.value)} placeholder="Global Search" />
                </span>
            </div>

            <div>
                {/* <Link to={`/Flat_Master/edit/${selectedRadioData.id}`}> */}
                <Button
                    icon="pi pi-pencil"
                    value="Edit"
                    className="btnEdit1"
                    onClick={onEdit}

                />
                {/* </Link>     */}

            </div>
        </div>
    );

    const onDelete = (rowData) => {
        if (rowData != null) {
            toast.current.show({
                severity: 'warn', sticky: true, content: (
                    <div className="flex flex-column" style={{ flex: '1' }}>
                        <div className="text-center">
                            <i className="pi pi-exclamation-triangle" style={{ fontSize: '3rem' }}></i>
                            <h4>Are you sure?</h4>
                            <p>Confirm to proceed</p>
                        </div>
                        <div className="grid p-fluid">
                            <div className="col-6">
                                <Button type="button" label="Yes" onClick={() => onYes(rowData)} className="p-button-success" />
                            </div>
                            <div className="col-6">
                                <Button type="button" label="No" onClick={() => onNo(rowData)} className="p-button-secondary" />
                            </div>
                        </div>
                    </div>
                )
            });
        }
        else {
            toast.current.show({ severity: 'warn', sticky: true, detail: "PLEASE SELECT ROW" });
            return;
        }
    }

    const onYes = (rowData) => {
        toast.current.clear();
        try {
            let index = invoiceData.findIndex(a => a.productId === rowData.productId);
            if (index > -1) {

                let _invoiceData = [...invoiceData];
                let data = _invoiceData.splice(index, 1);

                setInvoiceData(_invoiceData);

                let grandTotalValue = (_invoiceData && _invoiceData.reduce((a, v) => a + parseFloat(v.total), 0));
                setFooterValue('grandTotal', grandTotalValue.toFixed(2));
                setFooterValue('dueAmount', grandTotalValue.toFixed(2));

                toast.current.show({ severity: 'success', detail: "Product Removed from Invoice", autoDismiss: true });

            }
            else {
                toast.current.show({ severity: 'error', sticky: true, detail: "Product Not Removed from Invoice" });
            }
        }
        catch {

        }
        finally {


            toast.current.clear();

        }


        // window.location.reload();
    }

    const onNo = () => {
        toast.current.clear();
        return;
    };

    function invoiceDateChange(e){
        validateLockDate(e.target.value);
    }

    const ButtonIcon = (rowData) => {
        return <div>
            <Button icon="pi pi-trash" className="p-button-rounded p-button-danger" onClick={() => onDelete(rowData)} aria-label="Bookmark" />
        </div>
    }

    return (
        <BlockUI blocked={blockedPanel}>

            <ProgressSpinBarControl spinBarVisible={spinnerVisible} />
            <div className="Invoice" style={{ minHeight: '1029px' }}>
                <Toast ref={toast} position={'center'} />

                {/*   {!isCreateInvoice && (
                <div>
                    <h3 style={{ margin: '0px', padding: '10px' }}>Create New Invoice</h3>
                    <div className='box'>
                        <div className="field col">
                            <label style={{ marginLeft: '10px' }}>Select Product Category<span style={{ color: 'red' }}>*</span></label>
                            <Dropdown className='Dropdown' name="categoryName" value={selectedCategory} options={categories} id="category" optionLabel="name" ref={register} onChange={onChangeCategory} filter showClear placeholder="Select category" />
                        </div>
                        <div >
                            <button className='button' type="button" onClick={onNext}>Next</button>
                        </div>
                    </div>
                </div>
            )} */}

                {true && (

                    <div >

                        <h3 style={{ margin: '0px', padding: '10px', }}>Make Invoice</h3>

                        <div className='box' style={{ minHeight: '950px' }}>

                            <TabView >
                                <TabPanel header="Make Invoice" >
                                    <div>
                                        <label style={{ margin: '10px' }}>Customer Name<span style={{ color: 'red' }}>*</span></label>
                                        <Dropdown className='Dropdown' name="categoryName" value={selectedCustomer} options={customersData} id="category" optionLabel="customerName" ref={register} onChange={onChangeCustomer} filter showClear placeholder="Select Customer Name" />
                                    </div>
                                    <div>
                                        <label style={{ margin: '10px' }}>Invoice Date<span style={{ color: 'red' }}>*</span></label>
                                        <input className='input' type="date" name="invoiceDate" ref={register1}  onChange={invoiceDateChange} class="form-control" max={moment().format("YYYY-MM-DD")} />
                                    </div>

                                    <table class="table table-bordered table-striped table-nowrap dataTable">
                                        <thead>
                                            <tr>
                                                <th>Select Item</th>
                                                <th>Batch</th>
                                                <th>Expiry</th>
                                                <th>Stock</th>
                                                <th>Qty</th>
                                                <th>FRP</th>
                                                <th>MRP</th>
                                                <th>Amount</th>
                                                <th>Discount Type</th>
                                                <th>Discount</th>
                                                <th>Total</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>

                                        <tbody id="rows-list">
                                            <tr>
                                                <td class="product-list">
                                                    <Dropdown className='form-control p-inputtext' name="productName" value={selectedProduct} options={productData} id="productName" optionLabel="name" ref={register} onChange={onChangeProduct} filter showClear placeholder="Select Product" />
                                                </td>
                                                <td><Dropdown className='form-control p-inputtext' autoComplete="off" name="batchNo" value={selectedBatchNo} options={batchNoData} id="batchNo" optionLabel="batchNo" ref={register} onChange={onChangeBatchNo} filter showClear placeholder="Select Batch" /></td>
                                                <td><input className='input' type="text" ref={register} name="expiryDate" class="form-control" readonly="" /> </td>
                                                <td><input className='input' type="number" ref={register} name="stock" class="form-control stock" readonly="" /> </td>
                                                <td><input className='input' type="number" ref={register} autoComplete="off" name="quantity" onChange={onQuantity} class="form-control carton" /></td>
                                                <td>
                                                    <input className='input' type="number" ref={register} name="frp" class="form-control rate" readonly="" />
                                                    {/* <input type="hidden" name="mrp" class="form-control incentive" /> */}
                                                </td>
                                                <td><input className='input' type="number" ref={register} name="mrp" class="form-control mrp" readonly="" /></td>
                                                <td><input className='input' type="number" ref={register} name="amount" class="form-control gst" readonly="" /></td>
                                                <td><input className='input' type="text" ref={register} name="type" class="form-control " readonly="" /></td>

                                                {/* <td><input className='input' type="number" ref={register} name="gst" class="form-control gst" readonly="" /></td> */}
                                                <td><input className='input' type="number" ref={register} autoComplete="off" name="discount" onChange={onDiscount} class="form-control gst" /></td>
                                                <td><input className='input' type="number" ref={register} name="total" class="form-control total" readonly="" /></td>
                                                <td align="center">
                                                    <button type="button" className='button' disabled={disabledAddButton ||finalLock } onClick={handleSubmit(onAdd)}>Add</button>

                                                </td>
                                            </tr>

                                        </tbody>


                                    </table>
                                    <DataTable className="dataTable" value={invoiceData} editMode="row" selection={selectedRadioData} onSelectionChange={e => setSelectedRadioData(e.value)}  >
                                        <Column field="product" header="Selected Item" ></Column>
                                        <Column field="batchNo" header="Batch"  ></Column>
                                        <Column field="expiryDate" header="Expiry"  ></Column>
                                        <Column field="stock" header="Stock"  ></Column>
                                        <Column field="quantity" header="Quantity"  ></Column>
                                        <Column field="frp" header="FRP"  ></Column>
                                        <Column field="mrp" header="MRP"  ></Column>
                                        <Column field="gst" header="GST in %"  ></Column>
                                        <Column field="amount" header="Amount"  ></Column>
                                        <Column field="type" header="Type" ></Column>
                                        <Column field="discount" header="Discount in % "  ></Column>
                                        <Column field="discountAmount" header="Discount "  ></Column>
                                        <Column field="total" header="Total"  ></Column>
                                        <Column body={ButtonIcon} field="Action" header="Action"></Column>


                                    </DataTable>
                                    <div className="footer">
                                        <div className='rightAlign'>
                                            <label style={{ color: 'inherit' }}>R/O:</label>
                                            <input type="number" class="form-control input" ref={register1} onChange={onRoundUpValue} name="roundUp" />
                                        </div>
                                        <div className='rightAlign'>
                                            <label style={{ color: 'inherit' }}>Grand Total:</label>
                                            <input type="text" class="form-control input" ref={register1} name="grandTotal" readonly="readonly" />
                                        </div>
                                        <div className='rightAlign' >
                                            <label style={{ color: 'inherit' }}>Hamali:</label>
                                            <input type="number" class="form-control input" ref={register1} onChange={onHamaliValue} name="hamali" />
                                        </div>
                                        <div className='rightAlign'>
                                            <label style={{ color: 'inherit' }}>Transport:</label>
                                            <input type="number" id="Inv_Trans" disabled class="form-control input" ref={register1} onChange={onTransportValue} name="transport" />
                                        </div>
                                        <div className='rightAlign'>
                                            <label style={{ color: 'inherit' }}>Amount:</label>
                                            <input type="text" id="due_amount" class="form-control input" ref={register1} name="dueAmount" readonly="" />
                                        </div>
                                        <div className={finalLock? 'rightAlignLocked':'rightAlign'}>
                                            <button type="button" disabled={buttonDisable || finalLock} className='button' onClick={handleFooter(onSave)}>Save</button>
                                        </div>

                                    </div>
                                </TabPanel>
                                <TabPanel header="Add New Customer" >
                                    <CustomerMaster customerReload={() => getCustomersData()} />
                                </TabPanel>
                            </TabView>

                        </div>
                    </div>
                )
                }
                {/* </div> */}
            </div >
        </BlockUI>
    )
}

export default CreateInvoice;