import { Button } from 'primereact/button';
import axios from 'axios';
import GreenFieldService from '../../services/greenFieldService';
import Config from '../../Config.json';
import { Toast } from 'primereact/toast';
import 'primeflex/primeflex.css';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from "react-hook-form";
import React, { useState, useEffect, useRef } from 'react';
import './UnitMaster.css';
import '../../App.css';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { BlockUI } from 'primereact/blockui';
import ToolBoxGrid from '../Misc/ToolBoxGrid';
import authHeader from '../../authHeader';


function UnitMaster(id) {
    const validationSchema = Yup.object().shape({
        // title: Yup.string()
        //     .required('Title is required'),

    });

    const [columns,setColumns]=useState([{ title: 'SrNo', dataKey: 'srNo' },{ title: 'Name', dataKey: 'name' }]);
    const { errors, register, handleSubmit, reset, setValue } =
        useForm(
            { resolver: yupResolver(validationSchema) }
        );
    const greenFieldService = new GreenFieldService();
    const toast = useRef(null);
    const [unitData, setUnitsData] = useState([]);
    const [initialData, setInitialData] = useState();
    const [rowDataValue, setRowData] = useState();
    const [buttonLabel, setButtonLabel] = useState("Save");
    const [globalFilter, setGlobalFilter] = useState(null);
    const [selectedRadioData, setSelectedRadioData] = useState(null);
    const [isAddMode, setIsAddMode] = useState(true);
    const [filters1, setFilters1] = useState(null);
    const [blockedPanel, setBlockedPanel] = useState(false);
    var accessLevel = parseInt(sessionStorage.getItem('accessLevel'));


    useEffect(() => {
        getUnitDetails();
        initFilters1();
        if(accessLevel > 1)
        {
          setBlockedPanel(false);
        }
        else{
            setBlockedPanel(true);

        }

    }, [])
    const onChangeGlobal=(e)=>{
        let value=e.target.value;
       let _filters1 = { ...filters1 };
       _filters1['global'].value = value;

       setFilters1(_filters1);
       
        setGlobalFilter(value);
    }

    
    const initFilters1 = () => {
        setFilters1({
            'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
            
        });
        setGlobalFilter('');
    }
    const clearFilter1 = () => {
        initFilters1();
    }
    const getUnitDetails = () => {
        greenFieldService.GetUnitsData().then(data => {
            let serialNo = 1;
            data.forEach(a => {
                a.srNo = serialNo;
                serialNo++;
            })
            setUnitsData(data);
            setInitialData(data);
        });
    }



    const onSave = (data) => {
        console.warn(data);
        return isAddMode ? addUnits(data) : updateUnits(data);
    }
    const addUnits = (data) => {
        if (data.name === '') {
            toast.current.show({ severity: 'warn', detail: "Unit Name is required", sticky: true });
            return;

        }

        // setSpinnerVisible(true);
        return axios.post(`${Config.apiUrl}/Units/InsertUnits`, data, { headers: authHeader() })
            .then(response => {
                if (response.data.success) {
                    toast.current.show({ severity: 'success', detail: response.data.msg, autoDismiss: true, });
                }
                else {
                    toast.current.show({ severity: 'error', detail: response.data.msg, sticky: true });
                }
            }).catch(error => {
                toast.current.show({ severity: 'error', sticky: true, summary: 'Error', detail: error.message });
            })
            .finally(() => {
                setValue('name', '');
                getUnitDetails();

            })
    }

    const updateUnits = (data) => {

        // setSpinnerVisible(true);
        data.id = rowDataValue.id;
        return axios.put(`${Config.apiUrl}/Units/UpdateUnits`, data, { headers: authHeader() })
            .then(response => {
                if (response.data.success) {
                    toast.current.show({ severity: 'success', detail: 'Data Update Successfully', autoDismiss: true, });
                }
                else {
                    toast.current.show({ severity: 'error', detail: response.data.msg, sticky: true });
                }
            }).catch(error => {
                toast.current.show({ severity: 'error', sticky: true, summary: 'Error', detail: error });
            })
            .finally(() => {
                setValue('name', '');
                getUnitDetails();
                setIsAddMode(true);
                setButtonLabel("Save");

                // setSpinnerVisible(false);
                // SetFocusValue(true);
            })
    }

    const onEdit = (rowData) => {
        setIsAddMode(false);
        id = rowData.id;
        setValue("name", rowData.name);
        setButtonLabel("Update");
        setRowData(rowData);
    }

    const onDelete = (rowData) => {
        toast.current.show({
            severity: 'warn', sticky: true, content: (
                <div className="p-flex p-flex-column" style={{ flex: '1' }}>
                    <div className="text-center">
                        <i className="pi pi-exclamation-triangle" style={{ fontSize: '3rem' }}></i>
                        <h4>Are you sure?</h4>
                        <p>Confirm to Delete</p>
                    </div>
                    <div className="grid p-fluid">
                        <div className="col-6">
                            <Button type="button" label="Yes" className="p-button-success" onClick={() => onYes(rowData)} />
                        </div>
                        <div className="col-6">
                            <Button type="button" label="No" className="p-button-secondary" onClick={() => onNo()} />
                        </div>
                    </div>
                </div>
            )
        });
    }

    const onYes = (rowData) => {
        toast.current.clear();
        axios.delete(`${Config.apiUrl}/Units/Delete?id=${rowData.id}`, { headers: authHeader() })
            .then(response => {
                console.log(response)
                if (response.data.success) {
                    toast.current.show({ severity: 'success',sticky: true, detail: response.data.msg ,autoDismiss:true});
                }
                else {
                    toast.current.show({ severity: 'error', sticky: true, detail: response.data.msg });
                }
            })
            .catch(error => {
                toast.current.show({ severity: 'error', sticky: true, summary: 'Error', detail: error });
            })
            .finally(() => {
                toast.current.clear();
                getUnitDetails();

            })
    }

    const onNo = () => {
        toast.current.clear();
        return;
    };

    const header = (

        <div className="Unit table-header">

            <div>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" value={globalFilter}  onChange={onChangeGlobal} placeholder="Global Search" />
                </span>
              
            </div>

        </div>
    );

    const buttons = (rowData) => {
        return <div>

            <Button icon="pi pi-pencil" className="p-button-rounded p-button-info" onClick={() => onEdit(rowData)} aria-label="Bookmark" />
            <Button icon="pi pi-trash" className="p-button-rounded p-button-danger" onClick={() => onDelete(rowData)} aria-label="Bookmark" />


        </div>;
    }









    return (
        <BlockUI blocked={blockedPanel}>
         
        <div className="Unit" style={{ minHeight: '1029px' }}>
            <Toast ref={toast} position={'center'} />

            <h3 style={{ margin: '0px', padding: '10px' }}>Add Units</h3>
            <div className='box'>
                <label style={{ marginLeft: '10px' }}>Name<span style={{ color: 'red' }}>*</span></label>
                <div className="field col">
                    <input id="UnitName" type="text" name="name" ref={register({ required: true })} autoComplete="off" className="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"></input>
                </div>
                <div >
                    <button className='button' type="button" onClick={handleSubmit(onSave)}>{buttonLabel}</button>
                </div>
            </div>

            {/* <div className='box'> */}
            <div className='box'>
            <ToolBoxGrid columns={columns} data={unitData} fileName="Units"></ToolBoxGrid>
                <DataTable value={unitData} style={{width:'50%'}}  filters={filters1} globalFilterFields={['name']} header={header} className="dataTable" editMode="row"   >
                    <Column field="srNo" header="Sr.No" style={{width:'30px'}}  ></Column>
                    <Column field="name" header="Name"  ></Column>
                    <Column body={buttons} header="Action" style={{ width: '200px' }}></Column>

                </DataTable>
            </div>
            {/* </div> */}
        </div>
        </BlockUI>
        )
}

export default UnitMaster;