import './AllAccounts.css';
import 'primeflex/primeflex.css';
import React, { useState, useRef } from 'react';
import { useForm } from "react-hook-form";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'bootstrap';
import { useEffect } from 'react';
import GreenFieldService from '../services/greenFieldService';
import { Dialog } from 'primereact/dialog';
import axios from 'axios';
import authHeader from '../authHeader';
import { Toast } from 'primereact/toast';
import Config from '../Config.json';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { BlockUI } from 'primereact/blockui';
import ToolBoxGrid from './Misc/ToolBoxGrid';
import moment from 'moment';

var receivedAmountSum;


function AllAccounts() {

    const {
        register: Filter,
        errors: errors1,
        handleSubmit: handleFilter,
        setValue: setFilterValue,
    } = useForm();
    const [columns,setColumns]=useState([{ title: 'SrNo', dataKey: 'srNo' },{ title: 'Franchise', dataKey: 'franchiseName' },{ title: 'Amount Received(Rs.)', dataKey: 'amount' },{ title: 'Payment Mode', dataKey: 'type' },{ title: 'Ref. No', dataKey: 'reference' },{ title: 'Date', dataKey: 'paymentDateFormat' },{ title: 'Status', dataKey: 'status' }]);
    const { errors, register, handleSubmit, reset, setValue, } = useForm();
    const toast = useRef(null);
    const [globalFilter, setGlobalFilter] = useState();
    const [initialAccountData, setInitialAccountData] = useState([]);
    const greenFieldService = new GreenFieldService();
    const [accountData, setAccountData] = useState([]);
    const [visible, setVisible] = useState(false);
    const [selectedRowData, setRowData] = useState();
    const [amountData, setAmounts] = useState([]);
    const [blockedPanel, setBlockedPanel] = useState(false);
    var accessLevel = parseInt(sessionStorage.getItem('accessLevel'));
    const [exportData,setExportData]= useState([]);


    useEffect(() => {
        let d = moment(new Date()).format('YYYY-MM-DD');
        setFilterValue('fyDate',d);
        setFilterValue('toDate',d);
        
        getAllAccountDetails();
        
        getAllAmountDetails();
     

        if(accessLevel > 1)
        {
          setBlockedPanel(false);
        }
        else{
            setBlockedPanel(true);
        }

    }, []);

    const getAllAmountDetails =() =>{
        greenFieldService.GetAmounts(0).then(data => {
            setAmounts(data);
        })
    }

    const getExcelData=(data)=>{
        let exceldata=[];
        data.forEach(a=>{
            let newData={SrNo:a.serialNo,FranchiseName:a.franchiseName,Amount:a.amount,PaymentMode:a.type,RefNo:a.reference,Date:a.paymentDateFormat,Status:a.status};
            exceldata.push(newData);
        })
        setExportData(exceldata);
    }

    const getAllAccountDetails =() =>{
        greenFieldService.GetAllAccount(-1).then(data => {
            receivedAmountSum = (data.reduce((a, v) => a + parseFloat(v.amount), 0));
            let srNo = 1;
            data.forEach(d => {
                d.serialNo = srNo;
                d.paymentDate= d.paymentDate.replace('T', ' ');
                d.paymentDateValue=new Date(d.paymentDate);
                srNo++;
            })
            getExcelData(data);
            setAccountData(data);
            setInitialAccountData(data);
            // setRowData(data);
        });
    }

    const onFilter = (filterValue) => {
        let fromdate=new Date(filterValue.fyDate)  ;
        fromdate.setDate(fromdate.getDate()-1);
        let toDate=new Date(filterValue.toDate);

        let data = initialAccountData.filter(a => a.type=== filterValue.mode && a.status=== filterValue.status && a.paymentDateValue >= fromdate && a.paymentDateValue <= toDate );
        let srNo = 1;
        data.forEach(d => {
            d.serialNo = srNo;
            srNo++;
        })
        getExcelData(data);
        receivedAmountSum = (data.reduce((a, v) => a + parseFloat(v.amount), 0));
        setAccountData(data);

    }

    const onReset = () => {

        const fields = [
            "mode",
            "status",
            "fyDate",
            "toDate"
        ];
        fields.forEach((field) => setFilterValue(field, ''));
        getAllAccountDetails()
    }

    const Buttons = (rowData) => {
        return <div>
            <button type="button filter" className='filter' onClick={() => onManageStatus(rowData)}>Manage Status</button>

            {/* <button className=' delete' type="button" >Delete</button> */}
        </div>;
    }

    const onManageStatus = (rowData) => {
        setVisible(true);
        setRowData(rowData);

    }

    const onHide = () => {
        setVisible(false);

    }
    

    const renderFooter = () => {
        return (
            <div>
                <button className='button' type="button" onClick={handleSubmit(onUpdate)}>Update </button>
                <button className='reset' type="button" onClick={onHide}>cancel</button>
            </div>
        );
    }

    const onUpdate = (data) => {
        data.id = selectedRowData.id;

        return axios.put(`${Config.apiUrl}/Accounts/UpdateStatusOfAllAccount`, data, { headers: authHeader() })
            .then(response => {
                if (response.data.success) {
                    toast.current.show({ severity: 'success', detail: 'Data Update Successfully', autoDismiss:true });
                    onHide();
                    getAllAccountDetails();
                    getAllAmountDetails();
                }
                else {
                    toast.current.show({ severity: 'error', detail: response.data.msg, sticky: true });
                }
            }).catch(error => {
                toast.current.show({ severity: 'error', sticky: true, summary: 'Error', detail: error });
            })
            .finally(() => {
                // setAccountData(data);
                // setSpinnerVisible(false);
                // SetFocusValue(true);
            })


    }

    let totalFooter = <ColumnGroup>
<Row>
    <Column footer="Totals:" footerStyle={{ textAlign: 'right' }} />
    <Column footer='' />
    <Column footer={receivedAmountSum} />
    <Column footer='' />
    <Column footer='' />
    <Column footer='' />
    <Column footer='' />
    <Column footer='' />
</Row>
</ColumnGroup>;

    const header = (
        <div className="table-header">
            <div>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" className="globleInput" value={globalFilter} onChange={(e) => setGlobalFilter(e.target.value)} placeholder="Global Search" />
                </span>
            </div>
        </div>
    );

    return (
        <BlockUI blocked={blockedPanel}>
        <Toast ref={toast} position={'center'} />
        <div className="AllAccounts">

            <h3>Accounts</h3>
            <div className="Unit" style={{ display: 'flex' }}>
                <div className='box'>
                    <table className="table table-bordered">
                        {amountData.map((data, index) =>
                            <tbody>
                                <tr>
                                    <th>Total Sale : </th>
                                    <th>{amountData[index]?.totalSale}</th>
                                    <th>Paid Amount: </th>
                                    <th>{amountData[index]?.paidAmount}</th>
                                    <th>Balance Amount : </th>
                                    <th>{amountData[index]?.balanceAmount}</th>
                                </tr>
                            </tbody>
                        )
                        }
                    </table>

                    <div className="field col">
                            <label>Payment Mode:</label>
                            <select ref={Filter} name="mode" >
                                <option hidden value="" >Select Payment Mode</option>
                                <option>Online</option>
                                <option>Cash</option>
                            </select>

                            <label>Status:</label>
                            <select ref={Filter} name="status" >
                                <option hidden value="" >Select Status</option>
                                <option>Received</option>
                                <option>Not_Received</option>
                                <option>Pending</option>
                            </select>
                    </div>
                    <div className="field col">
                        <label>From:</label>
                        <input type='date' name='fyDate' className='date' ref={Filter} />
                        <label>To:</label>
                        <input type='date' name='toDate' className='date' ref={Filter} />
                        <button type="button filter" className='filter' onClick={handleFilter(onFilter)}>Filter</button>
                        <button type="button" className='reset' onClick={handleFilter(onReset)}>Reset</button>
                    </div>
                </div>
            </div>

            <div className="Unit" style={{ display: 'flex',minHeight: '900px' }}>
                <div className='box'>
                    {/* <div>
                        <button type='button'>Copy</button>
                        <button type='button'>CSV</button>
                        <button type='button'>Excel</button>
                        <button type='button'>PDF</button>
                        <button type='button'>Print</button>
                    </div> */}
                    <ToolBoxGrid columns={columns} data={exportData} fileName="accounts"></ToolBoxGrid>
                    <DataTable value={accountData} className='dataTable' scrollable scrollHeight='700px'  footerColumnGroup={totalFooter} globalFilter={globalFilter} header={header} editMode='row'>
                        <Column field="serialNo" header="Sr No"></Column>
                        <Column field="franchiseName" header="Franchise"></Column>
                        <Column field="amount" header="Amount Received(RS.)"></Column>
                        <Column field="type" header="Payment Mode"></Column>
                        <Column field="reference" header="Ref. No"></Column>
                        <Column field="paymentDateFormat" header="Date"></Column>
                        <Column field="status" header="Status"></Column>
                        <Column body={Buttons} field=""  header="Action"></Column>

                    </DataTable>

                </div>

                <Dialog header="Update Status" visible={visible} onHide={() => onHide()} breakpoints={{ '960px': '75vw' }} style={{ width: '50vw' }} footer={renderFooter('displayResponsive')}>
                    <div className="field col">

                        <label for="Product Name">Status<span style={{ color: 'red' }}>*</span></label>
                        <select name="status" ref={register} style={{ height: '30px', width: '200px', marginRight: '10px' }}>
                            <option hidden value="">Select Status </option>
                            <option>Received</option>
                            <option>Not_Received</option>
                            {/* <option>Return</option> */}
                        </select>
                    </div>

                </Dialog>
            </div>

        </div>
        </BlockUI>

    );
}
export default AllAccounts;
