// import './AdminStockReturn.css';
// import '../App.css';
import 'primeflex/primeflex.css';
import React, { useState, useEffect, useRef } from 'react';
import { useForm } from "react-hook-form";
import { Dropdown } from 'bootstrap';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import GreenFieldService from '../../services/greenFieldService';
import { BlockUI } from 'primereact/blockui';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { Toast } from 'primereact/toast';
import ProgressSpinBarControl from '../Misc/progressSpinBarControl';
import moment from 'moment';
import { useContext } from 'react';
import { GreenFieldContext } from '../../App';
import ToolBoxGrid from '../Misc/ToolBoxGrid';
import { LockDateTimerMilliSecondValue, shouldLockDate } from '../Misc/utilities';
var returnTotalQtySum;
var retunTotalValueSum;

function FranchiseStockReturn() {
    const toast = useRef(null);
    const dt = useRef(null);
    const [filters1, setFilters1] = useState(null);
    const [globalFilter, setGlobalFilter] = useState();
    const greenFieldService = new GreenFieldService();
    const [initialReceivedData, setInitialReceivedData] = useState([]);
    const [franchiseStockData, setFranchiseStockData] = useState([]);
    const franchiseId = localStorage.getItem('franchiseId');
    const [expandedRows, setExpandedRows] = useState(null);

    const [date3, setDate3] = useState(null);
    const {
        register: Filter,
        errors: errors1,
        handleSubmit: handleFilter,
        setValue: setFilterValue,
    } = useForm();

    const [returnStockData, setReturnStockData] = useState([]);
    const [initialReturnData, setInitialRetunData] = useState([]);
    const [blockedPanel, setBlockedPanel] = useState(false);
    var accessLevel = parseInt(sessionStorage.getItem('accessLevel'));

    const [spinnerVisible, setSpinnerVisible] = useState(false);
    const [columns,setColumns]=useState([{ title: 'Sr No', dataKey: 'serialNo' },{ title: 'Return Date', dataKey: 'rcDateFormat' },{ title: 'Rc Number', dataKey:'rcNo' },{ title: 'Total Quantity', dataKey: 'totalQuantity' }, { title: 'Total Value', dataKey: 'totalValue' }]);

    //const [locked, setIsLocked] = useState(false);

    const [lockDate, setIsLockDate] = useState();
    const [finalLock, setFinalLock] = useState(false); 
    useEffect(() => {
        countDown();
        let d = moment(new Date()).format('YYYY-MM-DD');
        setFilterValue('fyDate', d);
        setFilterValue('toDate', d);
        GetFranchiseReturnStockData();
        if (accessLevel > 1) {
            setBlockedPanel(false);
        }
        else {
            setBlockedPanel(true);
        }
       
    }, [])

    function validateLockDate(selectedDate) {
        if (locked) {

            if (shouldLockDate(selectedDate, lockDate)) {

                setFinalLock(true);
                toast.current.show({ severity: 'warn', detail: `Franchise is locked till ${lockDate}`, summary: 'Payment Details' });
            }
            else {
                setFinalLock(false);
            }
        }
        else {
            setFinalLock(false);
        }
    }


    // const [lockStatus]=useContext(GreenFieldContext);
    const [locked, setIsLocked]=useState(false);
    useEffect(() => {
        const interval = setInterval(() => {
            countDown();
        }, LockDateTimerMilliSecondValue);
        return () => clearInterval(interval);


    }, [])

    function countDown() {
        // time = time - 1;
        greenFieldService.GetFranchiseLockScreenStatus(localStorage.getItem('franchiseId')).then(data => {
         //   if(locked!=data.currentLockStatus){
            setIsLocked(data.currentLockStatus);
            setIsLockDate(data.lockDate);
           // }
        });
    }

    function dateTime(dateValue){
        var x = new Date(dateValue);// x is now a date object
        x.setHours(0,0,0,0); 
        return x;
       }

    const onFilter = (filterValue) => {
        let fromdate=dateTime(filterValue.fyDate)  ;
        // fromdate.setDate(fromdate.getDate()-1);
        let toDate=dateTime(filterValue.toDate);

        let data = initialReturnData.filter(a => a.rcDateValue >= fromdate && a.rcDateValue <= toDate);
        let srNo = 1;
        data.forEach(d => {
            d.serialNo = srNo;
            srNo++;
        })
        retunTotalValueSum = (data.reduce((a, v) => a + parseFloat(v.totalValue), 0));
        returnTotalQtySum = (data.reduce((a, v) => a + parseFloat(v.totalQuantity), 0));
        setReturnStockData(data);

    }

    const allowExpansion = (rowData) => {
        //  setExpandedIndex(rowData.orders.length);
        return rowData.orders.length > 0;
    };
    const onReset = () => {

        const fields = [
            "fyDate",
            "toDate"
        ];
        fields.forEach((field) => setFilterValue(field, ''));
        GetFranchiseReturnStockData();

    }

    const rowClass = (data) => {
        return data.statusColor;
    }

    const header = (
        <div className="table-header">
            <div>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" class="globleInput" value={globalFilter} onChange={(e) => setGlobalFilter(e.target.value)} placeholder="Global Search" />
                </span>
            </div>
        </div>
    );

    let totalFooter = <ColumnGroup>
        <Row>
            <Column footer="Totals:" footerStyle={{ textAlign: 'right' }} />
            <Column footer='' />
            <Column footer='' />
            <Column footer='' />
            <Column footer={returnTotalQtySum} />
            <Column footer={parseFloat(retunTotalValueSum).toFixed(2)} />
        </Row>
    </ColumnGroup>;

    const GetFranchiseReturnStockData = () => {
        setSpinnerVisible(true);
        greenFieldService.GetReturnStockDetails(franchiseId).then(data => {
            
            let rcSerialNo = 1;
            retunTotalValueSum = (data.reduce((a, v) => a + parseFloat(v.totalValue), 0));
            returnTotalQtySum = (data.reduce((a, v) => a + parseFloat(v.totalQuantity), 0));

            data.forEach(a => {
                a.serialNo = rcSerialNo;
                a.rcDate= a.rcDate.replace('T', ' ');
                a.rcDateValue=new Date(a.rcDate);
                // a.rcDateDisplay = a.rcDate.replace('T', ' ');
                let serialNo = 1;
                a.stockInfoDetails.forEach(b => {
                    b.serialNo = serialNo;
                    b.productName = `${b.productName} - ${b.package}`;
                    b.stockValue = b.stockValue.toFixed(2);
                    serialNo++;
                   
                }
                )
                let anyPending = a.stockInfoDetails.findIndex(a => a.status === "Return_Pending");
                if (anyPending >= 0) {
                    a.statusColor = "Pending";
                }
                else {
                    a.statusColor = "Confirmed";
                }
                // totalQuantitySum = (data.reduce((a, v) => a + parseFloat(v.totalQuantity), 0));
                // totalValueSum = (data.reduce((a, v) => a + parseFloat(v.totalValue), 0));
                rcSerialNo++;
            });
            setReturnStockData(data);
            setInitialRetunData(data);

        }).catch(error => {
            toast.current.show({ severity: 'error', sticky: true, summary: 'Error', detail: 'Error in getting Stock Returns Data' });
        }).finally(() => {
                setSpinnerVisible(false);
        })
    }
    const rowExpansionTemplate = (data) => {
        return (
            <div className="orders-subtable ReturnStock">
                {/* <h5>Orders for {data.name}</h5> */}
                <DataTable value={data.stockInfoDetails} dataKey="id" responsiveLayout="scroll" >
                    {/* <Column selectionMode="multiple" headerStyle={{width: '3em'}}></Column> */}
                    <Column field="serialNo" header="Sr.No"></Column>
                    <Column field="productName" header="Prodcut Name"></Column>
                    <Column field="batchNo" header="Batch No"></Column>
                    <Column field="quantity" header="Quantity"></Column>
                    {/* <Column field="dcNo" header="Rc No"></Column>
                    <Column field="dcDateFormat" header="RC Date"></Column> */}
                    {/* <Column field="addedDateFormat" header="Stock Added Date"></Column> */}
                    <Column field="stockValue" header="Stock Value"  ></Column>
                    <Column field="status" header="Status"  > </Column>
                    <Column field="note" header="Note" ></Column>
                    {/* <Column body={Button} field="mobile1" header="Action"></Column> */}
                </DataTable>

            </div>
        );
    }

function onToDateChange(e){
    validateLockDate(e.target.value);
}

    return (
        <BlockUI blocked={blockedPanel || locked}>
            <div className="AdminStockReturn">
                <h3>Returned Stock Entries</h3>
                <ProgressSpinBarControl spinBarVisible={spinnerVisible} />
                <Toast ref={toast} position={'center'} />
                <div className="Unit" style={{ minHeight: '1029px' }}>
                    <div className="box">
                        <div class="field col">
                            <label>From:</label>
                            <input type='date' name='fyDate' className='date' ref={Filter} />
                            <label>To:</label>
                            <input type='date' name='toDate' onChange={onToDateChange} className='date' ref={Filter} />
                            <button type="button filter" className='filter' onClick={handleFilter(onFilter)}>Filter</button>
                            <button type="button" className='reset' onClick={onReset}>Reset</button>
                        </div>
                        {/* <div>
                        
                        Show
                        <select className='dropdown'>
                            <option>50</option>
                            <option>100</option>
                            <option>All</option>
                        </select>
                        entries
                    </div> */}
                    </div>

                    <div className='box'>
                        <div>
                        <ToolBoxGrid columns={columns} data={returnStockData} fileName="Franchise Return Stock Report"></ToolBoxGrid>
                        </div><DataTable ref={dt} disabled={finalLock} value={returnStockData} header={header} filters={filters1} paginator rows={10}
                            className="dataTable" rowClassName={rowClass} editMode="row" rowExpansionTemplate={rowExpansionTemplate} expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)} footerColumnGroup={totalFooter} >
                            <Column expander={allowExpansion} style={{ width: '3em' }} />
                            <Column field="serialNo" header="sr No" sortable></Column>
                            <Column field="rcDateFormat" header="Return Date" sortable></Column>

                            <Column field="rcNo" header="Rc Number" sortable></Column>
                            {/* <Column field="dcDateFormat" header="Rc Date" sortable></Column> */}
                            <Column field="totalQuantity" header="Total Quantity" sortable></Column>
                            <Column field="totalValue" header="Total Value" sortable></Column>
                        </DataTable>

                        {/*                     <DataTable value={returnStockData} className='dataTable' header={header} globalFilter={globalFilter} editMode='row'>
                        <Column field="serialNo" header="Sr No"></Column>
                        <Column field="productName" header="Product"></Column>
                        <Column field="batchNo" header="Batch No"></Column>
                        <Column field="package" header="Package/Size"></Column>
                        <Column field="quantity" header="Qty"></Column>
                        <Column field="note" header="Note"></Column>
                        <Column field="addedDateFormat" header="Stock return Date"></Column>
                    </DataTable> */}
                    </div>
                </div>
            </div>
        </BlockUI>
    );
}
export default FranchiseStockReturn;