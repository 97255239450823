import React, { useState, useRef } from 'react'
import { Dropdown } from 'primereact/dropdown';
import { useEffect } from 'react';
import GreenFieldService from '../../services/greenFieldService';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { HubConnectionBuilder } from '@microsoft/signalr';
import { Checkbox, DataTable, TabPanel, TabView } from 'primereact';
import ToolBoxGrid from '../Misc/ToolBoxGrid';
import axios from 'axios';
import Config from '../../Config.json';
import { Toast } from 'primereact/toast';
import { useForm } from "react-hook-form";
import authHeader from '../../authHeader';
import moment from 'moment';
import './LockScreen.css';
import { BlockUI } from 'primereact/blockui';
import { replace } from 'formik';
import 'primeflex/primeflex.css';



function LockScreen() {
    var accessLevel = parseInt(sessionStorage.getItem('accessLevel'));
    /*   const { hubConnectionState, error } = useSignalR('https://localhost:5001/chathub', {
        listeners: {
          'ReceiveMessage': (user, message) => {
            console.log(`User ${user} sent message: ${message}`);
          },
        },
      }); */
    const [selectedDate, setSelectedDate] = React.useState(null);
    const [startDate, setStartDate] = React.useState(null);
    const [currentAllLock, setCurrentAllLock] = useState(false);
    const handleStartDateChange = (date) => {
        setStartDate(date);
    };

    const openDateTimePicker = () => {
    };
    const [endDate, setEndDate] = React.useState(null);
    const handleEndDateChange = (date) => {
        setEndDate(date);
    };
    const {
        register: Filter,
        setValue: setFilterValue,
    } = useForm();

    const toast = useRef(null);
    const [lockedStatusData, setLockedStatusData] = useState({});;
    const [franchiseData, setFranchiseData] = useState([]);
    const [franchise, setfranchise] = useState(0);
    const [LockScreenData, setLockScreenData] = useState([]);
    const [lockScreenRelevantData, setLockScreenRelevantData] = useState([]);
    const [selectedFranchise, setSelectedFranchise] = useState();
    const greenFieldService = new GreenFieldService();
    const [columns, setColumns] = useState([{ title: 'Sr No', dataKey: 'serialNo' }, { title: 'Franchise Id', dataKey: 'franchiseId' }, { title: 'Start Date', dataKey: 'startDate' }, { title: 'End Date', dataKey: 'endDate' }, { title: 'Is Active', dataKey: 'isActive' }]);
    const [globalFilter, setGlobalFilter] = useState();
    const [blockedPanel, setBlockedPanel] = useState(false);


    const handleClick = (e) => {
        e.preventDefault();
        if (startDate === undefined || startDate === null) {
            toast.current.show({ severity: 'error', detail: "Please select Lock Date!", autoDismiss: false });
            return;
        }
        if (lockedStatusData === undefined || lockedStatusData === null || !lockedStatusData.currentLockStatus) {
            if (startDate === undefined || startDate === null) {
                toast.current.show({ severity: 'error', detail: "Please select Lock Date!", autoDismiss: false });
                return;
            }
        }
        if (lockedStatusData === undefined || lockedStatusData === null || !lockedStatusData.currentLockStatus) {
            if (startDate === undefined || startDate === null) {
                toast.current.show({ severity: 'error', detail: "Please select Lock Date!", autoDismiss: false });
                return;
            }
        }

        if (selectedFranchise === undefined || selectedFranchise === null) {
            toast.current.show({ severity: 'error', detail: "Please select Franchise or All!", autoDismiss: false });
            return;
        }
        let data = {};
        data.franchise_Id = selectedFranchise.id;
        data.locked = !lockedStatusData.currentLockStatus;
        let date = startDate.split(' ');
        let dateStr = date[0] + "T" + date[1];
        data.startTime = dateStr + ":00";
        //  data.startTime= dateStr+":00";
        data.startTime = startDate;
        data.isActive = true;
        return axios.post(`${Config.apiUrl}/LockScreen/Insert`, data, { headers: authHeader() })
            .then(response => {
                if (response.data.success) {
                    toast.current.show({ severity: 'success', detail: "Lock Applied Successfully!", autoDismiss: true });
                }
                else {
                    toast.current.show({ severity: 'error', detail: "Failed to Apply Lock!", sticky: true });
                }
            }).catch(error => {
                toast.current.show({ severity: 'error', sticky: true, summary: 'Error', detail: error.message });
            })

            .finally(() => {
                // setValue('name', '');
                //   setFranchiseData();
                getLockScreenData();
                setFranchiseLockScreenStatus(selectedFranchise.id);

            })

        /* if (isLocked) {
          unlockAction();
        } else {
          lockAction();
        }
        setIsLocked(!isLocked); */
    };


    const unlockAction = () => {
    }
    const lockAction = () => {
    }
    const handleLockButtonClick = (isLocked) => {
        if (isLocked) {

            console.log('Franchise unlocked');
        } else {

            console.log('Franchise locked');
        }
    };


    const header = (
        <div className="table-header">
            <div>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" className="globleInput" value={globalFilter} onChange={(e) => setGlobalFilter(e.target.value)} placeholder="Global Search" />
                </span>
            </div>
        </div>
    );


    function getLockScreenData() {
        greenFieldService.GetLockScreenData().then(data => {
            data.forEach(item => {

                if (item.locked) {
                    item.lockedStatus = "Lock";

                }
                else {
                    item.lockedStatus = "Unlock";


                }  
                     
                 let startDateS = moment(item.startTime);
                item.startTimeFormatted = startDateS.format('DD-MM-YYYY HH:mm');
                let createdDateS = moment(item.createdTime);
                item.createdTimeFormatted = createdDateS.format('DD-MM-YYYY HH:mm');


            })

            setLockScreenData(data);
        })

        greenFieldService.GetLockScreenRelevantData().then(data => {
            data.forEach(item => {

                if (item.locked) {
                    item.lockedStatus = "Lock";
                }
                else {
                    item.lockedStatus = "Unlock";

                }

                let startDateS = moment(item.startTime);
                item.startTimeFormatted = startDateS.format('DD-MM-YYYY HH:mm');
                let createdDateS = moment(item.createdTime);
                item.createdTimeFormatted = createdDateS.format('DD-MM-YYYY HH:mm');
            })
            setLockScreenRelevantData(data);
        })
    }


    useEffect(() => {
        greenFieldService.GetFranchiseData().then(data => {
            data.splice(0, 0, { id: -1, name: 'All' })
            setFranchiseData(data);
        });

        getLockScreenData();

        if (accessLevel > 1) {
            setBlockedPanel(false);
        }
        else {
            setBlockedPanel(true);
        }
    }, []);

    const connection = new HubConnectionBuilder()
        .withUrl('https://localhost:5001/hubs/chat')
        .withAutomaticReconnect()
        .build();

    /*  connection.start()
         .then(result => {
             console.log('Connected!');
 
            /*  connection.on('ReceiveMessage', message => {
                 const updatedChat = [...latestChat.current];
                 updatedChat.push(message);
             
                 setChat(updatedChat);
             });
         }) */
    /*   .catch(e => console.log('Connection failed: ', e));
  }, []);
*/
    const sendMessage = async (user, message) => {
        const chatMessage = {
            user: user,
            message: message
        };

        try {
            await fetch('https://localhost:5001/chat/messages', {
                method: 'POST',
                body: JSON.stringify(chatMessage),
                headers: {
                    'Content-Type': 'application/json'
                }
            });
        }
        catch (e) {
            console.log('Sending message failed.', e);
        }
    }

    function setFranchiseLockScreenStatus(franchiseId) {
        greenFieldService.GetFranchiseLockScreenStatus(franchiseId).then(data => {
            setLockedStatusData(data);
            let date = moment(data.lockDate.replace('T', ' '));
            let f = date.format('YYYY-MM-DD HH:mm');
            setStartDate(f);
            // let date = moment(data.lockDate.replace('T', ' '));
            //let f = date.format('YYYY-MM-DD HH:mm');
            setStartDate(data.lockDate);
            setCurrentAllLock(data.areAllFranchisesLockCurrentlyOn);
        });
    }

    const toDateChange = (e) => {
        setStartDate(e.target.value);
    }
    const onFranchiseChange = (e) => {
        if (e.value) {
            setfranchise(e.value.id);
            setSelectedFranchise(e.value);
            setFranchiseLockScreenStatus(e.value.id);
        }
    }

    const rowClass = (data) => {
        return data.lockedStatus;
    }


    return (
        <div className='LockScreen'>
            <BlockUI blocked={blockedPanel}>
                {/* <Toast ref={toast} position={'center'} />
                <div className='unit' style={{ minHeight: '1029px' }}>
                    <div className='box'>
                        <div className="field col">
                            <Dropdown className="Dropdown" value={selectedFranchise} options={franchiseData} id="name" optionLabel="name" onChange={onFranchiseChange} showClear placeholder="Select Franchise" />
                            {(lockedStatusData === null || !lockedStatusData.currentLockStatus) && (
                                <div className="field col">
                                    <label >Start Date</label>
                                    <input type='datetime-local' value={startDate} name='toDate' onChange={toDateChange} className='date' max={moment().format("YYYY-MM-DD")} />
                                </div>
                            )}
                           
                            <button onClick={handleClick}>{lockedStatusData.currentLockStatus ? 'Unlock' : 'Lock'}</button>

                            <div className="flex align-items-center">
                                <Checkbox inputId="ingredient1" value="Cheese" checked={currentAllLock} />
                                <label htmlFor="ingredient1" className="ml-2">All Franchise Locks Currently On</label>
                            </div>

                        </div>
                    </div>
                    <div className="box">
                        <TabView>
                            <TabPanel header="Lock Screen">
                                <ToolBoxGrid columns={columns} data={lockScreenRelevantData} fileName="lockScreen"></ToolBoxGrid>
                                <DataTable className='DataTable' value={lockScreenRelevantData} selectionMode="checkbox" dataKey="id" header={header} globalFilter={globalFilter} >
                                    <Column field="id" header="Sr No"></Column>
                                    <Column field="franchise_Id" header="Franchise_Id"></Column>
                                    <Column field="startTime" header="Start Time"></Column>
                                    <Column field="createdTime" header="Created Time"></Column>

                                    <Column field="franchiseName" header="FranchiseName"></Column>
                                    <Column field="lockedStatus" header="Locked"></Column>
                                    <column field="isActive" header="Is Active"></column>
                                </DataTable>
                            </TabPanel>
                            <TabPanel header="LockScreenHistory">
                                <ToolBoxGrid columns={columns} data={LockScreenData} fileName="lockScreen"></ToolBoxGrid>
                                <DataTable className='DataTable' value={LockScreenData} selectionMode="checkbox" dataKey="id" header={header} globalFilter={globalFilter} >
                                    <Column field="id" header="Sr No"></Column>
                                    <Column field="franchise_Id" header="Franchise_Id"></Column>
                                    <Column field="startTime" header="Start Time"></Column>

                                    <Column field="createdTime" header="Created Time"></Column>
                                    <Column field="franchiseName" header="FranchiseName"></Column>
                                    <Column field="lockedStatus" header="Locked"></Column>
                                    <column field="isActive" header="Is Active"></column>
                                </DataTable>
                            </TabPanel>
                        </TabView>
                    </div>
                </div> */}
            
            <Toast ref={toast} position={'center'} />
            <div style={{ minHeight: '1029px' }}>
                <div >
                    <div className="p-formgroup-inline"  >
                        <div className="p-field " >
                            <Dropdown style={{ width: '400px' }} value={selectedFranchise} options={franchiseData} id="name" optionLabel="name" onChange={onFranchiseChange} showClear placeholder="Select Franchise" />
                        </div>
                        {/* {(lockedStatusData === null || !lockedStatusData.currentLockStatus) && ( */}
                        <div className="p-field" >
                            <label >Lock Date</label>
                            <input style={{ width: '200px' }} type='datetime-local' value={startDate} name='toDate' onChange={toDateChange} className='date' max={moment().format("YYYY-MM-DD")} />
                        </div>
                        <div className="p-field" >
                            <button onClick={handleClick}>{lockedStatusData.currentLockStatus ? 'Unlock' : 'Lock'}</button>
                        </div>

                    </div>

                    <div style={{ display: 'flex' }}>
                        <div className="flex align-items-center">
                            <Checkbox inputId="ingredient1" value="Cheese" checked={currentAllLock} />
                            <label htmlFor="ingredient1" className="ml-2">All Franchise Locks Currently On</label>
                        </div>
                        {lockedStatusData != null && lockedStatusData.currentLockStatus && <div className="flex align-items-center">
                            <Checkbox inputId="ingredient1" value="Cheese" checked={true} />
                            <label htmlFor="ingredient1" className="ml-2">{lockedStatusData.isLastLockFranchiseLock ? 'Franchise Level Lock' : 'All Level Lock'}</label>
                        </div>
                        }
                    </div>
                </div>
                <div className="box">
                    <TabView>
                        <TabPanel header="Lock Screen">
                            <ToolBoxGrid columns={columns} data={lockScreenRelevantData} fileName="lockScreen"></ToolBoxGrid>
                            <DataTable className='DataTable' sortField="createdTime" sortOrder={-1} value={lockScreenRelevantData} selectionMode="checkbox" dataKey="id" header={header} globalFilter={globalFilter} >
                                <Column field="id" header="Sr No"></Column>
                                <Column field="franchise_Id" header="Franchise_Id"></Column>
                                <Column field="startTimeFormatted" header="Lock Date"></Column>
                                <Column field="createdTimeFormatted" header="Created Time"></Column>

                                <Column field="franchiseName" header="FranchiseName"></Column>
                                <Column field="lockedStatus" header="Locked"></Column>

                                {/* <Column field="endDate" header="End Date"></Column> */}
                                <column field="isActive" header="Is Active"></column>
                            </DataTable>
                        </TabPanel>
                        <TabPanel header="LockScreenHistory">
                            <ToolBoxGrid columns={columns} data={LockScreenData} fileName="lockScreen"></ToolBoxGrid>
                            <DataTable className='DataTable' value={LockScreenData} sortField="createdTime" sortOrder={-1} selectionMode="checkbox" dataKey="id"
                             header={header} globalFilter={globalFilter} rowClassName={rowClass} >
                                <Column field="id" header="Sr No"></Column>
                                <Column field="franchise_Id" header="Franchise_Id"></Column>
                                <Column field="startTime" header="Start Time"></Column>

                                <Column field="createdTime" header="Created Time"></Column>
                                <Column field="franchiseName" header="FranchiseName"></Column>
                                <Column field="lockedStatus" header="Locked"></Column>

                                {/* <Column field="endDate" header="End Date"></Column> */}
                                <column field="isActive" header="Is Active"></column>
                            </DataTable>
                        </TabPanel>
                    </TabView>
                </div>
            </div>
            </BlockUI>
        </div>
    )

}

export default LockScreen;