import './StockReport.css';
import React, { useState,useRef } from 'react';
import { useForm } from "react-hook-form";
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { useEffect } from 'react';
import GreenFieldService from '../../services/greenFieldService';
import ToolBoxGrid from '../Misc/ToolBoxGrid';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';

var receivedStockSum;
var soldSum;
var remainingStockSum;
var stockValueSum;
var customerReturnSum;
var pendingStockSum;
var returnToAdminSum;

function StockReport() {
    const greenFieldService = new GreenFieldService();
    const [date3, setDate3] = useState(null);
    const {
        register: Filter,
        errors: errors1,
        handleSubmit: handleFilter,
        setValue: setFilterValue,
    } = useForm();
    const [columns,setColumns]=useState([{ title: 'Sr No', dataKey: 'serialNo' },{ title: 'Product Name', dataKey: 'productName' },{ title: 'Company', dataKey:'company' },{ title: 'Received Stock', dataKey: 'receivedStock' }, { title: 'Pending Stock', dataKey: 'pendingStock' },
    { title: 'Return to Admin', dataKey: 'returnToAdminStock' },{ title: 'Return Stock', dataKey: 'returnStock' },{ title: 'Sold Stock', dataKey:'soldStock' },{ title: 'Remaining Stock', dataKey: 'remainingStock' }, { title: 'Stock Value', dataKey: 'stockValue' }]);
    const [globalFilter, setGlobalFilter] = useState();
    const [stockReportData, setStockReportData] = useState([]);
    const toast = useRef(null);
    const [spinnerVisible, setSpinnerVisible] = useState(false);
    const [franchise, setfranchise] = useState();
    const [franchiseData, setFranchiseData] = useState([]);



    useEffect(() => {
        greenFieldService.GetFranchiseData().then(data => {
            data.splice(0, 0, { id:-1,name: 'All' })
            setFranchiseData(data);
        });  
    }, []);

    var errors = [];

    const filterValidate = (data) => {
        if (!data?.fyDate) {
            errors.push('* From Date is required.', <br />);
        }
        if (!data?.toDate) {
            errors.push('* To Date is required', <br />);
        }
    }

    const onFilter = (filterValue) => {
        filterValidate(filterValue)
        if (errors.length > 0) {
            // eslint-disable-next-line no-lone-blocks
            {
                errors.map(() => {
                    toast.current.show({ severity: 'warn', detail: errors, sticky: true });
                }

                )
            }
            errors = [];
            return;
        }
        setSpinnerVisible(true);
        greenFieldService.GetStockReportData(filterValue.fyDate,filterValue.toDate,franchise.id).then(data => {
             receivedStockSum = (data.reduce((a, v) => a + parseFloat(v.receivedStock), 0));
            pendingStockSum = (data.reduce((a, v) => a + parseFloat(v.pendingStock), 0));
            customerReturnSum = (data.reduce((a, v) => a + parseFloat(v.returnStock), 0));
            remainingStockSum = (data.reduce((a, v) => a + parseFloat(v.remainingStock), 0));
            returnToAdminSum = (data.reduce((a, v) => a + parseFloat(v.returnToAdminStock), 0));
            soldSum = (data.reduce((a, v) => a + parseFloat(v.soldStock), 0));
            stockValueSum = (data.reduce((a, v) => a + parseFloat(v.stockValue), 0));

            let srNo = 1;
            data.forEach(a => {
                a.stockValue=parseFloat(a.stockValue).toFixed(2);
                a.serialNo = srNo;
                srNo++;

            })
            setStockReportData(data);
        });
        // let data = initialReturnData.filter(a => a.addedDateFormat >= filterValue.fyDate && a.addedDateFormat <= filterValue.toDate);
        // let srNo = 1;
        // data.forEach(d => {
        //     d.serialNo = srNo;
        //     srNo++;
        // })
        // setStockReportData(data);

    }

    const onReset = () => {

        const fields = [
            "fyDate",
            "toDate"
        ];
        fields.forEach((field) => setFilterValue(field, ''));
        setStockReportData('');
        receivedStockSum= 0;
        soldSum= 0;
        remainingStockSum= 0;
        stockValueSum= 0.00;
        customerReturnSum= 0;
        pendingStockSum= 0;
        returnToAdminSum= 0;

    }

    const onFranchiseChange = (e) => {
        setfranchise(e.value);
    }

    const header = (
        <div className="table-header">
            <div>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" class="globleInput" value={globalFilter} onChange={(e) => setGlobalFilter(e.target.value)} placeholder="Global Search" />
                </span>
            </div>
        </div>
    );

    let footerGroup = <ColumnGroup>
        <Row>
            <Column footer="Totals:" footerStyle={{ textAlign: 'right' }} />
            <Column footer='' />
            <Column footer='' />
            <Column footer={receivedStockSum} />
            <Column footer={pendingStockSum} />
            <Column footer={customerReturnSum} />
            <Column footer={returnToAdminSum} />
            <Column footer={soldSum} />
            <Column footer={remainingStockSum} />
            <Column footer={parseFloat(stockValueSum).toFixed(2)} />


        </Row>
    </ColumnGroup>;

    return (
        <div className="StockReport">
            <h3>Stock Report</h3>
            <div  style={{ minHeight: '1029px' }}>
                <div className="box">
                    <div class="field col">
                    <Dropdown className="Dropdown" value={franchise} options={franchiseData} id="name" optionLabel="name" onChange={onFranchiseChange} showClear placeholder="Select Franchise"/>

                        <label>From:</label>
                        <input type='date' name='fyDate' className='date' ref={Filter} />
                        <label>To:</label>
                        <input type='date' name='toDate' className='date' ref={Filter} />
                        <button type="button filter" className='filter' onClick={handleFilter(onFilter)}>Load Report</button>
                        <button type="button" className='reset' onClick={handleFilter(onReset)}>Reset</button>
                    </div>
                </div>

                <div className='box'>
                    {/* <div>
                        <button type='button'>Copy</button>
                        <button type='button'>CSV</button>
                        <button type='button'>Excel</button>
                        <button type='button'>PDF</button>
                        <button type='button'>Print</button>
                    </div> */}
                    <ToolBoxGrid columns={columns} data={stockReportData} fileName="Stock Report"></ToolBoxGrid>
                    <DataTable value={stockReportData}  header={header} globalFilter={globalFilter} scrollable scrollHeight='700px' footerColumnGroup={footerGroup}>
                        <Column field="serialNo" header="Sr No"></Column>
                        <Column field="productName" header="Product Name"></Column>
                        <Column field="company" header="Company"></Column>
                        <Column field="receivedStock" header="Received Stock"></Column>
                        <Column field="pendingStock" header="Pending Stock"></Column>
                        <Column field="returnToAdminStock" header="Return To Admin Stock"></Column>
                        <Column field="returnStock" header="Return Stock"></Column>
                        <Column field="soldStock" header="Sold Stock"></Column>
                        <Column field="remainingStock" header="Remaining Stock"></Column>
                        <Column field="stockValue" header="Stock Value"></Column>

                        
                    </DataTable>
                </div>
            </div>

        </div>
    );
}
export default StockReport;