import { Button } from 'primereact/button';
import axios from 'axios';
import GreenFieldService from '../services/greenFieldService';
import Config from '../Config.json';
import { Toast } from 'primereact/toast';
import 'primeflex/primeflex.css';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from "react-hook-form";
import React, { useState, useEffect, useRef } from 'react';
// import './CustomerMaster.css';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import './ManageStockByFranchise.css';
import { TabView, TabPanel } from 'primereact/tabview';
import { BrowserRouter, useParams } from 'react-router-dom';
import { Hidden } from '@material-ui/core';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import moment from 'moment';
import ToolBoxGrid from './Misc/ToolBoxGrid';
import { BlockUI } from 'primereact/blockui';
import { useHistory } from 'react-router-dom';
import authHeader from '../authHeader';



// import '../App.css';
var receivedStockSum;
var soldSum;
var remainingStockSum;
var stockValueSum;
var totalQuantitySum;
var totalValueSum;
var pendingStockSum;
var returnStockSum;
var returnToAdminSum;
var customerReturnStock;
var productDetails=[];

function ManageStockByFranchise() {
    let history = useHistory();

    const { register, handleSubmit, reset, setValue } =
        useForm();
    const {
        register: Filter,
        // errors: errors1,
        handleSubmit: handleFilter,
        setValue: setFilterValue,
    } = useForm();


    const greenFieldService = new GreenFieldService();
    const toast = useRef(null);
    const [stocksData, setStocksData] = useState([]);
    const [buttonLabel, setButtonLabel] = useState("Save");
    const [buttonLabelStock, setButtonLabelStock] = useState("Add Stock");
    const [globalFilter, setGlobalFilter] = useState('');
    const [editRowData, setEditRowData] = useState();
    const [isAddMode, setIsAddMode] = useState(true);
    const [productData, setProductsData] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState();
    const [initialDistributedData, setInitialDistributeData] = useState([]);
    const [availabaleStock, setAvailableStock] = useState();
    const [distributedStock, setDistributeStock] = useState([]);
    const [franchiseStockData, setFranchiseStockData] = useState([]);
    const [initialFranchiseStockData, setInitialfranchiseStockData] = useState([]);
    const [batchNoData, setBatchNoData] = useState([]);
    const [selectedBatchNo, setSelectedBatchNo] = useState();
    const [stocksByProduct, setStocksByProduct] = useState([]);
    const franchiseName = localStorage.getItem("FranchiseName");
    const [filters1, setFilters1] = useState(null);
    const { franchiseId } = useParams();
    const [expandedRows, setExpandedRows] = useState(null);
    const [blockedPanel, setBlockedPanel] = useState(false);
    var accessLevel = parseInt(sessionStorage.getItem('accessLevel'));
    const dt = useRef(null);
    const [columns, setColumns] = useState([{ title: 'srNo', dataKey: 'serialNo' }, { title: 'Product Name', dataKey: 'productName' }, { title: 'Received Stock', dataKey: 'receivedStock' }, { title: 'Pending Stock', dataKey: 'pendingStock' }, { title: 'Customer Return', dataKey: 'returnStock' }, { title: 'Return To Admin', dataKey: 'returnToAdminStock' }
        , { title: 'sold', dataKey: 'soldStock' }, { title: 'Remaining', dataKey: 'remainingStock' }]);
    const fileName = `${franchiseName} Stock Data`;


    useEffect(() => {

        let d = moment(new Date()).format('YYYY-MM-DD');
        setFilterValue('fyDate', d);
        setFilterValue('toDate', d);
        setValue('dcDate', d);

        greenFieldService.GetProductsData().then(data => {
            data.forEach(a => {
                // a.productName = a.name 
                a.productName = `${a.name} - ${a.packingSize} ${a.unitName}`;
            });
            setProductsData(data);
        });


        getFranchiseStockData(franchiseId);

        getDistributedStockData(franchiseId);
        initFilters1();

        //Access level
        if (accessLevel > 1) {
            setBlockedPanel(false);
        }
        else {
            setBlockedPanel(true);
        }

    }, [])



    const inputNoOnly = (e) => {
        if (e.target.id === 'quantity') {
            const onlyNums = e.target.value.replace(/[^0-9.]/g, '');
            setValue('quantity', onlyNums);
        }
    }

    var errors = [];

    const validate = (data) => {

        if (!data?.dcDate) {
            errors.push('DC date is required.', <br />);
        }
        if (!data?.dcNo) {
            errors.push('DC number is required', <br />);
        }
        if (!selectedProduct) {
            errors.push('Product name is required.', <br />);
        }
        if (!selectedBatchNo) {
            errors.push('Batch No is required', <br />);
        }
        if (!data.quantity) {
            errors.push('Quantity is required', <br />);
        }
        if (!isAddMode) {
            if (!data.note) {
                errors.push('Note is required', <br />);
            }
        }


        // return errors;
    };

    const onChangeGlobal = (e) => {
        let value = e.target.value;
        let _filters1 = { ...filters1 };
        _filters1['global'].value = value;

        setFilters1(_filters1);

        setGlobalFilter(value);
    }


    const initFilters1 = () => {
        setFilters1({
            'global': { value: null, matchMode: FilterMatchMode.CONTAINS },

        });
        setGlobalFilter('');
    }

    const filterValidate = (data) => {
        if (!data?.fyDate) {
            errors.push('* From Date is required.', <br />);
        }
        if (!data?.toDate) {
            errors.push('* To Date is required', <br />);
        }

    }
    const onFilter = (filterValue) => {
        let fromdate=new Date(filterValue.fyDate)  ;
        fromdate.setDate(fromdate.getDate()-1);
        let toDate=new Date(filterValue.toDate);

        filterValidate(filterValue)
        if (errors.length > 0) {
            // eslint-disable-next-line no-lone-blocks
            {
                errors.map(() => {
                    toast.current.show({ severity: 'warn', detail: errors, sticky: true });
                }

                )
            }
            errors = [];
            return;
        }
        let data = initialDistributedData.filter(a => a.dcDateValue >= fromdate && a.dcDateValue <= toDate);
        let srNo = 1;
        data.forEach(d => {
            d.serialNo = srNo;
            srNo++;
        })
        totalQuantitySum = (data.reduce((a, v) => a + parseFloat(v.totalQuantity), 0));
        totalValueSum = (data.reduce((a, v) => a + parseFloat(v.totalValue), 0));
        setDistributeStock(data);

    }


    const onFilterStock = (filterValue) => {
        let fromdate=new Date(filterValue.fyDate)  ;
        fromdate.setDate(fromdate.getDate()-1);
        let toDate=new Date(filterValue.toDate);

        filterValidate(filterValue)
        if (errors.length > 0) {
            // eslint-disable-next-line no-lone-blocks
            {
                errors.map(() => {
                    toast.current.show({ severity: 'warn', detail: errors, sticky: true });
                }
                )
            }
            errors = [];
            return;
        }
        let data = initialFranchiseStockData.filter(a => a.addedDateValue >= fromdate && a.addedDateValue <= toDate);
        let srNo = 1;
        data.forEach(d => {
            d.serialNo = srNo;
            srNo++;
        })
        receivedStockSum = (data.reduce((a, v) => a + parseFloat(v.totalStock), 0));
        soldSum = (data.reduce((a, v) => a + parseFloat(v.sold), 0));
        remainingStockSum = (data.reduce((a, v) => a + parseFloat(v.remainingStock), 0));
        stockValueSum = (data.reduce((a, v) => a + parseFloat(v.remainStockValue), 0));
        setFranchiseStockData(data);

    }

    const onReset = () => {

        const fields = [
            "fyDate",
            "toDate"
        ];
        fields.forEach((field) => setFilterValue(field, ''));
        getDistributedStockData(franchiseId);

    }

    const onResetStock = () => {

        const fields = [
            "fyDate",
            "toDate"
        ];
        fields.forEach((field) => setFilterValue(field, ''));
        getFranchiseStockData(franchiseId);

    }

    const onChangeProduct = (e) => {

        setSelectedProduct(e.value);

        GetBatchData(e.value.id)

      
        greenFieldService.GetStocksOnProduct(e.value.id).then(data => {
            setStocksByProduct(data);
        });
    

    }

    const GetBatchData = (productId) => {
        greenFieldService.GetBatchNo(productId).then(data => {
            setBatchNoData(data);
        });
    }

    const onChangeBatchNo = (e) => {
        setSelectedBatchNo(e.value);
        GetAvailableStock(selectedProduct.id, e.value.batchNo);

        productDetails=stocksByProduct.find(a=> a.batchNo === e.value.batchNo);

    }
    const onQuantity = (e) => {

        if (isAddMode) {
            let quantity = parseInt(e.target.value);
            if (quantity > availabaleStock) {
                toast.current.show({ severity: 'warn', sticky: true, summary: 'Alert', detail: "Quantity is greater than available Stock" });
                setValue('quantity', null);
                // setDisabledAddButton(true);
                return;
            }
        }
        else{
            let oldQty=editRowData.quantity;
            let newAvlStock=availabaleStock+oldQty;
             let quantity = parseInt(e.target.value);
            if (quantity > newAvlStock) {
                toast.current.show({ severity: 'warn', sticky: true, summary: 'Alert', detail: "Quantity is greater than available Stock" });
                setValue('quantity', null);
                // setDisabledAddButton(true);
                return;
            }
        }
    }

    const GetAvailableStock = (productId, batchNo) => {
        greenFieldService.GetAvailableStock(productId, batchNo).then(data => {
            setAvailableStock(data);
            setValue('avlQuantity', data);
        });
    }

    const onSave = (data) => {
        console.warn(data);
        validate(data);
        if (errors.length > 0) {
            // eslint-disable-next-line no-lone-blocks
            {
                errors.map(() => {
                    toast.current.show({ severity: 'warn', detail: errors, sticky: true });
                }

                )
            }
            errors = [];
            return;
        }
        data.adminId = 1;
        data.franchiseId = parseInt(franchiseId);
        data.productId = selectedProduct.id
        data.productName = selectedProduct.productName;
        data.batchNo = selectedBatchNo.batchNo;
        data.manufactureDate=productDetails.manufactureDate;
        data.duration=productDetails.duration;
        data.expDate=productDetails.expDate;
        return isAddMode ? addStock(data) : updateStock(data);
    }
    const addStock = (data) => {
        data.Status = "Pending";
        let _stockData = [...stocksData];
        let _data = { ...data };
        _stockData.push(_data);
        setStocksData(_stockData);
        resetValue();

    }

    const saveStocks = () => {
        if (stocksData.length > 0) {

            return axios.post(`${Config.apiUrl}/Stocks/DistributedStock`, stocksData, { headers: authHeader() })
                .then(responses => {
                    responses.data.forEach(response => {
                        if (response.success) {
                            toast.current.show({ severity: 'success', detail: response.msg, autoDismiss: true });
                        }
                        else {
                            toast.current.show({ severity: 'error', detail: response.msg, sticky: true });
                        }

                    })

                }).catch(error => {
                    toast.current.show({ severity: 'error', sticky: true, summary: 'Error', detail: error.message });
                })
                .finally(() => {
                    getDistributedStockData(franchiseId);
                    getFranchiseStockData(franchiseId);
                    setStocksData('');
                    setSelectedProduct('');
                    setSelectedBatchNo('');
                    setValue('dcDate', '');
                    setValue('dcNo', '');

                    // getStockData(selectedProduct.id);
                    // setSpinnerVisible(false);
                    // SetFocusValue(true);
                })
        }
        else {
            toast.current.show({ severity: 'warn', detail: "Please add some Product Before Saving", autoDismiss: false });
            return;
        }
    }

    const updateStock = (data) => {


        // setSpinnerVisible(true);
        data.id = editRowData.refId;
        return axios.put(`${Config.apiUrl}/Stocks/UpdateDistributedStock`, data, { headers: authHeader() })
            .then(response => {
                if (response.data.success) {
                    toast.current.show({ severity: 'success', detail: 'Data Update Successfully', autoDismiss: true });
                }
                else {
                    toast.current.show({ severity: 'error', detail: response.data.msg, sticky: true });
                }
            }).catch(error => {
                toast.current.show({ severity: 'error', sticky: true, summary: 'Error', detail: error });
            })
            .finally(() => {
                getDistributedStockData(franchiseId);
                resetValue();
                // setSpinnerVisible(false);
                // SetFocusValue(true);
            })
    }

    const resetValue = () => {
        const fields = [
            'avlQuantity',
            "quantity",
            "note",
        ];
        fields.forEach((field) => setValue(field, ''));
        setSelectedProduct('');
        setSelectedBatchNo('');
    }


    const header = (
        <div className="table-header">
            <div>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" className="globleInput" value={globalFilter} onChange={onChangeGlobal} placeholder="Global Search" />

                </span>
            </div>
        </div>
    );

    const EditButton = (rowData) => {
        return <div>
            <Button className=' edit' type="button" onClick={() => onEdit(rowData)}>Edit</Button>
        </div>;
    }

    const DeleteButtonIcon = (rowData) => {
        return <div>
            <Button icon="pi pi-trash" className="p-button-rounded p-button-danger" onClick={() => onDelete(rowData)} />
        </div>
    }

    const onEdit = (rowData, e) => {
        GetAvailableStock(rowData.productId, rowData.batchNo);
        setIsAddMode(false);
        let data = rowData;
        setButtonLabelStock('Update Stock');

        const fields = [
            "quantity",
            "dcNo",
            "note",
            "batchNo",

        ];
        fields.forEach((field) => setValue(field, data[field]));
        // setValue('avlQuantity',);
        setValue('dcDate', data.dcDateFormat);
        let newSelectedProduct = productData.filter(a => a.id === data.productId);
        setSelectedProduct(newSelectedProduct[0]);
        GetBatchData(newSelectedProduct[0].id);
        setSelectedBatchNo({ batchNo: data.batchNo });
        setEditRowData(rowData);
    }

    const getDistributedStockData = (franchiseId) => {

        greenFieldService.GetDistributedStock(franchiseId).then(data => {
            let dcSerialNo = 1;

            data.forEach(a => {
                a.serialNo = dcSerialNo;
                a.dcDateValue=new Date(a.dcDate);

                let serialNo = 1;
                // totalQuantityDCwiseSum = (a.stockInfoDetails.reduce((a, v) => a + parseInt(v.quantity), 0));
                a.stockInfoDetails.forEach(b => {
                    b.serialNo = serialNo;
                    b.productName = `${b.productName} - ${b.package}`;
                    b.stockValue = b.stockValue.toFixed(2);
                    serialNo++;

                }
                )

                totalQuantitySum = (data.reduce((a, v) => a + parseFloat(v.totalQuantity), 0));
                totalValueSum = (data.reduce((a, v) => a + parseFloat(v.totalValue), 0));
                dcSerialNo++;
            });
            setDistributeStock(data);
            setInitialDistributeData(data);
        });
    }



    const onDelete = (rowData) => {
        if (rowData != null) {
            toast.current.show({
                severity: 'warn', sticky: true, content: (
                    <div className="flex flex-column" style={{ flex: '1' }}>
                        <div className="text-center">
                            <i className="pi pi-exclamation-triangle" style={{ fontSize: '3rem' }}></i>
                            <h4>Are you sure?</h4>
                            <p>Confirm to proceed</p>
                        </div>
                        <div className="grid p-fluid">
                            <div className="col-6">
                                <Button type="button" label="Yes" onClick={() => onYes(rowData)} className="p-button-success" />
                            </div>
                            <div className="col-6">
                                <Button type="button" label="No" onClick={() => onNo(rowData)} className="p-button-secondary" />
                            </div>
                        </div>
                    </div>
                )
            });
        }
        else {
            toast.current.show({ severity: 'warn', sticky: true, detail: "PLEASE SELECT ROW" });
            return;
        }
    }

    const onYes = (rowData) => {
        toast.current.clear();
        try {
            let index = stocksData.findIndex(a => a.productId === rowData.productId && a.batchNo === rowData.batchNo);
            if (index > -1) {

                let _stocksData = [...stocksData];
                let data = _stocksData.splice(index, 1);

                setStocksData(_stocksData);

                // let grandTotalValue = (_invoiceData && _invoiceData.reduce((a, v) => a + parseFloat(v.total), 0));
                // setFooterValue('grandTotal', grandTotalValue.toFixed(2));
                // setFooterValue('dueAmount', grandTotalValue.toFixed(2));

                toast.current.show({ severity: 'success', detail: "Product Removed ", autoDismiss: true });

            }
            else {
                toast.current.show({ severity: 'error', sticky: true, detail: "Product Not Removed " });
            }
        }
        catch {

        }
        finally {
            toast.current.clear();

        }
        // window.location.reload();
    }

    const onNo = () => {
        toast.current.clear();
        return;
    };

    const onBack = (e) => {
        // Reset();
        history.push(`/Franchise`);
        // setDisplayTable(true);

    }

    const getFranchiseStockData = (franchiseId) => {
        greenFieldService.GetFranchiseStock(franchiseId).then(data => {
            let srNo = 1;
            data.forEach(a => {
                a.serialNo = srNo;
                a.addedDateValue=new Date(a.addedDateValue);
                srNo++;
            })
            receivedStockSum = (data.reduce((a, v) => a + parseFloat(v.receivedStock), 0));
            pendingStockSum = (data.reduce((a, v) => a + parseFloat(v.pendingStock), 0));
            customerReturnStock = (data.reduce((a, v) => a + parseFloat(v.returnStock), 0));
            remainingStockSum = (data.reduce((a, v) => a + parseFloat(v.remainingStock), 0));
            returnToAdminSum = (data.reduce((a, v) => a + parseFloat(v.returnToAdminStock), 0));
            soldSum = (data.reduce((a, v) => a + parseFloat(v.soldStock), 0));
            setFranchiseStockData(data);
            setInitialfranchiseStockData(data);
        });
    }

    let stockFooterGroup = <ColumnGroup>
        <Row>
            <Column footer="Totals:" footerStyle={{ textAlign: 'right' }} />
            <Column footer='' />
            <Column footer={receivedStockSum} />
            <Column footer={pendingStockSum} />
            <Column footer={customerReturnStock} />
            <Column footer={returnToAdminSum} />
            <Column footer={soldSum} />
            <Column footer={remainingStockSum} />

        </Row>
    </ColumnGroup>;

    let stockEntriesFooterGroup = <ColumnGroup>
        <Row>
            <Column footer="Totals:" footerStyle={{ textAlign: 'right' }} />
            <Column footer='' />
            <Column footer='' />
            <Column footer='' />
            <Column footer={totalQuantitySum} />
            <Column footer={totalValueSum} />
        </Row>
    </ColumnGroup>;


    const rowExpansionTemplate = (data) => {
        return (
            <BlockUI blocked={blockedPanel}>

                <div className="orders-subtable">
                    {/* <h5>Orders for {data.name}</h5> */}
                    <DataTable value={data.stockInfoDetails} responsiveLayout="scroll" scrollable scrollHeight='300px'>
                        <Column field="serialNo" header="Sr.No"></Column>
                        <Column field="productName" header="Prodcut Name"></Column>
                        <Column field="batchNo" header="Batch No"></Column>
                        <Column field="quantity" header="Quantity"></Column>
                        <Column field="dcNo" header="Dc No"></Column>
                        <Column field="dcDateFormat" header="DC Date"></Column>
                        <Column field="status" header="Status"></Column>
                        <Column field="note" header="Note"></Column>
                        <Column field="addedDateFormat" header="Stock Added Date"></Column>
                        <Column field="stockValue" header="Stock Value"></Column>
                        <Column body={EditButton} field="mobile1" header="Action"></Column>
                    </DataTable>

                </div>
            </BlockUI>

        );
    }

    const allowExpansion = (rowData) => {
        return rowData.orders.length > 0;
    };

    return (
        <div className="ManageStock">
            <div className="Unit" style={{ minHeight: '1029px' }}>
                <Toast ref={toast} position={'center'} />
                <div><h3>Manage Stock :: {franchiseName} <button className='reset' style={{ width: '100px',justifyContent:'end' }} onClick={onBack} type="button">Back</button>
</h3>
                </div>
                {/* <div className="header"> */}
                {/* </div> */}

                <BlockUI blocked={blockedPanel}>
                    <div className='box'>

                        <div>
                            <h3 style={{ marginLeft: '5px' }}> Distribute Stock</h3>


                            <div className="formgrid grid align items left">

                                <div style={{ marginleft: '10px' }}>
                                    <div className="field col">

                                        <label >Dc Date<span style={{ color: 'red' }}>*</span></label>
                                        <input id="DcDate" type="date" name="dcDate" ref={register} className="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary"></input>
                                        <label for="Batch">DC No<span style={{ color: 'red' }}>*</span></label>
                                        <input id="Batch" type="text" name="dcNo" ref={register} autoComplete='off' className="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary"></input>

                                    </div>
                                    <div className="field col">

                                        <label for="Product Name">Product Name<span style={{ color: 'red' }}>*</span></label>
                                        <Dropdown className='Dropdown' value={selectedProduct} id="productId" options={productData} optionLabel="productName" onChange={onChangeProduct} filter showClear placeholder="Select Product" />

                                        <label for="Product Name">Batch No<span style={{ color: 'red' }}>*</span></label>
                                        <Dropdown className='Dropdown' value={selectedBatchNo} options={batchNoData} optionLabel="batchNo" onChange={onChangeBatchNo} filter showClear placeholder="Select Batch" />

                                        <label for="AvlQuantity">Available Quantity</label>
                                        <input id="avlQuantity" type="text" readOnly name="avlQuantity" ref={register} className="QuantityTextbox text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary"></input>
                                        <label for="Quantity">Enter Quantity<span style={{ color: 'red' }}>*</span></label>
                                        <input id="quantity" type="text" name="quantity" ref={register} autoComplete='off' onInput={inputNoOnly} onChange={onQuantity} className="QuantityTextbox text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary"></input>




                                    </div>
                                    <div className="field col">
                                        {!isAddMode && (
                                            <>
                                                <label for="Note">Note<span style={{ color: 'red' }}>*</span></label>
                                                <input id="Note" type="text" name="note" ref={register} className="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary"></input>
                                            </>
                                        )}
                                    </div>

                                    <div className="field col">
                                        <button className='button' type="button" onClick={handleSubmit(onSave)}>{buttonLabelStock}</button>
                                        <div hidden={!isAddMode}>
                                            <DataTable className="dataTable" value={stocksData} editMode="row"   >
                                                {/* <Column expander={allowExpansion} style={{ width: '3em' }} /> */}
                                                <Column field="dcDate" header="Dc Date" ></Column>
                                                <Column field="dcNo" header="Dc No"  ></Column>
                                                <Column field="productName" header="Product Name"  ></Column>
                                                <Column field="batchNo" header="Batch No"  ></Column>
                                                {/* <Column field="quantity" header="Available Quantity"  ></Column> */}
                                                <Column field="quantity" header="Quantity"  ></Column>
                                                <Column body={DeleteButtonIcon} header="Action"></Column>

                                            </DataTable>
                                            <button className='button' type="button" onClick={saveStocks}>Save Stock</button>

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>


                    </div>
                </BlockUI>

                {/* <div className='box'> */}
                <div className='box'>
                    <TabView>
                        <TabPanel header="Stock" >
                            <div className="field col">
                                <label>From:</label>
                                <input type='date' name='fyDate' className='date' ref={Filter} />
                                <label>To:</label>
                                <input type='date' name='toDate' className='date' ref={Filter} />
                                <button type="button filter" className='filter' onClick={handleFilter(onFilterStock)} >Filter</button>
                                <button type="button" className='reset' onClick={onResetStock}>Reset</button>
                            </div>
                            <ToolBoxGrid columns={columns} data={franchiseStockData} fileName={fileName} ></ToolBoxGrid>

                            <DataTable ref={dt} className='dataTable' value={franchiseStockData} header={header} footerColumnGroup={stockFooterGroup} filters={filters1} scrollable scrollHeight='500px' >
                                <Column field="serialNo" header="Sr No"></Column>
                                <Column field="productName" header="Product Name"></Column>
                                <Column field="receivedStock" header="Received Stock"></Column>
                                <Column field="pendingStock" header="Pending Stock"></Column>
                                <Column field="returnStock" header="Customer Return Stock"></Column>
                                <Column field="returnToAdminStock" header="Return To Admin Stock"></Column>
                                <Column field="soldStock" header="Sold Stock"></Column>
                                <Column field="remainingStock" header="Remaining Stock"></Column>
                            </DataTable>
                        </TabPanel>

                        <TabPanel header="Stock Entries">
                            <div className="field col">
                                <label>From:</label>
                                <input type='date' name='fyDate' className='date' ref={Filter} />
                                <label>To:</label>
                                <input type='date' name='toDate' className='date' ref={Filter} />
                                <button type="button filter" className='filter' onClick={handleFilter(onFilter)} >Filter</button>
                                <button type="button" className='reset' onClick={onReset}>Reset</button>
                            </div>
                            <DataTable value={distributedStock} header={header} filters={filters1} paginator rows={10}
                                className="dataTable" editMode="row" rowExpansionTemplate={rowExpansionTemplate} expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)}
                                footerColumnGroup={stockEntriesFooterGroup} >
                                <Column expander={allowExpansion} style={{ width: '3em' }} />
                                <Column field="serialNo" header="srNo" sortable></Column>
                                <Column field="dcNo" header="Dc Number" sortable></Column>
                                <Column field="dcDateFormat" header="Dc Date" sortable></Column>
                                <Column field="totalQuantity" header="Total Quantity" sortable></Column>
                                <Column field="totalValue" header="Total Value" sortable></Column>
                            </DataTable>

                            {/* <DataTable value={distributedStock} expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)}
                                onRowExpand={onRowExpand} onRowCollapse={onRowCollapse} responsiveLayout="scroll"
                                rowExpansionTemplate={rowExpansionTemplate} dataKey="id" header={header}>
                                <Column expander={allowExpansion} style={{ width: '3em' }} />
                                <Column field="name" header="Name" sortable />
                                <Column header="Image"  />
                                <Column field="price" header="Price" sortable  />
                                <Column field="category" header="Category" sortable />
                                <Column field="rating" header="Reviews" sortable />
                                <Column field="inventoryStatus" header="Status" sortable  />
                            </DataTable> */}
                        </TabPanel>
                    </TabView>
                </div>
                {/* </div> */}
            </div>




        </div>)
}

export default ManageStockByFranchise