import { Checkbox } from "@material-ui/core";
import { Button } from "primereact/button";
import React from 'react'
import { useForm } from "react-hook-form";
// import '../Popups/LoginPopup.css';
import "./LoginPopup.css"
import { useState, useEffect, useContext, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Dropdown } from 'primereact/dropdown';
import axios from "axios";
import Config from "../../Config.json";
import { Toast } from 'primereact/toast';
import authHeader from "../../authHeader";
import ProgressSpinBarControl from "../Misc/progressSpinBarControl";
import authenticationService from './../../services/authenticationService';
import GreenFieldService from './../../services/greenFieldService'
import { RadioButton } from 'primereact/radiobutton';

function LoginPopup(props) {
    // var societyId = parseInt(localStorage.getItem('societyId'));
    let history = useHistory();
    // const toast = useRef(null);
    // const societyService = new authenticationService();
    const toast = useRef(null);
    const { errors, register, reset, handleSubmit, setValue, clearErrors } = useForm();
    // const { societyId,societyName} = useContext(SocietyContext);
    var UserId = localStorage.getItem('userId');
    const [franchises, setfranchises] = useState([]);
    const [applicableDistricts, setApplicableDistricts] = useState([]);

    //var [franchisesOfDistrict,setfranchisesOfDistrict]=useState([]);
    const [selectedDistrict, setSelectedDistrict] = useState(null);
    const [franchisesOfDistrict, setFranchisesOfDistrict] = useState([]);
    const [franchise, setfranchise] = useState();
    const [spinnerVisible, setSpinnerVisible] = useState(false);
    const [userData, setUserData] = useState([]);
    const [submitDisabled, setSubmitDisabled] = useState(false);
    const greenFieldService = new GreenFieldService();
    const [userType, setUserType] = useState("Franchise");



    function compare(a, b) {
        if (a.last_nom < b.last_nom) {
            return -1;
        }
        if (a.last_nom > b.last_nom) {
            return 1;
        }
        return 0;
    }


    useEffect(() => {

        greenFieldService.GetFranchiseData().then(data => {
            data.forEach(a => a.franchiseAddress = `${a.address},${a.state}/${a.district}`);
            setfranchises(data);


            const unique = Array.from([...new Set(data.map(item => item.district))]);
            setApplicableDistricts(unique);
        });


        // societyService.getAllSocietyDetails()
        //     .then(data => {
        //         let tempArray = [];
        //         data.forEach(society => {
        //             let item = { 'id': society.societyId, 'name': society.societyName, 'city': society.city };
        //             tempArray.push(item);
        //             /* let existingIndex= tempArray.findIndex(a=>a.id=== item.id);
        //             if(existingIndex <0){
        //             tempArray.push(item);
        //             } */
        //         })
        //    tempArray.sort(compare);
        //         setSocietyArray(tempArray);

        //         let cities=[];
        //         tempArray.forEach(data=>{
        //             let item={'city':data.city.toUpperCase()};
        //             let duplicate=cities.find(a=>a.city.toUpperCase() === item.city);
        //             if(duplicate=== undefined)
        //             {
        //                 cities.push(item);
        //             }
        //         })
        //         setSocietyCities(cities);
        //     }
        //     );

        //     societyService.getUserName(UserId).then(data => {
        //         setUserData(data);
        //     });

        // societyService.getPermissionDetails().then(data => {
        //     let userId=data.find(a=>a.userName===props.userName);
        //     setUserId(userId);
        // });


    }, []);

    const onDistrictChange = (e) => {
        setSelectedDistrict(e.value);
        let franchisesDistrict = franchises.filter(a => a.district === e.value);
        setFranchisesOfDistrict(franchisesDistrict);
        // let index=societyArray.findIndex(a=>a.city===e.value.city);
        // setSocietyName(societyArray[index].name);
    }

    const onFranchiseChange = (e) => {

        setfranchise(e.value);
        // let duplicateSociety=userData.find(a=>a.societyName === e.value.name);
        // if(duplicateSociety!=undefined)
        // {
        //     toast.current.show({ severity: 'warn', sticky: true, summary: 'warning', detail: 'Society is Already added to your Account' });
        //     setSubmitDisabled(true);
        //     return;


        // }
    }

    const onSubmit = (data) => {
        setSpinnerVisible(true);

        if(userType==="Franchise"){
        
        data.userId = props.userId;
        data.franchiseId = franchise.id;
        if (selectedDistrict === undefined || selectedDistrict === null || selectedDistrict.length === 0) {
            alert("Please select District");
            return;
        }
        if (franchise === undefined || franchise == null || franchise.id === 0) {
            alert("Please select Franchise");
            return;
        }

        data.AccessLevel = 'ReadOnly';
        data.action = 'Default';
    }
    else{
        data.userId = props.userId;
        data.franchiseId =-1;
        data.AccessLevel = 'ReadOnly';
        data.action = 'Default';
    }

        // data.societyName = societyInfo.name;
        return axios.post(`${Config.apiUrl}/Permission/InsertDetails`, data)
            .then(response => {
                console.warn(data);
                console.log(response)
                if (response.data != null) {
                    toast.current.show({ severity: 'success', detail: response.data[0].msg , autoDismiss:true });
                }
                else {
                    toast.current.show({ severity: 'error', detail: response.data[0].msg });
                }
            })
            .catch(error => {
                toast.current.show({ severity: 'error', sticky: true, summary: 'Error', detail: error });
            })
            .finally(() => {
                setSpinnerVisible(false);
                window.location.reload();
            })
    }



    return (props.trigger) ? (


        <div className="popup">
            <Toast ref={toast} />
            <ProgressSpinBarControl spinBarVisible={spinnerVisible} />
            <div className="popup-inner">
                <div className="popup__header">
                    {/* <button classname="close-btn">CLOSE</button> */}
                    <h3 style={{ marginLeft: '15px' }}>Login Details</h3>
                    {/* {props.children} */}
                </div>
                <div className="popup-content">
                    <div>
                        <div className="field-radiobutton">
                            <RadioButton inputId="CenterAdmin" name="city" value="CenterAdmin" onChange={(e) => setUserType(e.value)} checked={userType === 'CenterAdmin'} />
                            <label htmlFor="CenterAdmin">Admin</label>
                        </div>
                        <div className="field-radiobutton">
                            <RadioButton inputId="Franchise" name="Franchise" value="Franchise" onChange={(e) => setUserType(e.value)} checked={userType === 'Franchise'} />
                            <label htmlFor="Franchise">Franchise</label>
                        </div>
                        <div>
                            {userType === "Franchise" && (
                                <div >
                                    <div>
                                        <Dropdown className="Dropdown" value={selectedDistrict} options={applicableDistricts} id="district" ref={register} onChange={onDistrictChange} showClear placeholder="Select a District"
                                        />
                                    </div>
                                    <Dropdown className="Dropdown" value={franchise} options={franchisesOfDistrict} id="name" optionLabel="name" ref={register} onChange={onFranchiseChange} showClear placeholder="Select Franchise"
                                    />
                                    {/* <div>
                                    <input type="text" name="wing" ref={register} placeholder="Wing" />
                                </div>
                                <div>
                                    <input type="text" name="flatNo" ref={register} placeholder="Flat Number" />
                                </div> */}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="popup__footer">
                    <Button onClick={handleSubmit(onSubmit)} style={{ marginRight: '20px', margin: '5px' }} disabled={submitDisabled}>SUBMIT</Button>
                    <Button onClick={() => props.setTrigger(false)} style={{ marginRight: '20px', margin: '5px' }}>CLOSE</Button>
                </div>
            </div>
        </div>

    ) : "";
}

export default LoginPopup

