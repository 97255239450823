import './AdminStockReturn.css';
import '../App.css';
import 'primeflex/primeflex.css';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { Calendar } from 'primereact/calendar';
// import 'primeicons/primeicons.css';
// import 'primereact/resources/themes/lara-light-indigo/theme.css';
// import 'primereact/resources/primereact.css';
import React, { useState, useEffect, useRef } from 'react';
import { useForm } from "react-hook-form";
// import { Dropdown } from 'bootstrap';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import GreenFieldService from '../services/greenFieldService';
import ExportCsvReport from '../exportReport/exportCsvReport';
import ExportPdfReport from '../exportReport/exportPdfReport';
import ExportExcelReport from '../exportReport/exportExcelReport';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { Checkbox } from 'primereact';
import axios from 'axios';
import { Dialog } from 'primereact/dialog';

import { Toast } from 'primereact/toast';
import Config from '../Config.json';
import { DATA_GRID_PROPTYPES } from '@material-ui/data-grid';
import { BlockUI } from 'primereact/blockui';
import ToolBoxGrid from './Misc/ToolBoxGrid';
import moment from 'moment';
import authHeader from '../authHeader';


// import { E } from 'chart.js/dist/chunks/helpers.core';



function AdminStockReturn() {

    const [selectedRC, setSelectedRc] = useState(null);
    const [date3, setDate3] = useState(null);
    const [returnStocks, setReturnStocks] = useState(null);
    const [returnStocksOriginal, setReturnStocksOriginal] = useState(null);
    const [columns,setColumns]=useState([{ title: 'srNo', dataKey: 'srNo' },{ title: 'FranchiseName', dataKey: 'franchiseName' },{ title: 'Rc Number', dataKey: 'rcNo' },{ title: 'Rc Dates', dataKey: 'rcDate' },{ title: 'Total Quantity', dataKey: 'totalQuantity' },{ title: 'Total Value', dataKey: 'totalValue' }]);
    const toast = useRef(null);
    const dt = useRef(null);
    const greenFieldService = new GreenFieldService();
    const [filters1, setFilters1] = useState(null);
    const [expandedRows, setExpandedRows] = useState(null);
    const {
        register: Filter,
        errors: errors1,
        handleSubmit: handleFilter,
        setValue: setFilterValue,
    } = useForm();
    const [globalFilter, setGlobalFilter] = useState('');
    const [blockedPanel, setBlockedPanel] = useState(false);
    const [exportData,setExportData]= useState([]);
    var accessLevel = parseInt(sessionStorage.getItem('accessLevel'));

    useEffect(() => {

        let d = moment(new Date()).format('YYYY-MM-DD');
        setFilterValue('fyDate',d);
        setFilterValue('toDate',d);
        //here getting return stock of admin from franchise
        getReturnStockAdmin();

        initFilters1();

        //Access level
        if(accessLevel > 1)
        {
          setBlockedPanel(false);
        }
        else{
            setBlockedPanel(true);
        }

    }, [])

    const getReturnStockAdmin=()=>{
        
        greenFieldService.GetReturnStockDetails(-1).then(data => {
            getExcelData(data);
            let serNo = 1
            data.forEach(rcItem => {
                rcItem.srNo = serNo++;
             rcItem.rcDate= rcItem.rcDate.replace('T', ' ');
             rcItem.rcDateValue=new Date(rcItem.rcDate);
                rcItem.changeStatus = "unmodified";
                let chSerNo = 1;
                rcItem.stockInfoDetails.forEach(child => {
                    child.srNo = chSerNo++;
                    child.originalStatus = child.status;
                    child.QtyReturnToAdmin = 0;

                    child.ret=false;
                    child.changeStatus = "unmodified";
                   
                    
                })

                // let _exportData = [...exportData]
                // let _excelData={rcNo:rcItem.rcNo,rcDate:rcItem.rcDateFormat,franchiseName:rcItem.franchinseName,totalQuantity:rcItem.totalQuantity,totalValue:rcItem.totalValue}
            
                // exportData.push(_excelData);
                // setExportData(_exportData);

                let anyPending = rcItem.stockInfoDetails.findIndex(a => a.status === "Return_Pending");
                if (anyPending >= 0) {
                    rcItem.statusColor = "Pending";
                }
                else {
                    rcItem.statusColor = "Confirmed";
                }

                

            })
            setReturnStocks(data);
            setReturnStocksOriginal(data);

        },
            (error) => console.log(error));
    }

    const getExcelData=(data)=>{
        let exceldata=[];
        data.forEach(a=>{
            let newData={franchiseName:a.franchinseName,rcNo:a.rcNo,rcDate:a.rcDateFormat,ReturnQuantity:a.totalQuantity,TotalValue:a.totalValue};
            exceldata.push(newData);
        })
        setExportData(exceldata);
    }


    const onFilter = (filterValue) => {
        let fromdate=new Date(filterValue.fyDate)  ;
        fromdate.setDate(fromdate.getDate()-1);

        let toDate=new Date(filterValue.toDate);

        let data = returnStocksOriginal.filter(a => a.rcDateValue >= fromdate   && a.rcDateValue <= toDate);
        let srNo = 1;
        data.forEach(d => {
            d.srNo = srNo;
            srNo++;
        })
        setReturnStocks(data);
        getExcelData(data);

    }

    
    const onChangeGlobal = (e) => {
        let value = e.target.value;
        let _filters1 = { ...filters1 };
        _filters1['global'].value = value;

        setFilters1(_filters1);

        setGlobalFilter(value);
    }


    const initFilters1 = () => {
        setFilters1({
            'global': { value: null, matchMode: FilterMatchMode.CONTAINS },

        });
        setGlobalFilter('');
    }

    const onReset = () => {

        const fields = [
            "fyDate",
            "toDate"
        ];
        fields.forEach((field) => setFilterValue(field, ''));
    }

    const header = (
        <div className="table-header">
            <div>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" className="globleInput" value={globalFilter} onChange={onChangeGlobal} placeholder="Global Search" />
                </span>
            </div>
        </div>
    );
    const allowExpansion = (rowData) => {
        //  setExpandedIndex(rowData.orders.length);
        return rowData.orders.length > 0;
    };

    const actionButtons = (rowData) => {
        //  setExpandedIndex(rowData.orders.length);
        return rowData.orders.length > 0;
    };

    const UpdateButton = (rowData) => {
        return <div>
            {/* <button className='reset' type="button" onClick={() => onUpdate(rowData)}>Update</button> */}

        </div>;
    }

    const onUpdate = (data) => {
        let changedData = data.filter(a => a.changeStatus != undefined && a.changeStatus.toLowerCase() === "modified");
        console.log(changedData);

        let ind= changedData.findIndex(a=>a.originalStatus==="Return_Confirmed");
        if(ind>=0){
            toast.current.show({ severity: 'warn', detail: "You can not change confirmed records.", sticky: true });
            return;
        }
        if(changedData.length <= 0)
        {
            toast.current.show({ severity: 'warn', detail: "No Change in data,please change first then update", sticky: true });
            return;
        }
        

            return axios.post(`${Config.apiUrl}/Stocks/UpdateReturnStocks`, changedData, { headers: authHeader() })
                .then(response => {

                    response.data.forEach(msg => {
                        if (msg.success) {
                            toast.current.show({ severity: 'success', detail: msg.msg, autoDismiss:true});
                        }
                        else {
                            toast.current.show({ severity: 'error', detail: msg.msg, sticky: true });
                        }
                    });
                }).catch(error => {
                    toast.current.show({ severity: 'error', sticky: true, summary: 'Error', detail: error });
                })
                .finally(() => {
                    //   GetFranchiseReturnStockData(franchiseId);
                    // setSpinnerVisible(false);
                    // SetFocusValue(true);
                    getReturnStockAdmin();

                })


    
    }
    const onCellEditComplete = (e) => {
        /*  let { rowData, newValue, field, originalEvent: event } = e;
 
         switch (field) {
             case 'quantity':
             case 'status':
                 
                     rowData['status'] = newValue;
                 
                 break;
 
             default:
                 if (newValue.trim().length > 0)
                     rowData[field] = newValue;
                 else
                     event.preventDefault();
                 break;
         } */
    }

    const onRowEditComplete = (e) => {
        console.log(selectedRC);
        if(e.data.originalStatus==="Return_Confirmed"){
            return;
        }
        e.data.status = e.newData.status;
        e.data.returnQtyToAdmin=e.newData.returnQtyToAdmin;
        e.data.changeStatus = "modified";
        /*  let _products = [...products];
         let { newData, index } = e;
 
         _products[index] = newData;
 
         setProducts(_products); */
    };


    /*  const onRowEditComplete = (e, data) => {
         let _products = [...data];
         let { newData, index } = e;
 
         _products[index] = newData;
 
         setProducts(_products);
     }; */
    const rowExpansionTemplate = (data) => {
        return (
            <BlockUI blocked={blockedPanel}>
            <div className="ReturnSubTable">
                {/* <h5>Orders for {data.name}</h5> */}
                <DataTable value={data.stockInfoDetails} rowClassName={rowClassChild} onRowEditComplete={onRowEditComplete}  editMode="row" dataKey="srNo" >
                    <Column field="srNo" header="Sr.No"></Column>
                    <Column field="productName" header="Prodcut Name"></Column>
                    <Column field="companyName" header="Company Name"></Column>
                    <Column field="batchNo" header="Batch No"></Column>
                    <Column field="quantity" header="Quantity"></Column>
                    {/*     <Column field="dcNo" header="Rc No"></Column>
                    <Column field="dcDateFormat" header="RC Date"></Column> */}
                    {/* <Column field="addedDateFormat" header="Stock Added Date"></Column> */}
                    <Column field="stockValue" header="Stock Value"  ></Column>
                    <Column field="status" header="Status" body={statusTemplate} editor={(options) => statusEditor(options)} onCellEditComplete={onCellEditComplete}> </Column>
                    <Column field="note" header="Note" ></Column>
                    <Column body={checkBoxTemplate} field="returnQtyToAdmin" editor={(options) => checkEditor(options)} header="RetunToAdmin"></Column>
                    {/* <Column body={UpdateButton} field="" header="Action"></Column> */}
                    <Column rowEditor headerStyle={{ width: '10%', minWidth: '8rem' }} bodyStyle={{ textAlign: 'center' }}></Column>
                    {/* <Column body={Button} field="mobile1" header="Action"></Column> */}
                </DataTable>
                <div>
                    <button className='reset' type="button" onClick={() => onUpdate(data.stockInfoDetails)}>Update</button>
                    {/* <button className='reset' style={{marginLeft:'20px'}} type="button" onClick={() => onUpdate(data.stockInfoDetails)}>Update</button> */}

                </div>
            </div>
            </BlockUI>
        );
    }

    const onChangeStatus = ((e, rowData) => {

        // rowData.status= e.target.value;
    })
    const [statuses, setStatuses] = useState([{ label: 'Return_Pending', value: 'Return_Pending' }, { label: 'Return_Confirmed', value: 'Return_Confirmed' }]);
    // const [statuses,setStatuses]=useState(['Return_Pending','Return_Confirmed','Return_Rejected']);
    /* const statusEditor = (rowData) => {
        return (
            <Dropdown value={rowData.status} options={statuses} optionLabel="status" optionValue="status"
                onChange={(e) => rowData.editorCallback(e.value)} placeholder="Select a Status"
                itemTemplate={(option) => {
                    return <span className={`product-badge status-${option.status.toLowerCase()}`}>{option.status}</span>
                }} />
        );
    } */
    const statusEditor = (options) => {
        // if (options.rowData.originalStatus === 'Return_Pending' ) {
            return (

                <Dropdown value={options.value} disabled={options.rowData['originalStatus']==="Return_Confirmed"} options={statuses} optionLabel="label" optionValue="value"
                    onChange={(e) => { options.editorCallback(e.value); options.rowData['status'] = e.value }} placeholder="Select a Status"
                    itemTemplate={(option) => {
                        return <span className={`product-badge status-${option.value.toLowerCase()}`}>{option.label}</span>
                    }} />
            )
      /*   }
        return (

           <label > {options.value}</label>
        ) */
    }

    const checkEditor = (options) => {
        // if (options.rowData.originalStatus === 'Return_Pending' ) {
            return (

                <Checkbox disabled={options.rowData['originalStatus']==="Return_Confirmed"} onChange=
                {(e) => { options.editorCallback(e.checked); 
                    options.rowData.returnQtyToAdmin = e.checked ;

                }} 
                    checked={options.rowData.returnQtyToAdmin}></Checkbox>
                
            )
      /*   }
        return (

           <label > {options.value}</label>
        ) */
    }



    const statusTemplate = (rowData) => {
        return rowData.status;
    }


    const [shouldAddtoAdmin, setShouldAddtoAdmin] = useState(false);
    const checkBoxTemplate = (rowData) => {
        return rowData.returnQtyToAdmin?1:0;

    }
    const rowClass = (data) => {
        return data.statusColor;
    }

    const rowClassChild = (data) => {

        if(data.status==="Return_Pending"){
          return  "Pending";
        }
        if(data.changeStatus==="modified"){
        return "Modified";
        }
        else{
        return "Unmodified";
        }
    }
    const onChangeReturnCheckOption = ((rowData, e) => {
        if (e.target.checked) {
            rowData.QtyReturnToAdmin = rowData.quantity;
        }
        else {
            rowData.QtyReturnToAdmin = 0;
        }
        rowData.returnQtyToAdmin=e.target.checked;
        rowData.changeStatus = "modified";

    });

    return (
        <div className="AdminStockReturn">
            <Toast ref={toast} position={'center'} />
            <h3>Returned Stock Entries</h3>
            <div  style={{ minHeight: '890px' }}>
                <div className="box">
                    <div className="field col">
                    <label>From:</label>
                        <input type='date' name='fyDate' className='date' ref={Filter} />
                        <label>To:</label>
                        <input type='date' name='toDate' className='date' ref={Filter} />
                        <button type="button filter" className='filter' onClick={handleFilter(onFilter)}>Filter</button>
                        <button type="button" className='reset' onClick={handleFilter(onReset)}>Reset</button>
                    </div>

                </div>

                <div className='box AdminStockReturn'>
                    {/* <div>
                        <button type='button'>Copy</button>
                        <button type='button'>CSV</button>
                        <button type='button'>Excel</button>
                        <button type='button'>PDF</button>
                        <button type='button'>Print</button>
                    </div> */}
                    <ToolBoxGrid columns={columns} data={exportData} fileName="ReturnStocks"></ToolBoxGrid>
                    <DataTable  onSelectionChange={e => setSelectedRc(e.value)} ref={dt} value={returnStocks} header={header} filters={filters1}  
                        className="dataTable" rowClassName={rowClass} editMode="row" rowExpansionTemplate={rowExpansionTemplate} expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)}>
                        <Column expander={allowExpansion}  />
                        <Column field="srNo" header="srNo" sortable exportable></Column>
                        <Column field="franchinseName" header="FranchiseName" sortable exportable></Column>
                        <Column field="rcNo" header="Rc Number" sortable exportable></Column>
                        <Column field="rcDateFormat" header="Rc Dates" sortable exportable></Column>
                        {/* <Column field="dcDateFormat" header="Rc Date" sortable></Column> */}
                        <Column field="totalQuantity" header="Total Quantity" sortable></Column>
                        <Column field="totalValue" header="Total Value" sortable></Column>
                    </DataTable>

                </div>
            </div>
        </div>
    );
}
export default AdminStockReturn;