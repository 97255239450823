import './HsnWiseReport.css';
import React, { useState,useRef } from 'react';
import { useForm } from "react-hook-form";
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { useEffect } from 'react';
import GreenFieldService from '../../services/greenFieldService';
import ToolBoxGrid from '../Misc/ToolBoxGrid';
import { Toast } from 'primereact/toast';
import ProgressSpinBarControl from '../Misc/progressSpinBarControl';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import moment from 'moment';


var qtySum;
var taxableValueSum;
var cgstSum;
var sgstSum;
var totalAmountSum;
var hamaliSum;
var royaltySum;
var discountSum;
var invAmountSum;

function InvoiceReport() {
    const greenFieldService = new GreenFieldService();
    const [date3, setDate3] = useState(null);
    const {
        register: Filter,
        errors: errors1,
        handleSubmit: handleFilter,
        setValue: setFilterValue,
    } = useForm();
    const [columns,setColumns]=useState([{ title: 'Sr No', dataKey: 'serialNo' },{ title: 'Customer Name', dataKey: 'customerName' },{ title: 'Inv No', dataKey:'invName' },{ title: 'Inv Date', dataKey: 'invDateFormat' }, { title: 'Taxable Value', dataKey: 'taxableValue' },{ title: 'CGST', dataKey: 'cgst' }
    ,{ title: 'SGST', dataKey: 'sgst' },{ title: 'Total', dataKey: 'total' },{ title: 'Hamali', dataKey: 'hamali' },{ title: 'Inv Amount', dataKey: 'invAmount' },{ title: 'Royalty', dataKey: 'royalty' },{ title:'Discount', dataKey: 'discount' }]);

    const [globalFilter, setGlobalFilter] = useState();
    const [invoiceData, setInvoiceData] = useState([]);
    const [franchiseData, setFranchiseData] = useState([]);
    const [franchise, setfranchise] = useState();
    const toast = useRef(null);
    const [spinnerVisible, setSpinnerVisible] = useState(false);
    const [exportData,setExportData]= useState([]);


    useEffect(() => {

        greenFieldService.GetFranchiseData().then(data => {
            data.splice(0, 0, { id:-1,name: 'All' })
            setFranchiseData(data);
        });  

       
    }, []);

    

    var errors = [];

    const filterValidate = (data) => {
        if (!data?.fyDate) {
            errors.push('* From Date is required.', <br />);
        }
        if (!data?.toDate) {
            errors.push('* To Date is required', <br />);
        }
    }

    const onFilter = (filterValue) => {
        filterValidate(filterValue)
        if (errors.length > 0) {
            // eslint-disable-next-line no-lone-blocks
            {
                errors.map(() => {
                    toast.current.show({ severity: 'warn', detail: errors, sticky: true });
                }

                )
            }
            errors = [];
            return;
        }
        setSpinnerVisible(true);
        greenFieldService.GetInvoiceReportData(filterValue.fyDate,filterValue.toDate,franchise.id).then(data => {
            taxableValueSum = (data.reduce((a, v) => a + parseFloat(v.taxableValue), 0));
            totalAmountSum = (data.reduce((a, v) => a + parseFloat(v.total), 0));
            cgstSum = (data.reduce((a, v) => a + parseFloat(v.cgst), 0));
            sgstSum = (data.reduce((a, v) => a + parseFloat(v.sgst), 0));
            hamaliSum = (data.reduce((a, v) => a + parseFloat(v.hamali), 0));
            invAmountSum = (data.reduce((a, v) => a + parseFloat(v.invAmount), 0));
            royaltySum = (data.reduce((a, v) => a + parseFloat(v.royalty), 0));
            discountSum = (data.reduce((a, v) => a + parseFloat(v.discount), 0));

            let srNo=1;
            data.forEach(a => {
                a.serialNo=srNo;
                // srNo++;
                a.taxableValue=a.taxableValue.toFixed(2);
                a.cgst=a.cgst.toFixed(2);
                a.sgst=a.sgst.toFixed(2);
                a.total=a.total.toFixed(2);
                a.hamali=a.hamali.toFixed(2);
                a.invAmount=a.invAmount.toFixed(2);
                a.royalty=a.royalty.toFixed(2);
                a.discount=a.discount.toFixed(2);


                srNo++;
            })
            getExcelData(data);
            setInvoiceData(data);
            setSpinnerVisible(false);

        });

    }

    const onReset = () => {

        const fields = [
            "fyDate",
            "toDate"
        ];
        fields.forEach((field) => setFilterValue(field, ''));
        setInvoiceData('');
        taxableValueSum= 0.00
        cgstSum= 0.00
        sgstSum= 0.00
        totalAmountSum= 0.00
        hamaliSum= 0.00
        royaltySum= 0.00
        discountSum= 0.00
        invAmountSum= 0.00
    }

     const onFranchiseChange = (e) => {
        setfranchise(e.value);
    }

    const getExcelData=(data)=>{
        let exceldata=[];
        data.forEach(a=>{
            let newData={'Sr.No':a.serialNo,'Customer Name':a.customerName,'Invoice No':a.invName,'Invoice Date':a.invDateFormat,'Taxable Value':a.taxableValue,CGST:a.cgst,SGST:a.sgst,Total:a.total,Hamali:a.hamali,'Inv Amount':a.invAmount,Royalty:a.royalty,Discount:a.discount};
            exceldata.push(newData);
        })
        setExportData(exceldata);
    }

    const header = (
        <div className="table-header">
            <div>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" className="globleInput" value={globalFilter} onChange={(e) => setGlobalFilter(e.target.value)} placeholder="Global Search" />
                </span>
            </div>
        </div>
    );

     let totalFooter = <ColumnGroup>
    <Row>
        <Column footer="Totals:" footerStyle={{ textAlign: 'right' }} />
        <Column footer='' />
        <Column footer='' />
        <Column footer='' />
        <Column footer={parseFloat(taxableValueSum).toFixed(2)} />
        <Column footer={parseFloat(cgstSum).toFixed(2)} />
        <Column footer={parseFloat(sgstSum).toFixed(2)} />
        <Column footer={parseFloat(totalAmountSum).toFixed(2)} />
        <Column footer={parseFloat(hamaliSum).toFixed(2)} />
        <Column footer={parseFloat(invAmountSum).toFixed(2)} />
        <Column footer={parseFloat(royaltySum).toFixed(2)} />
        <Column footer={parseFloat(discountSum).toFixed(2)} />
    </Row>
    </ColumnGroup>;

    return (
        <div className="HsnReport">
        <ProgressSpinBarControl spinBarVisible={spinnerVisible} />
            <Toast ref={toast} position={'center'} />
            <h3>Invoice Report</h3>
            <div  style={{ minHeight: '1029px' }}>
                <div className="box">
                    <div className="field col">
                        <Dropdown className="Dropdown" value={franchise} options={franchiseData} id="name" optionLabel="name" onChange={onFranchiseChange} showClear placeholder="Select Franchise"/>
                        <label>From:</label>
                        <input type='date' name='fyDate' className='date' ref={Filter} max={moment().format("YYYY-MM-DD")}  />
                        <label>To:</label>
                        <input type='date' name='toDate' className='date' ref={Filter} max={moment().format("YYYY-MM-DD")}  />
                        <button type="button filter" className='filter' onClick={handleFilter(onFilter)}>Load Report</button>
                        <button type="button" className='reset' onClick={handleFilter(onReset)}>Reset</button>
                    </div>
                </div>

                <div className='box'>
                    {/* <div>
                        <button type='button'>Copy</button>
                        <button type='button'>CSV</button>
                        <button type='button'>Excel</button>
                        <button type='button'>PDF</button>
                        <button type='button'>Print</button>
                    </div> */}
                    <ToolBoxGrid columns={columns} data={invoiceData} fileName="Invoice Report"></ToolBoxGrid>
                    <DataTable value={invoiceData}  header={header} globalFilter={globalFilter} scrollable scrollHeight='700px' editMode='row' footerColumnGroup={totalFooter}>
                        <Column field="serialNo" header="Sr No"></Column>
                        <Column field="customerName" header="Customer Name"></Column>
                        <Column field="invName" header="Inv No"></Column>
                        <Column field="invDateFormat" header="Inv Date"></Column>
                        <Column field="taxableValue" header="Taxable Value"></Column>
                        <Column field="cgst" header="CGST"></Column>
                        <Column field="sgst" header="SGST"></Column>
                        <Column field="total" header="Total"></Column>
                        <Column field="hamali" header="Hamali"></Column>
                        <Column field="invAmount" header="Inv Amount"></Column>
                        <Column field="royalty" header="Royalty"></Column>
                        <Column field="discount" header="Discount"></Column>


                    </DataTable>
                </div>
            </div>

        </div>
    );
}
export default InvoiceReport;