import 'primeicons/primeicons.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';
import React, { useState, useEffect, useContext, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import moment, { invalid } from 'moment';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import ProgressSpinBarControl from '../Misc/progressSpinBarControl';
import './InvoiceReport.css';
import GreenFieldService from '../../services/greenFieldService';
import { useParams } from 'react-router';



const franchiseId = parseInt(localStorage.getItem('franchiseId'));
// const customerId = parseInt(localStorage.getItem('customerId'));
// const invNo = parseInt(localStorage.getItem('invNo'));

var converter = require('number-to-words');
var franchiseDetails;
var customerDetail;
var invoiceDetail;
var taxableValueSum;
var centerAmountSum;
var stateAmountSum;
var totalTaxAmountSum;
function InvoicePrintReport({ props }) {


    const [BillDate, setBillDate] = useState();
    // const { customerId, franchiseId, categoryId, invNo,id } = useParams();
    const { customerId, invRefId } = useParams();

    var [spinnerVisible, setSpinnerVisible] = useState(false);
    var converter = require('number-to-words');
    const greenFieldService = new GreenFieldService();
    const [franchiseData, setFranchiseData] = useState([]);
    franchiseDetails = franchiseData;
    const [customerData, setCustomerData] = useState([]);
    customerDetail = customerData;
    const [invoiceData, setInvoiceData] = useState([]);
    invoiceDetail = invoiceData;


    useEffect(() => {
        greenFieldService.GetFranchiseDataOnId(franchiseId).then(data => {
            data.forEach(a => a.franchiseAddress = `${a.address},${a.state}/${a.district}`);
            setFranchiseData(data);
        });

        greenFieldService.GetCustomerDetail(customerId).then(data => {
            data.forEach(a => {
                a.isKisanCardValue = a.isKisanCard ? "Yes" : "No";
            })
            setCustomerData(data);
        });

        greenFieldService.GetInvoice(invRefId).then(data => {
            data[0].invoiceReportDetailsInfo.forEach(a => {
                a.taxableRate = parseFloat(a.taxableRate);
                a.totalTaxAmount = parseFloat(a.totalTaxAmount);
                a.stateAmount = parseFloat(a.stateAmount);
                a.centerAmount = parseFloat(a.centerAmount);
            })


            taxableValueSum = (data[0].invoiceReportDetailsInfo.reduce((a, v) => a + parseFloat(v.taxableRate), 0));
            centerAmountSum = (data[0].invoiceReportDetailsInfo.reduce((a, v) => a + parseFloat(v.centerAmount), 0));
            stateAmountSum = (data[0].invoiceReportDetailsInfo.reduce((a, v) => a + parseFloat(v.stateAmount), 0));
            totalTaxAmountSum = (data[0].invoiceReportDetailsInfo.reduce((a, v) => a + parseFloat(v.totalTaxAmount), 0));
            data[0].amountInWord = converter.toWords(Math.round(data[0]?.dueAmount))

            setInvoiceData(data);

        });



    }, []);

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    return (
        <div className='MemberBill'>
            <div class="card">
                <ProgressSpinBarControl spinBarVisible={spinnerVisible} />
                {/* <Toast ref={toast} position="center" /> */}

                <div className="Form-inline" >
                    {/* <div class="SelectionCard"  > */}

                    <Button label="Print" onClick={handlePrint} />
                </div>
                <MemberBills ref={componentRef} />
            </div>
        </div>
    );

}

const portrait = () => `
footer {
    font-size: 9px;
    color: #f00;
    text-align: center;
  }

  @page {
    size: portrait;
    margin: 10mm 15mm 5mm 15mm;
  }

  @media print {
    footer {
      position: fixed;
      bottom: 0;
    }

    .content-block, p {
      page-break-inside: avoid;
    }

    html, body {
      width: 210mm;
      height: 295mm;

    }
  }
`;

class MemberBills extends React.Component {
    render() {
        // const societyService = new SocietyService();


        return (
            <div class="InvoicePrint">
                <style>{portrait()}</style>

                <div class="Table">
                    <table>
                        <tbody><tr>
                            <th colspan="3" class="text-center">TAX INVOICE</th>
                        </tr>
                            <tr class="font-weight-bold">
                                <td width="33%">
                                    From,<br />
                                    {franchiseDetails[0]?.name}<br />
                                    {franchiseDetails[0]?.franchiseAddress}<br />
                                    GST No: {franchiseDetails[0]?.gstno}<br />
                                    Seed LIC: {franchiseDetails[0]?.seedLicense}<br />
                                    Insecticide LIC: {franchiseDetails[0]?.insectisideLicense}<br />
                                    Fertilizer LIC: {franchiseDetails[0]?.fertilizerLicense}<br />
                                    Mobile: {franchiseDetails[0]?.mobile1}<br />
                                    Email: {franchiseDetails[0]?.email}<br />

                                </td>
                                <td width="33%">
                                    To,<br />
                                    {customerDetail[0]?.name}<br />
                                    {customerDetail[0]?.town},{customerDetail[0]?.district},{customerDetail[0]?.state}<br />
                                    Mobile:{customerDetail[0]?.mobile1}<br />

                                </td>
                                {invoiceDetail.map((data, index) =>
                                    <td width="33%">

                                        Invoice:- {invoiceDetail[0].invName}<br />
                                        Date: {invoiceDetail[0].invDateFormat}
                                    </td>
                                )}
                            </tr>
                        </tbody>
                    </table>
                    {invoiceDetail.map((data, index) =>

                        <table>
                            <thead>
                                <tr>
                                    <th style={{ width: '5%' }} class="text-center">Sr No</th>
                                    <th style={{ width: '15%' }} class="text-center">Product</th>
                                    <th style={{ width: '7%' }} class="text-center">Exp.Date</th>
                                    <th style={{ width: '7%' }} class="text-center">Batch</th>
                                    <th style={{ width: '7%' }} class="text-center">HSN</th>
                                    <th style={{ width: '7%' }} class="text-center">Qty</th>
                                    <th style={{ width: '7%' }} class="text-center">RATE</th>
                                    <th style={{ width: '7%' }} class="text-center">TAXABLE AMOUNT</th>
                                    <th style={{ width: '7%' }} class="text-center">GST RATE</th>
                                    <th style={{ width: '7%' }} class="text-center">CGST</th>
                                    <th style={{ width: '7%' }} class="text-center">SGST</th>
                                    <th style={{ width: '10%' }} class="text-center">Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {invoiceDetail[index].invoiceReportDetailsInfo.map((item) => (
                                    <tr key={item}>
                                        <td>{item?.srNo}</td>
                                        <td>{item?.productName}</td>
                                        <td>{item?.expiryDate}</td>
                                        <td>{item?.batchNo}</td>
                                        <td>{item?.hsnCode}</td>
                                        <td style={{ textAlign: 'right' }}>{item?.quantity}</td>
                                        <td style={{ textAlign: 'right' }}>{item?.rate.toFixed(2)}</td>
                                        <td style={{ textAlign: 'right' }}>{item?.taxableRate.toFixed(2)}</td>
                                        <td style={{ textAlign: 'right' }}>{item?.gstRate}</td>
                                        <td style={{ textAlign: 'right' }}>{item?.centerAmount.toFixed(2)}</td>
                                        <td style={{ textAlign: 'right' }}>{item?.stateAmount.toFixed(2)}</td>
                                        <td style={{ textAlign: 'right' }}>{item?.total.toFixed(2)}</td>
                                    </tr>
                                ))
                                }
                            </tbody>

                            <tfoot>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td colspan="2" style={{ textAlign: 'left' }}>
                                        Less- Discount :
                                    </td>
                                    <td style={{ textAlign: 'right' }}>
                                        {invoiceDetail[index]?.discountAmount.toFixed(2)}                        </td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td colspan="2" style={{ textAlign: 'left' }}>
                                        Amount :
                                    </td>
                                    <td style={{ textAlign: 'right' }}>
                                        {invoiceDetail[index]?.grandTotal.toFixed(2)}                     </td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td colspan="2" style={{ textAlign: 'left' }}>
                                        R/O :
                                    </td>
                                    <td style={{ textAlign: 'right' }}>
                                        {invoiceDetail[index]?.roundUp.toFixed(2)}                    </td>
                                </tr>

                                { (invoiceDetail[index]?.hamali !== undefined && invoiceDetail[index]?.hamali > 0) &&
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td colspan="2" style={{ textAlign: 'left' }}>
                                            Add- Hamali :
                                        </td>
                                        <td style={{ textAlign: 'right' }}>
                                            {invoiceDetail[index]?.hamali.toFixed(2)}                       </td>
                                    </tr>
                                }
                                {(invoiceDetail[index]?.transport !== undefined && invoiceDetail[index]?.transport > 0) &&
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td colspan="2" style={{ textAlign: 'left' }}>
                                            Add- Transportation :
                                        </td>
                                        <td style={{ textAlign: 'right' }}>
                                            {invoiceDetail[index]?.transport.toFixed(2)}                      </td>
                                    </tr>
                                }
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td colspan="2" style={{ textAlign: 'left' }}>
                                        <b>Total :</b>
                                    </td>
                                    <td style={{ textAlign: 'right' }}>
                                        {invoiceDetail[index]?.dueAmount.toFixed(2)}                       </td>
                                </tr>

                                <tr>
                                    <td colspan="12"> <p >Amount Chargeable (in words): <b>{invoiceDetail[index].amountInWord}  Rupees  Only/-</b></p></td>
                                </tr>
                                <tr>
                                    <th class="text-center" colspan="5" rowspan="2">HSN</th>
                                    <th class="text-center" colspan="2" rowspan="2">Taxable Value</th>
                                    <th colspan="2" class="text-center">Central Tax</th>
                                    <th colspan="2" class="text-center">State Tax</th>
                                    <th class="text-center" rowspan="2">Total Tax Amount</th>
                                </tr>

                                <tr>
                                    <td colspan="1" class="text-center">Rate</td>
                                    <td colspan="1" class="text-center">Amount</td>
                                    <td colspan="1" class="text-center">Rate</td>
                                    <td colspan="1" class="text-center">Amount</td>
                                </tr>

                                {invoiceDetail[index].hsnReportDetailsInfo.map((item) => (

                                    <tr key={item}>
                                        <td colspan="5">{item.hsnCode}</td>
                                        <td colspan="2" style={{ textAlign: 'right' }}>{item?.taxableRate.toFixed(2)}</td>
                                        <td style={{ textAlign: 'right' }}>{item?.cgst}</td>
                                        <td style={{ textAlign: 'right' }}>{item?.centerAmount.toFixed(2)}</td>
                                        <td style={{ textAlign: 'right' }}>{item?.sgst}</td>
                                        <td style={{ textAlign: 'right' }}>{item?.stateAmount.toFixed(2)}</td>
                                        <td style={{ textAlign: 'right' }}>{item?.totalTaxAmount.toFixed(2)}</td>
                                    </tr>
                                ))}

                                <tr>
                                    <th colspan="5">Total</th>
                                    <th colspan="2" style={{ textAlign: 'right' }}>{taxableValueSum.toFixed(2)}</th>
                                    <th style={{ textAlign: 'right' }}></th>
                                    <th style={{ textAlign: 'right' }}>{centerAmountSum.toFixed(2)}</th>
                                    <th style={{ textAlign: 'right' }}></th>
                                    <th style={{ textAlign: 'right' }}>{stateAmountSum.toFixed(2)}</th>
                                    <th style={{ textAlign: 'right' }}>{totalTaxAmountSum.toFixed(2)}</th>
                                </tr>

                                <tr>
                                    <td colspan="9" width="70%">
                                        Declaration:
                                        <p style={{ fontWeight: 'normal' }}>1. We hereby certify that our registration certificate under the GST act 2017 is in force on the date on which the supply of the goods specified in this Tax invoice is made by us and that the transaction of supply covered <br />
                                            2. if there is any discrepancy in Oty/Quality or prices etc same should be informed within 7 days from invoice date otherwise taken as accepted to you <br />
                                            3. 24% Interest will be charg on a/c remain unpaid after due date <br />
                                            4. We do not responsible for any kind of loss in transit.</p>
                                    </td>
                                    <td colspan="3" class="text-center">
                                        <p>Authorized Signature</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="12" class="text-center"> <p>This is a Computer Generated Invoice</p></td>
                                </tr>
                            </tfoot>
                        </table>
                    )}
                </div>
            </div>

        )
    }
}
export default InvoicePrintReport;