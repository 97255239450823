import React from 'react';
import './AddComplaint.css';
import 'primeflex/primeflex.css';


function AddComplaint() {
    return (
        <div className="AddComplaint">

            <div class="card">
                <h3 >Add Complaint</h3>
                <div style={{ display: 'flex' }}>
                    <div class="formgrid grid">
                        <div class="field">
                            <label for="Subject">Subject<span style={{ color: 'red' }}>*</span></label>
                            <input id="Subject" type="text" class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary" ></input>
                        </div>
                        <div class="formgrid grid">
                            <div class="field">
                                <label for="Description" class="Description">Description<span style={{ color: 'red' }}>*</span></label>
                                <input id="Description" type="text" class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary"></input>
                            </div>
                        </div>
                    </div>
                    </div>
                    <div class="formgrid grid">
                        <button>Save</button>
                    </div>
            </div>
        </div>

    );
}
export default AddComplaint;
