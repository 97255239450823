import './Nav.css';
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import React, { useRef, useContext, useState, useEffect } from 'react';
import { SlideMenu } from 'primereact/slidemenu';
import { Button } from 'primereact/button';
import { Menubar } from 'primereact/menubar';
import { useHistory } from 'react-router-dom';
import { Dropdown } from 'primereact/dropdown';
import { Avatar } from '@material-ui/core';
import { Sidebar } from 'primereact/sidebar';
import { TieredMenu } from 'primereact/tieredmenu';
import { PanelMenu } from 'primereact/panelmenu';
import Home from '../home';



function Nav() {
  const [visible, setVisible] = useState(false);
  const franchiseId = localStorage.getItem('franchiseId');
  const franchiseName = localStorage.getItem('FranchiseName');
  const isAdmin = sessionStorage.getItem('IsAdmin');
  var Name = localStorage.getItem('name').toUpperCase();
  const [accessColor, setAccessColor] = useState('orange');
  var accessLevel = parseInt(sessionStorage.getItem('accessLevel'));

  var user;
  if (isAdmin === 'true') {
    user = 'AdminLogin';

  }
  else {
    user = 'FranchiseLogin';

  }

  useEffect(() => {
    //getting group name
    if (accessLevel === 1) {
      setAccessColor('green');
    }
    else if (accessLevel === 2) {
      setAccessColor('Orange');
    }
    else if (accessLevel === 3) {
      setAccessColor('red');
    }

  },[]);

  const stringAvatar = (Name) => {
    return {
      // sx: {
      //   bgcolor: stringToColor(name),
      // },
      children: `${Name?.split(' ')[0][0]}${Name.split(' ')[1][0]}`,
    };
  }


  let history = useHistory();
  const menu = useRef(null);
  let items = [];
  if (isAdmin === 'false') {
    items = [
      {
        label: 'Dashboard', icon: 'pi pi-home',
        command: () => { window.location = "/FranchiseDashboard" }
      },
      {
        label: 'Received Stock Entries',
        icon: 'pi pi-inbox',
        command: () => { window.location = "/ReceivedStockEntries" },
      },
      {
        label: 'Return Stock', icon: 'pi pi-angle-double-left',
        command: () => { window.location = "/ReturnStock" }
      },
      {
        label: 'Create Invoice',
        icon: 'pi pi-shopping-cart',
        command: () => { window.location = "/CreateInvoice" },
      },

      {
        label: 'Return Sales', icon: 'pi pi-arrow-up-left',
        command: () => { window.location = "/SalesReturnOfFranchise" },
      },

      {
        label: 'Accounts',
        icon: 'pi pi-wallet',
        command: () => { window.location = "/Account" },
      },


      // {
      //   label: 'Admin Stock Return', icon: 'pi pi-home',
      //   command: () => { window.location = "/AdminStockReturn" },
      // },
      // {
      //   label: 'Distributed Stock Entries', icon: 'pi pi-home',
      //   command: () => { window.location = "/Products" },
      // },
      // {
      //   label: 'All Accounts', icon: 'pi pi-home',
      //   command: () => { window.location = "/Products" },
      // },

      {
        label: 'Reports', icon: 'pi pi-home',
        items: [


          {
            label: 'Return Stocks', icon: 'pi pi-angle-double-left',
            command: () => { window.location = "/FranchiseStockReturn" },
          },
          {
            label: 'All Invoices',
            icon: 'pi pi-shopping-cart',
            command: () => { window.location = "/AllInvoices" },
          },
          {
            label: 'All Return Invoices',
            icon: 'pi pi-shopping-cart',
            command: () => { window.location = "/AllReturnInvoices" },
          },
          {
            label: 'Product Sales', icon: 'pi pi-paypal',
            command: () => { window.location = "/ProductSalesReport" },
          },
          {
            label: 'Stock Report', icon: 'pi pi-book',
            command: () => { window.location = "/FranchiseStockReport" },
          }
        ]


      },
      {
        label: 'Order Request', icon: 'pi pi-calendar-plus',
        command: () => { window.location = "/OrderRequest" },
      },
  
          {
            label: 'Add Franchise Complaint',
            icon: 'pi pi-flag-fill',
            command: () => { window.location = "/AddFranchiseComplaints" }
          },
    


      {
        label: 'Farmer Complaints', icon: 'pi pi-reddit',
        command: () => { window.location = "/FarmerComplaintsOfFranchise" },
      },

      /*  {
         label: 'Order Request', icon: 'pi pi-home',
         command: () => { window.location = "/OrderRequestOfFranchise" },
       }, */


    ];
  }
  else {
    items = [
      {
        label: 'Dashboard', icon: 'pi pi-home',
        command: () => { window.location = "/AdminDashboard" }
      },
      {
        label: 'Master', icon: 'pi pi-database',
        items: [
          {
            label: 'Units',
            icon: 'pi pi-box',
            command: () => { window.location = "/Units" }
          },
          {
            label: 'Category',
            icon: 'pi pi-share-alt',
            command: () => { window.location = "/Category" }
          },
          {
            label: 'Products', icon: 'pi pi-paypal',
            command: () => { window.location = "/Products" },
          },
        ]
      },
      {
        label: 'Admin Stock Return', icon: 'pi pi-directions-alt',
        command: () => { window.location = "/AdminStockReturn" },
      },
      {
        label: 'Add Stocks', icon: 'pi pi-cloud-upload',
        command: () => { window.location = "/Stock" }

        // command: () => { window.location = "/Products" },
      },

    
      {
        label: 'All Accounts', icon: 'pi pi-wallet',
        command: () => { window.location = "/AllAccounts" },
      },
      {
        label: 'Report', icon: 'pi pi-home',
        items: [
          {
            label: 'Invoice wise sale report',
            icon: 'pi pi-book',
            command: () => { window.location = "/InvoiceReport" },
          },
          {
            label: 'Product wise sale report',
            icon: 'pi pi-book',
            command: () => { window.location = "/ProductReport" },
          },
          {
            label: 'Customer Report',
            icon: 'pi pi-book',
            command: () => { window.location = "/CustomerReport" },
          },
          {
            label: 'Stock Report',
            icon: 'pi pi-book',
            command: () => { window.location = "/StockReport" },
          },
          {
            label: 'OutStanding Report',
            icon: 'pi pi-book',
            command: () => { window.location = "/OutStandingReport" },
          },
          {
            label: 'Stock Value Report',
            icon: 'pi pi-book',
            command: () => { window.location = "/StockValueReport" },
          },
          {
            label: 'Hsn Wise sale report',
            icon: 'pi pi-book',
            command: () => { window.location = "/HsnReport" },
          },
          {
            label: 'Distributed Stock Entries', icon: 'pi pi-slack',
            command: () => { window.location = "/DistributedStockEntry" },
          },
          {
            label: 'Invoice wise sale return report',
            icon: 'pi pi-book',
            command: () => { window.location = "/ReturnInvoiceReport" },
          },
          {
            label: 'Product wise sale return report',
            icon: 'pi pi-book',
            command: () => { window.location = "/ReturnProductReport" },
          },
          {
            label: 'Gkm wise Product report',
            icon: 'pi pi-book',
            command: () => { window.location = "/GkmProductReport" },
          },
          

        ]
      },

      {
        label: 'Invoices', icon: 'pi pi-shopping-cart',
        items: [
          {
            label: 'Sale Invoices',
            icon: 'pi pi-shopping-cart',
            command: () => { window.location = "/AllInvoices" },
          },
          {
            label: 'Return Invoices',
            icon: 'pi pi-shopping-cart',
            command: () => { window.location = "/AllReturnInvoices" },
          },
          

        ]
      },
      
      {
        label: 'Franchise', icon: 'pi pi-building',
        command: () => { window.location = "/Franchise" }

        // items: [
        //   {
        //     label: 'Franchise Master',
        //     icon: 'pi pi-fw pi-plus',
        //     command: () => { window.location = "/Franchise" }
        //   },
        //   {
        //     label: 'Customer Master',
        //     icon: 'pi pi-fw pi-plus',
        //     command: () => { window.location = "/Customer" }
        //   },
        // ]
      },
      {
        label: 'Employee', icon: 'pi pi-users',
        command: () => { window.location = "/Employee" },
      },
      {
        label: 'Franchise Complaints', icon: 'pi pi-flag-fill',
        command: () => { window.location = "/FranchiseComplaints" },
      },
      {
        label: 'All Farmer Complaints', icon: 'pi pi-list',
        command: () => { window.location = "/FarmerComplaints" },
      },
      {
        label: 'All Order Request', icon: 'pi pi-android',
        command: () => { window.location = "/AdminOrderRequest" },
      },
      {
        label: 'Lock Screen', icon: 'pi pi-lock',
        command: () => { window.location = "/LockScreen"},
      },

      {
        label: 'Permission', icon: 'pi pi-link', icon: 'pi pi-wrench',
        command: () => { window.location = "/Permission" },
      },
      {
        label: 'View Logs', icon: 'pi pi-link', 
        command: () => { window.location = "/ViewLogs" },
      }
    ];
  }

  const profile = [
    {
      label: 'View Profile', icon: 'pi pi-user',
      command: () => { window.location = "/Profile" }
    },
    {
      label: 'LogOut', icon: 'pi pi-sign-out',
      command: () => { window.location = "/login" },
    }

  ]

  return (

    <div>
      <div className={`Nav ${(user)}`} >
        <div style={{ display: 'flex', justifyContent: 'left' }}>
          <Button icon="pi pi-bars" onClick={(e) => setVisible(true)} />
        </div>
        {/* <div > */}
        {isAdmin === 'false' && (
          <div className="societyName" style={{ marginLeft: '20px' }}>
            Franchise Name : {franchiseName.toUpperCase()} -
            {franchiseId}
          </div>
        )}

        {/* <div className="UiName">
          {uiName}
        </div> */}
        <SlideMenu ref={menu} model={profile} popup viewportHeight={200} menuWidth={175}></SlideMenu>
        <div className="Menu" >
          <Avatar icon="user" onClick={(event) => menu.current.toggle(event)} style={{ background: accessColor, marginRight: '10px', alignSelf: 'flex-end' }}{...stringAvatar(Name)} ></Avatar>

        </div>
      </div>

      <div className='SideBar Nav'>
        <Sidebar visible={visible} position='left' style={{ width: 'auto', height: "100%" }} onHide={() => setVisible(false)} >
          <div >
            {/* <Menu model={items} style={{ backgroundColor: "rgb(232 217 245 / 35%)" }} /> */}
            {/* <TieredMenu model={items} header='GreenField' /> */}
            <PanelMenu model={items} style={{ width: '250px' }} />

          </div>
        </Sidebar>
      </div>
    </div>



  );
}

export default Nav;
