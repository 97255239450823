import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from "primereact/button";
import React, { useState, useEffect, useContext, useRef } from 'react';
import './Permission.css';
import Config from '../../Config.json';
import { Toast } from 'primereact/toast';
import { TabView, TabPanel } from 'primereact/tabview';
import axios from 'axios';
import { useForm } from "react-hook-form";
import authHeader from '../../authHeader';
import ProgressSpinBarControl from './progressSpinBarControl';
import GreenFieldService from '../../services/greenFieldService';
import { BlockUI } from 'primereact/blockui';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';

function Permission({ id }) {
    const [activeIndex, setActiveIndex] = useState(0);
    const { register, handleSubmit, errors, clearErrors, setValue } = useForm();
    var [selectedCheckboxData, setselectedCheckboxData] = useState([]);
    const service = new GreenFieldService();
    const isAddMode = !id;
    const toast = useRef(null);
    // const { societyId } = useContext(SocietyContext);
    var societyId = localStorage.getItem('societyId');
    const [actionData, setActionData] = useState([]);
    const [addPermissionDetails, setPermissionDetails] = useState();
    const [selectRole, setSelectRole] = useState();
    const [userSelectedAccessLevel, setUserSelectedAccessLevel] = useState("");
    var accessLevel = parseInt(sessionStorage.getItem('accessLevel'));
    const [DisableButton, setDisableButton] = useState(true);
    const [spinnerVisible, setSpinnerVisible] = useState(false);
    const [users, setUsers] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [blockedPanel, setBlockedPanel] = useState(false);
    var accessLevel = parseInt(sessionStorage.getItem('accessLevel'));

    useEffect(() => {
        getPermissionDetails();
        getUsers();
        //  console.warn(addPermissionDetails);

        if (accessLevel > 1) {
            setDisableButton(false);
            setBlockedPanel(false);
        }
        else {
            setDisableButton(true);
            setBlockedPanel(true);
        }
    }, []);

    const getPermissionDetails = (() => {
        service.getPermissionDetails(0).then(data => {
            data.forEach(a => {
                if (a.action === '1') {
                    a.actionName = 'Approved';
                }
                else {
                    a.actionName = 'Pending';
                }

                if (a.isAdmin) {
                    a.LoginType = "Admin";
                }
                else {
                    a.LoginType = "Franchise";
                }

                a.AccessRights = a.accessLevel == 2 ? "ReadWrite" : (a.accessLevel == 1 ? "ReadOnly" : "No Rights")
            })

            setPermissionDetails(data);
            console.log(data);
        }).catch(e => {
            console.log(e);
        });


    })
    const rowClass = (data) => {
        return {
            'Admin': data.isAdmin === true
        };
    };


    const getUsers = (() => {
        service.getUsers(0).then(data => {
            data.forEach(a => {
                // a.userName = a.firstName + " " + a.lastName;
            })

            setUsers(data);
            console.log(data);
        }).catch(e => {
            console.log(e);
        });

    })

    const onAction = (data, e) => {

        data= data.filter(a=>!((a.actionName.toUpperCase() ==="APPROVED") ||(a.actionName.toUpperCase() ==="REJECTED" )));

        if(data.length ===0){
            toast.current.show({ severity: 'info', detail: "No records with changed states are selected" });
            return;
        }

        data.forEach(a => {
            a.action = data.action;
            a.accesslevel = selectRole;
            let tempItem = {};

            tempItem.ID = a.permissionId;
            tempItem.action = a.action;
            tempItem.AccessLevel = selectRole;
            setSpinnerVisible(true);
            return axios.post(`${Config.apiUrl}/Permission/updateAction`, tempItem, { headers: authHeader() })
                .then(response => {
                    console.warn(data);
                    console.log(response)
                    if (response.data != null) {
                        toast.current.show({ severity: 'success', detail: response.data.msg ,autoDismiss:true});
                        window.location.reload();
                    }
                    else {
                        toast.current.show({ severity: 'error', detail: response.data.msg });
                    }
                })
                .catch(error => {
                    toast.current.show({ severity: 'error', sticky: true, summary: 'Error', detail: error });
                })
                .finally(() => {
                    setSpinnerVisible(false);
                    getPermissionDetails();
                })
        })

    }

    const confirmDeletePermissions = (e) => {
      //  e.preventDefault();
        confirmDialog({
            message: 'Do you want to delete selected Permissions?',
            header: 'Delete Confirmation',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            accept: () => deletepermissions(),
        reject: () => reject()
           
        });
    };
    
    const confirmDeleteUser = () => {
        confirmDialog({
            message: 'Do you want to delete selected Users?',
            header: 'Delete Confirmation',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            accept: () => deleteUser(),
        reject: () => reject()
        });
    };
    
    const reject = () => {
     //   toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
    }
    const deletepermissions = () => {

        let ids = selectedCheckboxData.map(a => a.permissionId);
        return axios.post(`${Config.apiUrl}/Permission/DeletePermissions`, ids, { headers: authHeader() })
            .then(data => {

                data.data.forEach(response => {
                    console.log(response)
                    if (response != null) {
                        toast.current.show({ severity: 'success', detail: response.msg, autoDismiss:true });
                        window.location.reload();
                    }
                    else {
                        toast.current.show({ severity: 'error', detail: response.msg });
                    }
                });
            }).catch(error => {
                toast.current.show({ severity: 'error', sticky: true, summary: 'Error', detail: error });
            })
            .finally(() => {
                setSpinnerVisible(false);
                getPermissionDetails();
            })

    }


    const approve = (e) => {

        if(selectedCheckboxData=== undefined|| selectedCheckboxData.length===0){
            toast.current.show({ severity: 'warn', detail: "No Permissions data is selected" });
            return;
        }


        if (selectRole === undefined || selectRole === "None" ) {
            toast.current.show({ severity: 'warn', detail: "please select User Role=> ReadWrite or ReadOnly" });

        }
        else {
            selectedCheckboxData.action = 'Approve';
            onAction(selectedCheckboxData);
        }
    }

    const decline = (e) => {
        selectedCheckboxData.action = 'Decline';
        onAction(selectedCheckboxData);


    }
    const selectedRole = (e) => {
        let selectedRole = e.currentTarget.value;
        setSelectRole(selectedRole);
    }



    const header = (
        <div className="table-header">
            <div style={{ marginRight: '50px' }}>
                <Button icon="pi pi-check" style={{ background: "Red" }} className="btnApprove" label="Delete Permissions" onClick={confirmDeletePermissions}/>
            </div>
            <select onChange={selectedRole} style={{ height: '30px', width: '200px', marginRight: '10px' }}>
                <option hidden value="">Select Role </option>
                <option>None</option>
                <option>ReadOnly</option>
                <option>ReadWrite</option>
            </select>

            <Button icon="pi pi-check" className="btnApprove" label="Approve" onClick={handleSubmit(approve)} />
            <Button icon="pi pi-times" className="btnDecline" label="Decline" onClick={handleSubmit(decline)} />
        </div>
    )


    const deleteUser = (e) => {

        if(selectedUsers=== undefined|| selectedUsers.length===0){
            toast.current.show({ severity: 'warn', detail: "No Users are selected" });
            return;
        }

        let ids = selectedUsers.map(a => a.userId);
        return axios.post(`${Config.apiUrl}/Permission/DeleteUser`, ids, { headers: authHeader() })
            .then(data => {

                data.data.forEach(response => {
                    console.log(response)
                    if (response != null) {
                        toast.current.show({ severity: 'success', detail: response.msg ,autoDismiss:true });
                        window.location.reload();
                    }
                    else {
                        toast.current.show({ severity: 'error', detail: response.msg });
                    }
                });
            }).catch(error => {
                toast.current.show({ severity: 'error', sticky: true, summary: 'Error', detail: error });
            })
            .finally(() => {
                setSpinnerVisible(false);
                getUsers();
            })

    }
    const userheader = (
        <div className="table-header">
            <div style={{ marginRight: '50px' }}>
                <Button icon="pi pi-check" style={{ background: "Red" }} className="btnApprove" label="Delete User" onClick={confirmDeleteUser} />
            </div>
        </div>
    )

    const onDeletePermissionDialog = (rowData) => {
        toast.current.show({
            severity: 'warn', sticky: true, content: (
                <div className="p-flex p-flex-column" style={{ flex: '1' }}>
                    <div className="text-center">
                        <i className="pi pi-exclamation-triangle" style={{ fontSize: '3rem' }}></i>
                        <h4>Are you sure?</h4>
                        <p>Confirm to Delete</p>
                    </div>
                    <div className="grid p-fluid">
                        <div className="col-6">
                            <Button type="button" label="Yes" className="p-button-success" onClick={() =>deletepermissions} />
                        </div>
                        <div className="col-6">
                            <Button type="button" label="No" className="p-button-secondary" onClick={() =>console.deg } />
                        </div>
                    </div>
                </div>
            )
        });
    }
    return (
        <BlockUI blocked={blockedPanel}>
            <div>
        
                <Toast ref={toast} />
                <ConfirmDialog />
                <ProgressSpinBarControl spinBarVisible={spinnerVisible} />


                <div className="PermissionTable">

                    <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                        <TabPanel header="Permissions">
                            <DataTable header={header} rowClassName={rowClass} value={addPermissionDetails} className="p-datatable-sm" width="500px" selection={selectedCheckboxData} onSelectionChange={e => setselectedCheckboxData(e.value)}>
                                <Column selectionMode="multiple" headerStyle={{ width: "3em" }}></Column>
                                <Column field="userName" header="User Name"></Column>
                                <Column field="LoginType" header="IsAdmin"></Column>
                                <Column field="AccessRights" header="AccessRights"></Column>
                                <Column field="firstName" header="First Name"></Column>
                                <Column field="lastName" header="Last Name"></Column>
                                <Column field="franchiseName" header="Franchise"></Column>
                                <Column field="mobileNo" header="Mobile No"></Column>
                                <Column field="actionName" header="State"></Column>

                            </DataTable>

                        </TabPanel>
                        <TabPanel header="Users">
                            <DataTable value={users} header={userheader} className="p-datatable-sm" width="500px" selection={selectedUsers} onSelectionChange={e => setSelectedUsers(e.value)}>
                                <Column selectionMode="multiple" headerStyle={{ width: "3em" }}></Column>
                                <Column field="userName" header="User Name"></Column>
                                <Column field="firstName" header="First Name"></Column>
                                <Column field="lastName" header="Last Name"></Column>
                                <Column field="mobileNo" header="Mobile No"></Column>

                            </DataTable>
                        </TabPanel>

                    </TabView>
                </div>


            </div>
        </BlockUI>

    );
}
export default Permission;