import './ProductSalesReport.css';
import React, { useState, useEffect, useRef } from 'react';
import { useForm } from "react-hook-form";
import { Dropdown } from 'bootstrap';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import GreenFieldService from '../../services/greenFieldService';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { BlockUI } from 'primereact/blockui';
import { Toast } from 'primereact/toast';
import ProgressSpinBarControl from '../Misc/progressSpinBarControl';
import moment from 'moment';
var totalQuantity;
var totalTaxableAmount;
var totalCgst;
var totalSgst;
var netTotal;



function ProductSalesReport() {
    const franchiseId = localStorage.getItem('franchiseId');
    const greenFieldService = new GreenFieldService();
    const [date3, setDate3] = useState(null);
    const dt = useRef(null);
    const {
        register: Filter,
        errors: errors1,
        handleSubmit: handleFilter,
        setValue: setFilterValue,
    } = useForm();
    const toast = useRef(null);
    const [filters1, setFilters1] = useState(null);
    const [globalFilter, setGlobalFilter] = useState();
    const [salesProductData, setSalesProductData] = useState();
    const [initialData, setInitialData] = useState();
    const [blockedPanel, setBlockedPanel] = useState(false);
    var accessLevel = parseInt(sessionStorage.getItem('accessLevel'));
    const [spinnerVisible, setSpinnerVisible] = useState(false);
    useEffect(() => {
        let d = moment(new Date()).format('YYYY-MM-DD');
        setFilterValue('fyDate', d);
        setFilterValue('toDate', d);

        getSaleProductList(franchiseId);

        if (accessLevel > 1) {
            setBlockedPanel(false);
        }
        else {
            setBlockedPanel(true);
        }
    }, []);

    const getSaleProductList = (franchiseId) => {
        setSpinnerVisible(true);
        greenFieldService.GetSaleProductDetails(franchiseId).then(data => {
            let srNo = 1;
            data.forEach(d => {
                d.serialNo = srNo;
                d.taxableAmount = d.taxableAmount.toFixed(2);
                d.cgst = d.cgst.toFixed(2);
                d.sgst = d.sgst.toFixed(2);
                d.total = d.total.toFixed(2);

                srNo++;
            })
            totalQuantity = (data.reduce((a, v) => a + parseFloat(v.quantity), 0));
            totalSgst = (data.reduce((a, v) => a + parseFloat(v.sgst), 0)).toFixed(2);
            totalCgst = (data.reduce((a, v) => a + parseFloat(v.cgst), 0)).toFixed(2);
            totalTaxableAmount = (data.reduce((a, v) => a + parseFloat(v.taxableAmount), 0)).toFixed(2);
            netTotal = (data.reduce((a, v) => a + parseFloat(v.total), 0)).toFixed(2);
            setSalesProductData(data);
            setInitialData(data);
        }).catch(error => {
            toast.current.show({ severity: 'error', sticky: true, summary: 'Error', detail: 'Error in getting All Product Sales Data' });
        })
            .finally(() => {
                setSpinnerVisible(false);
            });
    }
    

    const onFilter = (filterValue) => {
        let data = initialData.filter(a => a.addedDateFormat >= filterValue.fyDate && a.addedDateFormat <= filterValue.toDate);
        let srNo = 1;
        totalQuantity = (data.reduce((a, v) => a + parseFloat(v.quantity), 0));
        totalSgst = (data.reduce((a, v) => a + parseFloat(v.sgst), 0)).toFixed(2);
        totalCgst = (data.reduce((a, v) => a + parseFloat(v.cgst), 0)).toFixed(2);
        totalTaxableAmount = (data.reduce((a, v) => a + parseFloat(v.taxableAmount), 0)).toFixed(2);
        netTotal = (data.reduce((a, v) => a + parseFloat(v.total), 0)).toFixed(2);
        data.forEach(d => {
            d.serialNo = srNo;
            srNo++;
        })
        setSalesProductData(data);

    }

    const onReset = () => {

        const fields = [
            "fyDate",
            "toDate"
        ];
        fields.forEach((field) => setFilterValue(field, ''));
    }

    let totalFooter = <ColumnGroup>
        <Row>
            <Column footer="Totals:" footerStyle={{ textAlign: 'right' }} />
            <Column footer='' />
            <Column footer='' />
            <Column footer={totalQuantity} />
            <Column footer={totalTaxableAmount} />
            <Column footer='' />
            <Column footer={totalCgst} />
            <Column footer={totalSgst} />
            <Column footer={netTotal} />

        </Row>
    </ColumnGroup>;

    const header = (
        <div className="table-header">
            <div>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" class="globleInput" value={globalFilter} onChange={(e) => setGlobalFilter(e.target.value)} placeholder="Global Search" />
                </span>
            </div>
        </div>
    );

    return (
        <BlockUI blocked={blockedPanel}>
            <div className="ProductSalesReport">
                <h3>Product Sales Report</h3>
                <ProgressSpinBarControl spinBarVisible={spinnerVisible} />
                <Toast ref={toast} position={'center'} />
                <div className="Unit" style={{ minHeight: '1029px' }}>
                    <div className="box">
                        <div class="field col">
                            <label>From:</label>
                            <select id="Payment Mode" className='select'>
                                <option hidden value="">Select Product</option>
                                {/* <option>Online</option>
                                    <option>Cash</option> */}
                            </select>
                            <label>From:</label>
                            <input type='date' name='fyDate' className='date' ref={Filter} />
                            <label>To:</label>
                            <input type='date' name='toDate' className='date' ref={Filter} />
                            <button type="button filter" className='filter' onClick={handleFilter(onFilter)}>Filter</button>
                            <button type="button" className='reset' onClick={onReset()}>Reset</button>
                        </div>
                    </div>

                    <div className='box'>
                        <div>
                            <button type='button'>Copy</button>
                            <button type='button'>CSV</button>
                            <button type='button'>Excel</button>
                            <button type='button'>PDF</button>
                            <button type='button'>Print</button>
                        </div>
                        <DataTable ref={dt} value={salesProductData} className='dataTable' scrollable scrollHeight='800px' header={header} filters={filters1} globalFilter={globalFilter} footerColumnGroup={totalFooter}>
                            <Column field="serialNo" header="Sr No"></Column>
                            <Column field="product" header="Product"></Column>
                            <Column field="hsn" header="Hsn"></Column>
                            <Column field="quantity" header="Quantity"></Column>
                            <Column field="taxableAmount" header="TaxableAmount"></Column>
                            <Column field="gstRate" header="Gst Rate"></Column>
                            <Column field="cgst" header="CGST"></Column>
                            <Column field="sgst" header="SGST"></Column>
                            <Column field="total" header="Total"></Column>

                        </DataTable>
                    </div>
                </div>

            </div>
        </BlockUI>
    );
}
export default ProductSalesReport;