import { Button } from 'primereact/button';
import axios from 'axios';
import GreenFieldService from '../../services/greenFieldService';
import Config from '../../Config.json';
import { Toast } from 'primereact/toast';
import 'primeflex/primeflex.css';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from "react-hook-form";
import React, { useState, useEffect, useRef } from 'react';
import './CustomerMaster.css';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { allStates, allDistricts } from "state-district-component";
import { BlockUI } from 'primereact/blockui';
import authHeader from '../../authHeader';


function CustomerMaster({customerReload,id}) {
    const validationSchema = Yup.object().shape({
        // title: Yup.string()
        //     .required('Title is required'),

    });
    const { register, handleSubmit, reset, setValue } =
        useForm(
            { resolver: yupResolver(validationSchema) }
        );
    const greenFieldService = new GreenFieldService();
    const toast = useRef(null);
    const [customersData, setCustomersData] = useState([]);
    const [state, setSelectedState] = useState("Maharashtra");
    const [districtsOfState, setDistrictsOfState] = useState([]);
    const [buttonLabel, setButtonLabel] = useState("Save");
    const [globalFilter, setGlobalFilter] = useState('');
    const [selectedRadioData, setSelectedRadioData] = useState(null);
    const [isAddMode, setIsAddMode] = useState(true);
    const [isKisanCard, setIsKisanCard] = useState();
    const [blockedPanel, setBlockedPanel] = useState(false);
    var accessLevel = parseInt(sessionStorage.getItem('accessLevel'));
    const franchiseId = localStorage.getItem('franchiseId');

    useEffect(() => {
        getCustomerData(franchiseId);
        const districtsList = allDistricts.filter(elem => elem.state === state)
        setDistrictsOfState(districtsList[0].dists);

        if(accessLevel > 1)
        {
          setBlockedPanel(false);
        }
        else{
            setBlockedPanel(true);

        }

    }, [])

    const getCustomerData = (franchiseId) => {
        greenFieldService.GetCustomerData(franchiseId).then(data => {
            data.forEach(a => {
                a.isKisanCardValue = a.isKisanCard ? "Yes" : "No";

            })
            setCustomersData(data);
        });
    }

    const inputNoOnly = (e) => {
        if (e.target.id === 'mobileNo') {
            const onlyNums = e.target.value.replace(/[^0-9.]/g, '');
            setValue('mobile1', onlyNums);
        }
        if (e.target.id === 'altMobile') {
            const onlyNums = e.target.value.replace(/[^0-9.]/g, '');
            setValue('mobile2', onlyNums);
        }
    }

    var errors = [];

    const validate = (data) => {

        if (!data?.name) {
            errors.push('* Customer Name is required.', <br />);
        }
        if (!data?.mobile1) {
            errors.push('* Mobile number is required', <br />);
        }

        if (!data.state) {
            errors.push('* State is required', <br />);
        }
        if (!data.district) {
            errors.push('* District is required', <br />);
        }
        if (!data.town) {
            errors.push('* Town/Village is required', <br />);
        }


        // return errors;
    };

    const onSave = (data) => {
        data.franchiseId = franchiseId;
        validate(data);
        if (errors.length > 0) {
          
            // eslint-disable-next-line no-lone-blocks
            {
                errors.map(() => {
                    toast.current.show({ severity: 'warn', detail: errors, sticky: true });
                }

                )
            }
            errors = [];
            return;
        }
        return isAddMode ? addCustomer(data) : updateCustomer(data);
    }
    const addCustomer = (data) => {
        data.isKisanCard = isKisanCard;
        // setSpinnerVisible(true);
        return axios.post(`${Config.apiUrl}/Customer/InsertCustomer`, data, { headers: authHeader() })
            .then(response => {
                if (response.data.success) {
                    toast.current.show({ severity: 'success', detail: response.data.msg, autoDismiss:true });
                    getCustomerData(franchiseId);
                    onReset();
                    customerReload();
                }
                else {
                    toast.current.show({ severity: 'error', detail: response.data.msg, sticky: true });
                }
            }).catch(error => {
                toast.current.show({ severity: 'error', sticky: true, summary: 'Error', detail: error.message });
            })
            .finally(() => {
                // setSpinnerVisible(false);
                // SetFocusValue(true);
            })
    }

    const updateCustomer = (data) => {

        // setSpinnerVisible(true);
        data.id = selectedRadioData.id;
        data.isKisanCard = isKisanCard;

        return axios.put(`${Config.apiUrl}/Customer/UpdateCustomer`, data, { headers: authHeader() })
            .then(response => {
                if (response.data.success) {
                    toast.current.show({ severity: 'success', detail: 'Data Update Successfully', autoDismiss:true });
                    getCustomerData(franchiseId);
                    customerReload();
                }
                else {
                    toast.current.show({ severity: 'error', detail: response.data.msg, sticky: true });
                }
            }).catch(error => {
                toast.current.show({ severity: 'error', sticky: true, summary: 'Error', detail: error });
            })
            .finally(() => {
                // setSpinnerVisible(false);
                // SetFocusValue(true);
            })
    }

    const funcOnStateChange = (e) => {
        console.log(e);
        setSelectedState(e.currentTarget.value);
        const districtsList = allDistricts.filter(elem => elem.state === e.currentTarget.value)
        setDistrictsOfState(districtsList[0].dists);
    }

    const onEdit = (e) => {
        setIsAddMode(false);
        id = selectedRadioData.id;
        let data = selectedRadioData
        setButtonLabel("Update");
        setIsKisanCard(data.isKisanCard);
        setValue("kisanCardNo", data.kisanCardNo);

        const fields = [
            "name",
            "mobile1",
            "mobile2",
            "state",
            "district",
            "town",
            "kisanCardNo",
            "pinCode"


        ];
        fields.forEach((field) => setValue(field, data[field]));
        //   setValue("kisanCardNo",data.kisanCardNo);
    }

    const onReset=()=>{
        const fields = [
            "name",
            "mobile1",
            "mobile2",
            "state",
            "district",
            "town",
            "kisanCardNo",
            "pinCode"


        ];
        fields.forEach((field) => setValue(field, ''));
    }

    const header = (

        <div className="table-header">

            <div>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" value={globalFilter} onChange={(e) => setGlobalFilter(e.target.value)} placeholder="Global Search" />
                </span>
            </div>

            <div>
                {/* <Link to={`/Flat_Master/edit/${selectedRadioData.id}`}> */}
                <Button
                    icon="pi pi-pencil"
                    value="Edit"
                    className="btnEdit1"
                    onClick={onEdit}

                />
                {/* </Link>     */}

            </div>
        </div>
    );


    return (
        <BlockUI blocked={blockedPanel}>
        <div className="Customer">

            <div className="Unit" style={{ minHeight: '1029px' }}>
                <Toast ref={toast} position={'center'} />

                <div className='box'>
                    <h3 style={{ margin: '0px', padding: '10px' }}>Add Customer</h3>

                    <div class="formgrid grid align items left">
                        <div style={{ marginRight: '5px', marginleft: '10px' }}>
                            <div class="field col">
                                <label for="Customer Name">Customer Name<span style={{ color: 'red' }}>*</span></label>
                                <input id="Customer Name" type="text" name="name" ref={register} class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary "></input>
                                <label for="Mobile No">Mobile Number<span style={{ color: 'red' }}>*</span></label>
                                <input id="mobileNo" type="text" name="mobile1" ref={register} maxLength="10" onInput={inputNoOnly} class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary"></input>
                            </div>
                            <div class="field col">
                                <label for="Alt. Mobile">Alt. Mobile Number</label>
                                <input id="altMobile" type="text" name="mobile2" ref={register} maxLength="10" onInput={inputNoOnly} class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary"></input>
                                <label for="State">State<span style={{ color: 'red' }}>*</span></label>
                                {/* <input id="State" type="text" name="state" ref={register} class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary   "></input> */}
                                <select name="state" ref={register} onChange={funcOnStateChange} value={state} class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary">
                                    <option hidden value=""> Select State</option>
                                    {allStates.map(state => <option value={state} key={state}>{state}</option>)}

                                </select>

                            </div>
                            <div class="field col">
                                <label for="District">District<span style={{ color: 'red' }}>*</span></label>
                                <select name="district" ref={register} class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary" >
                                    <option hidden value=""> Select district</option>
                                    {districtsOfState.map(dis => <option key={dis}>{dis}</option>)}

                                </select>
                                {/* <input id="District" type="text" name="district" ref={register} class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary   "></input> */}
                                <label for="Town">Town_Village<span style={{ color: 'red' }}>*</span></label>
                                <input id="Town" type="text" name="town" ref={register} class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary   "></input>
                            </div>
                            <div class="field col">

                                {/* <div class="field-checkbox"> */}
                                <label for="Kisan Card">Is Kisan Card </label>
                                <Checkbox onChange={e => setIsKisanCard(e.checked)} checked={isKisanCard} ></Checkbox>


                                {/* </div> */}

                            </div>






                            {isKisanCard && (
                                <div class="field col">

                                    <label for="Kisan Number">Kisan Card Number</label>
                                    <input id="Kisan Number" type="text" name="kisanCardNo" ref={register} class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary   "></input>
                                </div>
                            )}
                            <div class="field col">

                                <label for="pinCode">PinCode<span style={{ color: 'red' }}></span></label>
                                <input id="pinCode" type="number" name="pinCode" ref={register} class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary   "></input>
                            </div>
                            <button className='button' type="button" onClick={handleSubmit(onSave)}>{buttonLabel}</button>

                        </div>
                    </div>
                </div>

                {/* <div className='box'> */}
                <div className='box'>
                    <DataTable value={customersData} header={header} globalFilter={globalFilter} className="dataTable" editMode="row" selection={selectedRadioData} onSelectionChange={e => setSelectedRadioData(e.value)}  >
                        <Column selectionMode="single" headerStyle={{ width: '3em' }}></Column>
                        <Column field="id" header="Sr.No"></Column>
                        <Column field="name" header="Name"></Column>
                        <Column field="mobile1" header="Mobile No"></Column>
                        <Column field="state" header="State"></Column>
                        <Column field="district" header="District"></Column>
                        <Column field="town" header="Town/Village"></Column>
                        <Column field="isKisanCardValue" header="Kisan Card"></Column>
                        <Column field="kisanCardNo" header="Kisan Card No"></Column>
                        <Column field="pinCode" header="PinCode"></Column>

                    </DataTable>
                </div>
                {/* </div> */}
            </div>
        </div>
        </BlockUI>
    )
}

export default CustomerMaster;