// import './AdminStockReturn.css';
// import '../App.css';
import 'primeflex/primeflex.css';
import React, { useState, useEffect, useRef } from 'react';
import { useForm } from "react-hook-form";
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import GreenFieldService from '../../services/greenFieldService';
import { Dialog } from 'primereact/dialog';
import axios from 'axios';
import { Toast } from 'primereact/toast';
import Config from '../../Config.json';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import './ReturnStocks.css';
import { Button } from 'primereact/button';
import ExportCsvReport from '../../exportReport/exportCsvReport';
import ExportPdfReport from '../../exportReport/exportPdfReport';
import ExportExcelReport from '../../exportReport/exportExcelReport';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { BlockUI } from 'primereact/blockui';
import moment from 'moment';
import authHeader from '../../authHeader';
import { useContext } from 'react';
import { GreenFieldContext } from '../../App';
import ToolBoxGrid from '../Misc/ToolBoxGrid';
import { shouldLockDate } from '../Misc/utilities';


var totalQuantitySum;
var totalValueSum;
function ReturnStocks() {

    const [date3, setDate3] = useState(null);
    const greenFieldService = new GreenFieldService();
    const exportCsvReport = new ExportCsvReport();
    const exportPdfReport = new ExportPdfReport();
    const exportExcelReport = new ExportExcelReport();
    const toast = useRef(null);
    const dt = useRef(null);
    const [initialReturnData, setInitialReturnData] = useState([]);
    const [franchiseReturnStockData, setFranchiseReturnStockData] = useState([]);
    const [visible, setVisible] = useState(false);
    const [selectedRowData, setRowData] = useState();
    const [filters1, setFilters1] = useState(null);
    const [globalFilter, setGlobalFilter] = useState();
    const [selectedProduct, setSelectedProduct] = useState();
    const [productName, setProductName] = useState();
    const [batchNo, setBatchNo] = useState();
    const [productData, setProductsData] = useState([]);
    const [batchNoData, setBatchNoData] = useState([]);
    const [selectedBatchNo, setSelectedBatchNo] = useState();
    const [selectedBatchNoValue, setSelectedBatchNoValue] = useState();
    const [returnStocksData, setReturnStocksData] = useState([]);
    const [availabaleStock, setAvailableStock] = useState();
    const [expandedRows, setExpandedRows] = useState(null);
    const [bootSerNo, setBootSerNo] = useState(0);
    const [rcChildSelection, setRcChildSelection]=useState([]);
    const franchiseId = localStorage.getItem('franchiseId');
    const franchiseName = localStorage.getItem('FranchiseName')
    const [blockedPanel, setBlockedPanel] = useState(false);
    var accessLevel = parseInt(sessionStorage.getItem('accessLevel'));
    
    const { register, handleSubmit, reset, setValue, } =
        useForm();
    const {
        register: Filter,
        handleSubmit: handleFilter,
        setValue: setFilterValue,
    } = useForm();
    const lockStatus=useContext(GreenFieldContext);
    const [locked, setIsLocked]=useState(false);
   
    const [lockDate, setIsLockDate] = useState();
    const [finalLock, setFinalLock] = useState(false); 
   
    useEffect(() => {
        let d = moment(new Date()).format('YYYY-MM-DD');
        setFilterValue('fyDate',d);
        setFilterValue('toDate',d);
        setValue('rcDate',d);

        GetFranchiseReturnStockData(parseInt(franchiseId));
        initFilters1();
        GetFranchiseProduct(franchiseId);

        if(accessLevel > 1)
        {
          setBlockedPanel(false);
        }
        else{
            setBlockedPanel(true);
        }
        countDown();

    }, [])

    useEffect(() => {
        const interval = setInterval(() => {
            countDown();
        }, 5000);
        return () => clearInterval(interval);


    }, [])

    function onToDateChange(e){
        validateLockDate(e.target.value);
    }

    function validateLockDate(selectedDate) {
        if (locked) {

            if (shouldLockDate(selectedDate, lockDate)) {

                setFinalLock(true);
                toast.current.show({ severity: 'warn', detail: `Franchise is locked till ${lockDate}`, summary: 'Payment Details' });
            }
            else {
                setFinalLock(false);
            }
        }
        else {
            setFinalLock(false);
        }
    }

    function countDown() {
        // time = time - 1;
        greenFieldService.GetFranchiseLockScreenStatus(localStorage.getItem('franchiseId')).then(data => {
      //      if(locked!=data.currentLockStatus){
            setIsLocked(data.currentLockStatus);
            setIsLockDate(data.lockDate);

        //    }
        });
    }

    var errors = [];

    const validate = (data) => {

        if (!data?.rcDate) {
            errors.push('RC date is required.', <br />);
        }
        if (!data?.rcNo) {
            errors.push('RC number is required', <br />);
        }
        if (!selectedProduct) {
            errors.push('Product name is required.', <br />);
        }
        if (!selectedBatchNo) {
            errors.push('Batch No is required', <br />);
        }
        if (!data.quantity) {
            errors.push('Quantity is required', <br />);
        }
        if (!data.note) {
            errors.push('Note is required', <br />);
        }


        // return errors;
    };

    const inputNoOnly = (e) => {
        if (e.target.id === 'quantity') {
            const onlyNums = e.target.value.replace(/[^0-9.]/g, '');
            setValue('quantity', onlyNums);
        }
    }

    const GetFranchiseProduct = (franchiseId) => {
        let isActive=0;
        greenFieldService.GetFranchiseProductsData(franchiseId,isActive).then(data => {
            setProductsData(
                //  data.map(({ product }) => ({ label:product?.name, value:product?.name}))
                data
            )

            // setProductsData(data);
        });
    }


    const onChangeProduct = (e) => {
        // let val = productData[e.target.value];
        setProductName(e.target.value);
        let val = productData.find(a => a.name === e.target.value.name);
        setSelectedProduct(val);
        if (val === undefined || val === null) {
            setBatchNoData(undefined);
            return;
        }
        setSelectedBatchNo(undefined);
        setAvailableStock(undefined);
        setValue('avlQuantity', undefined);
        setValue('quantity', undefined);
        greenFieldService.GetDistributedBatchNo(e.target.value.id, franchiseId).then(data => {
            setBatchNoData(data);
        });
    }

    const onChangeProductIndex = (index) => {
        let val = productData[+index];
        setSelectedProduct(val);


        if (val === undefined || val === null) {
            setProductName("-1");

            setBatchNoData(undefined);
            //  return;
        }
        setSelectedBatchNo(undefined);
        setAvailableStock(undefined);
        setValue('avlQuantity', undefined);
        setValue('quantity', undefined);
        /*   greenFieldService.GetDistributedBatchNo(val.id, franchiseId).then(data => {
              setBatchNoData(data);
          }); */
    }



    const onChangeBatchNo = (e) => {

        let batchNo = e.target.value.batchNo;
        setBatchNo(batchNo);
        let val = batchNoData.find(a => a.batchNo === batchNo);

        setSelectedBatchNo(val);
        // setSelectedBatchNoValue(val.batchNo);
        if (val === undefined || val === null) {
            setAvailableStock(undefined);
            setValue('avlQuantity', undefined);
            setValue('quantity', undefined);
            return;
        }

        getAvailableStock(selectedProduct.id, val.batchNo, franchiseId);

    }
    const getAvailableStock = (productId, batchNo, franchiseId) => {
        greenFieldService.GetFranchiseAvailableStock(productId, batchNo, franchiseId).then(data => {
            setAvailableStock(data);
            setValue('avlQuantity', data);
        });
    }

    const GetFranchiseReturnStockData = (franchiseId) => {

        greenFieldService.GetReturnStockDetails(franchiseId).then(data => {
            let rcSerialNo = 1;

            data.forEach(a => {
                a.serialNo = rcSerialNo;
                a.rcDate= a.rcDate.replace('T', ' ');
                a.rcDateValue=new Date(a.rcDate);
                
                let serialNo = 1;
                a.stockInfoDetails.forEach(b => {
                    b.serialNo = serialNo;
                    b.productName = `${b.productName} - ${b.package}`;
                    b.stockValue = b.stockValue.toFixed(2);
                    serialNo++;
                }
                )
                let anyPending = a.stockInfoDetails.findIndex(a=>a.status ==="Return_Pending");
                if(anyPending>=0){
                a.statusColor="Pending";
                }
                else{
                    a.statusColor="Confirmed";
                }
                // totalQuantitySum = (data.reduce((a, v) => a + parseFloat(v.totalQuantity), 0));
                // totalValueSum = (data.reduce((a, v) => a + parseFloat(v.totalValue), 0));
                rcSerialNo++;
            });
            setFranchiseReturnStockData(data);
            setInitialReturnData(data);

        });
    }

    const addReturnStock = (data) => {
        validate(data);
        if (errors.length > 0) {
            // eslint-disable-next-line no-lone-blocks
            {
                errors.map(() => {
                    toast.current.show({ severity: 'warn', detail: errors, sticky: true });
                }

                )
            }
            errors = [];
            return;
        }
        data.Status = "Return_Pending";
        data.franchiseId = franchiseId;

        data.productId = selectedProduct.id;
        data.productName = selectedProduct.name;
        data.batchNo = selectedBatchNo.batchNo;
        let duplicateEntry = returnStocksData.filter(a => a.productId === data.productId && a.batchNo === data.batchNo);
        if (duplicateEntry.length > 0) {
            toast.current.show({ severity: 'warn', detail: "This Record Already Added", sticky: true });
            return;
        }
        let _stockData = [data, ...returnStocksData];


        setReturnStocksData(_stockData);
        resetValue();

    }

    const resetValue = () => {
        const fields = [
            'avlQuantity',
            "quantity",
            "note",
        ];
        fields.forEach((field) => setValue(field, ''));
        onChangeProductIndex(-1);
        // setSelectedProduct(undefined);
        // setSelectedBatchNo(null);
    }

    const filterValidate = (data) => {
        if (!data?.fyDate) {
            errors.push('* From Date is required.', <br />);
        }
        if (!data?.toDate) {
            errors.push('* To Date is required', <br />);
        }
    }
    function dateTime(dateValue){
        var x = new Date(dateValue);// x is now a date object
        x.setHours(0,0,0,0); 
        return x;
       }

    const onFilter = (filterValue) => {
        let fromdate=dateTime(filterValue.fyDate);
        // let fromdate=new Date(filterValue.fyDate)  ;
        // fromdate.setDate(fromdate);
        let toDate=dateTime(filterValue.toDate);
        // toDate.setDate(toDate.getDate()+1);

        filterValidate(filterValue)
        if (errors.length > 0) {
            // eslint-disable-next-line no-lone-blocks
            {
                errors.map(() => {
                    toast.current.show({ severity: 'warn', detail: errors, sticky: true });
                }

                )
            }
            errors = [];
            return;
        }
        let data = initialReturnData.filter(a => a.rcDateValue >= fromdate && a.rcDateValue <= toDate );
        let srNo = 1;
        data.forEach(d => {
            d.serialNo = srNo;
            srNo++;
        })
        totalQuantitySum = (data.reduce((a, v) => a + parseFloat(v.totalQuantity), 0));
        totalValueSum = (data.reduce((a, v) => a + parseFloat(v.totalValue), 0));
        setFranchiseReturnStockData(data);

    }


    const onReset = () => {

        const fields = [
            "fyDate",
            "toDate"
        ];
        fields.forEach((field) => setFilterValue(field, ''));
        GetFranchiseReturnStockData(franchiseId);


    }

    const onChangeGlobal = (e) => {
        let value = e.target.value;
        let _filters1 = { ...filters1 };
        _filters1['global'].value = value;

        setFilters1(_filters1);

        setGlobalFilter(value);
    }

    const initFilters1 = () => {
        setFilters1({
            'global': { value: null, matchMode: FilterMatchMode.CONTAINS },

        });
        setGlobalFilter('');
    }



    const buttons = (rowData) => {
        return <div>

            <Button icon="pi pi-trash" disabled={finalLock} className="p-button-rounded p-button-danger" onClick={() => onDelete(rowData)} aria-label="Bookmark" />
            {/* <Button label="Show" icon="pi pi-external-link" onClick={() => onClick('displayResponsive')} /> */}


        </div>;
    }
    const onDelete = (rowData) => {
        if (rowData != null) {
            toast.current.show({
                severity: 'warn', sticky: true, content: (
                    <div className="flex flex-column" style={{ flex: '1' }}>
                        <div className="text-center">
                            <i className="pi pi-exclamation-triangle" style={{ fontSize: '3rem' }}></i>
                            <h4>Are you sure?</h4>
                            <p>Confirm to proceed</p>
                        </div>
                        <div className="grid p-fluid">
                            <div className="col-6">
                                <Button type="button" disabled={finalLock} label="Yes" onClick={() => onYes(rowData)} className="p-button-success" />
                            </div>
                            <div className="col-6">
                                <Button type="button" label="No" onClick={() => onNo(rowData)} className="p-button-secondary" />
                            </div>
                        </div>
                    </div>
                )
            });
        }
        else {
            toast.current.show({ severity: 'warn', sticky: true, detail: "PLEASE SELECT ROW" });
            return;
        }
    }

    const onYes = (rowData) => {
        toast.current.clear();
        let length = returnStocksData.length;
        let changedData = returnStocksData.filter(a => a.productId !== rowData.productId);
        setReturnStocksData(changedData);
        if (changedData.length < length) {
            toast.current.show({ severity: 'success', autoDismiss:true, detail: "Record Deleted Successfully" });
        }
        else {
            toast.current.show({ severity: 'error', sticky: true, detail: "Failed to Delete" });
        }
    }

    const onNo = () => {
        toast.current.clear();
        return;
    };



    const onHide = () => {
        setVisible(false);

    }

    const onQuantity = (e) => {
        let quantity = parseInt(e.target.value);
        if (quantity > availabaleStock || quantity <= 0) {
            toast.current.show({ severity: 'warn', detail: 'Please Enter Correct Quantity', sticky: true });
            setValue('quantity', '');
            return;
        }
    }

    const renderFooter = () => {
        return (
            <div>
                <button className='button' type="button" onClick={handleSubmit(onReturn)}>Update </button>
                <button className='reset' type="button" onClick={onHide}>cancel</button>
            </div>
        );
    }
    const onReturn = () => {
        // selectedRowData.franchiseId = franchiseId;
        // selectedRowData.quantity = data.quantity;
        // selectedRowData.note = data.note;
        // selectedRowData.status = "Return";
        if(returnStocksData.length <= 0)
        {
            toast.current.show({ severity: 'warn', detail: "Please Add stock before Return stock", sticky: true });
            return;
        
        }
        else{
        return axios.post(`${Config.apiUrl}/Stocks/ReturnStock`, returnStocksData, { headers: authHeader() })
            .then(response => {
                if (response.data.success) {
                    toast.current.show({ severity: 'success', detail: response.data.msg, autoDismiss:true });
                    setReturnStocksData('');
                    setSelectedProduct('');
                    setSelectedBatchNo('');
                    setValue('RcDate', '');
                    setValue('RcNo', '');
                }
                else {
                    toast.current.show({ severity: 'error', detail: response.data.msg, sticky: true });
                }
            }).catch(error => {
                toast.current.show({ severity: 'error', sticky: true, summary: 'Error', detail: error });
            })
            .finally(() => {
                GetFranchiseReturnStockData(franchiseId);
                setValue('rcNo','');
                // setSpinnerVisible(false);
                // SetFocusValue(true);
            })
        }
    }

    const header = (
        <div className="table-header">
            <div>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" class="globleInput" value={globalFilter} onChange={onChangeGlobal} placeholder="Global Search" />
                </span>
            </div>
        </div>
    );

    const allowExpansion = (rowData) => {
        //  setExpandedIndex(rowData.orders.length);
        return rowData.orders.length > 0;
    };

    const onDeleteItem = (data) => {
        let changedData= data.filter(a=>a.changeStatus!= undefined && a.changeStatus.toLowerCase()==="modified");
        let toBeDeletedData=rcChildSelection; 
        console.log(changedData);
    
        let statuses=rcChildSelection.filter(a=>a.status != "Return_Pending");
        if(statuses?.length >0){
            toast.current.show({ severity: 'warn', detail:"Only record which are in 'Pending' state will be deleted", sticky: true });
        }

        toBeDeletedData=rcChildSelection.filter(a=>a.status === "Return_Pending");
     if(toBeDeletedData.length === 0){
        toast.current.show({ severity: 'warn', detail:"No Pending states records selected for deletion!", sticky: true });
        return;
     }
            return axios.post(`${Config.apiUrl}/Stocks/DeleteReturnStocks`, toBeDeletedData, { headers: authHeader() })
            .then(response => {
    
                response.data.forEach(msg=>{
                if (msg.success) {
                    toast.current.show({ severity: 'success', detail: msg.msg, autoDismiss:true });
                 
                }
                else {
                    toast.current.show({ severity: 'error', detail: msg.msg, sticky: true });
                }
            });
            }).catch(error => {
                toast.current.show({ severity: 'error', sticky: true, summary: 'Error', detail: error });
            })
            .finally(() => {
                GetFranchiseReturnStockData(franchiseId);
                // setSpinnerVisible(false);
                // SetFocusValue(true);
            })
        }


    const rowExpansionTemplate = (data) => {
        return (
            <div className="orders-subtable ReturnStock">
                {/* <h5>Orders for {data.name}</h5> */}
                <DataTable value={data.stockInfoDetails} selection={rcChildSelection} onSelectionChange={e => setRcChildSelection(e.value)}  dataKey="id" responsiveLayout="scroll" >
                    <Column selectionMode="multiple" headerStyle={{width: '3em'}}></Column>
                    <Column field="serialNo" header="Sr.No"></Column>
                    <Column field="productName" header="Prodcut Name"></Column>
                    <Column field="batchNo" header="Batch No"></Column>
                    <Column field="quantity" header="Quantity"></Column>
                    <Column field="rcNo" header="Rc No"></Column>
                    <Column field="rcDateFormat" header="RC Date"></Column>
                    {/* <Column field="addedDateFormat" header="Stock Added Date"></Column> */}
                    <Column field="stockValue" header="Stock Value"  ></Column>
                    <Column field="status" header="Status" body={statusTemplate} > </Column>
                    <Column field="note" header="Note" ></Column>
                    {/* <Column body={Button} field="mobile1" header="Action"></Column> */}
                </DataTable>
                <div>
                    <button className='reset' type="button" onClick={() => onDeleteItem(data.stockInfoDetails)}>Delete</button>
                    {/* <button className='reset' style={{marginLeft:'20px'}} type="button" onClick={() => onUpdate(data.stockInfoDetails)}>Update</button> */}

                </div>
            </div>
        );
    }

    const statusTemplate = (rowData) => {
        return <span className={`product-badge status-${(rowData.status ? rowData.status.toLowerCase() : '')}`}>{rowData.status}</span>;
    }

    const cols = [
        { field: 'serialNo', header: 'srNo' },
        { field: 'rcNo', header: 'Rc Number' },
        { field: 'rcDateFormat', header: 'Rc Date' },
        { field: 'totalQuantity', header: 'Total Quantity' },
        { field: 'totalValue', header: 'Total Value' }

    ];

    const exportColumns = cols.map(col => ({ title: col.header, dataKey: col.field }));

    const exportCSV = (selectionOnly) => {
        // <exportCsvReport dt={dt}/>
        exportCsvReport.exportCSV(dt)
        // dt.current.exportCSV();
    }

    const exportPdf = () => {
        let fileName = "Franchise Return Stock Entires";
        exportPdfReport.exportPdf(exportColumns, franchiseReturnStockData, fileName);

    }

    const exportExcel = () => {
        let fileName = "Franchise Return Stock Entires";
        exportExcelReport.exportExcel(franchiseReturnStockData, fileName);
    }

    let stockEntriesFooterGroup = <ColumnGroup>
        <Row>
            <Column footer="Totals:" footerStyle={{ textAlign: 'right' }} />
            <Column footer='' />
            <Column footer='' />
            <Column footer='' />
            <Column footer={totalQuantitySum} />
            <Column footer={totalValueSum} />
        </Row>
    </ColumnGroup>;

const rowClass = (data) => {
    return data.statusColor;
}
    return (
        // <BlockUI blocked={blockedPanel || locked}>
        <BlockUI blocked={blockedPanel}>


        <div className="ReturnStock">
            <h3>Manage Stock :: {franchiseName}</h3>
            <div className="Unit" style={{ minHeight: '1029px' }}>
                <div className='box'>
                    <div className="formgrid grid align items left">

                        <div style={{ marginTop: '10px' }}>
                            <div className="field col">

                                <label >Rc Date<span style={{ color: 'red' }}>*</span></label>
                                <input id="RcDate" onChange={onToDateChange} type="date" name="rcDate" ref={register} className="QuantityTextbox text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary"></input>
                                <label for="Batch">RC No<span style={{ color: 'red' }}>*</span></label>
                                <input id="Batch" type="text" name="rcNo" autoComplete='off' ref={register} className="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary"></input>

                            </div>
                            <div className="field col">

                                <label for="Product Name">Product Name<span style={{ color: 'red' }}>*</span></label>
                                {/* <Dropdown className='Dropdown' value={selectedProduct} id="productId" options={productData} optionLabel="name" onChange={onChangeProduct} filter showClear placeholder="Select Product" /> */}
                                <Dropdown className='Dropdown' value={productName} options={productData} id="product" optionLabel="name" onChange={onChangeProduct} filter showClear placeholder="Select Product" />

                                {/* <select name="productName" value={productName} onChange={onChangeProduct} ref={register} style={{ height: '30px', width: '300px',marginLeft:'2px' }}>
                                    <option value="-1" >Select Product </option>
                                    {

                                        productData?.map((data) => (
                                            <option key={data.id} value={data.name}>
                                                {data.name}
                                            </option>
                                        ))}
                                   
                                </select> */}

                                <label for="Product Name">Batch No<span style={{ color: 'red' }}>*</span></label>
                                <Dropdown className='Dropdown' value={selectedBatchNo} options={batchNoData} optionLabel="batchNo" onChange={onChangeBatchNo} filter showClear placeholder="Select Batch" />


                                {/* <select name="batchNo" className="selectStyle" ref={register({ required: true })} style={{ height: '30px', width: '300px',marginLeft:'2px' }}
                                    onChange={onChangeBatchNo}>
                                    <option value="-1"> Select Batch No</option>
                                    {batchNoData?.map((batch) =>
                                        <option key={batch.batchNo} value={batch.batchNo}>{batch.batchNo}</option>
                                    )
                                    }
                                </select> */}

                            </div>
                            <div className="field col">
                                <label for="AvlQuantity">Available Quantity</label>
                                <input id="avlQuantity" type="text" readOnly name="avlQuantity" ref={register} className="QuantityTextbox text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary"></input>
                                <label for="Quantity">Enter Quantity<span style={{ color: 'red' }}>*</span></label>
                                <input id="quantity" type="text" name="quantity" ref={register} autoComplete='off' onInput={inputNoOnly} onChange={onQuantity} className="QuantityTextbox text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary"></input>
                            </div>
                            <div className="field col">
                                <label for="Note">Note<span style={{ color: 'red' }}>*</span></label>
                                <input id="Note" type="text" name="note" ref={register} autoComplete='off' className="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary"></input>
                            </div>

                            <div className="field col">
                                <button className='button' disabled={finalLock} type="button" onClick={handleSubmit(addReturnStock)}>Add </button>
                                <div >

                                    <DataTable   value={returnStocksData} editMode="row"   >
                                        {/* <Column expander={allowExpansion} style={{ width: '3em' }} /> */}
                                        <Column field="rcDate" header="Rc Date" ></Column>
                                        <Column field="rcNo" header="Rc No"  ></Column>
                                        <Column field="productName" header="Product Name"  ></Column>
                                        <Column field="batchNo" header="Batch No"  ></Column>
                                        {/* <Column field="quantity" header="Available Quantity"  ></Column> */}
                                        <Column field="quantity" header="Quantity"  ></Column>
                                        <Column field="note" header="Note"  ></Column>
                                        <Column body={buttons} header="Action"></Column>

                                    </DataTable>
                                    <button className='button' type="button" onClick={onReturn} >Return Stock</button>

                                </div>
                            </div>


                        </div>
                    </div>
                </div>

                <div className='box ReturnStock'>
                    <Toast ref={toast} position={'center'} />
                    <div className="field col">
                        <label>From:</label>
                        <input type='date' name='fyDate' className='date' ref={Filter} style={{ height: '30px', width: '300px' }}/>
                        <label>To:</label>
                        <input type='date' name='toDate' className='date' ref={Filter} style={{ height: '30px', width: '300px' }} />
                        <button type="button filter" className='filter' onClick={handleFilter(onFilter)}>Filter</button>
                        <button type="button" className='reset' onClick={onReset}>Reset</button>
                    </div>
                    <ToolBoxGrid columns={exportColumns} data={franchiseReturnStockData} fileName={`${franchiseName} return Stock`}></ToolBoxGrid>
                    <DataTable ref={dt} value={franchiseReturnStockData} header={header} filters={filters1} paginator rows={10}
                        className="dataTable" rowClassName={rowClass} editMode="row" rowExpansionTemplate={rowExpansionTemplate} expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)}>
                        <Column expander={allowExpansion} style={{ width: '3em' }} />
                        <Column field="serialNo" header="srNo" sortable></Column>
                        <Column field="rcNo" header="Rc Number" sortable></Column>
                        <Column field="rcDateFormat" header="Rc Date" sortable></Column> 
                        <Column field="totalQuantity" header="Total Quantity" sortable></Column>
                        <Column field="totalValue" header="Total Value" sortable></Column>
                    </DataTable>
                </div>

                {/* <Dialog header="Update Status" visible={visible} onHide={() => onHide()} breakpoints={{ '960px': '75vw' }} style={{ width: '50vw' }} footer={renderFooter('displayResponsive')}>
                <div class="field col">

                    <label for="quantity" style={{ width: '100px' }}>Quantity<span style={{ color: 'red' }}>*</span></label>
                    <input id="quantity" type="number" name="quantity" onChange={onQuantity} ref={register({ required: true })} class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary"></input>
                </div>
                <div class="field col">

                    <label for="Note" style={{ width: '100px' }}>Note<span style={{ color: 'red' }}>*</span></label>
                    <input id="Note" type="text" name="note" ref={register({ required: true })} class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary"></input>
                </div>
            </Dialog> */}
            </div>
        </div>
        </BlockUI>
    );
}
export default ReturnStocks;