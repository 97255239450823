import React, { useState, useEffect, useContext, useRef } from 'react';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Dialog } from 'primereact/dialog';
import  './progressSpinBarControl.css';

function ProgressSpinBarControl ({ spinBarVisible }) {
    return (

        <div className='spinBar'>
            <Dialog visible={spinBarVisible}  >
                <ProgressSpinner style={{ width: '80px', height: '80px' }} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
            </Dialog>
        </div>
    );
    
}
export default ProgressSpinBarControl;