import './DistributedStockEntry.css';
import '../App.css';
import 'primeflex/primeflex.css';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState, useRef } from 'react';
import { useForm } from "react-hook-form";
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { useEffect } from 'react';
import GreenFieldService from '../services/greenFieldService';
import * as Yup from 'yup';
import { Toast } from 'primereact/toast';
import axios from 'axios';
import Config from '../../src/Config.json';
import { BlockUI } from 'primereact/blockui';
import ToolBoxGrid from './Misc/ToolBoxGrid';
import authHeader from '../authHeader';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import moment from 'moment';
var qtySum;


function DistributedStockEntry() {

    const [date3, setDate3] = useState(null);
    const validationSchema = Yup.object().shape({
        //  quantity: Yup.string()
        //      .required('Quantity is required'),
        //  productName:Yup.string()
        //  .required('Product Name is Required'),

    });
    const { errors, register, handleSubmit, reset, setValue } =
        useForm(
            { resolver: yupResolver(validationSchema) }
        );
    const [columns,setColumns]=useState([{ title: 'Sr No', dataKey: 'serialNo' },{ title: 'Franchise Name', dataKey: 'franchies' },{ title: 'Product', dataKey:'productName' }, { title: 'Qty', dataKey: 'quantity' }, { title: 'Status', dataKey: 'status' }, { title: 'Note', dataKey: 'note' },{ title: 'Dc No', dataKey: 'dcNo' }, { title: 'Dc Date', dataKey: 'dcDateFormat' }]);   
    const toast = useRef(null);
    const greenFieldService = new GreenFieldService();
    const [globalFilter, setGlobalFilter] = useState();
    const [franchiesesDistributedStock, setFranchiesesDistributedStock] = useState();
    const [isUpdateClick, setIsUpdateClick] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState([]);
    const [productData, setProductData] = useState();
    const [selectedFranchiesName, setSelectedFranchiesName] = useState();
    const [productsData, setProductsData] = useState([]);
    const [batchNoData, setBatchNoData] = useState([]);
    const [selectedBatchNo, setSelectedBatchNo] = useState();
    const [availabaleStock, setAvailableStock] = useState();
    const [selectedRowData, setSelectedRowData] = useState();
    const [avlQuantity, setAvlQuantity] = useState();
    const [blockedPanel, setBlockedPanel] = useState(false);
    var accessLevel = parseInt(sessionStorage.getItem('accessLevel'));
    const {
        register: Filter,
        errors: errors1,
        handleSubmit: handleFilter,
        setValue: setFilterValue,
    } = useForm();

    const UpdateButton = (rowData) => {
        return <div>
            <button className='reset' type="button" onClick={() => onUpdate(rowData)}>Update</button>

        </div>;
    }

    const onUpdate = (rowData) => {
        let name = rowData.productName;
        setSelectedProduct(name);
        setSelectedFranchiesName(rowData.franchiesName);
        setIsUpdateClick(true);
        setSelectedRowData(rowData);
        setValue('note', rowData.note);
    }

    const onChangeQuantity = (e) => {
        let quantity = parseInt(e.target.value);
        if (avlQuantity < quantity) {
            toast.current.show({ severity: 'warn', detail: "Please Enter Proper Value", sticky: true });
        }
    }

    const header = (
        <div className="table-header">
            <div>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" className="globleInput" value={globalFilter} onChange={(e) => setGlobalFilter(e.target.value)} placeholder="Global Search" />
                </span>
            </div>
        </div>
    );

    useEffect((e) => {
     
        getFranchiesesDistributedStock();
        greenFieldService.GetProductsData().then(data => {
            setProductsData(data);
        });

        if(accessLevel > 1)
        {
          setBlockedPanel(false);
        }
        else{
            setBlockedPanel(true);
        }
    },[])

    const getFranchiesesDistributedStock=()=>{
    greenFieldService.GetFranchiesesDistributedStock().then(data => {
        qtySum = (data.reduce((a, v) => a + parseFloat(v.quantity), 0));
            let srNo=1;
            data.forEach(a => {
                a.franchies = `${a.franchiesName} ${a.franchiesAddress}`
                a.serialNo=srNo;
                a.dcDate= a.dcDate.replace('T', ' ');
                a.dcDateValue=new Date(a.dcDate);
                srNo++;
                
        });
            setFranchiesesDistributedStock(data);
        });
    }

    const onProductChange = (e) => {
        setSelectedProduct(e.value);

        greenFieldService.GetBatchNo(e.value.id).then(data => {
            setBatchNoData(data);
        });
    }

    const onChangeBatchNo = (e) => {
        setSelectedBatchNo(e.value);
        GetAvailableStock(selectedProduct.id, e.value.batchNo);
    }

    const GetAvailableStock = (productId, batchNo) => {
        greenFieldService.GetAvailableStock(productId, batchNo).then(data => {
            setAvailableStock(data);
            setValue('avlQuantity', data);
            setAvlQuantity(data);
        });
    }

    const onUpdateStock = (data) => {

        selectedRowData.quantity = data.quantity;
        selectedRowData.note = data.note;
        selectedRowData.avlQuantity = data.avlQuantity;

        return axios.put(`${Config.apiUrl}/Stocks/UpdateDistributedStockEntries?refId=${selectedRowData.refId}`, selectedRowData, { headers: authHeader() })
            .then(response => {
                if (response.data.success) {
                    toast.current.show({ severity: 'success', detail: 'Data Update Successfully', autoDismiss:true });
                }
                else {
                    toast.current.show({ severity: 'error', detail: response.data.msg, sticky: true });
                }
            }).catch(error => {
                toast.current.show({ severity: 'error', sticky: true, summary: 'Error', detail: error });
            })
            .finally(() => {
                window.location.reload();
                // setSpinnerVisible(false);
                // SetFocusValue(true);
            })
    }

    const onBack=()=>{
        setIsUpdateClick(false);
    }

    const onFilter = (filterValue) => {
        let fromdate=new Date(filterValue.fyDate)  ;
        fromdate.setDate(fromdate.getDate()-1);
        let toDate=new Date(filterValue.toDate);

        let data = franchiesesDistributedStock.filter(a => a.dcDateValue >= fromdate && a.dcDateValue <= toDate);
        qtySum = (data.reduce((a, v) => a + parseFloat(v.quantity), 0));
        let srNo = 1;
        data.forEach(d => {
            d.serialNo = srNo;
            srNo++;
        })
        setFranchiesesDistributedStock(data);

    }

    const onReset = () => {

        const fields = [
            "fyDate",
            "toDate"
        ];
        fields.forEach((field) => setFilterValue(field, ''));
        setProductsData('');
        getFranchiesesDistributedStock();
        qtySum = 0;

    }
    let totalFooter = <ColumnGroup>
    <Row>
        <Column footer="Totals:" footerStyle={{ textAlign: 'right' }} />
        <Column footer='' />
        <Column footer='' />
        <Column footer={qtySum} />
        <Column footer='' />
        <Column footer='' />
        <Column footer='' />
        <Column footer='' />

    </Row>
    </ColumnGroup>;

    return (
        <BlockUI blocked={blockedPanel}>
        <div className="DistributedStockEntry">
            <Toast ref={toast} position={'center'} />
            {!isUpdateClick && (
                <>
                    <h3>Distributed Not Received Stock Entries</h3>``
                    <div className="Unit" style={{ minHeight: '1029px' }}>
                    <div className="box">
                    <div class="field col">
                        <label>From:</label>
                        <input type='date' name='fyDate' className='date' ref={Filter} />
                        <label>To:</label>
                        <input type='date' name='toDate' className='date' ref={Filter} />
                        <button type="button filter" className='filter' onClick={handleFilter(onFilter)}>Filter</button>
                        <button type="button" className='reset' onClick={handleFilter(onReset)}>Reset</button>
                    </div>
                </div>
                        <div className='box'>
                            <ToolBoxGrid columns={columns} data={franchiesesDistributedStock} fileName="Disributed Not Received Stock Entries"></ToolBoxGrid>
                            <DataTable className='dataTable' header={header} globalFilter={globalFilter} value={franchiesesDistributedStock} editMode='row' footerColumnGroup={totalFooter}>
                                <Column field="serialNo" header="Sr No"></Column>
                                <Column field="franchies" header="Franchise Name"></Column>
                                <Column field="productName" header="Product"></Column>
                                <Column field="quantity" header="Qty"></Column>
                                <Column field="status" header="Status"></Column>
                                <Column field="note" header="Note"></Column>
                                <Column field="dcNo" header="Dc No"></Column>
                                <Column field="dcDateFormat" header="Dc Date"></Column>
                                {/* <Column body={UpdateButton} field="" header="Action"></Column> */}
                            </DataTable>
                        </div>
                    </div>
                </>
            )}

            {isUpdateClick && (
                <div>
                    <div style={{display:'flex',justifyContent:'space-between',margin:'10px'}}>
                        <h3>Update Stock :: {selectedFranchiesName} </h3>
                        <button className='reset' type="button" onClick={onBack} style={{width:'auto',height:'35px'}}>Back</button>
                    </div>
                    <div className='Unit' style={{ minHeigth: '1029px' }}>
                        <div className='box'>
                            <div>
                                <label>Product<span style={{ color: 'red' }}>*</span></label>
                                <Dropdown className='Dropdown' name="productName" id="productName" optionLabel="name" filter showClear options={productsData} value={selectedProduct} onChange={onProductChange} />
                                <label for="Product Name">Batch No<span style={{ color: 'red' }}>*</span></label>
                                <Dropdown className='Dropdown' value={selectedBatchNo} options={batchNoData} id="batchNo" optionLabel="batchNo" onChange={onChangeBatchNo} filter showClear />
                                <label>Available Quantity:</label>
                                <input type='text' className='inputText' id="avlQuantity" name="avlQuantity" ref={register} readOnly />
                                <label>Enter Quantity:</label>
                                <input type='text' className='inputText' ref={register} name="quantity" onChange={onChangeQuantity} />
                            </div>
                            <div>
                                <label>Note:</label>
                                {/* <textarea name="note" className='note' ref={register}></textarea> */}
                                <input type='text' className="note" ref={register} name="note" />
                                <button className='button' type="button" onClick={handleSubmit(onUpdateStock)}><i class="pi pi-check"></i>Update Stock</button>
                            </div>
                        </div>
                    </div>

                </div>
            )}

        </div>
        </BlockUI>
    );
}
export default DistributedStockEntry;