//import logo from '../../images/app logo.png'
import { Button } from "primereact/button";
import { useState, useEffect, useContext, useRef } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

// import { Password } from "primereact/password";
// import { InputText } from "primereact/inputtext";
// import { yupResolver } from "@hookform/resolvers/yup";
// import * as Yup from "yup";
// import { useGoogleAuth } from "./actions/authActions";
import { Dropdown } from "primereact/dropdown";
//const { register, handleSubmit, formState: { errors }} = useForm();
import { GoogleLogin, GoogleLogout } from "react-google-login";
import FacebookLogin from 'react-facebook-login';
import "./login.css";
import Config from '../../Config.json';
import axios from "axios";
import Popup from "reactjs-popup";
import LoginPopup from "./LoginPopup";
import { Toast } from "primereact/toast";
import { Link } from "react-router-dom";
import ProgressSpinBarControl from '../Misc/progressSpinBarControl';
import logo from "../../image/greenField logo.png";

import { GreenFieldContext } from "../../App";
const clientId =
  "55236503589-p9rl4c6jeo87okg69d6eitbgcafq5s8p.apps.googleusercontent.com";

function Login({userId }) {
  //const authService = new authenticationService();
  const {setIsLoggedIn}=useContext(GreenFieldContext);
  let history = useHistory();
  const toast = useRef(null);
  const [wrong, setWrong] = useState(null);
  const [loggedIn, setLoggedIn] = useState(false);
  const [societyArray, setSocietyArray] = useState([]);
  const [UserId, setUserId] = useState();
  const [franchiseId, setFranchiseId] = useState();
  // const [societyName] = useState();
  const [franchiseName, setFranchiseName] = useState([]);
  const [societyDetailsData, SetSocietyDetailsData] = useState([]);



  const [isGoogleSignIn, setIsGoogleSignIn] = useState(false);
  const [spinnerVisible, setSpinnerVisible] = useState(false);


  const [onFranchiseSelectionPopup, setonFranchiseSelectionPopup] = useState(false);
  // localStorage.setItem('userId', UserId);
  // localStorage.setItem('societyId', societyId);
  // localStorage.setItem('societyName', societyName);

  const [login, setLogin] = useState(false);
  const [data, setData] = useState({});
  const [picture, setPicture] = useState('');

  const { errors, register, reset, handleSubmit, setValue, clearErrors } =
    useForm();
  const handleLoginSuccess = (response, e) => {
    console.log(response);
    const googleresponse = {
      Name: response.profileObj.name,
      Email: response.profileObj.email,
      Token: response.googleId,
      Id: 1,
      // Image: response.profileObj.imageUrl,
      ProviderName: "Google",
    };
    setSpinnerVisible(true);
    let url = `${Config.apiUrl}/Authentication/Authenticate?token=${response.tokenId}`;
    console.log(googleresponse);
    console.log(url);
    axios
      .get(url)
      .then((response) => {
        processResponse(response);
        console.log("Bearer Token: " + response.data);
        console.warn(response.data);
      })
      .catch((error) => {
        toast.current.show({ severity: "error", sticky: true, detail: error });
      })
      .finally(() => {
        setSpinnerVisible(false);
      })

  };

  const handleLoginFailure = (error) => {
    console.log("Login Failure ", error);
  };

  const responseFacebook = (response) => {
    console.log(response);

    setSpinnerVisible(true);
    let apiurl = `${Config.apiUrl}/Authentication/AuthenticateFacebook?facebookToken=${response.accessToken}`;
    console.log(response);
    console.log(apiurl);
    axios
      .get(apiurl)
      .then((response) => {
        processResponse(response);
        // console.log("Bearer Token: " + response.data);
        console.warn(response.accessToken);
      })
      .catch((error) => {
        toast.current.show({ severity: "error", sticky: true, detail: error });
        // console.log("Error from api " + error);
      })
      .finally(() => {
        setSpinnerVisible(false);
      })
    setData(response);
    // setPicture(response.picture.data.url);
    if (response.accessToken) {
      setLogin(true);
    } else {
      setLogin(false);
    }
  }

  useEffect(() => {

    //getting group master data
  }, []);

  const processResponse = (response) => {
    console.log(response);
    if (response.data != null) {

      if (response.data.userLogInContextInfo.length > 0) {
        if (response.data.token) {
          sessionStorage.setItem("user", JSON.stringify(response.data));
          // localStorage.setItem("societyId", JSON.stringify(response.data));

        }
        setUserId(response.data.userLogInContextInfo[0].userId);
        localStorage.setItem("userId", response.data.userLogInContextInfo[0].userId);


        if (!response.data.validUser) {
          toast.current.show({ severity: "error", detail: "Invalid Crdentials" });
          return;
        }
        console.log("Checkpoint 1");
        let pendingAdmin = response.data.userLogInContextInfo.find(a => a.contextId === -1 && a.action == 0);
        if (pendingAdmin) {
          toast.current.show({ severity: "error", sticky: true, detail: `Your Request for CenterAdmin role is pending for Approval` });
          return;
        }
        let approvedAdmin = response.data.userLogInContextInfo.find(a => a.contextId === -1 && a.action > 0);
        if (approvedAdmin) {
          setLoggedIn(true);
       
          //if (approvedAdmin.length === 1) {
            setFranchiseId(approvedAdmin.contextId);
            // localStorage.setItem("FranchiseName", "");
            localStorage.setItem('name', (approvedAdmin.name));
            sessionStorage.setItem("accessLevel", JSON.stringify(approvedAdmin.accessLevel));
            sessionStorage.setItem("IsAdmin", true);
            localStorage.setItem("franchiseId", -1);
            setIsLoggedIn(true);
            // localStorage.setItem("franchiseId", (approvedAdmin.contextId));
            history.push('/AdminDashboard');
         // }
        }

          let approvedFrancises = response.data.userLogInContextInfo.find(a => a.contextId > 0 && a.action > 0);

          if (approvedFrancises === undefined) {
            let approvedFrancises = response.data.userLogInContextInfo.find(a => a.contextId > 0 && a.action === 0);
            if (approvedFrancises) {
              toast.current.show({ severity: "error", sticky: true, detail: `Your Request is pending for ${approvedFrancises[0].contextName}. Please Contact to Admin` });
              return;
            }
            else {
              setonFranchiseSelectionPopup(true);
              return;
            }
          }
          else {
            let approvedFranchises = response.data.userLogInContextInfo.filter(a => a.contextId > 0 && a.action === 1);
            if (approvedFranchises) {
              setLoggedIn(true);
             
              if (approvedFranchises.length === 1) {
                setFranchiseId(approvedFranchises[0].contextId);
                localStorage.setItem("FranchiseName", (approvedFranchises[0].contextName));
                localStorage.setItem('name', (approvedFranchises[0].name));
                sessionStorage.setItem("accessLevel", JSON.stringify(approvedFranchises[0].accessLevel));
                localStorage.setItem("franchiseId", (approvedFranchises[0].contextId));
                sessionStorage.setItem("IsAdmin", false);
                setIsLoggedIn(true);
                history.push('/FranchiseDashboard');

              }
              else {
                let tempArray = [];
                response.data.userLogInContextInfo.forEach((a) => {
                  if (a.action > 0) {
                    let existingIndex = tempArray.findIndex(t => t.contextId === a.contextId);
                    if (existingIndex < 0) {
                      tempArray.push(a);
                    }
                  }
                });
                tempArray.sort(compare);

                setSocietyArray(tempArray);
                //setonFranchiseSelectionPopup(true);
              }
            }
          }
        } else {
          toast.current.show({
            severity: "error",
            detail: response.data.errorMessage,
          });
        }
        // toast.current.show({severity:'success', detail:response.data[0].msg});
      }
    }

    function compare(a, b) {
      if (a.last_nom < b.last_nom) {
        return -1;
      }
      if (a.last_nom > b.last_nom) {
        return 1;
      }
      return 0;
    }

    const onSubmit = (data) => {
      // setUserName(data.loggedInId);
      setSpinnerVisible(true);
      return axios
        .post(`${Config.apiUrl}/Authentication/SignIn`, data)
        .then((response) => {
          processResponse(response);
        })
        .catch((error) => {
          toast.current.show({ severity: "error", detail: `Network issue` });

        })
        .finally(() => {
          setSpinnerVisible(false);
          // history.push('/HomePage');      
        })

    }

    const onSignUp = () => {
      history.push("/signUp");
    };

    const changeFranchise = (e) => {

      // setUserId(e.value.userId);
      localStorage.setItem("userId", e.value.userId);

      setFranchiseName(e.value.contextName);
      localStorage.setItem("FranchiseName", (e.value.contextName));
       localStorage.setItem('name', (e.value.name));
       sessionStorage.setItem("accessLevel", JSON.stringify(e.value.accessLevel));
       localStorage.setItem("franchiseId", (e.value.contextId));
       sessionStorage.setItem("IsAdmin", false);

      // updateSocietyData(e.value.societyId);
      //console.log(societyData);
      /* societyService.getSocietyDetails(e.value.societyId)
        .then(data => {
          localStorage.setItem("fyFromDate", (data[0].fyFromDate));
          SetSocietyDetailsData(data);
        }); */

        history.push('/FranchiseDashboard');

    };
    const onCheckGoogleSignIn = (e) => {
      setIsGoogleSignIn(!isGoogleSignIn);
    };

    return (
      <div className="login">
        <Toast ref={toast} />
        <ProgressSpinBarControl spinBarVisible={spinnerVisible} />
        <img src={logo} alt="logo" className="image" />

        {/* <img  alt="logo" height={100} width={300} /> */}
        <h1 style={{ alignContent: 'left', textAlign: 'center' }}>Login</h1>

        <form>
          {!loggedIn && (
            <div >
              <div className="p-field" >
                {/* <label style={{ lineHeight: "3", color: "white", width:'120px'}}>Username: </label> */}
                <input
                  type="text"
                  name="loggedInId"
                  placeholder="Enter Username "
                  ref={register}

                />
              </div>

              <div className="p-field" >
                {/* <label style={{ lineHeight: "3", color: "white",width:'120px' }}>Password : </label> */}
                <input
                  type="password"
                  name="password"
                  placeholder="Enter Your Password"
                  ref={register}
                />
              </div>

              <div style={{ color: "red" }}>{wrong}</div>

              <div className="p-formgroup-inline" style={{ justifyContent: "center", marginTop: "30px" }}>
                <div className="p-field" style={{ marginRight: '10px' }}>
                  <Button
                    label="Log in"
                    type="submit "
                    onClick={handleSubmit(onSubmit)}
                  />
                </div>
                <div className="p-field ">
                  <Button
                    label="sign up"
                    type="submit "
                    onClick={handleSubmit(onSignUp)}
                  />
                </div>
              </div>
              <div>
                <Link style={{ color: 'blue' }} to={'/ForgotPassword'}>Forgot Password?</Link>
              </div>
              <div style={{ display: 'row' }}>
                <div style={{ marginTop: "20px", justifyContent: "center" }}>
                  <GoogleLogin
                    clientId={clientId}
                    onSuccess={handleLoginSuccess}
                    onFailure={handleLoginFailure}
                    isSignedIn={false}
                  />
                </div>

                <div style={{ marginTop: "20px" }}>
                  <FacebookLogin
                    appId="2385537008263872"
                    autoLoad={false}
                    fields="name,email"
                    scope="public_profile,user_friends"
                    callback={responseFacebook}
                    icon="fa-facebook" />
                </div>
              </div>

              <LoginPopup
                trigger={onFranchiseSelectionPopup}
                setTrigger={setonFranchiseSelectionPopup}
                userId={UserId}
              ></LoginPopup>

            </div>
          )}
          {loggedIn && (
            <div
              className="p-formgroup-inline"
              style={{ justifyContent: "center", marginTop: "30px" }}
            >

              <Dropdown className="Dropdown" filter value={franchiseName} options={societyArray} id="societyName" optionLabel="contextName" onChange={changeFranchise} placeholder="Select a Franchise"
              />

            </div>
          )}  

        </form>

      </div>
    );
  }

  export default Login;
