import React from 'react';
import 'primeflex/primeflex.css';
import Products from './Products';
import Franchise from './Franchise';
import InvoiceList from './InvoiceList';
import Accounts from './Franchise/Accounts';
import Stock from './Stock';
import AdminStockReturn from './AdminStockReturn';
import ProductReport from './ProductReport';
import AddComplaint from './AddComplaint';
import Permission from './Misc/Permission';


const Home = () => {
   return (
<div>
   {/* <Products /> */}
   {/* <Franchise /> */}
   {/* <InvoiceList /> */}
    {/* <Accounts />  */}
   {/* <Stock /> */}
   {/* <AdminStockReturn /> */}
   {/* <ProductReport /> */}
   {/* <AddComplaint /> */}
   {/* <Permission /> */}
</div>
   );
}

export default Home;