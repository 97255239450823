import { Checkbox } from "@material-ui/core";
import { Button } from "primereact/button";
import React from 'react'
import { useForm } from "react-hook-form";
import { useState, useEffect, useContext, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Dropdown } from 'primereact/dropdown';
import axios from "axios";
import Config from '../../Config.json';
import { Toast } from 'primereact/toast';
import './ProfilePopup.css';
import authHeader from '../../authHeader';
import ProgressSpinBarControl from "../Misc/progressSpinBarControl";






function ProfilePopup(props) {

    let history = useHistory();
    const toast = useRef(null);
    const { errors, register, reset, handleSubmit, setValue, clearErrors } = useForm();
    var userId = parseInt(localStorage.getItem('userId'));
    const [spinnerVisible, setSpinnerVisible] = useState(false);


        if(props.trigger)
        {
            setValue("userName",props.userData[0].userName);
            setValue("email",props.userData[0].email);
            setValue("mobileNo",props.userData[0].mobileNo);
        }

        const onSave = (data, e) => {
            setSpinnerVisible(true);
            return axios.put(`${Config.apiUrl}/Profile/UpdateProfile?Id=${userId}`, data,{headers:authHeader()})
              .then(response => {
                if (response.data != null) {
                  toast.current.show({severity:'success', detail:response.data[0].msg});
                }
                else {
                  toast.current.show({severity: 'error', detail: response.data[0].msg});
                }
              })
              .catch(error => { 
                toast.current.show({ severity: 'error', sticky: true, summary: 'Error', detail: error });
            })
            .finally(() => {
                setSpinnerVisible(false);
              })
        
          }
    

    return (props.trigger) ? (

        <div className="popup">
            <Toast ref={toast} />
            <ProgressSpinBarControl spinBarVisible={spinnerVisible}/>
            <div className="popup-inner">
                <div className="popup-content">
                    <div>

                        <div className="p-formgroup-inline" >
                            <div className="p-field p-grid">
                                <div className="p-d-flex p-flex-column p-flex-md-row" >
                                    <label>User Name:</label>
                                    <input type="text" className="textBoxWidth" name="userName"  ref={register} />
                                </div>
                                <div className="p-d-flex p-flex-column p-flex-md-row" >
                                    <label>Email:</label>
                                    <input type="text" className="textBoxWidth" name="email" ref={register} />
                                </div>
                                <div className="p-d-flex p-flex-column p-flex-md-row" >
                                    <label>Mobile No:</label>
                                    <input type="text" className="textBoxWidth" name="mobileNo" ref={register} />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="popup__footer">
                    <Button type="button" label="Save" style={{ height: '30px' }} onClick={handleSubmit(onSave)}/>

                    <Button type="button" onClick={() => props.setTrigger(false)} label="Cancel" style={{ height: '30px', marginLeft: '10px' }}/>
                </div>
            </div>
        </div>
    ) : "";
}

export default ProfilePopup;

