import React from 'react';
import 'primeflex/primeflex.css';
import './adminDashboard.css';
import { useState } from 'react';
import { Chart } from 'primereact/chart';
import { useEffect } from 'react';
import axios from 'axios';
import Config from '../../Config.json';
import GreenFieldService from '../../services/greenFieldService';

function AdminDashboard() {
    const [totalSales, setTotalSales] = useState(0);
    const [currentMonthSales, setCurrentMonthSales] = useState(0);
    const [productsChartData, setProductsChartData] = useState({
        labels: ['A', 'B', 'C'],
        datasets: [
            {
                data: [300, 50, 100],
                backgroundColor: [
                    "#42A5F5",
                    "#66BB6A",
                    "#FFA726"
                ],
                hoverBackgroundColor: [
                    "#64B5F6",
                    "#81C784",
                    "#FFB74D"
                ]
            }
        ]
    });

    const [chartData, setChartData] = useState({
        labels: ['A', 'B', 'C'],
        datasets: [
            {
                data: [300, 50, 100],
                backgroundColor: [
                    "#42A5F5",
                    "#66BB6A",
                    "#FFA726"
                ],
                hoverBackgroundColor: [
                    "#64B5F6",
                    "#81C784",
                    "#FFB74D"
                ]
            }
        ]
    });

    const service = new GreenFieldService();
    useEffect(() => {

        service.GetAdminTotalSales().then(data => {
            let val = parseFloat(data);
            setTotalSales(val);
        });
        service.GetMostTransFranchises().then(data => {
            let franchises = data.map(a => a.franchiseName);
            let amounts = data.map(a => a.amount);
            let inData = {
                labels: franchises,
                datasets: [
                    {
                        data: amounts,
                        backgroundColor: [
                            "#42A5F5",
                            "#66BB6A",
                            "#FFA726"
                        ],
                        hoverBackgroundColor: [
                            "#64B5F6",
                            "#81C784",
                            "#FFB74D"
                        ]
                    }
                ]
            };
            setChartData(inData);
        });

        service.GetCurrentMonthSales().then(data => {
            
            setCurrentMonthSales(parseFloat(data));
        });

        service.GetMostTransProducts().then(data => {
            let Products = data.map(a => a.productName);
            let amounts = data.map(a => a.amount);
            let inProdData = {
                labels: Products,
                datasets: [
                    {
                        data: amounts,
                        backgroundColor: [
                            "#42A5F5",
                            "#66BB6A",
                            "#FFA726"
                        ],
                        hoverBackgroundColor: [
                            "#64B5F6",
                            "#81C784",
                            "#FFB74D"
                        ]
                    }
                ]
            };
            setProductsChartData(inProdData);
        });


    }, []);



    return (
        <div style={{ display: 'flex', margin: '10px', padding: '10px' }}>
            <div className='card  justify-content-center chartDiv' style={{ position: 'relative', width: '25%', margin: '10px', padding: '10px' }}>
                <h3>Top Franchises</h3>
                <Chart type="pie" data={chartData}  ></Chart>
            </div>


            <div className='card  justify-content-center chartDiv' style={{ position: 'relative', width: '25%', margin: '10px', padding: '10px' }}>
                <h3>Top Products</h3>
                <Chart type="pie" data={productsChartData}  ></Chart>
            </div>

            <div className='card  justify-content-center' style={{ position: 'relative', width: '20%', margin: '10px', padding: '10px' }}>
                <h3>Total Sales</h3>
                <label style={{ justifyContent: 'center' }}>{totalSales.toLocaleString('en-US', {minimumFractionDigits: 1, maximumFractionDigits: 1})} &#8377;</label>
            </div>

            <div className='card  justify-content-center' style={{ position: 'relative', width: '20%', margin: '10px', padding: '10px' }}>
                <h3>Current Month Sales</h3>
                <label style={{ justifyContent: 'center' }}>{currentMonthSales.toLocaleString('en-US', {minimumFractionDigits: 1, maximumFractionDigits: 1})} &#8377;</label>
            </div>


        </div>
    )
}
export default AdminDashboard;