// import './AdminStockReturn.css';
// import '../../App.css';
import 'primeflex/primeflex.css';
import { Calendar } from 'primereact/calendar';
import React, { useState,useEffect,useRef } from 'react';
import { useForm } from "react-hook-form";
import { Dropdown } from 'bootstrap';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import GreenFieldService from '../../services/greenFieldService';
import { useHistory } from 'react-router-dom';
import ExportCsvReport from '../../exportReport/exportCsvReport';
import ExportExcelReport from '../../exportReport/exportExcelReport';
import ExportPdfReport from '../../exportReport/exportPdfReport';
import { Toast } from 'primereact/toast';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
var totalQuantity;
var totalTaxableAmount;
var totalCgst;
var totalSgst;
var netTotal;


function SalesProduct() {

    const [date3, setDate3] = useState(null);
    const toast = useRef(null);
    const {
        register: Filter,
        handleSubmit: handleFilter,
        setValue: setFilterValue,
    } = useForm();
    const [filters1, setFilters1] = useState(null);
    const [globalFilter, setGlobalFilter] = useState();
    const [salesProductData,setSalesProductData]=useState();
    const [initialData,setInitialData]=useState();

    const greenFieldService = new GreenFieldService();
    const exportCsvReport = new ExportCsvReport();
    const exportPdfReport = new ExportPdfReport();
    const exportExcelReport = new ExportExcelReport();
    let history = useHistory();
    const dt = useRef(null);
    const franchiseId = localStorage.getItem('franchiseId');
    const franchiseName = localStorage.getItem('FranchiseName');



    useEffect(() => {
        GetSaleProductList(franchiseId);
        initFilters1();

    }, [])

    const GetSaleProductList=(franchiseId)=>{
        greenFieldService.GetSaleProductDetails(franchiseId).then(data => {
            let srNo = 1;
            data.forEach(d => {
                d.serialNo = srNo;
                d.taxableAmount=d.taxableAmount.toFixed(2);
                d.cgst=d.cgst.toFixed(2);
                d.sgst=d.sgst.toFixed(2);
                d.total=d.total.toFixed(2);

                srNo++;
            })
            totalQuantity = (data.reduce((a, v) => a + parseFloat(v.quantity), 0));
            totalSgst = (data.reduce((a, v) => a + parseFloat(v.sgst), 0)).toFixed(2);
            totalCgst = (data.reduce((a, v) => a + parseFloat(v.cgst), 0)).toFixed(2);
            totalTaxableAmount = (data.reduce((a, v) => a + parseFloat(v.taxableAmount), 0)).toFixed(2);
            netTotal = (data.reduce((a, v) => a + parseFloat(v.total), 0)).toFixed(2);


            setSalesProductData(data);
            setInitialData(data);
        });
    }

    const onChangeGlobal = (e) => {
        let value = e.target.value;
        let _filters1 = { ...filters1 };
        _filters1['global'].value = value;

        setFilters1(_filters1);

        setGlobalFilter(value);
    }
    
    const initFilters1 = () => {
        setFilters1({
            'global': { value: null, matchMode: FilterMatchMode.CONTAINS },

        });
        setGlobalFilter('');
    }

    const cols = [
        { field: 'serialNo', header: 'Sr.No' },
        { field: 'invName', header: 'Invoice Number' },
        { field: 'customerName', header: 'Customer' },
        { field: 'customerAddress', header: 'Address' },
        { field: 'grandTotal', header: 'Total Amount' },
        { field: 'discountAmount', header: 'Discount' },
        { field: 'returnAmount', header: 'Return' },
        { field: 'dueAmount', header: 'Due Amount' },
        { field: 'loyalty', header: 'Loyalty' },
        { field: 'invDateFormat', header: 'Date' }
        

    ];
    const exportColumns = cols.map(col => ({ title: col.header, dataKey: col.field }));

    var errors = [];

    const filterValidate = (data) => {
        if (!data?.fyDate) {
            errors.push('* From Date is required.', <br />);
        }
        if (!data?.toDate) {
            errors.push('* To Date is required', <br />);
        }
    }

    const onFilter = (filterValue) => {
        filterValidate(filterValue)
        if (errors.length > 0) {
            // eslint-disable-next-line no-lone-blocks
            {
                errors.map(() => {
                    toast.current.show({ severity: 'warn', detail: errors, sticky: true });
                }

                )
            }
            errors = [];
            return;
        }
        let data = initialData.filter(a => a.invDateFormat >= filterValue.fyDate && a.invDateFormat <= filterValue.toDate);
        let srNo = 1;
        data.forEach(d => {
            d.serialNo = srNo;
            srNo++;
        })
        // netAmountSum = (data.reduce((a, v) => a + parseFloat(v.dueAmount), 0));
        setSalesProductData(data);

    }

    const onReset = () => {

        const fields = [
            "fyDate",
            "toDate"
        ];
        fields.forEach((field) => setFilterValue(field, ''));
        GetSaleProductList(franchiseId);

    }
    const onBack = (e) => {
        history.push(`/Franchise`);

    }
   

    const buttons = (rowData) => {
        return <div>

            {/* <button className='reset' type="button" onClick={() => onManage(rowData)}>Manage Invoice</button> */}
            <button className=' button' type="button" onClick={() => onPrint(rowData)}>Print</button>


        </div>;
    }

    const onManage=()=>{

    }

    let totalFooter = <ColumnGroup>
    <Row>
        <Column footer="Totals:" footerStyle={{ textAlign: 'right' }} />
        <Column footer='' />
        <Column footer='' />
        <Column footer={totalQuantity} />
        <Column footer={totalTaxableAmount} />
        <Column footer='' />
        <Column footer={totalCgst} />
        <Column footer={totalSgst} />
        <Column footer={netTotal} />

    </Row>
    </ColumnGroup>;

    const onPrint =(rowData)=>{
        // history.push('/InvoicePrint');
        // history.push(`/InvoicePrint/${rowData.customerId}/${rowData.franchiseId}/${rowData.categoryId}/${rowData.invNo}`);
        history.push(`/InvoicePrint/${rowData.customerId}/${rowData.id}`);
    }

    const header = (
        <div className="table-header">
            <div>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" className="globleInput" value={globalFilter} onChange={onChangeGlobal} placeholder="Global Search" />
                </span>
            </div>
        </div>
    );

    const exportCSV = (selectionOnly) => {
        // <exportCsvReport dt={dt}/>
        exportCsvReport.exportCSV(dt)
        // dt.current.exportCSV();
    }

    const exportPdf = () => {
        let fileName="Invoice Report";
        exportPdfReport.exportPdf(exportColumns,salesProductData,fileName);

    }

    const exportExcel = () => {
        let fileName="Invoice Report";
        exportExcelReport.exportExcel(salesProductData,fileName);
    }


    return (
        <div className="AdminStockReturn">
            <Toast ref={toast} position={'center'} />
            <h3>Product Sales List - {franchiseName}
            <button className='reset' style={{ width: '100px',justifyContent:'end' }} onClick={onBack} type="button">Back</button> </h3>
            <div className="Unit" style={{ minHeight: '1029px' }}>
                <div className="box">
                    <div class="field col">
                        <label>From:</label>
                        <input type='date' name='fyDate' className='date' ref={Filter} />
                        <label>To:</label>
                        <input type='date' name='toDate' className='date' ref={Filter} />
                        <button type="button filter" onClick={handleFilter(onFilter)} className='filter'>Filter</button>
                        <button type="button" onClick={onReset} className='reset'>Reset</button>
                    </div>                  
                    {/* <div>
                        
                        Show
                        <select className='dropdown'>
                            <option>50</option>
                            <option>100</option>
                            <option>All</option>
                        </select>
                        entries
                    </div> */}
                </div>

                <div className='box'>
                <div>
                        <button type='button' >Copy</button>
                        <button type='button' onClick={() => exportCSV(false)}>CSV</button>
                        <button type='button' onClick={() => exportExcel()}>Excel</button>
                        <button type='button' onClick={() => exportPdf()}>PDF</button>
                        <button type='button'>Print</button>
                    </div>
                    <DataTable ref={dt} value={salesProductData} className='dataTable' scrollable scrollHeight='800px' header={header} filters={filters1} globalFilter={globalFilter}  footerColumnGroup={totalFooter} editMode='row'>
                        <Column field="serialNo" header="Sr No"></Column>
                        <Column field="product" header="Product"></Column>
                        <Column field="hsn" header="Hsn"></Column>
                        <Column field="quantity" header="Quantity"></Column>

                        <Column field="taxableAmount" header="TaxableAmount"></Column>
                        <Column field="gstRate" header="Gst Rate"></Column>
                        <Column field="cgst" header="CGST"></Column>
                        <Column field="sgst" header="SGST"></Column>
                        <Column field="total" header="Total"></Column>
                        {/* <Column field="note" header="Royalty"></Column> */}
                        {/* <Column field="status" header="Status"></Column> */}
                        {/* <Column body={buttons} header="Action"></Column> */}
                    </DataTable>
                </div>
            </div>
        </div>
    );
}
export default SalesProduct;