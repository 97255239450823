import { Button } from 'primereact/button';
import axios from 'axios';
import GreenFieldService from '../services/greenFieldService';
import Config from '../Config.json';
import { Toast } from 'primereact/toast';
import 'primeflex/primeflex.css';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from "react-hook-form";
import React, { useState, useEffect, useRef } from 'react';
// import './CustomerMaster.css';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import './Stock.css';
import '../App.css';
import moment from 'moment';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { BlockUI } from 'primereact/blockui';
import { Col } from 'react-bootstrap';
import authHeader from '../authHeader';

// import Stock from './Stock';

var inwardStockSum;
var distributedStockSum;
var remainingStockSum;
var notReceivedStockSum;
var returnStockSum;
var stockTotal;


function Stock(id) {

    const { register, handleSubmit, reset, setValue, } =
        useForm();

    const {
        register: Filter,
        // errors: errors1,
        handleSubmit: handleFilter,
        setValue: setFilterValue,
    } = useForm();

    const greenFieldService = new GreenFieldService();
    const toast = useRef(null);
    const [stocksData, setStocksData] = useState([]);
    // const [buttonLabel, setButtonLabel] = useState("Save");
    const [buttonLabelStock, setButtonLabelStock] = useState("Add Stock");
    const [globalFilter, setGlobalFilter] = useState('');
    const [globalFilter1, setGlobalFilter1] = useState('');
    const [editRowData, setEditRowData] = useState();
    const [isAddMode, setIsAddMode] = useState(true);
    const [productData, setProductsData] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState();
    const [isManageClick, setIsManageClick] = useState(false);
    const [isStockUpdate, setIsStockUpdate] = useState(false);
    const [stocksByProduct, setStocksByProduct] = useState([]);
    const [initialStockData, setInitialStockData] = useState([]);
    const [manufactureDate, setManufactureDate] = useState();
    const [filters, setFilters] = useState(null);
    const [filters1, setFilters1] = useState(null);
    const [blockedPanel, setBlockedPanel] = useState(false);
    const [disributedStockNo, setDisributedStockNo] = useState(false);
    const [batchNo,setBatchNo]=useState();
    var accessLevel = parseInt(sessionStorage.getItem('accessLevel'));



    useEffect(() => {
        greenFieldService.GetProductsData().then(data => {
            let srNo=1;
            data.forEach(a => {
                a.productName = `${a.name} - ${a.packingSize} ${a.unitName}`;
                a.packingSize = `${a.packingSize} ${a.unitName}`;
                a.serialNo = srNo;
                srNo++;
            })
            setProductsData(data);

        });

       //here getting stock details
        getStockDetails();
         

        initFilters1();

        if(accessLevel > 1)
        {
          setBlockedPanel(false);
        }
        else{
            setBlockedPanel(true);
        }
        

    }, [])

    const getStockDetails=()=>{
        greenFieldService.GetStockDetails().then(data => {
            let srNo=1;
            data.forEach(a => {
                a.serialNo = srNo;
                srNo++;
            })   
            inwardStockSum = (data.reduce((a, v) => a + parseFloat(v.inwardStock), 0));
            distributedStockSum = (data.reduce((a, v) => a + parseFloat(v.distributedStock), 0));
            notReceivedStockSum = (data.reduce((a, v) => a + parseFloat(v.notReceivedStock), 0));
            returnStockSum = (data.reduce((a, v) => a + parseFloat(v.returnStock), 0));
            remainingStockSum = (data.reduce((a, v) => a + parseFloat(v.remainingStock), 0));
            setStocksData(data);
        });
    }

    const inputNoOnly = (e) => {
        if(e.target.id === 'quantity'){
        const onlyNums = e.target.value.replace(/[^0-9.]/g, '');
        setValue('quantity', onlyNums);
        }
        if(e.target.id ==='duration')
        {
            const onlyNums = e.target.value.replace(/[^0-9.]/g, '');
            setValue('duration', onlyNums);
        }
    }
    

    var errors = [];

    const validate = (data) => {

        if (!selectedProduct?.productName) {
            errors.push('* Product Name is required.',<br/>);
        }
        if (!data?.quantity) {
            errors.push('* Quantity is required',<br/>);
        }
        if (!data?.batchNo) {
            errors.push('* Batch number is required.',<br/>);
        }
        if (!data?.manufactureDate) {
            errors.push('* Manufacture Date is required',<br/>);
        }
        if (!data?.duration) {
            errors.push('* Duration is required',<br/>);
        }
 
    };


    const onChangeGlobal = (e) => {
        let value = e.target.value;
        let _filters = { ...filters };
        _filters['global'].value = value;

        setFilters1(_filters);

        setGlobalFilter(value);
    }

    const onChangeGlobal1 = (e) => {
        let value = e.target.value;
        let _filters1 = { ...filters1 };
        _filters1['global'].value = value;

        setFilters1(_filters1);

        setGlobalFilter1(value);
    }

    const initFilters1 = () => {
        setFilters1({
            'global': { value: null, matchMode: FilterMatchMode.CONTAINS },

        });
        setGlobalFilter('');
    }

    const onChangeProduct = (e) => {
        setSelectedProduct(e.value);
        setValue('companyName',e.value?.companyName);
    }
    const onManufactureDate = (e) => {
        setManufactureDate(e.target.value);
    }
    const onDuration = (e) => {
        let duration = e.target.value - 1;
        let expDate = moment(manufactureDate).add(duration, 'M').format('YYYY-MM');
        setValue('expDate', expDate);
    }


    const onSave = (data) => {
        validate(data);
        if(errors.length > 0){
            // eslint-disable-next-line no-lone-blocks
            {errors.map(() => {
                toast.current.show({ severity: 'warn', detail: errors, sticky: true });
            }

            )}
            errors=[];
            return;
        }
        console.warn(data);
        data.adminId = 1;
        return isAddMode ? addStock(data) : updateStock(data);
    }
    const addStock = (data) => {
        // setSpinnerVisible(true)

        data.productId = selectedProduct.id
        return axios.post(`${Config.apiUrl}/Stocks/AddStocks`, data, { headers: authHeader() })
            .then(response => {
                if (response.data.success) {
                    toast.current.show({ severity: 'success', detail: response.data.msg, autoDismiss:true });
                    getStockData(selectedProduct.id);
                    getStockDetails();
                    onResetForm();
                }
                else {
                    toast.current.show({ severity: 'error', detail: response.data.msg, sticky: true });
                }
            }).catch(error => {
                toast.current.show({ severity: 'error', sticky: true, summary: 'Error', detail: error.message });
            })
            .finally(() => {
                // setSpinnerVisible(false);
                // SetFocusValue(true);
            })
    }

    const updateStock = (data) => {

        // setSpinnerVisible(true);
        data.id = editRowData.id;

        return axios.put(`${Config.apiUrl}/Stocks/UpdateStocks`, data, { headers: authHeader() })
            .then(response => {
                if (response.data.success) {
                    toast.current.show({ severity: 'success', detail: 'Data Update Successfully', autoDismiss:true });
                    onResetForm();
                }
                else {
                    toast.current.show({ severity: 'error', detail: response.data.msg, sticky: true });
                }
            }).catch(error => {
                toast.current.show({ severity: 'error', sticky: true, summary: 'Error', detail: error });
            })
            .finally(() => {
                getStockData(selectedProduct.id);

                // setSpinnerVisible(false);
                // SetFocusValue(true);
            })
    }

    const onDelete = (rowData) => {
        if (rowData != null) {
            toast.current.show({
                severity: 'warn', sticky: true, content: (
                    <div className="flex flex-column" style={{ flex: '1' }}>
                        <div className="text-center">
                            <i className="pi pi-exclamation-triangle" style={{ fontSize: '3rem' }}></i>
                            <h4>Are you sure?</h4>
                            <p>Confirm to proceed</p>
                        </div>
                        <div className="grid p-fluid">
                            <div className="col-6">
                                <Button type="button" label="Yes" onClick={() => onYes(rowData)} className="p-button-success" />
                            </div>
                            <div className="col-6">
                                <Button type="button" label="No" onClick={() => onNo(rowData)} className="p-button-secondary" />
                            </div>
                        </div>
                    </div>
                )
            });
        }
        else {
            toast.current.show({ severity: 'warn', sticky: true, detail: "PLEASE SELECT ROW" });
            return;
        }
    }

    const onYes = (rowData) => {
        toast.current.clear();
        axios.delete(`${Config.apiUrl}/Stocks/delete?id=${rowData.id}`, { headers: authHeader() })
            .then(response => {
                console.log(response)
                if (response.data.success) {
                    toast.current.show({ severity: 'success', sticky: true, detail: response.data.msg,autoDismiss:true });
                }
                else {
                    toast.current.show({ severity: 'error', sticky: true, detail: response.data.msg });
                }
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                getStockData(selectedProduct.id);
            })
        // window.location.reload();
    }

    const onNo = () => {
        toast.current.clear();
        return;
    };

    const onEdit = (rowData) => {
        setIsAddMode(false);
        id = rowData.id;
        let data = rowData;
        setIsStockUpdate(true);
        setBatchNo(rowData.batchNo);
        setButtonLabelStock("Update Stock");

        const fields = [
            "quantity",
            "batchNo",
            "duration",
            "companyName"

        ];
        fields.forEach((field) => setValue(field, data[field]));
        setIsAddMode(false);
        setEditRowData(rowData);
        setValue('manufactureDate', data.manufactureDate);
        setValue('expDate', data.expDate);
        setManufactureDate(data.manufactureDate);

    }

    const onChangeQuantity = (e)=>{
        let qty=e.currentTarget.value;
        setValue('quantity',qty);
        // if(!isAddMode)
        // {    

        //     greenFieldService.GetDistibutedStockBatchWise(selectedProduct.id,batchNo).then(data =>{
        //         if(data < qty )
        //         {
        //             toast.current.show({ severity: 'Warn', sticky: true, detail: "Quantity is less than distributed Quntity" });
        //             setValue('quantity',data);

        //         }
        //         setDisributedStockNo(data);
        // });
           
        
        // }
    }

    const onResetForm = () => {
        
        const fields = [
            "quantity",
            "batchNo",
            "manufactureDate",
            "duration",
            "expDate"
        ];
        fields.forEach((field) => setValue(field, ''));
        if(!isManageClick)
        {
            setSelectedProduct('');
        }
    }

    const header = (

        <div className="table-header">

            <div>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" className="globleInput" value={globalFilter} onChange={onChangeGlobal} placeholder="Global Search" />
                </span>
            </div>
        </div>
    );

    const stockDataHeader = (

        <div className="table-header">

            <div>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" className="globleInput" value={globalFilter1} onChange={onChangeGlobal1} placeholder="Global Search" />
                </span>
            </div>
        </div>
    );

    const ButtonIcon = (rowData) => {
        return <div>
            <Button icon="pi pi-pencil" className="p-button-rounded p-button-info" onClick={() => onEdit(rowData)} aria-label="Bookmark" />
            <Button icon="pi pi-trash" disabled className="p-button-rounded p-button-danger" onClick={() => onDelete(rowData)} aria-label="Bookmark" />
        </div>
    }

    const ManageButton = (rowData, column) => {
        return <div>
            {/* <button className='button' type="button" onClick={onManage(rowData)}>Manage</button> */}
            <button className='button' type="button" onClick={() => onManage(rowData)}>Manage</button>

        </div>;
    }

    const onManage = (rowData) => {
        let productId = rowData.id
        setSelectedProduct(rowData)
        setIsManageClick(true);

        getStockData(productId);
        
        // setFilterValue("toDate",currentDate);

    }

    const getStockData = (productId) => {
        greenFieldService.GetStocksOnProduct(productId).then(data => {
            stockTotal = (data.reduce((a, v) => a + parseFloat(v.quantity), 0));
            setStocksByProduct(data);
            setInitialStockData(data);
        });
    }
    const filterValidate=(data)=>{
        if (!data?.fyDate) {
            errors.push('* From Date is required.', <br />);
        }
        if (!data?.toDate) {
            errors.push('* To Date is required', <br />);
        }
    }

    const onFilter = (filterValue) => {
        filterValidate(filterValue)
        if (errors.length > 0) {
            // eslint-disable-next-line no-lone-blocks
            {
                errors.map(() => {
                    toast.current.show({ severity: 'warn', detail: errors, sticky: true });
                }

                )
            }
            errors = [];
            return;
        }
        let data = initialStockData.filter(a => a.addedDateFormat >= filterValue.fyDate && a.addedDateFormat <= filterValue.toDate);
        let srNo = 1;
        data.forEach(d => {
            d.serialNo = srNo;
            srNo++;
        })
        stockTotal = (data.reduce((a, v) => a + parseFloat(v.quantity), 0));

        setStocksByProduct(data);

    }

    const onReset = () => {

        const fields = [
            "fyDate",
            "toDate"
        ];
        fields.forEach((field) => setFilterValue(field, ''));
    }

    const onBack = ()=>{
        setIsManageClick(false);
    }

    let stockFooterGroup = <ColumnGroup>
        <Row>
            <Column footer="Totals:" footerStyle={{ textAlign: 'right' }} />
            <Column footer='' />
            <Column footer={inwardStockSum} />
            <Column footer={distributedStockSum} />
            <Column footer={notReceivedStockSum} />
            <Column footer={returnStockSum}/>
            <Column footer={remainingStockSum} />
            <Column footer='' />
        </Row>
    </ColumnGroup>;

let manageStockFooterGroup = <ColumnGroup>
<Row>
    <Column footer="Totals:" footerStyle={{ textAlign: 'right' }} />
    <Column footer={stockTotal} />
    <Column footer='' />
    <Column footer='' />
    <Column footer='' />
    <Column footer='' />
    <Column footer='' />
</Row>
</ColumnGroup>;

    return (
        <div className="Stock">
            <div className="Unit" style={{ minHeight: '950px' }}>
                <Toast ref={toast} position={'center'} />
                
                <div hidden={!isManageClick} >
                <button  className='reset backButton' onClick={onBack} type="button">Back</button>
                </div>

                <BlockUI blocked={blockedPanel}>
                <div className='box'>
                    <>
                        {isManageClick ? (
                            <div>
                            <h3>Manage Stock : : {selectedProduct?.productName}</h3>                     
                            </div>


                        ) : (
                            <h3 style={{ margin: '0px', padding: '10px' }}>Add Stock</h3>
                        )}
                    </>


                    <div className="formgrid grid align items left">
                        <div style={{ marginRight: '5px', marginLeft: '10px' }}>
                            <div className="field col">
                                {!isManageClick && (
                                    <>
                                        <label htmlFor="Product Name">Product Name:<span style={{ color: 'red' }}>*</span></label>
                                        <Dropdown className='Dropdown' value={selectedProduct} options={productData} id="product" optionLabel="productName" onChange={onChangeProduct} filter showClear placeholder="Select Product" />
                                    </>
                                )}
                                <label htmlFor="Quantity">Enter Quantity:<span style={{ color: 'red' }}>*</span></label>
                                <input id="quantity" type="text" name="quantity" ref={register} autoComplete="off" onInput={inputNoOnly} onChange={onChangeQuantity} className="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary"></input>
                                <label htmlFor="Batch">Batch No:<span style={{ color: 'red' }}>*</span></label>
                                <input id="Batch" type="text" name="batchNo" ref={register} autoComplete="off" className="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary"></input>
                                {/* <div className="invalid-feedback" >{errors.productName?.message} {errors.quantity?.message} </div> */}

                            </div>
                            <div className="field col">


                                <label >Manufacture Date:<span style={{ color: 'red' }}>*</span></label>
                                <input id="ManufactureDate" type="month" name="manufactureDate" autoComplete="off" onChange={onManufactureDate} ref={register} ></input>

                                <label htmlFor="Duration">Expiry Duration:<span style={{ color: 'red' }}>*</span></label>
                                <input id="duration" type="text" name="duration" autoComplete="off" onChange={onDuration} onInput={inputNoOnly} ref={register} ></input>

                                <label >Expiry Date:</label>
                                <input id="expDate" type="month" readOnly name="expDate" autoComplete="off" ref={register} ></input>
                            </div>
                            <div className="field col">
                                <label htmlFor="CompanyName">Company Name:</label>
                                <input id="companyName" type="text" ref={register} name="companyName" readOnly className="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary"></input>

                            </div>
                            <div className="field col">
                            <button className='button' type="button" onClick={handleSubmit(onSave)}>{buttonLabelStock}</button>
                            </div>

                            {/* <div className="field col">

                                </div> */}

                        </div>
                    </div>
                </div>
                </BlockUI>

                {/* <div className='box'> */}

                <div className='box'>
                    {isManageClick ? (
                        <div>
                            <div className="field col">
                                <label>From:</label>
                                <input type='date' name='fyDate' className='date' ref={Filter}  />
                                <label>To:</label>
                                <input type='date' name='toDate' className='date' ref={Filter} />
                                <button type="button filter" className='filter' onClick={handleFilter(onFilter)} >Filter</button>
                                <button type="button" className='reset' onClick={onReset}>Reset</button>
                            </div>


                            <DataTable value={stocksByProduct} scrollable scrollHeight='700px' footerColumnGroup={manageStockFooterGroup} header={header} filters={filters} className="dataTable" editMode="row" >
                                <Column field="serialNo" header="Sr.No"></Column>
                                <Column field="quantity" header="Stock"></Column>
                                <Column field="batchNo" header="Batch"></Column>
                                <Column field="addedDateFormat" header="Added Date"></Column>
                                <Column field="manufactureDate" header="Manufacture Date"></Column>
                                <Column field="expDate" header="Expiry Date"></Column>

                                <Column body={ButtonIcon} field="Action" header="Action"></Column>
                            </DataTable>
                        </div>
                    ) : (
                        <DataTable value={stocksData} filters={filters1} scrollable scrollHeight='700px' footerColumnGroup={stockFooterGroup} filterDisplay="menu" header={stockDataHeader} className="dataTable" editMode="row"   >
                            <Column field="serialNo" header="Sr.No"></Column>
                            <Column field="productName" header="Prodcut Name"></Column>
                            <Column field="inwardStock" header="Inward Stock"></Column>
                            <Column field="distributedStock" header="Distributed Stock"></Column>
                            <Column field="notReceivedStock" header="Distributed- But Not Received"></Column>
                            <Column field="returnStock" header="Return"></Column>
                            <Column field="remainingStock" header="Remaining Stock"></Column>
                            <Column body={ManageButton} field="" header="Action"></Column>

                        </DataTable>
                    )}
                </div>


                {/* </div> */}
            </div>



        </div>
    )

}

export default Stock;