import React, { useState,useRef, useEffect } from 'react';
import 'primeflex/primeflex.css';
import './InvoiceList.css';
import '../App.css';
import { useForm } from "react-hook-form";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { BrowserRouter, useParams } from 'react-router-dom';
import GreenFieldService from '../../src/services/greenFieldService';
import { useHistory } from 'react-router-dom';
import { BlockUI } from 'primereact/blockui';


function InvoiceList() {

    const {
        register: Filter,
        errors: errors1,
        handleSubmit: handleFilter,
        setValue: setFilterValue,
    } = useForm();
    const [globalFilter, setGlobalFilter] = useState();
    const { customerId } = useParams();
    const greenFieldService = new GreenFieldService();
    const [invoiceOfCustomer, setInvoiceOfCutomer] = useState([]);
    let history = useHistory();
    const [blockedPanel, setBlockedPanel] = useState(false);
    var accessLevel = parseInt(sessionStorage.getItem('accessLevel'));
    


    const onPrint =(rowData)=>{
        // history.push('/InvoicePrint');
        history.push(`/InvoicePrint/${rowData.customerId}/${rowData.id}`);
        
    }

    const Buttons=(rowData)=>{
        return <div>
            <button className='reset' type="button" >Manage</button>
            <button className='button' type="button" onClick={() => onPrint(rowData)}>Print</button>


        </div>
    }


    const header = (
        <div className="table-header">
            <div>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" class="globleInput" value={globalFilter} onChange={(e) => setGlobalFilter(e.target.value)} placeholder="Global Search" />
                </span>
            </div>
        </div>
    );

    useEffect(() => {
        greenFieldService.GetAllInvoiceOfCustomer(customerId).then(data => {
            setInvoiceOfCutomer(data);           
        });

        if(accessLevel > 1)
        {
          setBlockedPanel(false);
        }
        else{
            setBlockedPanel(true);
        }

    }, [])
    

    return (
        <BlockUI blocked={blockedPanel}>
        <div className="InvoiceList" >
            <h3>Invoice List</h3>
            <div className="Unit" style={{ minHeight: '1029px' }}>
                <div className='box'>
                    <div class="field col">
                        <label>From:</label>
                        <input type='date' name='fyDate' className='date' ref={Filter} />
                        <label>To:</label>
                        <input type='date' name='toDate' className='date' ref={Filter} />
                        <button type="button filter" className='filter'>Filter</button>
                        <button type="button" className='reset'>Reset</button>
                    </div>
                </div>

                <div className='box'>
                    
                    <div>
                        <button type='button' >Copy</button>
                        <button type='button'>CSV</button>
                        <button type='button'>Excel</button>
                        <button type='button'>PDF</button>
                        <button type='button'>Print</button>
                    </div>
                    <DataTable className='dataTable' globalFilter={globalFilter} header={header} editMode='row' value={invoiceOfCustomer}>
                        <Column field="srNo" header="Sr No"></Column>
                        <Column field="invNo" header="Invoice Number"></Column>
                        <Column field="grandTotal" header="Total Amount"></Column>
                        <Column field="discountAmount" header="Discount"></Column>
                        <Column field="returnAmount" header="Return"></Column>
                        <Column field="dueAmount" header="Due Amount"></Column>
                        <Column field="status" header="Status"></Column>
                        <Column field="invDateFormat" header="Date"></Column>
                        <Column body={Buttons} field="" header="Action"></Column>

                    </DataTable>
                </div>



            </div>

        </div>
        </BlockUI>
    );
}

export default InvoiceList;