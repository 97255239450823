import './StockValueReport.css';
import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import { Dropdown } from 'bootstrap';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { useEffect } from 'react';
import GreenFieldService from '../../services/greenFieldService';
import ToolBoxGrid from '../Misc/ToolBoxGrid';
import { Toast } from 'primereact/toast';
import ProgressSpinBarControl from '../Misc/progressSpinBarControl';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import moment from 'moment';
var greenfieldValueSum;
var otherValueSum;
var totalValueSum

function StockValueReport() {
    const greenFieldService = new GreenFieldService();
    const [date3, setDate3] = useState(null);
    const {
        register: Filter,
        errors: errors1,
        handleSubmit: handleFilter,
        setValue: setFilterValue,
    } = useForm();
    const [columns,setColumns]=useState([{ title: 'Sr No', dataKey: 'serialNo' },{ title: 'Franchise Name', dataKey: 'franchiseName' },{ title: 'GreenField', dataKey:'greenfieldValue' },{ title: 'Other', dataKey: 'otherValue' }, { title: 'Total', dataKey: 'total' }]);
    const [globalFilter, setGlobalFilter] = useState();
    const [stockValueData, setStockValueData] = useState([]);
    const [initialReturnData, setInitialRetunData] = useState([]);


    useEffect(() => {
       
    }, []);

    const onFilter = (filterValue) => {
        greenFieldService.GetFranchiseStockValueData(filterValue.fyDate,filterValue.toDate).then(data => {
            greenfieldValueSum = (data.reduce((a, v) => a + parseFloat(v.greenfieldValue), 0));
            otherValueSum = (data.reduce((a, v) => a + parseFloat(v.otherValue), 0));
            totalValueSum = (data.reduce((a, v) => a + parseFloat(v.total), 0));
            let srNo=1;
            data.forEach(a => {
                a.serialNo=srNo;
                a.greenfieldValue=a.greenfieldValue.toFixed(2);
                a.otherValue=a.otherValue.toFixed(2);
                a.total=a.total.toFixed(2);
                srNo++;
            })


            setStockValueData(data);
        });

    }

    const onReset = () => {

        const fields = [
            "fyDate",
            "toDate"
        ];
        fields.forEach((field) => setFilterValue(field, ''));
        setStockValueData('');
        greenfieldValueSum= 0.00;
        otherValueSum= 0.00;
        totalValueSum= 0.00;
    }

    const header = (
        <div className="table-header">
            <div>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" class="globleInput" value={globalFilter} onChange={(e) => setGlobalFilter(e.target.value)} placeholder="Global Search" />
                </span>
            </div>
        </div>
    );

    let totalFooter = <ColumnGroup>
    <Row>
        <Column footer="Totals:" footerStyle={{ textAlign: 'right' }} />
        <Column footer='' />
        <Column footer={parseFloat(greenfieldValueSum).toFixed(2)} />
        <Column footer={parseFloat(otherValueSum).toFixed(2)} />
        <Column footer={parseFloat(totalValueSum).toFixed(2)} />
    </Row>
    </ColumnGroup>;

    return (
        <div className="StockValue">
            <h3>Stock Value Report</h3>
            <div  style={{ minHeight: '1029px' }}>
                <div className="box">
                    <div class="field col">
                        <label>From:</label>
                        <input type='date' name='fyDate' className='date' ref={Filter} max={moment().format("YYYY-MM-DD")} />
                        <label>To:</label>
                        <input type='date' name='toDate' className='date' ref={Filter} max={moment().format("YYYY-MM-DD")}/>
                        <button type="button filter" className='filter' onClick={handleFilter(onFilter)}>Load Report</button>
                        <button type="button" className='reset' onClick={handleFilter(onReset)}>Reset</button>
                    </div>
                </div>

                <div className='box'>
                    {/* <div>
                        <button type='button'>Copy</button>
                        <button type='button'>CSV</button>
                        <button type='button'>Excel</button>
                        <button type='button'>PDF</button>
                        <button type='button'>Print</button>
                    </div> */}
                    <ToolBoxGrid columns={columns} data={stockValueData} fileName="Stock value"></ToolBoxGrid>
                    <DataTable value={stockValueData}  header={header} globalFilter={globalFilter}  editMode='row' footerColumnGroup={totalFooter}>
                        <Column field="serialNo" header="Sr No" style={{width:'100px'}}></Column>
                        <Column field="franchiseName" header="Franchise Name"></Column>
                        <Column field="greenfieldValue" header="Greenfield" style={{width:'150px'}}></Column>
                        <Column field="otherValue" header="Other" style={{width:'150px'}}></Column>
                        <Column field="total" header="Total" style={{width:'150px'}}></Column>
                    </DataTable>
                </div>
            </div>

        </div>
    );
}
export default StockValueReport;