import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import { Dropdown } from 'bootstrap';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { useEffect } from 'react';
import './FranchiseComplaints.css';
import axios from 'axios';
import GreenFieldService from '../../services/greenFieldService';
import { BlockUI } from 'primereact/blockui';
import ToolBoxGrid from '../Misc/ToolBoxGrid';
import moment from 'moment';

function FarmerComplaints() {
    const {
        register: Filter,
        errors: errors1,
        handleSubmit: handleFilter,
        setValue: setFilterValue,
    } = useForm();
    const [columns,setColumns]=useState([{ title: 'Sr No', dataKey: 'srNo' },{ title: 'Createdate', dataKey: 'formattedDate' },{ title: 'CustomerName', dataKey: 'customerName' },{ title: 'FranchiseName', dataKey: 'franchiseName' },{ title: 'Resolution Comment', dataKey: 'resolutionComments' },{ title: 'Subject', dataKey: 'subject' },{ title: 'Franchise Name', dataKey: 'description' }]);
    const [globalFilter, setGlobalFilter] = useState();
    const [initialReturnData, setInitialRetunData] = useState([]);
    const greenFieldService = new GreenFieldService();
    const franchiseId = localStorage.getItem('franchiseId');

    const [farmerComplaintsData, setfarmerComplaintsData] = useState([]);
    const [farmerComplaintsOriginalData, setFarmerComplaintsOriginalData] = useState([]);
    const [blockedPanel, setBlockedPanel] = useState(false);
    var accessLevel = parseInt(sessionStorage.getItem('accessLevel'));

    const getFarmerComplaints = () => {
        greenFieldService.GetFarmerComplaintsData(-1).then(data => {
            let srNo = 1;
            data.forEach(item => {
                item.srNo = srNo++;
                item.formattedDate = item.createdate.replace("T", " ");
            })
            setfarmerComplaintsData(data);
            setFarmerComplaintsOriginalData(data);
        });
    }

    useEffect(() => {
        let d = moment(new Date()).format('YYYY-MM-DD');
        setFilterValue('fyDate',d);
        setFilterValue('toDate',d);
        getFarmerComplaints();

        if(accessLevel > 1)
        {
          setBlockedPanel(false);
        }
        else{
            setBlockedPanel(true);
        }
    }, [])
    const header = (
        <div className="table-header">
            <div>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" class="globleInput" value={globalFilter} onChange={(e) => setGlobalFilter(e.target.value)} placeholder="Global Search" />
                </span>
            </div>
        </div>
    );

    const onFilter = (filterValue) => {
        let data = farmerComplaintsOriginalData.filter(a => a.createdate >= filterValue.fyDate && a.createdate <= filterValue.toDate);
        let srNo = 1;
        data.forEach(d => {
            d.srNo = srNo;
            srNo++;
        })


        setfarmerComplaintsData(data);
        // setProductsData(data);

    }

    const onReset = () => {

        const fields = [
            "fyDate",
            "toDate"
        ];
        fields.forEach((field) => setFilterValue(field, ''));

        //let data=[...farmerComplaintsOriginalData];

        setfarmerComplaintsData(farmerComplaintsOriginalData);
    }

    return (
        <BlockUI blocked={blockedPanel}>
        <div className="FranchiseComplaints">
            <h3>All Franchise Farmer Complaints</h3>
            <div className="Unit" style={{ minHeight: '1029px' }}>
                <div className="box">
                    <div class="field col">
                        <label>From:</label>
                        <input type='date' name='fyDate' className='date' ref={Filter} />
                        <label>To:</label>
                        <input type='date' name='toDate' className='date' ref={Filter} />
                        <button type="button filter" className='filter' onClick={handleFilter(onFilter)}>Filter</button>
                        <button type="button" className='reset' onClick={onReset}>Reset</button>
                    </div>
                </div>

                <div className='box'>
                    {/* <div>
                        <button type='button'>Copy</button>
                        <button type='button'>CSV</button>
                        <button type='button'>Excel</button>
                        <button type='button'>PDF</button>
                        <button type='button'>Print</button>
                    </div> */}
                    <ToolBoxGrid columns={columns} data={farmerComplaintsData} fileName="franchise farmer Complaints"></ToolBoxGrid>
                    <DataTable className='dataTable' value={farmerComplaintsData} header={header} globalFilter={globalFilter} editMode='row'>
                        <Column field="srNo" header="Sr No"></Column>
                        <Column field="formattedDate" header="Createdate"></Column>
                        <Column field="customerName" header="CustomerName"></Column>
                        {/* <Column field="franchise_Id" header="Franchise_Id"></Column> */}
                        <Column field="franchiseName" header="FranchiseName"></Column>
                        <Column field="resolutionComments" header="Resolution Comment"></Column>
                        <Column field="subject" header="Subject"></Column>
                        <Column field="description" header="Franchise Name"></Column>

                    </DataTable>
                </div>
            </div>

        </div>
        </BlockUI>
    )
}
export default FarmerComplaints;