import React from 'react'

class ExportPdfReport extends React.Component {
    exportPdf = (exportColumns,data,fileName) => {
        import('jspdf').then(jsPDF => {
            import('jspdf-autotable').then(() => {
                const doc = new jsPDF.default(0, 0);
                doc.autoTable(exportColumns, data);
                doc.save(`${fileName}.pdf`);
            })
        }) 
    }

  
}

export default ExportPdfReport