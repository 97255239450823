import { date } from "yup";


export function shouldLockDate(validateDate, lockDate) {
    if (lockDate > validateDate) {
        return true;
    }
    else {
        return false;
    }
}

export const LockDateTimerMilliSecondValue=5000;