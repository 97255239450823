import 'primeicons/primeicons.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';
import React, { useState, useEffect, useContext, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import moment, { invalid } from 'moment';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import ProgressSpinBarControl from '../Misc/progressSpinBarControl';
import '../Franchise/InvoiceReport.css';
import GreenFieldService from '../../services/greenFieldService';
import { useParams } from 'react-router';
import logo from "../../image/greenfield new logo.png";



const franchiseId = parseInt(localStorage.getItem('franchiseId'));
// const customerId = parseInt(localStorage.getItem('customerId'));
// const invNo = parseInt(localStorage.getItem('invNo'));

var converter = require('number-to-words');
var franchiseDetails;
var customerDetail;
var invoiceDetail;
var taxableValueSum;
var centerAmountSum;
var stateAmountSum;
var totalTaxAmountSum;
function ReturnInvoicePrint({ props }) {


    const [BillDate, setBillDate] = useState();
    const { customerId, invNo, franchiseId } = useParams();
    var [spinnerVisible, setSpinnerVisible] = useState(false);
    var converter = require('number-to-words');
    const greenFieldService = new GreenFieldService();
    const [franchiseData, setFranchiseData] = useState([]);
    franchiseDetails = franchiseData;
    const [customerData, setCustomerData] = useState([]);
    customerDetail = customerData;
    const [invoiceData, setInvoiceData] = useState([]);
    invoiceDetail = invoiceData;


    useEffect(() => {
        greenFieldService.GetFranchiseDataOnId(franchiseId).then(data => {
            data.forEach(a => a.franchiseAddress = `${a.address},${a.state}/${a.district}`);
            setFranchiseData(data);
        });

        greenFieldService.GetCustomerDetail(customerId).then(data => {
            data.forEach(a => {
                a.isKisanCardValue = a.isKisanCard ? "Yes" : "No";
            })
            setCustomerData(data);
        });

        greenFieldService.GetReturnInvoice(invNo, franchiseId).then(data => {
            data[0].invoiceReportDetailsInfo.forEach(a => {
                a.taxableRate = parseFloat(a.taxableRate);
                a.totalTaxAmount = parseFloat(a.totalTaxAmount);
                a.stateAmount = parseFloat(a.stateAmount);
                a.centerAmount = parseFloat(a.centerAmount);

            })
            taxableValueSum = (data[0].invoiceReportDetailsInfo.reduce((a, v) => a + parseFloat(v.taxableRate), 0));
            centerAmountSum = (data[0].invoiceReportDetailsInfo.reduce((a, v) => a + parseFloat(v.centerAmount), 0));
            stateAmountSum = (data[0].invoiceReportDetailsInfo.reduce((a, v) => a + parseFloat(v.stateAmount), 0));
            totalTaxAmountSum = (data[0].invoiceReportDetailsInfo.reduce((a, v) => a + parseFloat(v.totalTaxAmount), 0));
            data[0].amountInWord = converter.toWords(Math.round(data[0]?.grandTotal))
            data[0].amountInWord =data[0].amountInWord.toUpperCase();
            let srNo=1;
            data[0].hsnReportDetailsInfo.forEach(a=>{
                a.serialNo=srNo;
                srNo++;
            })

            setInvoiceData(data);

        });



    }, []);

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

   

    return (
        <div className='MemberBill'>
            <div class="card">
                <ProgressSpinBarControl spinBarVisible={spinnerVisible} />
                {/* <Toast ref={toast} position="center" /> */}

                <div className="Form-inline" >
                    {/* <div class="SelectionCard"  > */}

                    <Button label="Print" onClick={handlePrint} />
                </div>
                <MemberBills ref={componentRef} />
            </div>
        </div>
    );

}

const portrait = () => `
footer {
    font-size: 9px;
    color: #f00;
    text-align: center;
  }

  @page {
    size: a5 landscape;
    margin: 5mm 10mm 5mm 10mm  !important;
  }

  @media print {
    footer {
      position: fixed;
      bottom: 0;
    }

    .content-block, p {
      page-break-inside: avoid;
    }

    html, body {
      width: 210mm;
      height: 148mm;

    }
  }
`;

class MemberBills extends React.Component {
    render() {
        // const societyService = new SocietyService();


        return (
            <div class="InvoicePrint">
                <style>{portrait()}</style>

                <div class="Table">
                    <table>
                        <tbody>
                           

                            <tr>
                                <th class="text-center" rowspan="4" style={{ width: '10%' }}>
                                    <img src={logo} alt="logo" style={{ width: '100px' }} />

                                </th>
                                <th class="text-center" rowspan="3" style={{ width: '600px' }}>
                                    <b style={{ height: '50px' }} > {franchiseDetails[0]?.name} </b><br />
                                    <b style={{ marginTop: 'auto' }}> {franchiseDetails[0]?.franchiseAddress} </b><br />
                                    <b> Contact : {franchiseDetails[0]?.mobile1} Email :{franchiseDetails[0]?.email} </b>
                                    <table>
                                        <tbody>
                                            <td style={{ width: '50%' }}>
                                                Place Of supply : Maharashtra ,Code: 27
                                            </td>
                                            <td style={{ width: '50%' }}>
                                                GST :{franchiseDetails[0]?.gstno}
                                            </td>
                                        </tbody>
                                    </table>
                                </th>
                            </tr>
                        </tbody>
                    </table>

                    <table>
                        <tbody>
                            <tr colspan="3">
                                <td class="text-center">{franchiseDetails[0]?.fertilizerLicense}</td>
                                <td class="text-center">{franchiseDetails[0]?.seedLicense}</td>
                                <td class="text-center">{franchiseDetails[0]?.insectisideLicense}</td>
                            </tr>
                        </tbody>
                    </table>

                    <table>
                        <tbody>
                            <tr colspan="2">
                                <td style={{ width: '50%' }}>
                                    Buyer &nbsp; &nbsp;&nbsp;: {customerDetail[0]?.name} &nbsp;&nbsp;  Mobile:{customerDetail[0]?.mobile1} <br />
                                    Address : {customerDetail[0]?.town},{customerDetail[0]?.district},{customerDetail[0]?.state} <br />
                                    {/* Gst No &nbsp; : {franchiseDetails[0]?.gstno}<br /> */}

                                </td>
                                <td style={{ width: '50%' }}>
                                    Return Inv No &nbsp;&nbsp;&nbsp;: {invoiceDetail[0]?.reInvName} &nbsp;&nbsp;&nbsp; Inv No:{invoiceDetail[0]?.invName} <br />
                                    Return Inv Date : {invoiceDetail[0]?.reInvDateFormat} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Invoice Date: {invoiceDetail[0]?.invDateFormat}<br />
                                </td>
                            </tr>

                        </tbody>
                    </table>


                    {invoiceDetail.map((data, index) =>

                        <table>
                            <thead>
                                <tr>
                                    <th style={{ width: '5%' }} class="text-center">Sr No</th>
                                    <th style={{ width: '15%' }} class="text-center">Description</th>
                                    <th style={{ width: '7%' }} class="text-center">HSN</th>
                                    <th style={{ width: '7%' }} class="text-center">GST %</th>
                                    <th style={{ width: '7%' }} class="text-center">Batch</th>
                                    <th style={{ width: '7%' }} class="text-center">Exp.Date</th>
                                    <th style={{ width: '7%' }} class="text-center">Gross Rate</th>
                                    <th style={{ width: '7%' }} class="text-center">Qty</th>
                                    <th style={{ width: '7%' }} class="text-center">Taxable Rate</th>
                                    <th style={{ width: '7%' }} class="text-center">Discount</th>
                                    <th style={{ width: '7%' }} class="text-center">Taxable Amount</th>
                                    {/* <th style={{ width: '7%' }} class="text-center">CGST</th>
                                    <th style={{ width: '7%' }} class="text-center">SGST</th> */}
                                    {/* <th style={{ width: '10%' }} class="text-center">Total</th> */}
                                </tr>
                            </thead>

                            <tbody>
                                {invoiceDetail[index].invoiceReportDetailsInfo.map((item) => (
                                    <tr key={item}>
                                        <td style={{ textAlign: 'right' }}>{item?.srNo}</td>
                                        <td>{item?.productName}</td>
                                        <td style={{ textAlign: 'right' }}>{item?.hsnCode}</td>
                                        <td style={{ textAlign: 'right' }}>{item?.gstRate}</td>
                                        <td style={{ textAlign: 'right' }}>{item?.batchNo}</td>
                                        <td style={{ textAlign: 'right' }}>{new Date(item?.expiryDate).toLocaleDateString('en-En',{ year: 'numeric', month: 'short' })}</td>
                                        <td style={{ textAlign: 'right' }}>{item?.frp.toFixed(2)}</td>
                                        <td style={{ textAlign: 'right' }}>{item?.quantity}</td>
                                        <td style={{ textAlign: 'right' }}>{item?.rate.toFixed(2)}</td>
                                        <td style={{ textAlign: 'right' }}>{item?.actualReturnDiscount.toFixed(2)}</td>
                                        <td style={{ textAlign: 'right' }}>{item?.taxableRate.toFixed(2)}</td>
                                        {/* <td style={{ textAlign: 'right' }}>{item?.centerAmount.toFixed(2)}</td>
                                        <td style={{ textAlign: 'right' }}>{item?.stateAmount.toFixed(2)}</td> */}
                                        {/* <td style={{ textAlign: 'right' }}>{item?.total.toFixed(2)}</td> */}
                                    </tr>
                                ))
                                }
                            </tbody>



                        </table>
                    )}

                    {invoiceDetail.map((data, index) =>
                            <table >
                                <tbody>
                                    <tr>
                                    <th class="text-center" colspan="5" rowspan="2" style={{ width: '4%' }}>Sr No</th>
                                        <th class="text-center" colspan="2" rowspan="2" style={{ width: '15%' }}>Taxable Value</th>
                                        <th colspan="2" class="text-center" style={{ width: '14%' }}>Central Tax</th>
                                        <th colspan="2" class="text-center" style={{ width: '14%' }}>State Tax</th>
                                        <th class="text-center" rowspan="2" style={{ width: '14%' }}>Total Tax Amount</th>

                                        <th class="text-center" rowspan="4" style={{ width: '14%' }}>
                                            <tr style={{ textAlign: 'right' }}>Taxable Total </tr>
                                            <tr style={{ textAlign: 'right'}}>CGST </tr>
                                            <tr style={{ textAlign: 'right'}}>SGST </tr>
                                            <tr style={{ textAlign: 'right' }}>SubTotal </tr>
                                            <tr style={{ textAlign: 'right' }}>Round Off </tr>
                                            <tr style={{ textAlign: 'right' }}>Hamali </tr>
                                            <tr style={{ textAlign: 'right' }}>Transport </tr>
                                            
                                        </th>
                                        <th class="text-center" rowspan="4" style={{ width: '7%' }}>
                                             <tr style={{ textAlign: 'right' ,display:'block' }}>{taxableValueSum.toFixed(2)}</tr>
                                            <tr style={{ textAlign: 'right' ,display:'block' }}>{centerAmountSum.toFixed(2)}</tr>
                                            <tr style={{ textAlign: 'right' ,display:'block' }}>{stateAmountSum.toFixed(2)}</tr>
                                            <tr style={{ textAlign: 'right' ,display:'block' }}>{invoiceDetail[index]?.grandTotal.toFixed(2)}</tr>
                                            <tr style={{ textAlign: 'right' ,display:'block' }}>{invoiceDetail[index]?.roundUp.toFixed(2)}</tr>
                                            <tr style={{ textAlign: 'right' ,display:'block' }}>{invoiceDetail[index]?.hamali.toFixed(2)}</tr>
                                            <tr style={{ textAlign: 'right' ,display:'block' }}>{invoiceDetail[index]?.transport.toFixed(2)}</tr>
                                            
                                            
                                        </th>
                                        

                                    </tr>

                                    <tr>
                                        <td colspan="1" style={{ width: '7%' }} class="text-center">Rate</td>
                                        <td colspan="1" style={{ width: '7%' }} class="text-center">Amount</td>
                                        <td colspan="1" style={{ width: '7%' }} class="text-center">Rate</td>
                                        <td colspan="1" style={{ width: '7%' }} class="text-center">Amount</td>

                                    </tr>



                                    {invoiceDetail[index].hsnReportDetailsInfo.map((item) => (

                                        <tr key={item}>
                                            <td colspan="5" style={{ textAlign: 'right' }}>{item.serialNo}</td>
                                            <td colspan="2" style={{ textAlign: 'right' }}>{item?.taxableRate.toFixed(2)}</td>
                                            <td style={{ textAlign: 'right' }}>{item?.cgst}</td>
                                            <td style={{ textAlign: 'right' }}>{item?.centerAmount.toFixed(2)}</td>
                                            <td style={{ textAlign: 'right' }}>{item?.sgst}</td>
                                            <td style={{ textAlign: 'right' }}>{item?.stateAmount.toFixed(2)}</td>
                                            <td style={{ textAlign: 'right' }}>{item?.totalTaxAmount.toFixed(2)}</td>
                                       
                                        </tr>
  
                                    ))}
                                    

                                    <tr>
                                        <th colspan="5">Total</th>
                                        <th colspan="2" style={{ textAlign: 'right' }}>{taxableValueSum.toFixed(2)}</th>
                                        <th style={{ textAlign: 'right' }}></th>
                                        <th style={{ textAlign: 'right' }}>{centerAmountSum.toFixed(2)}</th>
                                        <th style={{ textAlign: 'right' }}></th>
                                        <th style={{ textAlign: 'right' }}>{stateAmountSum.toFixed(2)}</th>
                                        <th style={{ textAlign: 'right' }}>{totalTaxAmountSum.toFixed(2)}</th>
                                        {/* <th></th>
                                        <th></th> */}
                                    </tr>
                                </tbody>
                            </table>


                    )}



                    {invoiceDetail.map((data, index) =>

                    <table>
                        <tbody>
                            <tr colspan="3">
                                <td style={{ width: '62%' }}><b>AMOUNT IN WORD :{invoiceDetail[index].amountInWord} RUPPES ONLY </b></td>
                                <td style={{ width: '14%' }}>Including Tax Total</td>
                                <td style={{ width: '7%', textAlign: 'right'}}><b>{invoiceDetail[index]?.grandTotal.toFixed(2)}</b></td>

                            </tr>
                        </tbody>
                    </table>
                    )}

                    <table>
                        <tbody>
                            <tr>
                                <td >Here Declare that this invoice shows the actual price of the goods described and that all particulars are true and correct</td>
                            </tr>
                        </tbody>
                    </table>

                    <table>
                        <tbody>
                            <tr colspan="3">
                                <td rowspan="3" >
                                    &nbsp;<br />
                                    &nbsp;<br />
                                    <b style={{ textAlign: 'right' }}>Receiver Sign</b>
                                </td>
                                <td style={{ width: '40%' }} >
                                    Our Bank Name &nbsp;: &nbsp; <b>ICICI BANK </b> <br />
                                    Account Number :&nbsp;&nbsp; <b>645205500482 </b><br />
                                    Branch & IFSC &nbsp;&nbsp; :&nbsp; <b>PHALTAN & ICIC0006452</b>
                                </td>

                                <td>
                                    &nbsp;<br />
                                    &nbsp;<br />
                                    <b style={{ textAlign: 'end' }}>For {franchiseDetails[0]?.name}</b>
                                </td>

                            </tr>
                        </tbody>
                    </table>
                    {/* )} */}
                </div>
            </div >

        )
    }
}
export default ReturnInvoicePrint;