// import './AdminStockReturn.css';
import '../../App.css';
import 'primeflex/primeflex.css';
import { Calendar } from 'primereact/calendar';
import React, { useState, useEffect, useRef } from 'react';
import { useForm } from "react-hook-form";
import { Dropdown } from 'bootstrap';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import GreenFieldService from '../../services/greenFieldService';
import { useHistory } from 'react-router-dom';
import ExportCsvReport from '../../exportReport/exportCsvReport';
import ExportExcelReport from '../../exportReport/exportExcelReport';
import ExportPdfReport from '../../exportReport/exportPdfReport';
import { Toast } from 'primereact/toast';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { BlockUI } from 'primereact/blockui';
import moment from 'moment';
import ProgressSpinBarControl from '../Misc/progressSpinBarControl';
import ToolBoxGrid from '../Misc/ToolBoxGrid';
var netAmountSum;
var royaltySum;
function AllInvoices() {

    const [date3, setDate3] = useState(null);
    const toast = useRef(null);
    const {
        register: Filter,
        handleSubmit: handleFilter,
        setValue: setFilterValue,
    } = useForm();
    const [filters1, setFilters1] = useState(null);
    const [globalFilter, setGlobalFilter] = useState();
    const [invoicesData, setInvoicesData] = useState();
    const [initialData, setInitialData] = useState();

    const greenFieldService = new GreenFieldService();
    const exportCsvReport = new ExportCsvReport();
    const exportPdfReport = new ExportPdfReport();
    const exportExcelReport = new ExportExcelReport();
    let history = useHistory();
    const dt = useRef(null);
    const isAdmin = sessionStorage.getItem('IsAdmin');
    const franchiseId = localStorage.getItem('franchiseId');
    const [blockedPanel, setBlockedPanel] = useState(false);
    var accessLevel = parseInt(sessionStorage.getItem('accessLevel'));
    const [spinnerVisible, setSpinnerVisible] = useState(false);

    useEffect(() => {
        let d = moment(new Date()).format('YYYY-MM-DD');
        setFilterValue('fyDate',d);
        setFilterValue('toDate',d);
        
        // GetAllInvoiceDetails(franchiseId);
        initFilters1();

        if (accessLevel > 1) {
            setBlockedPanel(false);
        }
        else {
            setBlockedPanel(true);
        }

    }, [])

    const GetAllInvoiceDetails = (franchiseId,fromDate,toDate) => {
        let boolString = isAdmin; 
        let isAdminValue = (boolString.toLowerCase() === "true"); 
        setSpinnerVisible(true);
        greenFieldService.GetInvoiceList(!isAdminValue ? franchiseId : -1,fromDate,toDate).then(data => {
            let srNo = 1;
            data.forEach(d => {
                d.serialNo = srNo;
                srNo++;
                d.invoiceDate= d.invoiceDate.replace('T', ' ');
                d.invDateValue=new Date(d.invoiceDate);
            })
            netAmountSum = (data.reduce((a, v) => a + parseFloat(v.dueAmount), 0));
            royaltySum = (data.reduce((a, v) => a + parseFloat(v.royalty), 0));
            setInvoicesData(data);
            setInitialData(data);
        }).catch(error => {
            toast.current.show({ severity: 'error', sticky: true, summary: 'Error', detail: 'Error in getting All Invoices Data' });
        })
        .finally(() => {
            setSpinnerVisible(false);
        });
    }

    const onChangeGlobal = (e) => {
        let value = e.target.value;
        let _filters1 = { ...filters1 };
        _filters1['global'].value = value;

        setFilters1(_filters1);

        setGlobalFilter(value);
    }

    const initFilters1 = () => {
        setFilters1({
            'global': { value: null, matchMode: FilterMatchMode.CONTAINS },

        });
        setGlobalFilter('');
    }

    const cols = [
        { field: 'serialNo', header: 'Sr.No' },
        { field: 'invName', header: 'Invoice Number' },
        { field: 'customerName', header: 'Customer' },
        { field: 'customerAddress', header: 'Address' },
        // { field: 'grandTotal', header: 'Total Amount' },
        // { field: 'discountAmount', header: 'Discount' },
        // { field: 'returnAmount', header: 'Return' },
        { field: 'invDateFormat', header: 'Date' },
        { field: 'dueAmount', header: 'Due Amount' },
        { field: 'royalty', header: 'Royalty' },


    ];
    const exportColumns = cols.map(col => ({ title: col.header, dataKey: col.field }));

    var errors = [];

    const filterValidate = (data) => {
        if (!data?.fyDate) {
            errors.push('* From Date is required.', <br />);
        }
        if (!data?.toDate) {
            errors.push('* To Date is required', <br />);
        }
    }

    const onLoad = (filterValue) => {
        // let fromdate=new Date(filterValue.fyDate)  ;
        // fromdate.setDate(fromdate.getDate() - 1);
        // let toDate=new Date(filterValue.toDate);
        filterValidate(filterValue)
        if (errors.length > 0) {
            // eslint-disable-next-line no-lone-blocks
            {
                errors.map(() => {
                    toast.current.show({ severity: 'warn', detail: errors, sticky: true });
                }

                )
            }
            errors = [];
            return;
        }
        // let data = initialData.filter(a =>a.invDateValue >= fromdate && a.invDateValue <= toDate);
        // let srNo = 1;
        // data.forEach(d => {
        //     d.serialNo = srNo;
        //     srNo++;
        // })
        // netAmountSum = (data.reduce((a, v) => a + parseFloat(v.dueAmount), 0));
        // royaltySum = (data.reduce((a, v) => a + parseFloat(v.royalty), 0));

        // setInvoicesData(data);
        GetAllInvoiceDetails(franchiseId,filterValue.fyDate,filterValue.toDate)

    }

    const onReset = () => {

        const fields = [
            "fyDate",
            "toDate"
        ];
        fields.forEach((field) => setFilterValue(field, ''));
        // GetAllInvoiceDetails(franchiseId);

    }



    const buttons = (rowData) => {
        return <div>

            {/* <button className='reset' type="button" onClick={() => onManage(rowData)}>Manage Invoice</button> */}
            <button className=' button' type="button" onClick={() => onPrint(rowData)}>Print</button>


        </div>;
    }

    const onManage = () => {

    }

    let totalFooter = <ColumnGroup>
        <Row>
            <Column footer="Totals:" footerStyle={{ textAlign: 'right' }} />
            <Column footer='' />
            <Column footer='' />
            <Column footer='' />
            <Column footer={netAmountSum} />
            <Column footer={royaltySum} />
            <Column footer='' />

        </Row>
    </ColumnGroup>;

    const onPrint = (rowData) => {
        // history.push('/InvoicePrint');
        // history.push(`/InvoicePrint/${rowData.customerId}/${rowData.franchiseId}/${rowData.categoryId}/${rowData.invNo}`);
        history.push(`/InvoicePrint/${rowData.customerId}/${rowData.invNo}/${rowData.franchiseId}`);
    }

    const header = (
        <div className="table-header">
            <div>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" className="globleInput" value={globalFilter} onChange={onChangeGlobal} placeholder="Global Search" />
                </span>
            </div>
        </div>
    );




    return (
        <BlockUI blocked={blockedPanel}>
            <div className="AdminStockReturn">
            <ProgressSpinBarControl spinBarVisible={spinnerVisible} />
            <Toast ref={toast} position={'center'} />
                <h3>Invoice List</h3>
                <div className="Unit" style={{ minHeight: '870px' }}>
                    <div className="box">
                        <div class="field col">
                            <label>From:</label>
                            <input type='date' name='fyDate' className='date' ref={Filter} />
                            <label>To:</label>
                            <input type='date' name='toDate' className='date' ref={Filter} />
                            <button type="button filter" onClick={handleFilter(onLoad)} className='filter'>Load Invoices</button>
                            <button type="button" onClick={onReset} className='reset'>Reset</button>
                        </div>
                        {/* <div>
                        
                        Show
                        <select className='dropdown'>
                            <option>50</option>
                            <option>100</option>
                            <option>All</option>
                        </select>
                        entries
                    </div> */}
                    </div>

                    <div className='box'>
                        {/* <div>
                            <button type='button' onClick={() => exportCSV(false)}>CSV</button>
                            <button type='button' onClick={() => exportExcel()}>Excel</button>
                            <button type='button' onClick={() => exportPdf()}>PDF</button>
                        </div> */}
                        <ToolBoxGrid columns={exportColumns} data={invoicesData} fileName="Franchise Invoices Report"></ToolBoxGrid>
                        <DataTable ref={dt} value={invoicesData} resizableColumns={true} scrollHeight='650px' header={header} filters={filters1} globalFilter={globalFilter} footerColumnGroup={totalFooter} editMode='row'>
                            <Column field="serialNo" header="Sr No" style={{width:'10px'}}></Column>
                            <Column field="invName" header="Invoice Number" style={{width:'150px'}}></Column>
                            <Column field="customerName" header="Customer" style={{width:'300px'}}></Column>
                            <Column field="invDateFormat" header="Invoice Date" style={{width:'150px'}}></Column>

                            {/* <Column field="customerAddress" header="Address"></Column>
                        <Column field="grandTotal" header="Total Amount"></Column>
                        <Column field="discountAmount" header="Discount"></Column>
                        <Column field="returnAmount" header="Return"></Column> */}
                            <Column field="dueAmount" header="Net Amount" style={{width:'150px'}}></Column>
                            <Column field="royalty" header="Royalty" style={{width:'150px'}}></Column>
                            {/* <Column field="status" header="Status"></Column> */}
                            <Column body={buttons} header="Action" style={{width:'150px'}}></Column>
                        </DataTable>
                    </div>
                </div>
            </div>
        </BlockUI>
    );
}
export default AllInvoices;