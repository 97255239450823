import './ProductReport.css';
import React, { useState,useRef} from 'react';
import { useForm } from "react-hook-form";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { useEffect } from 'react';
import GreenFieldService from '../../services/greenFieldService';
import ToolBoxGrid from '../Misc/ToolBoxGrid';
import { Dropdown } from 'primereact/dropdown';
import moment from 'moment';
import { Toast } from 'primereact/toast';
import ProgressSpinBarControl from '../Misc/progressSpinBarControl';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';

var qtySum;
var taxableValueSum;
var cgstSum;
var sgstSum;
var totalAmountSum;

function ReturnProductReport() {
    const greenFieldService = new GreenFieldService();
    const [date3, setDate3] = useState(null);
    const {
        register: Filter,
        errors: errors1,
        handleSubmit: handleFilter,
        setValue: setFilterValue,
    } = useForm();
    const [columns,setColumns]=useState([{ title: 'Sr No', dataKey: 'serialNo' },{ title: 'Product Name', dataKey: 'productName' },{ title: 'Company', dataKey:'company' },{ title: 'HSN', dataKey: 'hsn' }, {title: 'Gst Rate', dataKey:'gstRate' },{ title: 'Quantity', dataKey: 'quantity' }, { title: 'Taxable Value', dataKey: 'taxableValue' },{ title: 'CGST', dataKey: 'cGst' }
    ,{ title: 'SGST', dataKey: 'sGst' },{ title: 'Total Amount', dataKey: 'totalAmount' }]);
    const [globalFilter, setGlobalFilter] = useState();
    const [productwiseData, setProductsWiseData] = useState([]);
    const [franchiseData, setFranchiseData] = useState([]);
    const [franchise, setfranchise] = useState();
    const toast = useRef(null);
    const [spinnerVisible, setSpinnerVisible] = useState(false);


    useEffect(() => {

        greenFieldService.GetFranchiseData().then(data => {
            data.splice(0, 0, { id:-1,name: 'All' })
            setFranchiseData(data);
        });       

       
    }, []);

    var errors = [];

    const filterValidate = (data) => {
        if (!data?.fyDate) {
            errors.push('* From Date is required.', <br />);
        }
        if (!data?.toDate) {
            errors.push('* To Date is required', <br />);
        }
    }

    const onFilter = (filterValue) => {
        filterValidate(filterValue)
        if (errors.length > 0) {
            // eslint-disable-next-line no-lone-blocks
            {
                errors.map(() => {
                    toast.current.show({ severity: 'warn', detail: errors, sticky: true });
                }

                )
            }
            errors = [];
            return;
        }
        setSpinnerVisible(true);

        greenFieldService.GetReturnProductWiseReportData(filterValue.fyDate,filterValue.toDate,franchise.id).then(data => {
            qtySum = (data.reduce((a, v) => a + parseFloat(v.quantity), 0));
            taxableValueSum = (data.reduce((a, v) => a + parseFloat(v.taxableValue), 0));
            cgstSum = (data.reduce((a, v) => a + parseFloat(v.cGst), 0));
            sgstSum = (data.reduce((a, v) => a + parseFloat(v.sGst), 0));
            totalAmountSum = (data.reduce((a, v) => a + parseFloat(v.totalAmount), 0));

            let srNo=1
            data.forEach(a => {
                a.serialNo=srNo;
                srNo++

                a.cGst=a.cGst.toFixed(2);
                a.sGst=a.sGst.toFixed(2);
                a.totalAmount=a.totalAmount.toFixed(2);
                a.taxableValue=a.taxableValue.toFixed(2);
                a.gstRate=a.gstRate.toFixed(2);
            })
            setProductsWiseData(data);
            setSpinnerVisible(false);

            // setInitialRetunData(data);
        });
        // let data = initialReturnData.filter(a => a.addedDateFormat >= filterValue.fyDate && a.addedDateFormat <= filterValue.toDate);
        // let srNo = 1;
        // data.forEach(d => {
        //     d.serialNo = srNo;
        //     srNo++;
        // })
        // setProductsWiseData(data);

    }

    const onReset = () => {

        const fields = [
            "fyDate",
            "toDate"
        ];
        fields.forEach((field) => setFilterValue(field, ''));
        setProductsWiseData('');
        qtySum= 0.00
        taxableValueSum= 0.00
        cgstSum= 0.00
        sgstSum= 0.00
        totalAmountSum= 0.00
    }

    let totalFooter = <ColumnGroup>
    <Row>
        <Column footer="Totals:" footerStyle={{ textAlign: 'right' }} />
        <Column footer='' />
        <Column footer='' />
        <Column footer='' />
        <Column footer='' />
        <Column footer={parseFloat(qtySum).toFixed(2)} />
        <Column footer={parseFloat(taxableValueSum).toFixed(2)} />
        <Column footer={parseFloat(cgstSum).toFixed(2)} />
        <Column footer={parseFloat(sgstSum).toFixed(2)} />
        <Column footer={parseFloat(totalAmountSum).toFixed(2)} />
    </Row>
    </ColumnGroup>;

    const header = (
        <div className="table-header">
            <div>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" class="globleInput" value={globalFilter} onChange={(e) => setGlobalFilter(e.target.value)} placeholder="Global Search" />
                </span>
            </div>
        </div>
    );

    const onFranchiseChange = (e) => {
        setfranchise(e.value);
    }

    return (
        <div className="ProductReport">
        <ProgressSpinBarControl spinBarVisible={spinnerVisible} />
            <Toast ref={toast} position={'center'} />
            <h3>Sales Return Products Report</h3>
            <div style={{ minHeight: '1029px' }}>
                <div className="box">
                    <div class="field col">
                        <Dropdown className="Dropdown" value={franchise} options={franchiseData} id="name" optionLabel="name" onChange={onFranchiseChange} showClear placeholder="Select Franchise"/>
                        <label>From:</label>
                        <input type='date' name='fyDate' className='date' ref={Filter} max={moment().format("YYYY-MM-DD")}  />
                        <label>To:</label>
                        <input type='date' name='toDate' className='date' ref={Filter} max={moment().format("YYYY-MM-DD")} />
                        <button type="button filter" className='filter' onClick={handleFilter(onFilter)}>Load Report</button>
                        <button type="button" className='reset' onClick={handleFilter(onReset)}>Reset</button>
                    </div>
                </div>

                <div className='box'>
              
                    <ToolBoxGrid columns={columns} data={productwiseData} fileName="Return products"></ToolBoxGrid>
                    <DataTable value={productwiseData}  header={header} globalFilter={globalFilter} editMode='row' footerColumnGroup={totalFooter}>
                        <Column field="serialNo" header="Sr No"></Column>
                        <Column field="productName" header="Product Name"></Column>
                        <Column field="company" header="Company"></Column>
                        <Column field="hsn" header="HSN"></Column>
                        <Column field="gstRate" header="GST Rate"></Column>
                        <Column field="quantity" header="Qty"></Column>
                        <Column field="taxableValue" header="Taxable Value"></Column>
                        <Column field="cGst" header="CGST"></Column>
                        <Column field="sGst" header="SGST"></Column>
                        <Column field="totalAmount" header="Total Amount"></Column>


                    </DataTable>
                </div>
            </div>

        </div>
    );
}
export default ReturnProductReport;