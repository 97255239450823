//import React, { useEffect, useState } from 'react';
import 'primeflex/primeflex.css';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from "react-hook-form";
import React, { useState, useEffect, useRef } from 'react';
import './Products.css';
import { Button } from 'primereact/button';
import axios from 'axios';
import GreenFieldService from '../services/greenFieldService';
import Config from '../Config.json';
import { Toast } from 'primereact/toast';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { BlockUI } from 'primereact/blockui';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { useTabIndex } from 'react-tabindex';
import ToolBoxGrid from './Misc/ToolBoxGrid';
import authHeader from '../authHeader';


function Products() {
    const greenFieldService = new GreenFieldService();
    const toast = useRef(null);
    const [columns,setColumns]=useState([{ title: 'SrNo', dataKey: 'srNo' },{ title: 'Name', dataKey: 'mergedProductName' },{ title: 'Category', dataKey: 'categoryName' },{ title: 'Packing', dataKey: 'packingSize' },{ title: 'FR', dataKey: 'salePrice' },{ title: 'MRP', dataKey: 'mrp' },{ title: 'Royalty', dataKey: 'royaltyRate' },{ title: 'Tax(in %)', dataKey: 'taxRate' },{ title: 'HSN', dataKey: 'hsnCode' },{ title: 'Type', dataKey: 'type' },{ title: 'Discount', dataKey: 'discountValue' },{ title: 'Company Name', dataKey: 'companyName' }]);
    const [categories, setCategoryData] = useState([]);
    const tabIndex = useTabIndex();
    const [selectedCategory, setSelectedCategory] = useState();
    const [globalFilter, setGlobalFilter] = useState('');
    const {  register, handleSubmit, reset, setValue } =
        useForm(
            // { resolver: yupResolver(validationSchema) }
        );
    const [unitData, setUnitsData] = useState([]);
    const [productData, setProductsData] = useState([]);
    const [selectedRadioData, setSelectedRadioData] = useState(null);
    const [isAddMode, setIsAddMode] = useState(true);
    const [buttonLabel, setButtonLabel] = useState("Save");
    const [SelectedUnit, setSelectedUnit] = useState("");
    const [checked, setChecked] = useState(true);
    const [selectedType, setSelectedType] = useState();
    const [selectedCompany, setSelectedCompany] = useState();
    const [rowDataValue, setRowData] = useState();
    const [initialData, setInitialData] = useState();
    const [filters1, setFilters1] = useState(null);
    const [blockedPanel, setBlockedPanel] = useState(false);
    const [isDisabled,setIsDisabled]=useState(false);

var accessLevel = parseInt(sessionStorage.getItem('accessLevel'));



    useEffect(() => {

        greenFieldService.GetUnitsData().then(data => {
            setUnitsData(data);
        });

        greenFieldService.GetCategoryData().then(data => {
            setCategoryData(data);
        });

        getProductDetails();
        initFilters1();

        if(accessLevel > 1)
        {
          setBlockedPanel(false);
          setIsDisabled(false);
        }
        else{
            setBlockedPanel(true);
            setIsDisabled(true);

        }

    }, []);

    const getProductDetails = () => {
        greenFieldService.GetAllProductsData().then(data => {
            let serialNo = 1;
            data.forEach(a=>{
                a.mergedProductName=`${a.name}-${a.packingSize}${a.unitName}`
                a.srNo = serialNo;
                serialNo++;
            })
            setProductsData(data);
            setInitialData(data);
        });
    }


    const Types = [
        { type: 'Percentage' },
        { type: 'Absolute' },
      ];

    const Company = [
        { companyName: 'Greenfield' },
        { companyName: 'Other' },
      ];      


    const onChangeCategory = (e) => {
        setSelectedCategory(e.value);
    }

    const onChangeUnit = (e) => {
        setSelectedUnit(e.value);
    }

    const onChangeType =(e)=>{
        setSelectedType(e.value);
        setValue('discountValue',0);
    }
    const onChangeCompany =(e)=>{
        setSelectedCompany(e.value);
    }
    const inputNoOnly = (e) => {
        if(e.target.id === 'packingSize'){
        const onlyNums = e.target.value.replace(/[^0-9.]/g, '');
        setValue('packingSize', onlyNums);
        }
        if(e.target.id ==='frp')
        {
            const onlyNums = e.target.value.replace(/[^0-9.]/g, '');
            setValue('salePrice', onlyNums);
        }
        if(e.target.id ==='mrp')
        {
            const onlyNums = e.target.value.replace(/[^0-9.]/g, '');
            setValue('mrp', onlyNums);
        }
        if(e.target.id ==='royalty')
        {
            const onlyNums = e.target.value.replace(/[^0-9.]/g, '');
            setValue('royaltyRate', onlyNums);
        }
        if(e.target.id ==='tax')
        {
            const onlyNums = e.target.value.replace(/[^0-9.]/g, '');
            setValue('taxRate', onlyNums);
        }
        if(e.target.id ==='alertQuantity')
        {
            const onlyNums = e.target.value.replace(/[^0-9.]/g, '');
            setValue('alertQty', onlyNums);
        }
        if(e.target.id ==='discountValue')
        {
            const onlyNums = e.target.value.replace(/[^0-9.]/g, '');
            setValue('discountValue', onlyNums);
        }
      }
    var errors = [];

    const validate = (data) => {

        if (!data?.name) {
            errors.push('Product Name is required.',<br/>);
        }

        if (!data?.packingSize) {
            errors.push('Packing size is required',<br/>);
        }
        if (!data?.salePrice) {
            errors.push('Framer Selling Price is required.',<br/>);
        }
        if (!data?.taxRate) {
            errors.push('Tax Rate is required',<br/>);
        }
        if (!selectedType) {
            errors.push('Discount Type is required',<br/>);
        }
        if (!selectedCategory?.id) {
            errors.push('Category is required.',<br/>);
        }
        if (!SelectedUnit?.id) {
            errors.push('Unit is required',<br/>);
        }
        if (!data?.mrp) {
            errors.push('Mrp is required',<br/>);
        }
        if (!data?.hsnCode) {
            errors.push('HsnCode is required.',<br/>);
        }
        if (!data?.alertQty) {
            errors.push('Alert Quantity is required',<br/>);
        }
        if (!selectedType) {
            errors.push(' Discount type is required.',<br/>);
        }
        if (!data?.discountValue) {
            errors.push('Discount Value is required.',<br/>);
        }
        if (!selectedCompany) {
            errors.push('Company Name is required.',<br/>);
        }
        // return errors;
    };

    const Reset=()=>{
        const fields = [
            "name",
            "packingSize",
            "salePrice",
            "mrp",
            "royaltyRate",
            "taxRate",
            "hsnCode",
            "alertQty",
            "categoryName",
            "discountValue"

        ];
        fields.forEach((field) => setValue(field, ''));
        setSelectedCategory('');
        setSelectedUnit('');
        setSelectedType('');
        setSelectedCompany('');
    }

    const onSave = (data) => {
        console.warn(data);
        validate(data);
        if(errors.length > 0){
            // eslint-disable-next-line no-lone-blocks
            {errors.map(() => {
                toast.current.show({ severity: 'warn', detail: errors, sticky: true });
            }

            )}
            errors=[];
            return;
        }
        data.isActive = checked;
        data.categoryId = selectedCategory?.id;
        data.unitId = SelectedUnit?.id;
        data.type=selectedType?.type;
        data.companyName=selectedCompany.companyName;
        if(data.royaltyRate === ''){
            data.royaltyRate=0;
        }
        return isAddMode ? addProducts(data) : updateProducts(data);
    }
    const addProducts = (data) => {

        // setSpinnerVisible(true);
        return axios.post(`${Config.apiUrl}/Products/InsertProduct`, data, { headers: authHeader() })
            .then(response => {
                if (response.data.success) {
                    toast.current.show({ severity: 'success', detail: 'Data Saved Successfully', autoDismiss:true });
                    Reset();
                }
                else {
                    toast.current.show({ severity: 'error', detail: response.data.msg, sticky: true });
                }
            }).catch(error => {
                toast.current.show({ severity: 'error', sticky: true, summary: 'Error', detail: error });
            })
            .finally(() => {
                getProductDetails();
            })
    }

    const updateProducts = (data) => {

        // setSpinnerVisible(true);
        data.id = rowDataValue.id;
        return axios.put(`${Config.apiUrl}/Products/UpdateProduct`, data, { headers: authHeader() })
            .then(response => {
                if (response.data.success) {
                    toast.current.show({ severity: 'success', detail: 'Data Update Successfully', autoDismiss:true });
                    Reset();
                    setButtonLabel('Save');
                }
                else {
                    toast.current.show({ severity: 'error', detail: response.data.msg, sticky: true });
                }
            }).catch(error => {
                toast.current.show({ severity: 'error', sticky: true, summary: 'Error', detail: error });
            })
            .finally(() => {
                getProductDetails();
                setIsAddMode(true);

            })
    }

    const onEdit = (rowData) => {
        setIsAddMode(false);
        var data = rowData;
        let id = data.id;
        const fields = [
            "name",
            "packingSize",
            "salePrice",
            "mrp",
            "royaltyRate",
            "taxRate",
            "hsnCode",
            "alertQty",
            "categoryName",
            "discountValue"

        ];
        fields.forEach((field) => setValue(field, data[field]));
        setChecked(data.isActive);
        // data.name=data.categoryName;
        setSelectedCategory({id: data.categoryId, name: data.categoryName});
        console.warn(selectedCategory)
        setSelectedUnit({ id: data.unitId, name: data.unitName });
        setSelectedType({ type: data.type});
        setSelectedCompany({companyName:data.companyName})
        setRowData(rowData);
        setButtonLabel("Update");
        setValue('expDate', data.expDateFormat);

    }
    // const onClear=()=>{
    //     getProductDetails()
    // }
    const onChangeGlobal=(e)=>{
        let value=e.target.value;
       let _filters1 = { ...filters1 };
       _filters1['global'].value = value;

       setFilters1(_filters1);
       
        setGlobalFilter(value);
    }

    
    const initFilters1 = () => {
        setFilters1({
            'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
            
        });
        setGlobalFilter('');
    }
    const clearFilter1 = () => {
        initFilters1();
    }
    const header = (

        <div className="table-header">
            <div>
            {/* <Button type="button" icon="pi pi-filter-slash" label="Clear" className="p-button-outlined" onClick={clearFilter1} /> */}
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    {/* <InputText type="text" value={globalFilter} onChange={(e) => setGlobalFilter(e.target.value)}  placeholder="Global Search" /> */}
                    <InputText  value={globalFilter} onChange={onChangeGlobal}  placeholder="Global Search" />

                </span>
            </div>
        </div>
    );

    const onDelete = (rowData) => {
        toast.current.show({
            severity: 'warn', sticky: true, content: (
                <div className="p-flex p-flex-column" style={{ flex: '1' }}>
                    <div className="text-center">
                        <i className="pi pi-exclamation-triangle" style={{ fontSize: '3rem' }}></i>
                        <h4>Are you sure?</h4>
                        <p>Confirm to Delete</p>
                    </div>
                    <div className="grid p-fluid">
                        <div className="col-6">
                            <Button type="button" label="Yes" className="p-button-success" onClick={() => onYes(rowData)} />
                        </div>
                        <div className="col-6">
                            <Button type="button" label="No" className="p-button-secondary" onClick={() => onNo()} />
                        </div>
                    </div>
                </div>
            )
        });
    }

    const onYes = (rowData) => {
        toast.current.clear();
        axios.delete(`${Config.apiUrl}/Products/Delete?id=${rowData.id}`, { headers: authHeader() })
            .then(response => {
                console.log(response)
                if (response.data.success) {
                    toast.current.show({ severity: 'success',sticky: true, detail: response.data.msg ,autoDismiss:true});
                }
                else {
                    toast.current.show({ severity: 'error', sticky: true, detail: response.data.msg });
                }
            })
            .catch(error => {
                toast.current.show({ severity: 'error', sticky: true, summary: 'Error', detail: error });
            })
            .finally(() => {
                getProductDetails();

            })
    }

    const onNo = () => {
        toast.current.clear();
        return;
    };

    const buttons = (rowData) => {
        return <div>
            <Button icon="pi pi-pencil" className="p-button-rounded p-button-info" disabled={isDisabled} onClick={() => onEdit(rowData)} aria-label="Bookmark" />
            <Button icon="pi pi-trash" className="p-button-rounded p-button-danger" disabled={isDisabled} onClick={() => onDelete(rowData)} aria-label="Bookmark" />
        </div>;
    }



    return (
        <div className="Products">
            <Toast ref={toast} position={'center'} />
            <div className="box ">
            <BlockUI blocked={blockedPanel}>
                <h3>Add Products</h3>
                <div style={{ display: 'flex' }}>
                    <div style={{ marginRight: '50px', marginleft: '10px' }}>
                        <div className="field col">
                            <label >Name<span style={{ color: 'red' }}>*</span></label>
                            <input id="Name" type="text" name="name" ref={register } tabIndex="1" autoComplete='off' className="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary "></input>
                        </div>
                        {/* <div className="Invalid">
                          {errors.name?.message}
                        </div> */}

                        <div className="field col">
                            <label >Packing/Size<span style={{ color: 'red' }}>*</span></label>
                            <input id="packingSize" type="text" name="packingSize" ref={register } tabIndex="3" autoComplete='off'  onInput={inputNoOnly} className="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary "></input>
                        </div>
                        {/* <div className="Invalid">
                          {errors.packingSize?.message}
                        </div> */}
                        
                        


                        <div className="field col">
                            <label >Farmer Selling Price<span style={{ color: 'red' }}>*</span></label>
                            <input id="frp" name="salePrice" ref={register } onInput={inputNoOnly} type="text" autoComplete='off'  tabIndex="5" className="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary "></input>
                        </div>
                        {/* <div className="Invalid">
                          {errors.salePrice?.message}
                        </div> */}

                        <div className="field col">
                            <label >Royalty</label>
                            <input id="royalty" type="text" name="royaltyRate" onInput={inputNoOnly} ref={register} autoComplete='off' tabIndex="7" className="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary "></input>
                        </div>

                        <div className="field col">
                            <label >Tax<span style={{ color: 'red' }}>*</span></label>
                            <input id="tax" type="text" name="taxRate" onInput={inputNoOnly} ref={register }  tabIndex="9" autoComplete='off' className="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary "></input>
                        </div>
                        {/* <div className="Invalid">
                          {errors.taxRate?.message}
                        </div> */}

                        <div className="field col">
                            <label >Discount Type<span style={{ color: 'red' }}>*</span></label>
                            <Dropdown className='Dropdown' value={selectedType}  options={Types} id="type" optionLabel="type"  tabIndex="11" onChange={onChangeType} filter showClear placeholder="Select Type" />
                        </div>
                        {/* <div className="Invalid">
                          {errors.discountType?.message}
                        </div> */}

                        <div className="field col">
                            <label >Company Name<span style={{ color: 'red' }}>*</span></label>
                            <Dropdown className='Dropdown' value={selectedCompany}  options={Company} id="company" optionLabel="companyName"  tabIndex="13" onChange={onChangeCompany} filter showClear  placeholder="Select Company" />
                        </div>

                        
                        

                    </div>

                    <div >

                        <div className="field col">
                            <label >Category<span style={{ color: 'red' }}>*</span></label>
                            {/* <Dropdown className='Dropdown' value={selectedCategory} options={categories} tabIndex="2"  id="category" optionLabel="name"  onChange={onChangeCategory} filter showClear placeholder="Select category" /> */}
                            <Dropdown className='Dropdown' value={selectedCategory}  options={categories} id="category" optionLabel="name"  tabIndex="2" onChange={onChangeCategory} filter showClear  placeholder="Select category" />

                        </div>
                        {/* <div className="Invalid">
                          {errors.categoryName?.message}
                        </div> */}

                        <div className="field col">
                            <label >Unit<span style={{ color: 'red' }}>*</span></label>
                            <Dropdown className='Dropdown' value={SelectedUnit} options={unitData}  tabIndex="4" id="ownerName" optionLabel="name"  onChange={onChangeUnit} filter showClear placeholder="Select Unit" />
                        </div>
                        {/* <div className="Invalid">
                          {errors.unitName?.message}
                        </div> */}

                        <div className="field col">
                            <label >MRP<span style={{ color: 'red' }}>*</span></label>
                            <input id="mrp" type="text" name='mrp'  ref={register } onInput={inputNoOnly}  tabIndex="6" autoComplete='off' className="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary"></input>
                        </div>
                        {/* <div className="Invalid">
                          {errors.mrp?.message}
                        </div> */}

                        <div className="field col">
                            <label >HSN Code<span style={{ color: 'red' }}>*</span></label>
                            <input id="HSN Code" name="hsnCode" ref={register} type="text"  tabIndex="8" autoComplete='off' className="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary "></input>
                        </div>
                        {/* <div className="Invalid">
                          {errors.hsnCode?.message}
                        </div> */}

                        <div className="field col">
                            <label >Alert Quantity<span style={{ color: 'red' }}>*</span></label>
                            <input id="alertQuantity" type="text" name="alertQty" ref={register }  tabIndex="10" autoComplete='off' onInput={inputNoOnly} className="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary "></input>
                        </div>
                        {/* <div className="Invalid">
                          {errors.alertQty?.message}
                        </div> */}

                        <div className="field col">
                            <label >Enter Discount <span style={{ color: 'red' }}>*</span></label>
                            <input id="discountValue" type="text" name="discountValue" ref={register } onInput={inputNoOnly} autoComplete='off'  tabIndex="12" className="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary "></input>
                        </div>
                        {/* <div className="Invalid">
                          {errors.discountValue?.message}
                        </div> */}
                        <div className="field col">
                            <label >isActive</label>
                            <Checkbox onChange={e => setChecked(e.checked)} checked={checked}  tabIndex="13" ></Checkbox>
                        </div>
                        
                    </div>

                </div>
                <div>
                    {/* <Button type="button" onClick={handleSubmit(onSave)}>Save</Button> */}
                    <button className='button' type="button" onClick={handleSubmit(onSave)}  tabIndex="14">{buttonLabel}</button>

                </div>
                </BlockUI>
            </div>

            <div className='box'>
                <ToolBoxGrid columns={columns} data={productData} fileName="products"></ToolBoxGrid>
                <DataTable dataKey='id' value={productData} scrollable scrollHeight='500px' filters={filters1} filterDisplay="menu" globalFilterFields={['categoryName', 'mergedProductName', 'hsnCode', 'type','companyName']} header={header} className="dataTable"  editMode="row"   >
                    <Column field="srNo" header="Sr.No"  ></Column>
                    <Column field="mergedProductName" header="Name"  ></Column>
                    <Column field="categoryName" header="Category"  ></Column>
                    <Column field="packingSize" header="Packing"  ></Column>
                    <Column field="salePrice" header="FR"  ></Column>
                    <Column field="mrp" header="MRP"  ></Column>
                    <Column field="royaltyRate" header="Royalty"  ></Column>
                    <Column field="taxRate" header="Tax(in %)"  ></Column>
                    <Column field="hsnCode" header="HSN"  ></Column>
                    <Column field="type" header="Type"  ></Column>
                    <Column field="discountValue" header="Discount"  ></Column>
                    <Column field="companyName" header="Company Name"  ></Column>
                    <Column body={buttons} header="Action" style={{ width: '100px' }}></Column>

                </DataTable>
            </div>
        </div>
    );
}

export default Products;