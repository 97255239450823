import React from 'react';
import 'primeflex/primeflex.css';
import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Config from '../../Config.json';
import GreenFieldService from '../../services/greenFieldService';
import './franchiseDashboard.css';
import { object } from 'yup';

function FranchiseDashboard() {
    const [totalSales, setTotalSales] = useState(0);
    const [currentMonthSales, setCurrentMonthSales] = useState(0);
    const [totalPaidAmt, setTotalPaidAmount] = useState(0);
    const [totalDues, setTotalDues] = useState(0);
    const [totalBalance, setTotalBalance] = useState(0);
    const [franchiseId,setFranchiseId]=useState(0);
    const [expiredProducts, setExpiredProducts]=useState([]);
    const service = new GreenFieldService();
    useEffect(() => {
       let franId= parseInt(localStorage.getItem("franchiseId"));
       setFranchiseId(franId);
        service.GetFranchiseDashboardData(franId).then(data => {
            setCurrentMonthSales(data.currentMonthSales.toFixed(2));
            setTotalSales(data.totalSales.toFixed(2));
            setTotalPaidAmount(data.totalPaidAmount.toFixed(2));
            setTotalDues(data.totalDues.toFixed(2));
            setTotalBalance(data.totalBalanceAmount.toFixed(2));
        });

        service.GetExpiredProducts(franId).then(data => {
           /*  let obj = {};
            let items =[];
            data.forEach(element => {
                obj = {};
                obj.Name=element;
                items.push(obj);
            }); */
            setExpiredProducts(data);
        }, err=>{console.log("Error in Getting Expired Products")});


    }, []);
    return (
        //Franchise Dashboard  style={{ marginRight: '50px', marginleft: '10px' }}
        <div className="FranchiseDashboard" style={{display:'flex', flexDirection:'column' , width:'100%'}}>
            <div className='BulletValues'>
            <div className='card' style={{ display: 'flex',width:'100%',height:'100%', justifyContent:'space-around' }}>
                <div className='card  justify-content-center chartDiv' style={{ position: 'relative', width: '25%', margin: '10px', padding: '10px' }}>
                    <h4>Current Month Sale-Rs - </h4>
                    <label>&#8377; { currentMonthSales }  </label>
                </div>
                <div className='card  justify-content-center chartDiv' style={{ position: 'relative', width: '25%', margin: '10px', padding: '10px' }}>
                    <h4>Total Sale-Rs- </h4>
                    <label >&#8377; { totalSales }  </label>
                </div>
                <div className='card  justify-content-center chartDiv' style={{ position: 'relative', width: '25%', margin: '10px', padding: '10px' }}>
                    <h4>Total Due-Rs</h4>
                    <label>&#8377; { totalDues }  </label>
                </div>
            </div>
            <div style={{display:'flex', width:'100%', justifyContent:'space-around' }}>
                
                <div className='card  justify-content-center chartDiv' style={{ position: 'relative', width: '25%', margin: '10px', padding: '10px' }}>
                    <h4>Total Paid Amount-Rs</h4>
                    <label >&#8377; { totalPaidAmt }  </label>
                </div>
                <div className='card  justify-content-center chartDiv' style={{ position: 'relative', width: '25%', margin: '10px', padding: '10px' }}>
                    <h4>Balance Amount-Rs</h4>
                    <label >&#8377; {totalBalance }  </label>
                </div >
                <div className='card  justify-content-center chartDiv' style={{ position: 'relative', width: '25%', margin: '10px', padding: '10px' }}></div>
            </div>
            </div>
            {/* <div className='tableGrid' style={{marginLeft:'30px' }}>
            <DataTable  header='Expired Products' sortField='expiredDate' value={expiredProducts} responsiveLayout="scroll" scrollHeight='200px' style={{width:'300px',height:'500px', margin:'5px', padding:'2px'}} >
                    <Column field="productName" header="Product" ></Column>
                    <Column field="expiredDate" header='ExpiryDate'></Column>
                    <Column field="batchNo" header='BatchNo'></Column>
                </DataTable>
                </div> */}
        </div>
    );
}

export default FranchiseDashboard;