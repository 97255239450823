// import './AdminStockReturn.css';
// import '../App.css';
import 'primeflex/primeflex.css';
import './Accounts.css';
import { Calendar } from 'primereact/calendar';
// import 'primeicons/primeicons.css';
// import 'primereact/resources/themes/lara-light-indigo/theme.css';
// import 'primereact/resources/primereact.css';
import React, { useState, useEffect, useRef } from 'react';
import { useForm } from "react-hook-form";
// import { Dropdown } from 'bootstrap';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import GreenFieldService from '../../services/greenFieldService';
import axios from 'axios';
import { Toast } from 'primereact/toast';
import Config from '../../Config.json';
import { Dropdown } from 'primereact/dropdown';
import { classNames } from 'primereact/utils';
import { Button } from 'primereact/button';
import { BlockUI } from 'primereact/blockui';
import moment from 'moment';
import authHeader from '../../authHeader';
import { useContext } from 'react';
import { GreenFieldContext } from '../../App';
import ToolBoxGrid from '../Misc/ToolBoxGrid';
import { shouldLockDate } from '../Misc/utilities';


function Accounts() {

    const [date3, setDate3] = useState(null);
    const greenFieldService = new GreenFieldService();
    const toast = useRef(null);
    const franchiseId = localStorage.getItem('franchiseId');
    const [amountsDetails, setAmountsDetails] = useState([]);
    const [paymentDetails, setPaymentsDetails] = useState([]);
    const [initialData, setInitialData] = useState();
    const [editingRows, setEditingRows] = useState({})
    const [selectedPayments, setSelectedPayments] = useState([]);
    const [modes, setModes] = useState([{ label: 'Cash', value: 'Cash' }, { label: 'Online', value: 'Online' }])
    const [blockedPanel, setBlockedPanel] = useState(false);
    var accessLevel = parseInt(sessionStorage.getItem('accessLevel'));
    //const [notificationMsgs]=useContext(GreenFieldContext);
    const lockStatus = useContext(GreenFieldContext);
    const [locked, setIsLocked] = useState(false);
    const [lockDate, setIsLockDate] = useState();
    const [finalLock, setFinalLock] = useState(false);


    function countDown() {
        // time = time - 1;
        greenFieldService.GetFranchiseLockScreenStatus(localStorage.getItem('franchiseId')).then(data => {
            //if(locked!=data.currentLockStatus){
            setIsLocked(data.currentLockStatus);
            setIsLockDate(data.lockDate);
            ///  }
        });
    }

    const {
        register,
        handleSubmit,
        setValue,
    } = useForm();
    const {
        register: Filter,
        errors: errors1,
        handleSubmit: handleFilter,
        setValue: setFilterValue,
    } = useForm();
    const [globalFilter, setGlobalFilter] = useState();

    const GetPaymentDetails = () => {
        greenFieldService.GetPaymentDetails(franchiseId).then(data => {
            data.sort((a, b) => b.paymentDate.localeCompare(a.paymentDate));
            let serialNo = 1;
            data.forEach(a => {
                a.serialNo = serialNo;
                serialNo++;

                a.originalStatus = a.status;
                // a.State = "unmodified";
                a.changeStatus = "unmodified";
                if (a.status === "Pending") {
                    a.statusColor = "Red";
                }
                else {
                    a.statusColor = "Green";
                }
                a.paymentDateValue = new Date(a.paymentDate);
            })

            setPaymentsDetails(data);
            //  GetPaymentDetails();
            setInitialData(data);
            setSelectedPayments(null);
        });
    }
    useEffect(() => {
        countDown();
        let d = moment(new Date()).format('YYYY-MM-DD');
        setFilterValue('fyDate', d);
        setFilterValue('toDate', d);
        setValue('paymentDate', d);

        greenFieldService.GetAmounts(franchiseId).then(data => {
            setAmountsDetails(data);
        });

        GetPaymentDetails();

        if (accessLevel > 1) {
            setBlockedPanel(false);
        }
        else {
            setBlockedPanel(true);
        }

    }, [])

    useEffect(() => {
        const interval = setInterval(() => {
            countDown();
        }, 5000);
        return () => clearInterval(interval);


    }, [])
    var errors = [];

    const validate = (data) => {

        if (!data?.amount) {
            errors.push('* Amount is required.', <br />);
        }
        if (!data?.type) {
            errors.push('* Amount Type is required', <br />);
        }

        if (!data.reference) {
            errors.push('* Refrence number/Note is required', <br />);
        }
        if (!data.paymentDate) {
            errors.push('* Payment date is required', <br />);
        }

        if (shouldLockDate(data.paymentDate, lockDate)) {
            errors.push('* Franchise is locked for this payment date', <br />);
        }

    };

    const onPayment = (data) => {

        data.status = "Pending";
        data.franchiseId = franchiseId;
        data.amount = parseFloat(data.amount);
        validate(data);
        if (errors.length > 0) {
            // eslint-disable-next-line no-lone-blocks
            {
                errors.map(() => {
                    toast.current.show({ severity: 'warn', detail: errors, sticky: true });
                }

                )
            }
            errors = [];
            return;
        }
        return axios.post(`${Config.apiUrl}/Payment/InsertPayment`, data, { headers: authHeader() })
            .then(response => {
                if (response.data.success) {
                    toast.current.show({ severity: 'success', detail: response.data.msg, autoDismiss: true });
                    GetPaymentDetails();
                    onReset();
                }
                else {
                    toast.current.show({ severity: 'error', detail: response.data.msg, sticky: true });
                }
            }).catch(error => {
                toast.current.show({ severity: 'error', sticky: true, summary: 'Error', detail: error.message });
            })
            .finally(() => {
                // SetFocusValue(true);
            })
    }

    const onReset = () => {
        const fields = [
            "amount",
            "type",
            "reference",
            "paymentDate"
        ];
        fields.forEach((field) => setValue(field, ''));
    }



    const onFilter = (filterValue) => {
        let fromdate = new Date(filterValue.fyDate);
        fromdate.setDate(fromdate.getDate() - 1);
        let toDate = new Date(filterValue.toDate);

        let data = initialData.filter(a => a.type === filterValue.mode && a.status === filterValue.status && a.paymentDateValue >= fromdate && a.paymentDateValue <= toDate);
        let srNo = 1;
        data.forEach(d => {
            d.serialNo = srNo;
            srNo++;
        })
        setPaymentsDetails(data);

    }


    const onFilterReset = () => {

        const fields = [
            "fyDate",
            "toDate",
            "mode",
            "status"
        ];
        fields.forEach((field) => setFilterValue(field, ''));
        GetPaymentDetails();


    }
    const onRowEditComplete2 = (e) => {

        let item = paymentDetails[e.index];
        // if (item.status !== 'Pending') {
        //     // toast.current.show({ severity: 'error', detail: "Only data which is in pending state can be updated", sticky: true });
        //     return;
        // }
        e.data.changeStatus = "modified";
        let _products3 = [...paymentDetails];
        let { newData, index } = e;
        newData.State = "Modified";
        _products3[index] = newData;

        setPaymentsDetails(_products3);

        onEditChanges(newData);
        /*   let newSelection= [newData, ...selectedPayments];
          setSelectedPayments(newSelection); */
    }

    const onEditChanges = (data) => {

        // let changedData = paymentDetails.filter(a => a.State === 'Modified');
        let changedData = paymentDetails.filter(a => a.changeStatus === 'Modified');

        if (changedData?.length == 0) {
            toast.current.show({ severity: 'error', detail: "No data changed for updation", sticky: true });

            return;
        }
        // if (changedData) {

        return axios.post(`${Config.apiUrl}/Payment/UpdatePayment`, data, { headers: authHeader() })
            .then(response => {
                response.data.forEach(a => {
                    if (a.success) {
                        toast.current.show({ severity: 'success', detail: a.msg, autoDismiss: true });

                    }
                    else {
                        toast.current.show({ severity: 'error', detail: a.msg, sticky: true });
                    }
                })
            }).catch(error => {
                toast.current.show({ severity: 'error', sticky: true, summary: 'Error', detail: error.message });
            })
            .finally(() => {
                GetPaymentDetails();
            });
        // }
        // else {
        //     toast.current.show({ severity: 'warn', detail: "No Changes to Save", sticky: true });
        // }

    }

    const onRowEditChange = (e) => {

        setEditingRows(e.data);

    }

    const header = (
        <div className="table-header">
            <div style={{ display: 'flex' }}>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" className="globleInput" value={globalFilter} onChange={(e) => setGlobalFilter(e.target.value)} placeholder="Global Search" />
                </span>

                <div>
                    {/* <Button style={{ width: '150px', height: '30px', marginLeft: '100px' }} label='Save' onClick={() => onEditChanges()} /> */}
                    <Button style={{ width: '150px', height: '30px', marginLeft: '100px' }} label='Delete' onClick={() => onDeletePayments()} />
                </div>
            </div>
        </div>
    );

    const onDeletePayments = (() => {
        if (!selectedPayments) {
            toast.current.show({ severity: 'warn', detail: "Please select row first!", sticky: true });
            return;
        }
        // let changedData = selectedPayments.filter(a => a.status === 'Pending' );
        // if (changedData?.length > 0) {
        //     toast.current.show({ severity: 'error', detail: "No 'Pending' state data selected for deletion!", sticky: true });
        //     return;
        // }
        let changedData = selectedPayments.filter(a => a.status !== 'Received');
        // if (changedData?.length > 0) {
        //     toast.current.show({ severity: 'error', detail: "No 'Received' state data selected for deletion!", sticky: true });
        //     return;
        // }
        if (changedData) {

            // return axios.post(`${Config.apiUrl}/Payment/DeletePayment`, changedData, { headers: authHeader() })
            return axios.post(`${Config.apiUrl}/Payment/DeletePayment`, changedData, { headers: authHeader() })
                .then(response => {
                    response.data.forEach(a => {
                        if (a.success) {
                            toast.current.show({ severity: 'success', detail: a.msg, autoDismiss: true });

                        }
                        else {
                            toast.current.show({ severity: 'error', detail: a.msg, sticky: true });
                        }
                    })
                }).catch(error => {
                    toast.current.show({ severity: 'error', sticky: true, summary: 'Error', detail: error.message });
                })
                .finally(() => {
                    GetPaymentDetails();
                });
        }
        else {
            toast.current.show({ severity: 'warn', detail: "No Changes to Save", sticky: true });
        }

    })
    const updatePaymentData = (data => {

    });

    const updateDelete = (rowData) => {
        return <div>
            <button type="button" className='filter' onClick={() => updatePaymentData(rowData)}>Edit</button>

            <button className='button delete' type="button" >Delete</button>
        </div>;
    }

    const modeEditor = (options) => {
        return (
            <Dropdown value={options.value} disabled={options.rowData['originalStatus'] === "Received"} options={modes} optionLabel="label" optionValue="value"
                onChange={(e) => { options.editorCallback(e.value); options.rowData['type'] = e.value }} placeholder="Select a Mode"
                itemTemplate={(option) => {
                    return <span >{option.label}</span>
                }} />
        );
    }
    const textEditor = (options) => {
        return <InputText type="text" disabled={options.rowData['originalStatus'] === "Received"} value={options.value} onChange={(e) => {
            options.editorCallback(e.target.value);
            /*   setValue('amount', e.target.value);
          paymentDetails[options.rowIndex]["amount"]=e.target.value;
          paymentDetails=[...paymentDetails]; */
        }} />;
    }

    const modeBodyTemplate = (rowData) => {
        return rowData.type;
    }

    /* const formatDate = (value) => {
        return (
                if (value){
                 moment(value, "YYYY-MM-DD");
                };
                )
    }
 
    const dateBodyTemplate = (rowData) => {
        return formatDate(rowData.date);
    } */
    // const rowClass = (data) => {

    //     return { 'selectedRow': data.State === 'Modified' }



    // }

    function validateLockDate(selectedDate) {
        if (lockStatus) {

            if (shouldLockDate(selectedDate, lockDate)) {

                setFinalLock(true);
                toast.current.show({ severity: 'warn', detail: `Franchise is locked till ${lockDate}`, summary: 'Payment Details' });
            }
            else {
                setFinalLock(false);
            }
        }
        else {
            setFinalLock(false);
        }
    }

    const onPaymentDateChange = (e => {
        validateLockDate(e.target.value);

    })

    const rowClass = (data) => {
        if (data.status === "Pending") {
            return "Pending";
        }
        if (data.changeStatus === "modified") {
            return "Modified";
        }
        else {
            return "Unmodified";
        }
    }

    const cols = [
        { field: 'serialNo', header: 'Sr.No' },
        { field: 'amount', header: 'Amount Sent' },
        { field: 'type', header: 'Payment Mode' },
        { field: 'reference', header: 'Reference Number' },
        { field: 'paymentDateFormat', header: 'Date' },
        { field: 'status', header: 'Status' },


    ];
    const exportColumns = cols.map(col => ({ title: col.header, dataKey: col.field }));
    return (
        <BlockUI blocked={blockedPanel}>

            <div className="Accounts">
                <Toast ref={toast} position={'center'} />
                <h3>Accounts</h3>
                <div style={{ minHeight: '1029px' }}>
                    <div className="box">
                        <table className="table table-bordered">
                            {amountsDetails.map((data, index) =>
                                <tbody>
                                    {/* <tr>
                                <th>Total Stock Amount: </th>
                                <th>{amountsDetails[index]?.totalDue}</th>
                            </tr> */}
                                    <tr>
                                        <th>Total Sale : </th>
                                        <th>{amountsDetails[index]?.totalSale}</th>
                                        <th>Paid Amount: </th>
                                        <th>{amountsDetails[index]?.paidAmount}</th>
                                        <th>Balance Amount : </th>
                                        <th>{amountsDetails[index]?.balanceAmount}</th>
                                    </tr>
                                </tbody>
                            )
                            }
                        </table>
                        <div className="formgrid grid">
                            <div className="field">
                                <label for="Total Sold">Amount Sent:</label>
                                <input id="Total Sold" name='amount' ref={register} type="text" className="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary m-2" placeholder="Enter Amount"></input>
                            </div>
                            <div className="field">

                                <label for="Paid Amount" className="Paid Amount">Type:</label>
                                <select name="type" ref={register} className="text-base text-color surface-overlay p-1 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary m-2">
                                    <option hidden value="">Select Type</option>
                                    <option >Online</option>
                                    <option >Cash</option>
                                </select>
                                {/* <input id="Paid Amount" type="text" className="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary m-2" placeholder="Paid Amount"></input> */}
                            </div>
                            <div className="field">
                                <label for="Total Sold">Reference Number/Note:</label>
                                <input id="Total Sold" name="reference" ref={register} type="text" className="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary m-2" placeholder="Reference No/Note"></input>
                            </div>
                            <div className="field">

                                <label for="Paid Amount" className="Paid Amount">Date:</label>
                                <input type='date' onChange={onPaymentDateChange} name="paymentDate" ref={register} max={moment().format("YYYY-MM-DD")} className="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary  m-2" />
                            </div>
                            <div className="field">
                                <button className="button" disabled={finalLock} onClick={handleSubmit(onPayment)}>Payment</button>
                            </div>
                        </div>


                        <hr />


                        <div className="field col">
                            <div className='filterBox'>
                                <div>
                                    <label for="Payment Mode">Payment Mode:</label>
                                    <select id="Payment Mode" name="mode" ref={Filter} className="field col" >
                                        <option hidden>Select Payment Mode</option>
                                        <option>Online</option>
                                        <option>Cash</option>
                                    </select>
                                    <label for="Status">Status:</label>
                                    <select id="Status" type="text" name="status" ref={Filter} className="field col ">
                                        <option hidden>Select Status</option>
                                        <option>Received</option>
                                        <option>Not_Received</option>
                                        <option>Pending</option>
                                    </select>
                                </div>
                                <div>
                                    <label>From:</label>
                                    <input type='date' name='fyDate' className='date' ref={Filter} />
                                    <label>To:</label>
                                    <input type='date' name='toDate' className='date' ref={Filter} />
                                    <button type="button filter" className='filter' onClick={handleFilter(onFilter)}>Filter</button>
                                    <button type="button" className='reset' onClick={onFilterReset}>Reset</button>
                                </div>
                            </div>


                        </div>

                    </div>

                    <div className='box'>
                        <ToolBoxGrid columns={exportColumns} data={paymentDetails} fileName="Franchise Payment Report"></ToolBoxGrid>

                        {/* <DataTable rowClassName={rowClass} dataKey="serialNo" onSelectionChange={(e) => setSelectedPayments(e.value)} selection={selectedPayments} value={paymentDetails} editingRows={editingRows} onRowEditChange={onRowEditChange} onRowEditComplete={onRowEditComplete2} header={header} globalFilter={globalFilter} editMode="row"> */}
                        <DataTable rowClassName={rowClass} dataKey="serialNo" onSelectionChange={(e) => setSelectedPayments(e.value)} globalFilter={globalFilter} selection={selectedPayments} value={paymentDetails} onRowEditComplete={onRowEditComplete2} header={header} editMode="row">
                            <Column selectionMode="multiple" headerStyle={{ width: '3em' }}></Column>
                            <Column field="serialNo" header="Sr No"></Column>
                            <Column field="amount" header="Amount Sent" editor={(options) => textEditor(options)}></Column>
                            <Column field="type" header="Payment Mode" body={modeBodyTemplate} editor={(options) => modeEditor(options)} ></Column>
                            <Column field="reference" header="Reference Number" editor={(options) => textEditor(options)} ></Column>
                            <Column field="paymentDateFormat" header="Date"></Column>
                            <Column field="status" header="Status"></Column>
                            <Column rowEditor header="Action" headerStyle={{ width: '10%', minWidth: '8rem' }} bodyStyle={{ textAlign: 'center' }}></Column>
                        </DataTable>
                    </div>
                </div>
            </div>
        </BlockUI>

    );
}
export default Accounts;