
import './App.css';
import Home from './components/home';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Nav from './components/Nav/Nav';
import Login from './components/LogIn/Login';
import RegisterUser from './components/LogIn/RegisterUser';
import ProfilePopup from './components/LogIn/ProfilePopup';
import Products from './components/Products';
import Stock from './components/Stock';
import AdminStockReturn from './components/AdminStockReturn';
import Franchise from './components/Franchise';
import InvoiceList from './components/InvoiceList';
import Config from './Config.json';
import { Sidebar } from 'primereact/sidebar';
import Profile from './components/LogIn/Profile';
import "primereact/resources/themes/lara-light-indigo/theme.css";
import UnitMaster from './components/Master/UnitMaster';
import CategoryMaster from './components/Master/CategoryMaster';
import CustomerMaster from './components/Master/CustomerMaster';
import ManageStockByFranchise from './components/ManageStockByFranchise';
import AddComplaint from './components/AddComplaint';
import ProductReport from './components/Report/ProductReport';
import CreateInvoice from './components/Franchise/CreateInvoice';
import CustomerReport from './components/Report/CustomerReport';
import AllInvoices from './components/Franchise/AllInvoices';
import InvoicePrintReport from './components/Franchise/InvoicePrintReport';
import Permission from './components/Misc/Permission';
import ReceivedStockEntries from './components/Franchise/ReceivedStockEntries';
// import FranchiesStock from './components/Franchise/FranchiesStock';
import DistributedStockEntry from './components/DistributedStockEntry';
import FranchiseStockReturn from './components/Franchise/FranchiseStockReturn';
import Accounts from './components/Franchise/Accounts';
import AdminDashboard from './components/Dashboard/adminDashboard';
import AdminOrderRequest from './components/AdminOrderRequest';
import FranchiseDashboard from './components/Dashboard/franchiseDashboard';
import AllAccounts from './components/AllAcounts';
import FranchiseComplaints from './components/Complaints/FranchiseComplaints';
import FarmerComplaints from './components/Complaints/FarmerComplaints';

import Employee from './components/Employee';
import FarmerComplaintsOfFranchise from './components/Complaints/FarmerComplaintsOfFranchise';
// import OrderRequestOfFranchise from './components/Franchise/OrderRequestOfFranchise';
import ProductSalesReport from './components/Franchise/ProductSalesReport';
import SalesReturnOfFranchise from './components/Franchise/SalesReturnOfFranchise';
import ReturnStocks from './components/Franchise/ReturnStocks';
import SalesInvoice from './components/Admin/SalesInvoice';
// import SalesInvoice from './components/Admin/salesInvoice';
import SalesProduct from './components/Admin/SalesProduct';
import InvoiceReportA5 from './components/Franchise/InvoiceReportA5';
import OrderRequest from './components/Franchise/OrderRequest';
import AddFranchiseComplaint from './components/Complaints/AddFranchiseComplaint';
import FranchiseStockReport from './components/Franchise/FranchiseStockReport';

import ViewLogs from './components/Admin/ViewLogs';
import AccountFranchiseWise from './components/Admin/AccountFranchiseWise';
import AllReturnInvoices from './components/Franchise/AllReturnInvoices';
import ReturnInvoicePrint from './components/Report/RetunInvoicePrint';
import OutStandingReport from './components/Report/OutStandingReport';
import StockValueReport from './components/Report/StockValueReport';
import StockReport from './components/Report/StockReport';
import HsnWiseReport from './components/Report/HsnWiseReport';
import InvoiceReport from './components/Report/InvoiceReport';
import ReturnInvoiceReport from './components/Report/ReturnInvoiceReport';
import ReturnProductReport from './components/Report/ReturnProductReport';
import OrderRequestPrint from './components/Report/orderRequestPrint';
import LockScreen from './components/Misc/LockScreen';
import React, { useState, useEffect, useRef } from 'react';
import { HubConnection, HubConnectionBuilder, HttpTransportType } from "@microsoft/signalr";
import { createContext } from 'react';
import AllInvoicePrint from './components/Report/AllInvoicePrint';
import GkmWiseProduct from './components/Report/GkmWiseProduct';
import authHeader from './authHeader';
import GreenFieldService from './services/greenFieldService';
import ForgotPassword from './components/LogIn/ForgotPassword';
//import LockScreen from './components/Report/LockScreen';
//import ViewLogs from './components/Admin/ViewLogs';

export const GreenFieldContext = createContext();
function App() {

  const greenFieldService = new GreenFieldService();
  const [connection, setConnection] = useState(null);
  const [inputText, setInputText] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [notificationMsgs, setNotificationMsgs] = useState('');
  const [lockStatus, setLockStatus] = useState(false);
  const [lockStatusObj, setLockStatusObj] = useState({});


  const logInQueryString = () => {

    let franchiseId = localStorage.getItem('franchiseId');


    let name = localStorage.getItem('name');
    let userId = localStorage.getItem('userId');
    let accessLevel = sessionStorage.getItem("accessLevel");
    let accessString = `${franchiseId}_${userId}_${accessLevel}`;
    return accessString;
  }


  // function countDown() {
  //   // time = time - 1;
  //   console.log("Checking Status");
  //   // alert("IN app ");
  //   if (isLoggedIn) {
  //     greenFieldService.GetFranchiseLockScreenStatus(localStorage.getItem('franchiseId')).then(data => {
  //       // if ((lockStatusObj === undefined) || (data.currentLockStatus != lockStatusObj.currentLockStatus)) {
  //         // alert("IN app " + data.currentLockStatus);
  //         setLockStatusObj([...data]);
  //         setLockStatus(data.currentLockStatus);
  //       // };
  //     });
  //   }
  // }
  // console.log(time);

  // useEffect(() => {
  //   // alert("isloggedin ");
  //   if (isLoggedIn) {
  //     // alert("starting Timer ");
  //     const interval = setInterval(() => {
  //       countDown()
  //     }, 5000);

  //     // return () => clearInterval(interval);

  //     /*   const connect = new HubConnectionBuilder()
  //         .withUrl(`http://103.86.177.49/hubs/`, {
  //           withCredentials: false, skipNegotiation: true, accessTokenFactory: () =>
  //             logInQueryString(), transport: HttpTransportType.WebSockets
  //         })
  //         .withAutomaticReconnect()
  //         .build();
  
  //       setConnection(connect);
  //     } */
  //   }
  // }, [isLoggedIn]);

  // useEffect(() => {
  //   if (connection) {
  //     let franchiseId = parseInt(localStorage.getItem('franchiseId'));
  //     let isAdmin = false;
  //     if (franchiseId <= 0) {
  //       isAdmin = true;
  //     }

  //     connection
  //       .start()
  //       .then(() => {
  //         if (!isAdmin) {
  //           connection.on("ToFrachiseMessage", (message) => {
  //             console.log("received message" + message);
  //             setNotificationMsgs(message);
  //           });
  //         }
  //         else {
  //           connection.on("ToAdminMessage", (message) => {

  //           });
  //         }
  //       })
  //       .catch((error) => console.log(error));
  //   }
  // }, [connection]);

  // const sendMessage = async () => {
  //   if (connection) await connection.send("SendMessage", inputText);
  //   setInputText("");
  //   //setLoggedIn={setIsLoggedIn}
  // };


  return (

    <div >
      <GreenFieldContext.Provider value={{ setIsLoggedIn, lockStatusObj }}>
        <Router>

          <div className="App">
            {/* <Nav /> */}
            <Switch>
              <Route path='/' exact component={Login} />
              <Route path='/login' component={Login} />
              <Route path='/signUp' component={RegisterUser} />
              <Route path='/ProfilePopup' component={ProfilePopup} />
              <Route path='/ForgotPassword' exact component={ForgotPassword}/>

              <div>
                <Nav />
                {/* <Route path='/actions/ForgotPassword' exact component={ForgotPassword}/> */}


                {/* <Route path='/HomePage' exact component={Home} />
            <Route path='/Products' exact component={Products} />
            <Route path='/Units'exact component={UnitMaster}/>
            <Route path='/Category' exact component={CategoryMaster}/>
            <Route path='/Stock' exact component={Stock} />
            <Route path='/AdminStockReturn' exact component={AdminStockReturn} />
            <Route path='/Franchise' exact component={Franchise} />
            <Route path='/InvoiceList' exact component={InvoiceList} />
            <Route path='/Franchise' exact component={Franchise}/>
            <Route path='/Customer' exact component={CustomerMaster}/>
            <Route path='/ManageStock/:franchiseId' exact component={ManageStockByFranchise} />
            <Route path='/AddComplaint' exact component={AddComplaint}/>
            <Route path='/CreateInvoice' exact component={CreateInvoice}/>
            <Route path='/ProductReport' exact component={ProductReport}/>
            <Route path='/CustomerReport' exact component={CustomerReport}/>
            <Route path='/AllInvoices' exact component={AllInvoices}/>
            <Route path='/InvoicePrint' exact component={InvoiceReport}/> */}


                <Route path='/Profile' exact component={Profile} />
                <Route path='/AdminDashboard' exact component={AdminDashboard} />
                <Route path='/FranchiseDashboard' exact component={FranchiseDashboard} />
                <Route path='/HomePage' exact component={Home} />
                <Route path='/Products' exact component={Products} />
                <Route path='/Units' exact component={UnitMaster} />
                <Route path='/Category' exact component={CategoryMaster} />
                <Route path='/Stock' exact component={Stock} />
                <Route path='/AdminStockReturn' exact component={AdminStockReturn} />
                <Route path='/Franchise' exact component={Franchise} />
                <Route path='/InvoiceList/:customerId' exact component={InvoiceList} />
                <Route path='/Customer' exact component={CustomerMaster} />
                <Route path='/ManageStock/:franchiseId' exact component={ManageStockByFranchise} />
                <Route path='/SalesInvoice/:franchiseId' exact component={SalesInvoice} />
                <Route path='/SalesProduct/:franchiseId' exact component={SalesProduct} />
                <Route path='/AccountFranchiseWise/:franchiseId' exact component={AccountFranchiseWise} />

                <Route path='/AddComplaint' exact component={AddComplaint} />
                {/* <Route path='/CreateInvoice' exact component={CreateInvoice} /> */}
                <Route path='/CreateInvoice' render={()=><CreateInvoice lockStatus={lockStatusObj}/>}/>
                <Route path='/ProductReport' exact component={ProductReport} />
                <Route path='/CustomerReport' exact component={CustomerReport} />
                <Route path='/OutStandingReport' exact component={OutStandingReport} />
                <Route path='/StockValueReport' exact component={StockValueReport} />
                <Route path='/StockReport' exact component={StockReport} />
                <Route path='/HsnReport' exact component={HsnWiseReport} />
                <Route path='/InvoiceReport' exact component={InvoiceReport} />
                <Route path='/ReturnInvoiceReport' exact component={ReturnInvoiceReport} />
                <Route path='/ReturnProductReport' exact component={ReturnProductReport} />
                <Route path='/GkmProductReport' exact component={GkmWiseProduct} />
                <Route path='/AllInvoices' exact component={AllInvoices} />
                <Route path='/AllReturnInvoices' exact component={AllReturnInvoices} />
                <Route path='/InvoicePrint' exact component={InvoicePrintReport} />
                <Route path='/InvoicePrint/:customerId/:invNo/:franchiseId' exact component={InvoiceReportA5} />
                <Route path='/ReInvoicePrint/:customerId/:invNo/:franchiseId' exact component={ReturnInvoicePrint} />
                <Route path='/OrderRequestPrint' exact component={OrderRequestPrint} />
                <Route path='/InvoicesPrint' exact component={AllInvoicePrint} />
                <Route path='/Permission' exact component={Permission} />
                <Route path='/ViewLogs' exact component={ViewLogs} />
                <Route path='/ReceivedStockEntries' exact component={ReceivedStockEntries} />
                <Route path='/ReturnStock' exact component={ReturnStocks} />
                <Route path='/FranchiseStockReturn' exact component={FranchiseStockReturn} />

                <Route path='/Account' exact component={Accounts} />
                <Route path='/AllAccounts' exact component={AllAccounts} />

                <Route path='/DistributedStockEntry' exact component={DistributedStockEntry} />
                <Route path='/AddFranchiseComplaints' exact component={AddFranchiseComplaint} />
                <Route path='/FranchiseComplaints' exact component={FranchiseComplaints} />
                <Route path='/FarmerComplaints' exact component={FarmerComplaints} />
                <Route path='/OrderRequest' exact component={OrderRequest} />
                <Route path='/AdminOrderRequest' exact component={AdminOrderRequest} />

                <Route path='/Employee' exact component={Employee} />
                <Route path='/FarmerComplaintsOfFranchise' exact component={FarmerComplaintsOfFranchise} />
                {/* <Route path='/OrderRequestOfFranchise' exact component={OrderRequestOfFranchise}/> */}
                <Route path='/ProductSalesReport' exact component={ProductSalesReport} />
                <Route path='/SalesReturnOfFranchise' exact component={SalesReturnOfFranchise} />
                <Route path='/FranchiseStockReport' exact component={FranchiseStockReport} />
                <Route path='/LockScreen' exact component={LockScreen} />



              </div>





            </Switch>
          </div>

        </Router>
      </GreenFieldContext.Provider>


    </div>
  );
}

export default App;
