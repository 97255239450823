import React, { useState, useEffect, useContext, useRef } from 'react';
import ExportCsvReport from '../../exportReport/exportCsvReport';
import ExportPdfReport from '../../exportReport/exportPdfReport';
import ExportExcelReport from '../../exportReport/exportExcelReport';

function ToolBoxGrid({columns, data, fileName}) {

    const exportCsvReport = new ExportCsvReport();
    const exportPdfReport = new ExportPdfReport();
    const exportExcelReport = new ExportExcelReport();
    const exportCSV = (selectionOnly) => {
        // <exportCsvReport dt={dt}/>
        exportCsvReport.exportCSV(data);
        // dt.current.exportCSV();
    }

    const exportPdf = () => {
       // let fileName="Invoice Report";
        exportPdfReport.exportPdf(columns ,data,fileName);

    }

    const exportExcel = () => {
      //  let fileName="Invoice Report";
      let exceldata=[...data];
     let itemSample= exceldata[0];
     let toDeletedProps = [];
     
      Object.keys(itemSample).forEach(e =>{
      let index=  columns.findIndex(a=>a.dataKey===e);
      if(index <0){
        toDeletedProps.push(e);
      }
    });
      
      exceldata.forEach(a=>{
       
        toDeletedProps.forEach(delProp=>{
           delete a[delProp]; 
        })
    });
       
    let tempArray=[]; 
    exceldata.forEach(a=>{
       let item=[];

       columns.forEach(col=>{
         item[col.title]= a[col.dataKey];

       })
       tempArray.push(item);
    });
        // exceldata.forEach(a=>{
        //     a.forEach(item=>{})
        //     let index=  columns.findIndex(a=>a.dataKey===e);

        // })
        exportExcelReport.exportExcel(tempArray,fileName);
    }


    return (
        <div>
            {/* <button type='button' >Copy</button> */}
            <button type='button' onClick={() => exportCSV(false)}>CSV</button>
            <button type='button' onClick={() => exportExcel()}>Excel</button>
            <button type='button' onClick={() => exportPdf()}>PDF</button>
            {/* <button type='button'>Print</button> */}
        </div>
    )
}
export default ToolBoxGrid;