import React, { useState, useEffect, useRef } from 'react';
import './Franchise.css';
import 'primeflex/primeflex.css';
import { useForm } from "react-hook-form";
import axios from 'axios';
import GreenFieldService from '../services/greenFieldService';
import Config from '../Config.json';
import { Toast } from 'primereact/toast';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { allStates, allDistricts } from "state-district-component";
import { useHistory } from 'react-router-dom';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { BlockUI } from 'primereact/blockui';
import authHeader from '../authHeader';


function Franchise(id) {

    const { register, handleSubmit, reset, setValue } =
        useForm(
            // { resolver: yupResolver(validationSchema) }
        );
    const [uploadUrl, setUploadUrl] = useState(`${Config.apiUrl}/Franchise/UpdateFranchise`);
    const [stateCode, setStateCode] = useState();
    const [displayTable, setDisplayTable] = useState(true);
    const [buttonLabel, setButtonLabel] = useState("Save");
    const [globalFilter, setGlobalFilter] = useState('');
    const [selectedRadioData, setSelectedRadioData] = useState(null);
    const [isAddMode, setIsAddMode] = useState(true);
    const [state, setSelectedState] = useState("Maharashtra");
    const [districtsOfState, setDistrictsOfState] = useState([]);
    const greenFieldService = new GreenFieldService();
    const toast = useRef(null);
    const [franchiseData, setFranchiseData] = useState([]);
    const [rowDataValue, setRowData] = useState();
    const [checked, setChecked] = useState(false);
    const [filters1, setFilters1] = useState(null);
    const [blockedPanel, setBlockedPanel] = useState(false);
    const [disabledButton,setdisabledButton]=useState(false);
    var accessLevel = parseInt(sessionStorage.getItem('accessLevel'));
    let history = useHistory();


    useEffect(() => {
        greenFieldService.GetFranchiseData().then(data => {
            data.forEach(a => a.franchiseAddress = `${a.address},${a.state}/${a.district} -${a.pincode}`);
            setFranchiseData(data);
        });

        const districtsList = allDistricts.filter(elem => elem.state === state)
        setDistrictsOfState(districtsList[0].dists);

        initFilters1();

        if(accessLevel > 1)
        {
          setBlockedPanel(false);
        }
        else{
            setBlockedPanel(true);
            setdisabledButton(true);
            
        }

    }, [])

    const onAdd = (e) => {
        setDisplayTable(false);
    }
    const onBack = (e) => {
        Reset();
        setButtonLabel("Save");
        setDisplayTable(true);

    }
    var errors = [];

    const validate = (data) => {

        if (!data?.name) {
            errors.push('* Franchise Name is required.', <br />);
        }

        if (!data?.gstno) {
            errors.push('* Gst Number is required', <br />);
        }
        if (!data?.email) {
            errors.push('* Email is required.', <br />);
        }
        else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data?.email)) {
            errors.push('* Invalid email address. E.g. example@gmail.com', <br />);
            // toast.current.show({ severity: 'error', detail: 'Invalid email address. E.g. example@email.com', sticky: true });
        }
        if (!data?.mobile1) {
            errors.push('* MobileNumber is required', <br />);
        }
        if (!data?.seedLicense) {
            errors.push('* Seed License is required', <br />);
        }
        if (!data?.insectisideLicense) {
            errors.push('* Insertiside License is required.', <br />);
        }
        if (!data?.fertilizerLicense) {
            errors.push('* Fertilizer License is required', <br />);
        }
        if (!data?.state) {
            errors.push('* State is required', <br />);
        }
        if (!data?.district) {
            errors.push('* District is required', <br />);
        }
        if (!data?.address) {
            errors.push('* Franchise address is required.', <br />);
        }
        if (!data?.pincode) {
            errors.push('* Franchise pincode is required.', <br />);
        }
        if (data?.ownerEmail) {
            if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data?.ownerEmail)) {
                errors.push('* Invalid Owner email address. E.g. example@gmail.com', <br />);
            }
        }
        
        // if (!data?.ownerName) {
        //     errors.push('* Owner Name is required', <br />);
        // }
        // if (!data?.ownerEmail) {
        //     errors.push('* Owner Email is required.', <br />);
        // }
        // else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data?.ownerEmail)) {
        //     errors.push('* Invalid email address. E.g. example@gmail.com', <br />);
        // }
        // if (!data?.ownerMobile1) {
        //     errors.push('* Owner Mobile is required', <br />);
        // }
        // if (!data?.aadharNo) {
        //     errors.push('* Aadhar Number is required.', <br />);
        // }
        // if (!data?.aadharFileInfo) {
        //     errors.push('* Aadhar Image is required.', <br />);
        // }
        // if (!data?.pancardNo) {
        //     errors.push('* Pan Number is required', <br />);
        // }
        // if (!data?.panFileInfo) {
        //     errors.push('* Pan Image is required.', <br />);
        // }
        // if (!data?.ownerAddress) {
        //     errors.push('* Owner Address is required', <br />);
        // }

    };

    const inputNoOnly = (e) => {
        if (e.target.id === 'mobile') {
            const onlyNums = e.target.value.replace(/[^0-9.]/g, '');
            setValue('mobile1', onlyNums);
        }
        if (e.target.id === 'altMobile') {
            const onlyNums = e.target.value.replace(/[^0-9.]/g, '');
            setValue('mobile2', onlyNums);
        }
        if (e.target.id === 'ownerMobile') {
            const onlyNums = e.target.value.replace(/[^0-9.]/g, '');
            setValue('ownerMobile1', onlyNums);
        }
        if (e.target.id === 'ownerAltMobile') {
            const onlyNums = e.target.value.replace(/[^0-9.]/g, '');
            setValue('ownerMobile2', onlyNums);
        }
        if (e.target.id === 'aadharNo') {
            const onlyNums = e.target.value.replace(/[^0-9.]/g, '');
            setValue('aadharNo', onlyNums);
        }
        if (e.target.id === 'Pincode') {
            const onlyNums = e.target.value.replace(/[^0-9.]/g, '');
            setValue('pincode', onlyNums);
        }

    }

    const Reset = () => {
        const fields = [
            "name",
            "gstno",    
            "email",
            "mobile1",
            "mobile2",
            "seedLicense",
            "insectisideLicense",
            "fertilizerLicense",
            "state",
            "district",
            "address",
            "pincode",
            "ownerName",
            "ownerEmail",
            "ownerMobile1",
            "ownerMobile2",
            "aadharNo",
            "aadharFile",
            "pancardNo",
            "panFileInfo",
            "ownerAddress"

        ];
        fields.forEach((field) => setValue(field, ''));

    }

    const onSave = (data) => {
        validate(data);
        if (errors.length > 0) {
            // eslint-disable-next-line no-lone-blocks
            {
                errors.map(() => {
                    toast.current.show({ severity: 'warn', detail: errors, sticky: true });
                }

                )
            }
            errors = [];
            return;
        }
        return isAddMode ? addFranchise(data) : updateFranchise(data);

    }
    const addFranchise = (data) => {
        // setSpinnerVisible(true);
        if (checked) {
            data.aadharFile = data.aadharFileInfo[0]?.name;
            data.pancardFile = data.panFileInfo[0]?.name;
        }
        let submitdata = JSON.parse(JSON.stringify(data));
        submitdata.aadharFileInfo = "";
        submitdata.panFileInfo = "";

        return axios.post(`${Config.apiUrl}/Franchise/InsertFranchise`, submitdata, { headers: authHeader() })
            .then(response => {
                if (response.data.success) {
                    if (!data.aadharFileInfo && !data.pancardFile) {
                        uploadFranchiseFile(data.aadharFileInfo[0], data.aadharFile, "A", response.data.id);
                        uploadFranchiseFile(data.panFileInfo[0], data.pancardFile, "B", response.data.id);
                    }

                    toast.current.show({ severity: 'success', detail: response.data.msg, autoDismiss:true });
                }
                else {
                    toast.current.show({ severity: 'error', detail: response.data.msg, sticky: true });
                }
            }).catch(error => {
                toast.current.show({ severity: 'error', sticky: true, summary: 'Error', detail: error.message });
            })
            .finally(() => {
                window.location.reload();
                // SetFocusValue(true);
            })
    }

    const updateFranchise = (data) => {
        data.id = rowDataValue.id;
        if (checked) {
            data.aadharFile = data.aadharFileInfo[0]?.name;
            data.pancardFile = data.panFileInfo[0]?.name;
        }
        let updatedData = JSON.parse(JSON.stringify(data));
        updatedData.aadharFileInfo = "";
        updatedData.panFileInfo = "";
        return axios.put(`${Config.apiUrl}/Franchise/UpdateFranchise`, updatedData, { headers: authHeader() })
            .then(response => {
                console.log(response)
                if (response.data.success) {
                    if (!data.aadharFileInfo && !data.pancardFile) {
                        uploadFranchiseFile(data.aadharFileInfo[0], data.aadharFile, "A", response.data.id);
                        uploadFranchiseFile(data.panFileInfo[0], data.pancardFile, "B", response.data.id);
                    }
                    toast.current.show({ severity: 'success', detail: response.data.msg,autoDismiss:true });
                }
                else {
                    toast.current.show({ severity: 'error', detail: response.data.msg });
                }
            }).catch(error => {
                toast.current.show({ severity: 'error', sticky: true, summary: 'Error', detail: error.message });
            })
            .finally(() => {
                window.location.reload();
                // setSpinnerVisible(false);
                // history.push('/login');
            })
    }

    const onChangeGlobal=(e)=>{
        let value=e.target.value;
       let _filters1 = { ...filters1 };
       _filters1['global'].value = value;

       setFilters1(_filters1);
       
        setGlobalFilter(value);
    }

    
    const initFilters1 = () => {
        setFilters1({
            'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
            
        });
        setGlobalFilter('');
    }

    const funcOnStateChange = (e) => {
        console.log(e);
        setSelectedState(e.currentTarget.value);
        const districtsList = allDistricts.filter(elem => elem.state === e.currentTarget.value)
        setDistrictsOfState(districtsList[0].dists);
    }
   /*  const funcOnPinCodeChange = (e) => {
        console.log(e);
        setSelectedPinCode(e.currentTarget.value);
        const pincodeList = allPinCode.filter(elem => elem.state === e.currentTarget.value)
        setPinCodeOfState(pincodeList[0].dists);
    } */



    const header = (

        <div className="table-header">

            <div >
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText className="globalSearch" type="search" value={globalFilter} onChange={onChangeGlobal} placeholder="Global Search" />
                </span>
            </div>

        </div>
    );


    const onStock = (rowData) => {
        // return <div>
        // window.location = "/ManageStock?"+ rowData.id;
        // </div>
        history.push(`/ManageStock/${rowData.id}`);
        localStorage.setItem("FranchiseName", (rowData.name));


    }
    const onAccounts = (rowData) => {
        history.push(`/AccountFranchiseWise/${rowData.id}`);
    }
    const onSalesInvoice = (rowData) => {
        history.push(`/SalesInvoice/${rowData.id}`);
        localStorage.setItem("franchiseId", (rowData.id));
        localStorage.setItem("FranchiseName", (rowData.name));


    }
    const onSalesProduct = (rowData) => {
        history.push(`/SalesProduct/${rowData.id}`);
        localStorage.setItem("franchiseId", (rowData.id));
        localStorage.setItem("FranchiseName", (rowData.name));
    }
    const onSalesReturn = (rowData) => {

    }

    const onEmployee = (rowData) => {

    }



    const onDelete = (rowData) => {

    }


    const Buttons = (rowData) => {
        return <div>
            {/* <button className='button' type="button" onClick={onManage(rowData)}>Manage</button> */}
            <button className='button ' type="button" onClick={() => onStock(rowData)}>Stock</button>
            <button className='button accounts' type="button" onClick={() => onAccounts(rowData)}>Accounts</button>
            <button className='button salesInvoice' type="button" onClick={() => onSalesInvoice(rowData)}>Sales Invoice</button>
            <button className='button' type="button" onClick={() => onSalesProduct(rowData)}>Sales Product</button>
            {/* <button className='button salesReturn' type="button" onClick={() => onSalesReturn(rowData)}>Sales Return</button> */}
            {/* <button className='button employee' type="button" onClick={() => onEmployee(rowData)}>Employee</button> */}
            <button className='button edit' type="button" onClick={() => onEdit(rowData)} disabled={disabledButton}>Edit</button>
            {/* <button className='button delete' type="button" onClick={() => onDelete(rowData)}>Delete</button> */}
        </div>;
    }

    const onEdit = (rowData) => {
        setIsAddMode(false);
        setDisplayTable(false);
        setButtonLabel('Update');
        // let data = rowData;

        const fields = [
            "name",
            'alias',
            "gstno",
            "email",
            "mobile1",
            "mobile2",
            "seedLicense",
            "insectisideLicense",
            "fertilizerLicense",
            "state",
            "district",
            "address",
            "pincode",
            "ownerName",
            "ownerEmail",
            "ownerMobile1",
            "ownerMobile2",
            "aadharNo",
            "aadharFile",
            "pancardNo",
            "panFileInfo",
            "ownerAddress"
        ];
        fields.forEach((field) => {
            setValue(field, rowData[field])
        });
        setRowData(rowData);

    }



    const onAdhharUpload = ({ files }) => {
        uploadFile(files[0]);
        // const [file] = files;
        // const fileReader = new FileReader();
        // fileReader.onload = (e) => {
        //     uploadFile(e.target.result);
        // };
        // fileReader.readAsDataURL(file);
    };

    function uploadFranchiseFile(file, fileName, type, identity) {
        //   const file In= file;
        const formData = new FormData();
        formData.append("formFile", file);
        formData.append("fileName", fileName);
        return axios.post(`${Config.apiUrl}/Franchise/Upload?identifier=${identity}&type=${type}`, formData, { headers: authHeader() }).then(rsult => {
            console.debug("OK");
        });
    }
    const uploadFile = async (invoiceFile) => {

        const file = invoiceFile;
        const formData = new FormData();
        formData.append("formFile", invoiceFile);
        formData.append("fileName", "Test");
        // const reader = new FileReader();
        // let blob = await fetch(file.objectURL).then(r => r.blob()); //blob:url
        // reader.readAsDataURL(blob);
        // reader.onloadend = function () {
        //     const base64data = reader.result;
        //     console.log(base64data);
        //     formData.append('invoiceFile', blob);

        //        return axios.post(`${Config.apiUrl}/Franchise/Upload?identifier=${Config.apiUrl}&type=${type}`, formData).then(rsult => {
        //          console.debug("OK");
        //     })
    }

    /*  .then(response => {
         if (response.data.success) {
             toast.current.show({ severity: 'success', detail: response.data.msg, sticky: true });
         }
     const response = await fetch(`orders/${orderId}/uploadInvoiceFile`,
         {
             method: 'POST',
             body: formData
         },
     ); */

    return (

        <div className="Franchise">
            <Toast ref={toast} position={'center'} />
            {/* {!displayTable && ( */}
            <div hidden={displayTable}>

                <div className="header">
                    <button className='addButton button' style={{ width: '100px' }} onClick={onBack} type="button">Back</button>
                </div>

                <div className="box">

                    <h3 >Franchise List</h3>
                    <div className="formgrid grid align items left">
                        <div style={{ marginRight: '5px', marginleft: '10px' }}>

                            <div className="field col">
                                <label>Franchise Name<span style={{ color: 'red' }}>*</span></label>
                                <input id="franchiseName" type="text" name="name" ref={register} className="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary "></input>
                                <label>Franchise Alias<span style={{ color: 'red' }}>*</span></label>
                                <input id="franchiseAlias" type="text" name="alias" maxLength="5" ref={register} className="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary "></input>
                              </div>
                            <div className="field col">
                            <label>GST No<span style={{ color: 'red' }}>*</span></label>
                                <input id="gstNo" type="text" name="gstno" ref={register} className="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary"></input>
                            </div>
                            <div className="field col">
                                <label>Email<span style={{ color: 'red' }}>*</span></label>
                                <input id="email" type="text" name="email" ref={register} className="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary"></input>
                                <label>Mobile<span style={{ color: 'red' }}>*</span></label>
                                <input id="mobile" type="text" name="mobile1" ref={register} maxLength="10" onInput={inputNoOnly} className="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary   "></input>
                            </div>
                            <div className="field col">
                                <label>Seed LIC<span style={{ color: 'red' }}>*</span></label>
                                <input id="seedLIC" type="text" name="seedLicense" maxLength="20" ref={register} className="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary   "></input>
                                <label>Alt. Mobile</label>
                                <input id="altMobile" type="text" name="mobile2" ref={register} maxLength="10" onInput={inputNoOnly} className="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary   "></input>
                            </div>
                            <div className="field col">
                                <label>Insecticide LIC<span style={{ color: 'red' }}>*</span></label>
                                <input id="insecticideLIC" type="text" name="insectisideLicense" maxLength="20" ref={register} className="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary   "></input>
                                <label>Fertilizer LIC<span style={{ color: 'red' }}>*</span></label>
                                <input id="fertilizerLIC" type="text" name="fertilizerLicense" maxLength="20" ref={register} className="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary   "></input>
                            </div>

                            <div className="field col">
                                <label>State<span style={{ color: 'red' }}>*</span></label>
                                <select name="state" ref={register} onChange={funcOnStateChange} value={state} className="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary">
                                    <option hidden value=""> Select State</option>
                                    {allStates.map(state => <option value={state} key={state}>{state}</option>)}
                                </select>

                                <label>District<span style={{ color: 'red' }}>*</span></label>
                                <select name="district" ref={register} className="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary" >
                                    <option hidden value=""> Select district</option>
                                    {districtsOfState.map(dis => <option key={dis}>{dis}</option>)}
                                </select>

                            </div>

                            <div className="field col" style={{ display: 'flex' }}>
                                <label>Address Details<span style={{ color: 'red' }}>*</span></label>
                                <input id="address" align="center" name="address" ref={register} className="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary   "></input>

                                <label>Pin Code<span style={{ color: 'red' }}>*</span></label>
                                <input id="Pincode" align="center" name="pincode" ref={register}  onInput={inputNoOnly} className="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary   "></input>
                            </div>
                            <div className="field col" style={{ display: 'flex' }}>
                            <label >Fill Owner Details</label>
                                <Checkbox onChange={e => setChecked(e.checked)} checked={checked} ></Checkbox>
                            </div> 
                            <div hidden={!checked}>
                                <hr />
                                <div className="field col">
                                    <label>Owner Name</label>
                                    <input id="Owner Name" type="text" name="ownerName" ref={register} className="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary   "></input>
                                    <label>Email</label>
                                    <input id="Email" type="text" name="ownerEmail" ref={register} className="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary   "></input>
                                </div>
                                <div className="field col">
                                    <label>Mobile</label>
                                    <input id="ownerMobile" type="text" name="ownerMobile1" maxLength="10" onInput={inputNoOnly} ref={register} className="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary   "></input>
                                    <label>Alt. Mobile</label>
                                    <input id="ownerAltMobile" type="text" name="ownerMobile2" ref={register} maxLength="10" onInput={inputNoOnly} className="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary   "></input>
                                </div>
                                <div className="field col">
                                    <label>Aadhar No</label>
                                    <input id="aadharNo" type="text" name="aadharNo" maxLength="12" onInput={inputNoOnly} ref={register} className="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary   "></input>
                                    <label>Aadhar File</label>
                                    {/* <FileUpload mode="basic" name="demo[]" customUpload  url={uploadUrl} accept="image/*" maxFileSize={1000000} uploadHandler={onAdhharUpload} /> */}
                                    <input id="aadharFile" type="file" name="aadharFileInfo" ref={register} className="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary   "></input>
                                </div>
                                <div className="field col">
                                    <label>Pan No</label>
                                    <input id="panNo." type="text" name="pancardNo" ref={register} maxLength="10" className="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary   "></input>
                                    <label>Pan File</label>
                                    <input id="Pan File" type="file" name="panFileInfo" ref={register} className="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary   "></input>
                                </div>
                                <div className="field col" style={{ display: 'flex' }}>
                                    <label>Address Details</label>
                                    <input id="Address Details" type="text" name="ownerAddress" ref={register} className="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary   "></input>
                                </div>
                            </div>
                            <div className="formgroup col">
                                <button className='saveButton button' style={{ width: '100px' }} type="button" onClick={handleSubmit(onSave)}>{buttonLabel}</button>
                            </div>
                            {/* </div> */}
                        </div>
                    </div>
                </div>
            </div>

            {/* )} */}


            {/* {displayTable && ( */}
            <div hidden={!displayTable}>
                <div className='box'>
                    <div className="header">
                        <button className='addButton button ' style={{ width: '100px' }} disabled={disabledButton} onClick={onAdd} type="button">Add</button>
                    </div>

                    <DataTable value={franchiseData} header={header} filters={filters1} filterDisplay="menu" globalFilterFields={['name', 'ownerName', 'mobile1', 'franchiseAddress']} className="dataTable"  >
                        <Column field="serialNo" header="Sr.No"  ></Column>
                        <Column field="name" header="Franchise Name"  ></Column>
                        <Column field="ownerName" header="Owner"  ></Column>
                        <Column field="mobile1" header="Mobile"  ></Column>
                        <Column field="franchiseAddress" header="Address"  ></Column>
                        <Column body={Buttons} style={{ width: '500px' }} field="name" header="Action"  ></Column>

                    </DataTable>
                </div>
            </div>
            {/* )} */}
        </div>



    );
}
export default Franchise;