// import './AdminStockReturn.css';
// import '../App.css';
import 'primeflex/primeflex.css';

import { Calendar } from 'primereact/calendar';

import React, { useState, useEffect, useRef } from 'react';
import { useForm } from "react-hook-form";
import { Dropdown } from 'bootstrap';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import GreenFieldService from '../../services/greenFieldService';
import { Dialog } from 'primereact/dialog';
import axios from 'axios';
import { Toast } from 'primereact/toast';
import Config from '../../Config.json';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import ExportCsvReport from '../../exportReport/exportCsvReport';
import ExportPdfReport from '../../exportReport/exportPdfReport';
import ExportExcelReport from '../../exportReport/exportExcelReport';
import '../../App.css';
import '../../components/Franchise/ReceivedStockEnties.css';
import moment from 'moment';
import { BlockUI } from 'primereact/blockui';
import authHeader from '../../authHeader';
import { useContext } from 'react';
import { GreenFieldContext } from '../../App';
import ToolBoxGrid from '../Misc/ToolBoxGrid';
import { LockDateTimerMilliSecondValue, shouldLockDate } from '../Misc/utilities';
var totalQuantitySum;
var totalValueSum;

function ReceivedStockEntries() {
    const dt = useRef(null);
    const [date3, setDate3] = useState(null);
    const greenFieldService = new GreenFieldService();
    const exportCsvReport = new ExportCsvReport();
    const exportPdfReport = new ExportPdfReport();
    const exportExcelReport = new ExportExcelReport();

    const toast = useRef(null);
    const [initialReceivedData, setInitialReceivedData] = useState([]);
    const [receivedStock, setReceivedStock] = useState();
    const [visible, setVisible] = useState(false);
    const [selectedRowData, setRowData] = useState();
    const [filters1, setFilters1] = useState(null);
    const [expandedRows, setExpandedRows] = useState(null);
    const [updatedData, setUpdatedData] = useState([]);
    const [expandedCount,setExpandedCount]=useState(0);
    const [disable, setDisable] = useState(false);
    const franchiseId = localStorage.getItem('franchiseId');
    const [blockedPanel, setBlockedPanel] = useState(false);
    var accessLevel = parseInt(sessionStorage.getItem('accessLevel'));


    const cols = [
        { field: 'serialNo', header: 'Sr.No' },
        { field: 'dcNo', header: 'Dc Number' },
        { field: 'dcDateFormat', header: 'Dc Date' },
        { field: 'totalQuantity', header: 'Total Quantity' },
        { field: 'totalValue', header: 'Total Value' }

    ];

    const exportColumns = cols.map(col => ({ title: col.header, dataKey: col.field }));


    const {register, handleSubmit, reset, setValue, } =
        useForm();
    const {
        register: Filter,
        handleSubmit: handleFilter,
        setValue: setFilterValue,
    } = useForm();
    const [globalFilter, setGlobalFilter] = useState();

    const [locked, setIsLocked]=useState(false);
   
    const [lockDate, setIsLockDate] = useState();
    const [finalLock, setFinalLock] = useState(false); 

    function countDown() {
        // time = time - 1;
        greenFieldService.GetFranchiseLockScreenStatus(localStorage.getItem('franchiseId')).then(data => {
        //    if(locked!=data.currentLockStatus){
            setIsLocked(data.currentLockStatus);
          //  }
        });
    }

      
    useEffect(() => {
        let d = moment(new Date()).format('YYYY-MM-DD');
        setFilterValue('fyDate',d);
        setFilterValue('toDate',d);

        getReceivedtockData(parseInt(franchiseId));
        initFilters1();

        if(accessLevel > 1)
        {
          setBlockedPanel(false);
        }
        else{
            setBlockedPanel(true);
        }
        countDown();
    }, [])

    useEffect(() => {
        const interval = setInterval(() => {
            countDown();
        }, LockDateTimerMilliSecondValue);
        return () => clearInterval(interval);


    }, [])

    const getReceivedtockData = (franchiseId) => {
        let isReceivedStock = true;
        greenFieldService.GetIsReceivedStock(franchiseId, isReceivedStock).then(data => {
            let dcSerialNo = 1;

            data.forEach(a => {
                a.serialNo = dcSerialNo;
                a.dcDate= a.dcDate.replace('T', ' ');
                a.dcDateValue=new Date(a.dcDate);
                let serialNo = 1;
                // totalQuantityDCwiseSum = (a.stockInfoDetails.reduce((a, v) => a + parseInt(v.quantity), 0));
                a.stockInfoDetails.forEach(b => {
                    b.serialNo = serialNo;
                    b.productName = `${b.productName} - ${b.package}`;
                    b.stockValue = b.stockValue.toFixed(2);
                    serialNo++;
                }
                )

                totalQuantitySum = (data.reduce((a, v) => a + parseFloat(v.totalQuantity), 0));
                totalValueSum = (data.reduce((a, v) => a + parseFloat(v.totalValue), 0));
                dcSerialNo++;
            });
            setReceivedStock(data);
            setInitialReceivedData(data);
        });
    }
    const onChangeGlobal = (e) => {
        let value = e.target.value;
        let _filters1 = { ...filters1 };
        _filters1['global'].value = value;

        setFilters1(_filters1);

        setGlobalFilter(value);
    }

    const initFilters1 = () => {
        setFilters1({
            'global': { value: null, matchMode: FilterMatchMode.CONTAINS },

        });
        setGlobalFilter('');
    }
    var errors = [];

    const filterValidate = (data) => {
        if (!data?.fyDate) {
            errors.push('* From Date is required.', <br />);
        }
        if (!data?.toDate) {
            errors.push('* To Date is required', <br />);
        }
    }
    function dateTime(dateValue){
        var x = new Date(dateValue);// x is now a date object
        x.setHours(0,0,0,0); 
        return x;
       }

    const onFilter = (filterValue) => {
        let fromdate=dateTime(filterValue.fyDate)  ;
        // fromdate.setDate(fromdate.getDate()-1);
        let toDate=dateTime(filterValue.toDate);

        filterValidate(filterValue)
        if (errors.length > 0) {
            // eslint-disable-next-line no-lone-blocks
            {
                errors.map(() => {
                    toast.current.show({ severity: 'warn', detail: errors, sticky: true });
                }

                )
            }
            errors = [];
            return;
        }
        let data = initialReceivedData.filter(a => a.dcDateValue >= fromdate && a.dcDateValue <= toDate);
        let srNo = 1;
        data.forEach(d => {
            d.serialNo = srNo;
            srNo++;
        })
        totalQuantitySum = (data.reduce((a, v) => a + parseFloat(v.totalQuantity), 0));
        totalValueSum = (data.reduce((a, v) => a + parseFloat(v.totalValue), 0));
        setReceivedStock(data);

    }


    const onReset = () => {

        const fields = [
            "fyDate",
            "toDate"
        ];
        fields.forEach((field) => setFilterValue(field, ''));
        getReceivedtockData(franchiseId);
      

    }

    const buttons =
        <ColumnGroup>
            <Row>
                <Column footer="" footerStyle={{ textAlign: 'right' }} />
                <Column footer='' />
                <Column footer='' />
                <Column footer='' />
                <Column footer={''} />
                <Column footer='' />
                <Column footer='' />
                <Column footer='' />
                <Column footer='' /> 
                <Column footer=<button className='reset' hidden={disable} type="button" onClick={() => onUpdateStatus()}>Update</button>
                />
                
            </Row>
        </ColumnGroup>;

    const onUpdateStatus = () => {
        // setVisible(true);
        // setRowData(rowData)
        if (updatedData.length <= 0) {
            toast.current.show({ severity: 'warn', detail: "No data To Update", sticky: true });
            return;
        }
        return axios.put(`${Config.apiUrl}/Stocks/UpdateStatus`, updatedData, { headers: authHeader() })
            .then(response => {
                if (response.data.success) {
                    toast.current.show({ severity: 'success', detail: 'Data Update Successfully', autoDismiss:true });
                    setUpdatedData('');
                }
                else {
                    toast.current.show({ severity: 'error', detail: response.data.msg, sticky: true });
                }
            }).catch(error => {
                toast.current.show({ severity: 'error', sticky: true, summary: 'Error', detail: error });
            })
            .finally(() => {
                getReceivedtockData(franchiseId);
                setExpandedCount(0);

                // setSpinnerVisible(false);
                // SetFocusValue(true);
            })

    }

    const onHide = () => {
        setVisible(false);

    }

    const statuses = [
        'Pending', 'Received', 'Not_Received'
      ];
      
      const statusTemplate = (rowData) => {
        return <span className={`product-badge status-${(rowData.status ? rowData.status.toLowerCase() : '')}`}>{rowData.status}</span>;
      }
      const receivedStatusTemplate = (status) => {
        return <span className={`product-badge status-${(status ? status.toLowerCase() : '')}`}>{status}</span>;
      }


    const header = (
        <div className="table-header">
            <div>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" className="globleInput" value={globalFilter} onChange={onChangeGlobal} placeholder="Global Search" />
                </span>
            </div>
        </div>
    );

    const cellEditor = (options) => {
        if (options.field === 'status')
            return statusDropdown(options)
        else
            return noteInput(options)
        
    }
    const onRowGroupExpand = (event) => {
        let count=expandedCount+1;
        setExpandedCount(count);
        
        if(count > 1)
        {
            setDisable(true);
        }
    }

    const onRowGroupCollapse = (event) => {
        let count=expandedCount - 1;
        setExpandedCount(count);
        if(count <= 1)
        {
            setDisable(false);
        }
    }

    const allowExpansion = (rowData) => {
        //  setExpandedIndex(rowData.orders.length);
        return rowData.orders.length > 0;
    };

    const rowExpansionTemplate = (data) => {
        return (
            <div className="orders-subtable">
                {/* <h5>Orders for {data.name}</h5> */}
                <DataTable value={data.stockInfoDetails} responsiveLayout="scroll" onCellEditComplete={onCellEditComplete} footerColumnGroup={!disable ? buttons : ''}>
                    <Column field="serialNo" header="Sr.No"></Column>
                    <Column field="productName" header="Prodcut Name"></Column>
                    <Column field="batchNo" header="Batch No"></Column>
                    <Column field="quantity" header="Quantity"></Column>
                    <Column field="dcNo" header="Dc No"></Column>
                    <Column field="dcDateFormat" header="DC Date"></Column>
                    <Column field="addedDateFormat" header="Stock Added Date"></Column>
                    <Column field="stockValue" header="Stock Value"  ></Column>
                    <Column field="status" header="Status" body={statusTemplate} editor={(options) => options.value.toUpperCase()!== "RECEIVED" ? cellEditor(options) : receivedStatusTemplate(options.value) }  onCellEditComplete={ onCellEditComplete}> </Column>
                    <Column field="note" header="Note" editor={(options) => options.rowData.status.toUpperCase()!== "RECEIVED" ? cellEditor(options) : options.value} onCellEditComplete={onCellEditComplete}></Column>
                    {/* <Column body={Button} field="mobile1" header="Action"></Column> */}
                </DataTable>
            </div>
        );
    }

    let stockEntriesFooterGroup = <ColumnGroup>
        <Row>
            <Column footer="Totals:" footerStyle={{ textAlign: 'right' }} />
            <Column footer='' />
            <Column footer='' />
            <Column footer='' />
            <Column footer={totalQuantitySum} />
            <Column footer={totalValueSum} />
        </Row>
    </ColumnGroup>;

    const statusDropdown = (options) => (
        // data.stockInfoDetails.forEach(a=>{
        //     setValue('status',a.status);
        //     // setValue('note',a.note);
        // })


        <div>
            <select name="status" ref={register} value={options.value} onChange={(e) => options.editorCallback(e.target.value)} style={{ height: '30px', width: '200px', marginRight: '10px' }}>
                <option hidden value="">Select Status </option>
                {/* <option >Pending </option>
                <option >Return </option> */}
                <option>Received</option>
                <option>Not_Received</option>
            </select>

        </div>
    );

    function onToDateChange(e){
        validateLockDate(e.target.value);
    }

    function validateLockDate(selectedDate) {
        if (locked) {

            if (shouldLockDate(selectedDate, lockDate)) {

                setFinalLock(true);
                toast.current.show({ severity: 'warn', detail: `Franchise is locked till ${lockDate}`, summary: 'Payment Details' });
            }
            else {
                setFinalLock(false);
            }
        }
        else {
            setFinalLock(false);
        }
    }
    const noteInput = (data) => (
        <div className="table-header">
            <div>
                <input id="Note" type="text" name="note" ref={register} value={data.value} onChange={(e) => data.editorCallback(e.target.value)} style={{ width: '350px', height: '30px' }}></input>
                {/* <InputText type="text" value={data.value} onChange={(e) => data.editorCallback(e.target.value)} /> */}

            </div>
        </div>
    );

    const onCellEditComplete = (e) => {
        let { rowData, newValue, field, originalEvent: event } = e;
        rowData.state = 'Updated';
        if (field === 'status') {
            rowData[field] = newValue;
        }
        else {
            rowData[field] = newValue;
        }
        // updatedData.push(rowData);
        // setUpdatedData(...updatedData);

        let _updatedData = [...updatedData];
        let index = _updatedData.findIndex(a => a.id === rowData.refId);
        if (index >= 0) {
            updatedData[index].status = rowData.status;
            updatedData[index].note = rowData.note;

        }
        else {
            let _rowData = { id: rowData.refId, status: rowData.status, note: rowData.note };
            _updatedData.push(_rowData);
            setUpdatedData(_updatedData);
        }
    }

    const exportCSV = (selectionOnly) => {
        // <exportCsvReport dt={dt}/>
        exportCsvReport.exportCSV(dt)
        // dt.current.exportCSV();
    }

    const exportPdf = () => {
        let fileName="Received Stock Entires";
        exportPdfReport.exportPdf(exportColumns,receivedStock,fileName);

    }

    const exportExcel = () => {
        let fileName="Received Stock Entires";
        exportExcelReport.exportExcel(receivedStock,fileName);
    }

    const getRowClassName = (rowData) => {
        if (rowData.status === 'Received') {
          return 'received-row';
        } 
        else {
          return 'pending-row';        
        }
      };

    return (
        // <BlockUI blocked={blockedPanel || locked}>
        <BlockUI blocked={blockedPanel}>

        <div className="AdminStockReturn">
            <Toast ref={toast} position={'center'} />
            <h3>Received Stock Entries</h3>
            <div  style={{ minHeight: '1029px' }}>
                <div className="box">
                    <div className="field col">
                        <label>From:</label>
                        <input type='date' name='fyDate' className='date' ref={Filter} />
                        <label>To:</label>
                        <input type='date' name='toDate' onChange={onToDateChange} className='date' ref={Filter} />
                        <button type="button filter" className='filter' onClick={handleFilter(onFilter)}>Filter</button>
                        <button type="button" className='reset' onClick={onReset}>Reset</button>
                    </div>
                </div>

                <div className='box'>
                    {/* <div>
                        <button type='button' >Copy</button>
                        <button type='button' onClick={() => exportCSV(false)}>CSV</button>
                        <button type='button' onClick={() => exportExcel()}>Excel</button>
                        <button type='button' onClick={() => exportPdf()}>PDF</button>
                        <button type='button'>Print</button>
                    </div> */}
                    {/* <DataTable value={receivedStock} className='dataTable' header={header} globalFilter={globalFilter} editMode='row'>
                        <Column field="serialNo" header="Sr No"></Column>
                        <Column field="productName" header="Product"></Column>
                        <Column field="batchNo" header="Batch No"></Column>
                        <Column field="package" header="Package/Size"></Column>
                        <Column field="quantity" header="Qty"></Column>
                        <Column field="addedDateFormat" header="Stock Added Date"></Column>
                        <Column body={statusDropdown} header="Status"></Column>
                        <Column field="note" header="Note"></Column>
                        <Column body={buttons} header="Action"></Column>
                    </DataTable> */}
                    <ToolBoxGrid columns={exportColumns} data={receivedStock} fileName="Franchise received Stock Report"></ToolBoxGrid>

                    <DataTable ref={dt} value={receivedStock} header={header} filters={filters1} paginator rows={10}
                        className="dataTable" editMode="row" rowExpansionTemplate={rowExpansionTemplate} expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)}
                        onRowExpand={onRowGroupExpand} onRowCollapse={onRowGroupCollapse} footerColumnGroup={stockEntriesFooterGroup} rowClassName={getRowClassName}>
                        <Column expander={allowExpansion} style={{ width: '3em' }} />
                        <Column field="serialNo" header="Sr.No" sortable></Column>
                        <Column field="dcNo" header="Dc Number" sortable></Column>
                        <Column field="dcDateFormat" header="Dc Date" sortable></Column>
                        <Column field="totalQuantity" header="Total Quantity" sortable></Column>
                        <Column field="totalValue" header="Total Value" sortable></Column>
                    </DataTable>
                </div>

                {/* <Dialog header="Update Status" visible={visible} onHide={() => onHide()} breakpoints={{ '960px': '75vw' }} style={{ width: '50vw' }} footer={renderFooter('displayResponsive')}>
                    <div className="field col">
                        <Toast ref={toast} position={'center'} />

                        <label for="Product Name">Status<span style={{ color: 'red' }}>*</span></label>
                        <select name="status" ref={register} style={{ height: '30px', width: '200px', marginRight: '10px' }}>
                            <option hidden value="">Select Status </option>
                            <option>Received</option>
                            <option>Not_Received</option>
                        </select>
                    </div>
                    <div className="field col">

                        <label for="Note">Note<span style={{ color: 'red' }}>*</span></label>
                        <input id="Note" type="text" name="note" ref={register({ required: true })} className="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary"></input>
                    </div>
                </Dialog> */}
            </div>
        </div>
        </BlockUI>
    );
}
export default ReceivedStockEntries;