import '../../App.css';
import { DataTable } from 'primereact/datatable';
import React, { useState, useEffect, useRef } from 'react';
import GreenFieldService from '../../services/greenFieldService';
import { Column } from 'primereact/column';

const ViewLogs = () => {
    const [globalFilter, setGlobalFilter] = useState();
    const greenFieldService = new GreenFieldService();
    const [logs, setLogs] = useState([]);
    useEffect(() => {
        greenFieldService.GetLogs().then(data => {
            let count = 1;
            
        let arrMsgs= [];
            data.forEach(item => {
                let logItem={};

                logItem.id = count++;
                logItem.msg=item;
                arrMsgs.push(logItem);
            });
            setLogs(arrMsgs);
        
        });
    }, [])

        return (

            <div className='box'>
                <DataTable className='dataTable' value={logs} dataKey="id"  globalFilter={globalFilter} >
                        <Column  style={{widith:'0%', display:'none'}} field="id" header="Sr No"></Column>
                        <Column field="msg" header="Message"></Column>
                </DataTable>

            </div>


        );
    }

export default ViewLogs;