import 'primeicons/primeicons.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';
import React, { useState, useEffect, useContext, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import moment, { invalid } from 'moment';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import ProgressSpinBarControl from '../Misc/progressSpinBarControl';
import '../Franchise/InvoiceReport.css';
import GreenFieldService from '../../services/greenFieldService';
import { useParams } from 'react-router';
import { useLocation } from "react-router-dom";
import logo from "../../image/greenfield new logo.png";

const franchiseId = parseInt(localStorage.getItem('franchiseId'));
// const customerId = parseInt(localStorage.getItem('customerId'));
// const invNo = parseInt(localStorage.getItem('invNo'));

var converter = require('number-to-words');
var PrintData;
var requestedQtySum;
var dispatchQtySum;

function OrderRequestPrint({ props }) {


    const location = useLocation();

  // setBillDetails(location.state.detail);
    PrintData = location.state.detail;
    var [spinnerVisible, setSpinnerVisible] = useState(false);
    var converter = require('number-to-words');
    const greenFieldService = new GreenFieldService();
    requestedQtySum = (PrintData.orderDetails.reduce((a, v) => a + parseFloat(v.quantity), 0));
    dispatchQtySum = (PrintData.orderDetails.reduce((a, v) => a + parseFloat(v.dispatchQuantity), 0));

    // const [franchiseData, setFranchiseData] = useState([]);
    // franchiseDetails = franchiseData;
    // const [customerData, setCustomerData] = useState([]);
    // customerDetail = customerData;
    // const [invoiceData, setInvoiceData] = useState([]);
    // PrintData = PrintData;


    useEffect(() => {
        // greenFieldService.GetFranchiseDataOnId(franchiseId).then(data => {
        //     data.forEach(a => a.franchiseAddress = `${a.address},${a.state}/${a.district}`);
        //     setFranchiseData(data);
        // });

        // greenFieldService.GetCustomerDetail(customerId).then(data => {
        //     data.forEach(a => {
        //         a.isKisanCardValue = a.isKisanCard ? "Yes" : "No";
        //     })
        //     setCustomerData(data);
        // });

        // greenFieldService.GetReturnInvoice(invNo, franchiseId).then(data => {
        //     data[0].invoiceReportDetailsInfo.forEach(a => {
        //         a.taxableRate = parseFloat(a.taxableRate);
        //         a.totalTaxAmount = parseFloat(a.totalTaxAmount);
        //         a.stateAmount = parseFloat(a.stateAmount);
        //         a.centerAmount = parseFloat(a.centerAmount);

        //     })
        //     taxableValueSum = (data[0].invoiceReportDetailsInfo.reduce((a, v) => a + parseFloat(v.taxableRate), 0));
        //     centerAmountSum = (data[0].invoiceReportDetailsInfo.reduce((a, v) => a + parseFloat(v.centerAmount), 0));
        //     stateAmountSum = (data[0].invoiceReportDetailsInfo.reduce((a, v) => a + parseFloat(v.stateAmount), 0));
        //     totalTaxAmountSum = (data[0].invoiceReportDetailsInfo.reduce((a, v) => a + parseFloat(v.totalTaxAmount), 0));
        //     data[0].amountInWord = converter.toWords(Math.round(data[0]?.grandTotal))
        //     data[0].amountInWord =data[0].amountInWord.toUpperCase();
        //     let srNo=1;
        //     data[0].hsnReportDetailsInfo.forEach(a=>{
        //         a.serialNo=srNo;
        //         srNo++;
        //     })

        //     setInvoiceData(data);

        // });



    }, []);

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

   

    return (
        <div className='MemberBill'>
            <div class="card">
                <ProgressSpinBarControl spinBarVisible={spinnerVisible} />
                {/* <Toast ref={toast} position="center" /> */}

                <div className="Form-inline" >
                    {/* <div class="SelectionCard"  > */}

                    <Button label="Print" onClick={handlePrint} />
                </div>
                <OrderPrint ref={componentRef} />
            </div>
        </div>
    );

}

const portrait = () => `
footer {
    font-size: 9px;
    color: #f00;
    text-align: center;
  }

  @page {
    size: portrait;
    margin: 10mm 15mm 5mm 15mm;
  }

  @media print {
    footer {
      position: fixed;
      bottom: 0;
    }

    .content-block, p {
      page-break-inside: avoid;
    }

    html, body {
      width: 210mm;
      height: 295mm;

    }
  }
`;

class OrderPrint extends React.Component {
    render() {
        // const societyService = new SocietyService();


        return (
            <div className="InvoicePrint">
                <style>{portrait()}</style>

                <div className="Table">
                    <table>
                        <tbody>
                           

                            <tr>
                                <th class="text-center" rowspan="4" style={{ width: '10%' }}>
                                    <img src={logo} alt="logo" style={{ width: '100px' }} />

                                </th>
                                <th class="text-center" rowspan="3" style={{ width: '600px' }}>
                                    <b style={{ height: '50px' }} ><h3> {PrintData.franchiseName}</h3> </b><br />
                                    <table>
                                        <tbody>
                                            <td style={{ width: '50%' }}>
                                                Order Number : {PrintData.fullOrderNo}
                                            </td>
                                            <td style={{ width: '50%' }}>
                                                Order Date :{PrintData.orderDateFormat}
                                            </td>
                                        </tbody>
                                    </table>
                                </th>
                            </tr>
                        </tbody>
                    </table>

               

                  


                    {/* {PrintData.orderDetails.map((data, index) => */}

                        <table>
                            <thead>
                                <tr>
                                    <th style={{ width: '7%' }} className="text-center">Sr No</th>
                                    <th style={{ width: '43%' }} className="text-center">Product Name</th>
                                    <th style={{ width: '25%' }} className="text-center">Requested Qty</th>
                                    <th style={{ width: '25%' }} className="text-center">Dispatched Qty</th>
                                   
                                </tr>
                            </thead>

                            <tbody>
                                {PrintData.orderDetails.map((item,index) => (
                                    <tr key={item}>
                                        <td style={{ textAlign: 'right' }}>{item?.srNo}</td>
                                        <td>{item?.productName}</td>
                                        <td style={{ textAlign: 'right' }}>{item?.quantity}</td>
                                        <td style={{ textAlign: 'right' }}>{item?.dispatchQuantity}</td>
                                       
                                    </tr>
                                ))
                                }
                            </tbody>



                        </table>
                    {/* )} */}

                    



                    {/* {PrintData.map((data, index) =>

                    <table>
                        <tbody>
                            <tr colspan="3">
                                <td style={{ width: '62%' }}><b>AMOUNT IN WORD :{PrintData[index].amountInWord} RUPPES ONLY </b></td>
                                <td style={{ width: '14%' }}>Including Tax Total</td>
                                <td style={{ width: '7%', textAlign: 'right'}}><b>{PrintData[index]?.grandTotal.toFixed(2)}</b></td>

                            </tr>
                        </tbody>
                    </table>
                    )} */}
                    
                    {/* <table>
                        <tbody>
                            <tr colspan="2">
                                <td style={{ width: '62%' }}><b>AMOUNT IN WORD :{""} RUPPES ONLY </b></td>
                                <td style={{ width: '14%' }}>Including Tax Total</td>
                                <td style={{ width: '7%', textAlign: 'right'}}><b>{""}</b></td>

                            </tr>
                        </tbody>
                    </table> */}

                    <table>
                        <tbody>
                            <tr>
                            <td style={{ width: '50%' ,textAlign: 'right' }}><b>Total</b></td>
                                <td style={{ width: '25%',textAlign: 'right'  }}><b>{requestedQtySum}</b></td>
                                <td style={{ width: '25%',textAlign: 'right'  }}><b>{dispatchQtySum}</b></td>                           
                            </tr>
                            <tr>
                            <td style={{ width: '50%' }}><b>Vehicle No :  </b></td>
                                <td style={{ width: '25%' }}><b> Dc No :  </b></td>
                                <td style={{ width: '25%' }}><b>Dc Date : </b></td>                           
                            </tr>
                        </tbody>
                    </table>

                    <table>
                        <tbody>
                            <tr colspan="3">
                                <td style={{ width: '50%',textAlign: 'center' }}  >
                                    &nbsp;<br />
                                    &nbsp;<br />
                                    <b style={{ textAlign: 'right' }}>Dispatch Supervisor Sign</b>
                                </td>
                                <td style={{ width: '50%',textAlign: 'center' }} >
                                &nbsp;<br />
                                    &nbsp;<br />
                                <b >Manager Sign</b>

                                </td>

                         

                            </tr>
                        </tbody>
                    </table>
                    {/* )} */}
                </div>
            </div>

        )
    }
}
export default OrderRequestPrint;