// import './AdminStockReturn.css';
import '../../App.css';
import 'primeflex/primeflex.css';
import { Calendar } from 'primereact/calendar';
import React, { useState,useEffect,useRef } from 'react';
import { useForm } from "react-hook-form";
import { Dropdown } from 'bootstrap';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import GreenFieldService from '../../services/greenFieldService';
import { useHistory } from 'react-router-dom';
import ExportCsvReport from '../../exportReport/exportCsvReport';
import ExportExcelReport from '../../exportReport/exportExcelReport';
import ExportPdfReport from '../../exportReport/exportPdfReport';
import { Toast } from 'primereact/toast';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { Button } from 'primereact/button';
import axios from 'axios';
import Config from '../../Config.json';
import authHeader from '../../authHeader';
import ProgressSpinBarControl from '../Misc/progressSpinBarControl';

var netAmountSum;
var royaltySum;
var accessLevel = parseInt(sessionStorage.getItem('accessLevel'));

function SalesInvoice() {
    let history = useHistory();
    const [date3, setDate3] = useState(null);
    const toast = useRef(null);
    const {
        register: Filter,
        handleSubmit: handleFilter,
        setValue: setFilterValue,
    } = useForm();
    const [filters1, setFilters1] = useState(null);
    const [globalFilter, setGlobalFilter] = useState();
    const [invoicesData,setInvoicesData]=useState();
    const [initialData,setInitialData]=useState();
    const [isDisabled,setIsDisabled]=useState(false);


    const greenFieldService = new GreenFieldService();
    const exportCsvReport = new ExportCsvReport();
    const exportPdfReport = new ExportPdfReport();
    const exportExcelReport = new ExportExcelReport();
    const dt = useRef(null);
    const franchiseId = localStorage.getItem('franchiseId');
    const franchiseName = localStorage.getItem('FranchiseName');
    const [spinnerVisible, setSpinnerVisible] = useState(false);
    const [fromDate,setFromDate]=useState();
    const [toDate,setToDate]=useState();;




    useEffect(() => {
        // GetAllInvoiceDetails(franchiseId);
        initFilters1();

        if (accessLevel > 1) {
            setIsDisabled(false);
        }
        else {
            setIsDisabled(true);
        }

    }, [])

    const GetAllInvoiceDetails=(franchiseId,fromDate,toDate)=>{
        greenFieldService.GetInvoiceList(franchiseId,fromDate,toDate).then(data => {
            netAmountSum = (data.reduce((a, v) => a + parseFloat(v.dueAmount), 0));
            royaltySum = (data.reduce((a, v) => a + parseFloat(v.royalty), 0));
            let srNo = 1;
            data.forEach(d => {
                d.serialNo = srNo;
                srNo++;
                d.dueAmount=parseFloat(d.dueAmount).toFixed(2);
                d.royalty=parseFloat(d.royalty).toFixed(2);
                d.invoiceDate= d.invoiceDate.replace('T', ' ');
                d.invoiceDateValue=new Date(d.invoiceDate);
             })
            
            setInvoicesData(data);
            setInitialData(data);
        }).catch(error => {
            toast.current.show({ severity: 'error', sticky: true, summary: 'Error', detail: 'Error in getting All Invoices Data' });
        })
        .finally(() => {
            setSpinnerVisible(false);
        });
    }

    const onChangeGlobal = (e) => {
        let value = e.target.value;
        let _filters1 = { ...filters1 };
        _filters1['global'].value = value;

        setFilters1(_filters1);

        setGlobalFilter(value);
    }
    
    const initFilters1 = () => {
        setFilters1({
            'global': { value: null, matchMode: FilterMatchMode.CONTAINS },

        });
        setGlobalFilter('');
    }

    const cols = [
        { field: 'serialNo', header: 'Sr.No' },
        { field: 'invName', header: 'Invoice Number' },
        { field: 'customerName', header: 'Customer' },
        { field: 'customerAddress', header: 'Address' },
        { field: 'grandTotal', header: 'Total Amount' },
        { field: 'discountAmount', header: 'Discount' },
        { field: 'dueAmount', header: 'Due Amount' },
        { field: 'Royalty', header: 'Loyalty' },
        { field: 'invDateFormat', header: 'Date' }
        

    ];
    const exportColumns = cols.map(col => ({ title: col.header, dataKey: col.field }));

    var errors = [];

    const filterValidate = (data) => {
        if (!data?.fyDate) {
            errors.push('* From Date is required.', <br />);
        }
        if (!data?.toDate) {
            errors.push('* To Date is required', <br />);
        }
    }

    const onFilter = (filterValue) => {
        // let fromdate=new Date(filterValue.fyDate)  ;
        // fromdate.setDate(fromdate.getDate()-1);
        // let toDate=new Date(filterValue.toDate);

        filterValidate(filterValue)
        if (errors.length > 0) {
            // eslint-disable-next-line no-lone-blocks
            {
                errors.map(() => {
                    toast.current.show({ severity: 'warn', detail: errors, sticky: true });
                }

                )
            }
            errors = [];
            return;
        }
        
        GetAllInvoiceDetails(franchiseId,filterValue.fyDate,filterValue.toDate);
        setFromDate(filterValue.fyDate);
        setToDate(filterValue.toDate);  

        // let data = initialData.filter(a => a.invoiceDateValue >= fromdate && a.invoiceDateValue <= toDate);
        // netAmountSum = (data.reduce((a, v) => a + parseFloat(v.dueAmount), 0));
        // royaltySum = (data.reduce((a, v) => a + parseFloat(v.royalty), 0));
        // let srNo = 1;
        // data.forEach(d => {
        //     d.serialNo = srNo;
        //     srNo++;
        // })
        

        // setInvoicesData(data);

    }

    const onReset = () => {

        const fields = [
            "fyDate",
            "toDate"
        ];
        fields.forEach((field) => setFilterValue(field, ''));
        GetAllInvoiceDetails(franchiseId);

    }

    const onBack = (e) => {
        history.push(`/Franchise`);

    }
   

    const buttons = (rowData) => {
        return <div>
            <button className=' button' type="button" onClick={() => onPrint(rowData)}>Print</button>
            {rowData.status !== "Return" && 
            <button className=' delete' type="button" disabled={isDisabled} onClick={() => onDelete(rowData)}>Delete</button>
            }
        </div>;
    }

    const onManage=()=>{

    }

    let totalFooter = <ColumnGroup>
    <Row>
        <Column footer="Totals:" footerStyle={{ textAlign: 'right' }} />
        <Column footer='' />
        <Column footer='' />
        <Column footer='' />
        <Column footer={parseFloat(netAmountSum).toFixed(2)} />
        <Column footer={parseFloat(royaltySum).toFixed(2)} />
        <Column footer='' />
    </Row>
    </ColumnGroup>;

    const onPrint =(rowData)=>{
        // history.push('/InvoicePrint');
        // history.push(`/InvoicePrint/${rowData.customerId}/${rowData.franchiseId}/${rowData.categoryId}/${rowData.invNo}`);
        history.push(`/InvoicePrint/${rowData.customerId}/${rowData.invNo}/${rowData.franchiseId}`);
    }

    const onAllPrint=()=>{
        // history.push(`/InvoicesPrint/${franchiseId}`);

        history.push({
            pathname: '/InvoicesPrint',
            state: { franchiseId: franchiseId ,fromDate : fromDate ,toDate:toDate}
        })
    }

    const onDelete = (rowData) => {
        if (rowData != null) {
            toast.current.show({
                severity: 'warn', sticky: true, content: (
                    <div className="flex flex-column" style={{ flex: '1' }}>
                        <div className="text-center">
                            <i className="pi pi-exclamation-triangle" style={{ fontSize: '3rem' }}></i>
                            <h4>Are you sure?</h4>
                            <p>Confirm to proceed</p>
                        </div>
                        <div className="grid p-fluid">
                            <div className="col-6">
                                <Button type="button" label="Yes" onClick={() => onYes(rowData)} className="p-button-success" />
                            </div>
                            <div className="col-6">
                                <Button type="button" label="No" onClick={() => onNo(rowData)} className="p-button-secondary" />
                            </div>
                        </div>
                    </div>
                )
            });
        }
        else {
            toast.current.show({ severity: 'warn', sticky: true, detail: "PLEASE SELECT ROW" });
            return;
        }
    }

    const onYes = (rowData) => {
        toast.current.clear();
        try {
            axios.delete(`${Config.apiUrl}/Invoice/DeleteInvoice?id=${rowData.id}`, { headers: authHeader() })
                .then(response => {
                    if (response.data.success) {
                        toast.current.show({ severity: 'success', detail: response.data.msg, autoDismiss:true});
                        // tempComplaints = tempComplaints.filter(a => a.id !== item.id);

                    }
                    else {
                        toast.current.show({ severity: 'error', detail: response.data.msg, sticky: true });
                    }

                }).catch(error => {
                    toast.current.show({ severity: 'error', sticky: true, summary: 'Error', detail: error });
                })
                .finally(() => {
                    // setSelectedFranchiseComplaints(tempComplaints);
                    // getFranchiseComplaints();
                })
            // let index = stocksData.findIndex(a => a.productId === rowData.productId && a.batchNo === rowData.batchNo);
            // if (index > -1) {

            //     let _stocksData = [...stocksData];
            //     let data = _stocksData.splice(index, 1);

            //     setStocksData(_stocksData);

            //     // let grandTotalValue = (_invoiceData && _invoiceData.reduce((a, v) => a + parseFloat(v.total), 0));
            //     // setFooterValue('grandTotal', grandTotalValue.toFixed(2));
            //     // setFooterValue('dueAmount', grandTotalValue.toFixed(2));

            //     toast.current.show({ severity: 'success', detail: "Product Removed ", autoDismiss: true });

            // }
            // else {
            //     toast.current.show({ severity: 'error', sticky: true, detail: "Product Not Removed " });
            // }
        }
        catch {

        }
        finally {
            toast.current.clear();

        }
        // window.location.reload();
    }

    const onNo = () => {
        toast.current.clear();
        return;
    };

    const header = (
        <div className="table-header">
            <div>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" className="globleInput" value={globalFilter} onChange={onChangeGlobal} placeholder="Global Search" />
                </span>
            </div>
        </div>
    );

    const exportCSV = (selectionOnly) => {
        // <exportCsvReport dt={dt}/>
        exportCsvReport.exportCSV(dt)
        // dt.current.exportCSV();
    }

    const exportPdf = () => {
        let fileName="Sales Invoice Report";
        exportPdfReport.exportPdf(exportColumns,invoicesData,fileName);

    }

    const exportExcel = () => {
        let fileName="Sales Invoice Report";
        exportExcelReport.exportExcel(invoicesData,fileName);
    }


    return (
        <div className="AdminStockReturn">
            <ProgressSpinBarControl spinBarVisible={spinnerVisible} />
            <Toast ref={toast} position={'center'} />
            <h3>Invoice List - {franchiseName} 
            <button className='reset' style={{ width: '100px',justifyContent:'end' }} onClick={onBack} type="button">Back</button> </h3>
            <div className="Unit" style={{ minHeight: '890px' }}>
                <div className="box">
                    <div class="field col">
                        <label>From:</label>
                        <input type='date' name='fyDate' className='date' ref={Filter} />
                        <label>To:</label>
                        <input type='date' name='toDate' className='date' ref={Filter} />
                        <button type="button filter" onClick={handleFilter(onFilter)} className='filter'>Load Invoices</button>
                        <button type="button" onClick={onReset} className='reset'>Reset</button>
                    </div>                  
                    {/* <div>
                        
                        Show
                        <select className='dropdown'>
                            <option>50</option>
                            <option>100</option>
                            <option>All</option>
                        </select>
                        entries
                    </div> */}
                </div>

                <div className='box'>
                <div>
                        <button type='button' onClick={() => exportCSV(false)}>CSV</button>
                        <button type='button' onClick={() => exportExcel()}>Excel</button>
                        <button type='button' onClick={() => exportPdf()}>PDF</button>
                        <button className=' button' type="button" onClick={() => onAllPrint()}>All Invoice Print</button>

                    </div>
                    <DataTable ref={dt} value={invoicesData} scrollable scrollHeight='650px' className='dataTable' header={header} filters={filters1} globalFilter={globalFilter}  footerColumnGroup={totalFooter} editMode='row'>
                        <Column field="serialNo" header="Sr No" headerStyle={{ width: '3rem' }}></Column>
                        <Column field="invName" header="Invoice Number"></Column>
                        <Column field="customerName" header="Customer"></Column>
                        <Column field="invDateFormat" header="Invoice Date"></Column>

                        {/* <Column field="customerAddress" header="Address"></Column>
                        <Column field="grandTotal" header="Total Amount"></Column>
                        <Column field="discountAmount" header="Discount"></Column>
                        <Column field="returnAmount" header="Return"></Column> */}
                        <Column field="dueAmount" header="Net Amount"></Column>
                        <Column field="royalty" header="Royalty"></Column>
                        {/* <Column field="status" header="Status"></Column> */}
                        <Column body={buttons} header="Action"></Column>
                    </DataTable>
                </div>
            </div>
        </div>
    );
}
export default SalesInvoice;