import './OutsatndingReport.css';
import React, { useState, useRef } from 'react';
import { useForm } from "react-hook-form";
import { Dropdown } from 'bootstrap';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { useEffect } from 'react';
import GreenFieldService from '../../services/greenFieldService';
import ToolBoxGrid from '../Misc/ToolBoxGrid';
import { Toast } from 'primereact/toast';
import ProgressSpinBarControl from '../Misc/progressSpinBarControl';
import moment from 'moment';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
var saleSum;
var receiptSum;
var totalOutsatndingSum;

function OutStandingReport() {
    const greenFieldService = new GreenFieldService();
    const [date3, setDate3] = useState(null);
    const {
        register: Filter,
        errors: errors1,
        handleSubmit: handleFilter,
        setValue: setFilterValue,
    } = useForm();
    const [columns, setColumns] = useState([{ title: 'Sr No', dataKey: 'serialNo' }, { title: 'Franchise Name', dataKey: 'franchiseName' }, { title: 'Sale', dataKey: 'totalSale' }, { title: 'Receipt', dataKey: 'receipt' }, { title: 'Outstanding Amount', dataKey: 'outstanding' }]);
    const [globalFilter, setGlobalFilter] = useState();
    const [outsatndingData, setOutsatndingData] = useState([]);
    const toast = useRef(null);
    const [spinnerVisible, setSpinnerVisible] = useState(false);



    useEffect(() => {

    }, []);

    var errors = [];

    const filterValidate = (data) => {
        if (!data?.fyDate) {
            errors.push('* From Date is required.', <br />);
        }
        if (!data?.toDate) {
            errors.push('* To Date is required', <br />);
        }
    }

    const onFilter = (filterValue) => {
        filterValidate(filterValue)
        if (errors.length > 0) {
            // eslint-disable-next-line no-lone-blocks
            {
                errors.map(() => {
                    toast.current.show({ severity: 'warn', detail: errors, sticky: true });
                }

                )
            }
            errors = [];
            return;
        }
        setSpinnerVisible(true);
        greenFieldService.GetOutstandingData(filterValue.fyDate, filterValue.toDate).then(data => {
            saleSum = (data.reduce((a, v) => a + parseFloat(v.totalSale), 0));
            receiptSum = (data.reduce((a, v) => a + parseFloat(v.receipt), 0));
            totalOutsatndingSum = (data.reduce((a, v) => a + parseFloat(v.outstanding), 0));
            let srNo = 1;
            data.forEach(a => {
                a.totalSale = a.totalSale.toFixed(2);
                a.receipt = a.receipt.toFixed(2);
                a.outstanding = a.outstanding.toFixed(2);
                a.serialNo = srNo;
                srNo++;
            })
            setOutsatndingData(data);
            setSpinnerVisible(false);
            // setInitialRetunData(data);
        });

        // let data = outsatndingData.filter(a => a.addedDateFormat >= filterValue.fyDate && a.addedDateFormat <= filterValue.toDate);
        // let srNo = 1;
        // data.forEach(d => {
        //     d.totalSale=d.totalSale.toFixed(2);
        //     d.receipt=d.receipt.toFixed(2);
        //     d.outstanding=d.outstanding.toFixed(2);

        //     d.serialNo = srNo;
        //     srNo++;
        // })
        // setOutsatndingData(data);

    }

    const onReset = () => {

        const fields = [
            "fyDate",
            "toDate"
        ];
        fields.forEach((field) => setFilterValue(field, ''));
        setOutsatndingData('');
        saleSum= 0.00;
        receiptSum= 0.00;
        totalOutsatndingSum= 0.00;
    }

    const header = (
        <div className="table-header">
            <div>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" class="globleInput" value={globalFilter} onChange={(e) => setGlobalFilter(e.target.value)} placeholder="Global Search" />
                </span>
            </div>
        </div>
    );

    let totalFooter = <ColumnGroup>
    <Row>
        <Column footer="Totals:" footerStyle={{ textAlign: 'right' }} />
        <Column footer="" />
        <Column footer={parseFloat(saleSum).toFixed(2)} />
        <Column footer={parseFloat(receiptSum).toFixed(2)} />
        <Column footer={parseFloat(totalOutsatndingSum).toFixed(2)} />
    </Row>
    </ColumnGroup>;

    return (
        <div className="OutStanding">
            <ProgressSpinBarControl spinBarVisible={spinnerVisible} />
            <Toast ref={toast} position={'center'} />
            <h3>OutStanding Report</h3>
            <div >
                <div className="box">
                    <div class="field col">
                        <label>From:</label>
                        <input type='date' name='fyDate' className='date' ref={Filter} max={moment().format("YYYY-MM-DD")} />
                        <label>To:</label>
                        <input type='date' name='toDate' className='date' ref={Filter} max={moment().format("YYYY-MM-DD")} />
                        <button type="button filter" className='filter' onClick={handleFilter(onFilter)}>Load Report</button>
                        <button type="button" className='reset' onClick={handleFilter(onReset)}>Reset</button>
                    </div>
                </div>

                <div className='box'>


                    {/* <div>
                        <button type='button'>Copy</button>
                        <button type='button'>CSV</button>
                        <button type='button'>Excel</button>
                        <button type='button'>PDF</button>
                        <button type='button'>Print</button>
                    </div> */}
                    <ToolBoxGrid columns={columns} data={outsatndingData} fileName="Outstanding Report"></ToolBoxGrid>
                    <DataTable value={outsatndingData} header={header} globalFilter={globalFilter} scrollHeight='700px' footerColumnGroup={totalFooter} >
                        <Column field="serialNo" header="Sr No" style={{ width: '5%' }}></Column>
                        <Column field="franchiseName" header="Franchise Name" style={{ width: '40%' }}></Column>
                        <Column field="totalSale" header="Sale" style={{ width: '15%' }}></Column>
                        <Column field="receipt" header="Receipt" style={{ width: '15%' }}></Column>
                        <Column field="outstanding" header="Outstanding Amount" style={{ width: '15%' }}></Column>
                        <Column field="invoiceDateFormat" header="Last Invoice Date" style={{ width: '25%' }}></Column>

                    </DataTable>
                </div>
            </div>

        </div>
    );
}
export default OutStandingReport;