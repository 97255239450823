import './ProductSalesReport.css';
import React, { useState,useEffect,useRef } from 'react';
import { useForm } from "react-hook-form";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import GreenFieldService from '../../services/greenFieldService';
import { Dropdown } from 'primereact/dropdown';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import axios from 'axios';
import { Toast } from 'primereact/toast';
import Config from '../../Config.json';
import { BlockUI } from 'primereact/blockui';
import moment from 'moment';
import authHeader from '../../authHeader';
import { useHistory } from 'react-router-dom';
import ToolBoxGrid from '../Misc/ToolBoxGrid';
import { shouldLockDate } from '../Misc/utilities';

function SalesReturnOfFranchise() {
    let history = useHistory();
    const greenFieldService = new GreenFieldService();
    const dt = useRef(null);
    const toast = useRef(null);


    const [date3, setDate3] = useState(null);
    const {
        register: Filter,
        handleSubmit: handleFilter,
        setValue: setFilterValue,
    } = useForm();
    const [globalFilter, setGlobalFilter] = useState();
    const [filters1, setFilters1] = useState(null);
    const [productData, setProductsData] = useState([]);
    const [initialReturnData, setInitialRetunData] = useState([]);
    const [customersData, setCustomersData] = useState([]);
    const [selectedCustomer,setSelectedCustomer]=useState();
    const franchiseId = localStorage.getItem('franchiseId');
    const [invoicesData,setInvoicesData]=useState();
    const [initialData,setInitialData]=useState();
    const [expandedRows, setExpandedRows] = useState(null);
    const [disable, setDisable] = useState(false);
    const [expandedCount,setExpandedCount]=useState(0);
    const [updatedData, setUpdatedData] = useState([]);
    const [blockedPanel, setBlockedPanel] = useState(false);
    var accessLevel = parseInt(sessionStorage.getItem('accessLevel'));
    var reInvNo;
    const [locked, setIsLocked]=useState(false);
    const [lockDate, setIsLockDate] = useState();
    const [finalLock, setFinalLock] = useState(false); 

    useEffect(() => {
        let d = moment(new Date()).format('YYYY-MM-DD');
            setFilterValue('fyDate',d);
            setFilterValue('toDate',d);

        getCustomersData();

        // if(accessLevel > 1)
        // {
        //   setBlockedPanel(false);
        // }
        // else{
        //     setBlockedPanel(true);
        // }
        setBlockedPanel(true);

    }, []);

    const [toDate, setToDate]=useState();
    function onToDateChange(e){
        setToDate(e.target.value);
//        validateLockDate(e.target.value);
    }

    function validateLockDate(selectedDate) {
        if (locked) {

            if (shouldLockDate(selectedDate, lockDate)) {

                setFinalLock(true);
                toast.current.show({ severity: 'warn', detail: `Franchise is locked till ${lockDate}`, summary: 'Payment Details' });
            }
            else {
                setFinalLock(false);
            }
        }
        else {
            setFinalLock(false);
        }
    }

    let getCustomersData=(()=>{
        greenFieldService.GetCustomerData(franchiseId).then(data => {
            data.forEach(a => {
                a.isKisanCardValue = a.isKisanCard ? "Yes" : "No";
                a.customerName = `${a.name},|${a.district},${a.town}| Kisan Card -${a.isKisanCardValue}|`;

            })
            
            setCustomersData(data);
        });
       
    })


    const onChangeCustomer=(e)=>{
        setSelectedCustomer(e.value);
        getAllInvoiceDetails(e.value.id);
        setExpandedCount(0);
        //  let currentDate = moment(new Date()).format('YYYY-MM-DD');
        // setFooterValue("invoiceDate",currentDate);
    }

    const getAllInvoiceDetails=(customerId)=>{
        greenFieldService.GetInvoiceListOnCustomerId(customerId).then(data => {
            let srNo = 1;
            data.forEach(d => {
                d.invoiceDate= d.invoiceDate.replace('T', ' ');
                d.invoiceDateValue=new Date(d.invoiceDate);
                d.serialNo = srNo;
                srNo++;

                let sNo=1;
                d.salesInvoiceProductDetail.forEach(a=>{
                    a.netAmount=a.dueAmount.toFixed(2);
                    a.gstAmount=a.gstAmount.toFixed(2);
                    a.grandTotal = (parseFloat(a.netAmount) - a.discountAmount + parseFloat (a.gstAmount)).toFixed(2) ;


                    a.serialNo=sNo;
                    sNo++;
                })

            })
            // netAmountSum = (data.reduce((a, v) => a + parseFloat(v.dueAmount), 0));
            setInvoicesData(data);
            setInitialData(data);
        });
    }
    function dateTime(dateValue){
        var x = new Date(dateValue);// x is now a date object
        x.setHours(0,0,0,0); 
        return x;
       }

    const onFilter = (filterValue) => {
        let fromdate=dateTime(filterValue.fyDate)  ;
        // fromdate.setDate(fromdate.getDate()-1);
        let toDate=dateTime(filterValue.toDate);

        let data = initialData.filter(a => a.invoiceDateValue >= fromdate && a.invoiceDateValue <= toDate);
        let srNo = 1;
        data.forEach(d => {
            d.serialNo = srNo;
            srNo++;
        })
        setInvoicesData(data);
       validateLockDate(toDate);

    }

    const PrintPage = () => {
        history.push(`/ReInvoicePrint/${selectedCustomer.id}/${reInvNo}/${franchiseId}`);


    }

    const onReturnProduct = (data) => {
        if(updatedData.length > 0)
        {
        let ReturnAmountTotal=(updatedData.reduce((a, v) => a + parseFloat(v.returnAmount), 0));
        updatedData[0].returnAmountSum=ReturnAmountTotal;


        console.warn(data);
        return axios.post(`${Config.apiUrl}/SalesReturn/InvoiceReturnEntry`, updatedData, { headers: authHeader() })
            .then(response => {
                if (response.data.success) {
                    toast.current.show({ severity: 'success', detail: 'Product Returned ', autoDismiss:true });
                    reInvNo = parseInt(response.data.invNo);
                    PrintPage();
                    getAllInvoiceDetails(selectedCustomer.id);
                    setExpandedCount(0);
                }
                else {
                    toast.current.show({ severity: 'error', detail: response.data.msg, sticky: true });
                }
            }).catch(error => {
                toast.current.show({ severity: 'error', sticky: true, summary: 'Error', detail: error.message });
            })
            .finally(() => {
              
            })
        }
        else{
            toast.current.show({ severity: 'warn', detail: "No data To Return,please Enter quantity then click on Return", sticky: true });
            return;
        }

    }

    const onReset = () => {

        const fields = [
            "fyDate",
            "toDate"
        ];
        fields.forEach((field) => setFilterValue(field, ''));
        getAllInvoiceDetails(selectedCustomer.id);
    }


    const QuantityInput = (data) => (
        <div className="table-header">
            <div>
                <input id="quantity" type="text" name="quantity"  value={data.value} onChange={(e) => onChangeQuantity(data,e)} style={{ width: '100px', height: '30px' }}></input>
                {/* <InputText type="text" value={data.value} onChange={(e) => data.editorCallback(e.target.value)} /> */}

            </div>
        </div>
    );
    var returnAmount;
    var quantity;
    var totalReturnAmount;

    const onChangeQuantity =(data,e)=>{
        data.editorCallback(e.target.value);
        quantity=e.target.value;
        if(e.target.value > data.rowData.quantity - data.rowData.returnedQuantity){
            toast.current.show({ severity: 'warn', detail: 'Return Quantity is greater than sold Quantity ', sticky:true });
            data.editorCallback(0);
            returnAmount=0;
            data.rowData.returnAmount=0;

            return;
        }
        else{
        let returnAmountCal= (data.rowData.productAmount) * (quantity === '' ? 0 :quantity);
        // data.rowData.returnAmount=returnAmountCal.toFixed(2);
         returnAmount= returnAmountCal.toFixed(2);
        
        }
        if(quantity > 0){
            data.rowData.state="modified";
        }
        
        // let returnGstCal=data.rowData.gstAmount * quantity;
        // returnGst=returnGstCal.toFixed(2);

        // let totalReturnCal=returnAmountCal + returnGstCal;
        // totalReturnAmount=totalReturnCal.toFixed(2);
    }

    const cellEditor = (options) => {
            return QuantityInput(options)
        
    }

    let totalFooter = <ColumnGroup>
    <Row>
        <Column footer="Totals:" footerStyle={{ textAlign: 'right' }} />
        <Column footer='' />
        <Column footer='' />
        <Column footer='' />
        <Column footer='' />
        <Column footer='' />
        <Column footer='' />
        <Column footer='' />
        <Column footer='' />

    </Row>
    </ColumnGroup>;

const buttons =
<ColumnGroup>
    <Row>
        <Column footer="" footerStyle={{ textAlign: 'right' }} />
        <Column footer='' />
        <Column footer='' />
        <Column footer='' />
        <Column footer={''} />
        <Column footer='' />
        <Column footer='' />
        <Column footer='' />
        <Column footer='' />
        <Column footer='' />

        <Column footer=<button className='reset' hidden={disable} type="button" onClick={() => onReturnProduct()}>Return</button>
        />
        
    </Row>
</ColumnGroup>;

const onRowGroupExpand = (event) => {
    let count=expandedCount+1;
    setExpandedCount(count);
    
    if(count > 1)
    {
        setDisable(true);
    }
}

const onRowGroupCollapse = (event) => {
    let count=expandedCount - 1;
    setExpandedCount(count);
    if(count <= 1)
    {
        setDisable(false);
    }
}

const rowExpansionTemplate = (data) => {
    return (
        <div className="orders-subtable">
            {/* <h5>Orders for {data.name}</h5> */}
            <DataTable value={data.salesInvoiceProductDetail}  responsiveLayout="scroll" footerColumnGroup={!disable ? buttons : ''}>
                <Column field="serialNo" header="Sr.No"></Column>
                <Column field="productName" header="Prodcut Name"></Column>
                <Column field="batchNo" header="Batch No"></Column>
                <Column field="quantity" header="Quantity"></Column>
                <Column field="netAmount" header="Net Amount"></Column>  
                <Column field="gstAmount" header="Gst"></Column>  
                <Column field="discountAmount" header="Discount"></Column> 
                <Column field="grandTotal" header="Total Amount"></Column>
                <Column field="returnedQuantity" header="Total Returned"></Column>
                <Column field="returnQuantity" header="Return Quantity" editor={(options) => cellEditor(options) } onCellEditComplete={onCellEditComplete}></Column> 
                <Column field="returnAmount" header="Return Amount"  ></Column> 

            </DataTable>
            
        </div>
    );
}

const onCellEditComplete = (e) => {
    let { rowData, newValue, field, originalEvent: event } = e;
    if(newValue)
    {
        rowData.returnAmount=rowData.productAmount * quantity;
        rowData.returnAmount=rowData.returnAmount;
    }
    if (field === 'returnQuantity') {
        if(newValue === undefined || newValue === '')
        {
            rowData[field] = '';
            rowData['returnAmount']='';

        }
        else        
        {
            // if(rowData.returnAmount === '')
            // {
            //      returnAmount=rowData.productAmount * rowData.returnQuantity;
            //     // returnAmount= returnAmountCal.toFixed(2);

            // }
            // else{
            rowData[field] = parseInt(newValue === '' ? 0 :newValue);
            if(newValue === 0)
            {
                rowData['returnAmount']=parseFloat(0);

            }
            else{
                rowData['returnAmount']=parseFloat(rowData.returnAmount ? rowData.returnAmount : returnAmount);

            }

            // }


        }
        if(rowData.returnQuantity > 0)
        {
            rowData.status="Return";
        }
    }
    // rowData['returnGst']=returnGst;
    // rowData['totalReturnAmount']=totalReturnAmount;
    if(rowData.status === "Return")
    {
    let duplicate=updatedData.find(a=>a.productId === rowData.productId && a.batchNo === rowData.batchNo);
    if(!duplicate)
    {
    let _updatedData = [...updatedData];
        let _rowData = { invNo: rowData.invNo, productId: rowData.productId,franchiseId:rowData.franchiseId,customerId:selectedCustomer.id,hsnCode:rowData.hsnCode,gstPercentage:rowData.gst,
                         batchNo: rowData.batchNo ,returnQuantity :rowData.returnQuantity,returnAmount:rowData.returnAmount,
                         returnDiscount:(rowData.discountAmount/rowData.quantity)*rowData.returnQuantity,actualReturnDiscount:(rowData.actualDiscount/rowData.quantity)*rowData.returnQuantity,
                         returnGst:(rowData.gstAmount/rowData.quantity)*rowData.returnQuantity
                        ,status:rowData.status,returnRoyalty:(rowData.royalty/rowData.quantity)};
        _updatedData.push(_rowData);
        setUpdatedData(_updatedData);
    }
    }
}

const allowExpansion = (rowData) => {
    return rowData.orders.length > 0;
};
    const header = (
        <div className="table-header">
            <div>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" className="globleInput" value={globalFilter} onChange={(e) => setGlobalFilter(e.target.value)} placeholder="Global Search" />
                </span>
            </div>
        </div>
    );

    const cols = [
        { field: 'serialNo', header: 'Sr.No' },
        { field: 'invNo', header: 'Invoice Number' },
        { field: 'invDateFormat', header: 'Invoice Date' },
        { field : "quantity",header:"Quantity" },
        { field :"grandTotal" , header: "Total Amount" },
        { field : "discountAmount",header :"Discount" },
        // { field: 'discountAmount', header: 'Discount' },
        // { field: 'returnAmount', header: 'Return' },
        
        { field :"returnQuantity",header:"Return"},
        { field :"dueAmount", header:"Net Amount"},


    ];
    const exportColumns = cols.map(col => ({ title: col.header, dataKey: col.field }));

    return (
        // <BlockUI blocked={blockedPanel}>

        <div className="ProductSalesReport">
            <h3>Sales Return</h3>
            <div className="Unit" style={{ minHeight: '1029px' }}>
            <Toast ref={toast} position={'center'} />
                <div className="box">
                <div>
                    <label style={{ margin: '10px' }}>Customer Name<span style={{ color: 'red' }}>*</span></label>
                    <Dropdown className='Dropdown' name="categoryName" value={selectedCustomer} options={customersData} id="category" optionLabel="customerName"  onChange={onChangeCustomer} filter showClear placeholder="Select Customer Name" />
                </div>
                    <div className="field col">
                        {/* <label>Product:</label> */}
                        {/* <select id="Payment Mode" className='select'>
                            <option hidden value="">Select Product</option>
                            
                        </select> */}
                        <label>From:</label>
                        <input type='date' name='fyDate' className='date' ref={Filter} />
                        <label>To:</label>
                        <input type='date' onChange={onToDateChange} name='toDate' className='date' ref={Filter} />
                        <button type="button filter" className='filter' onClick={handleFilter(onFilter)}>Filter</button>
                        <button type="button" className='reset' onClick={onReset}>Reset</button>
                    </div>
                </div>

                <div className='box'>
                <div>
                <ToolBoxGrid columns={exportColumns} data={invoicesData} fileName="Franchise Sales Return"></ToolBoxGrid>

                    </div>
                    <DataTable ref={dt} value={invoicesData} disabled={finalLock} className='dataTable' header={header} filters={filters1} globalFilter={globalFilter}  footerColumnGroup={totalFooter} editMode='row'
                     rowExpansionTemplate={rowExpansionTemplate} expandedRows={expandedRows} onRowExpand={onRowGroupExpand} onRowCollapse={onRowGroupCollapse}  onRowToggle={(e) => setExpandedRows(e.data)} >
                        <Column expander={allowExpansion} style={{ width: '3em' }} />
                        <Column field="serialNo" header="Sr No"></Column>
                        <Column field="invNo" header="Invoice Number"></Column>
                        {/* <Column field="customerName" header="Customer"></Column> */}
                        <Column field="invDateFormat" header="Invoice Date"></Column>
                        <Column field="quantity" header="Quantity"></Column>
                        <Column field="grandTotal" header="Total Amount"></Column>
                        <Column field="discountAmount" header="Discount"></Column>
                        <Column field="returnQuantity" header="Return"></Column> 
                        <Column field="dueAmount" header="Net Amount"></Column>
                        {/* <Column field="status" header="Status"></Column> */}
                        {/* <Column body={buttons} header="Action"></Column> */}
                    </DataTable>
                </div>
            </div>

        </div>
        
    );
}
export default SalesReturnOfFranchise;
